/*
This document is used to hold functions that may be used by multiple components, or complex functions pertaining to submitting problems via the dashboard.

Functions:
  • checkConfigType() - check if type arg exists in the solver configs object
  • getAllowedValues() - return "allowed_values" from solver configs
  • hideConfig() - return boolean value for whether the input should be hidden
  • serializeMolecule() - build molecule object with custom values
  • formatEnumVal() - extract the enum value from the stingified value
  • formatBasicPyToJS() - return js value from pythonic True, False & None
  • formatBasicJSToPy() - return python value from js true, false & null
  • formatValToJS() - return js value from pythonified params
  • buildSerializedSolver() - build solver object used to submit problem to the apiserver
  • determineMolValue() - determine the molecule input value depending on solver constraints
  • checkAtomConstraints() - get array of atom input disallowed elements
  • getAllowedSystemTypes() - get array of system types allowed for the solver pipeline
  • getAllowedMolValue() - get the value from the molecule constraints object key
  • hideContainer() - hide input containers that do not have any configurable inputs
  
Notes
  • Solver "pipeline" objects are pythonified
  • "defaultParams" object is jsonified
  • "serializedSolver" object is jsonified
*/

import { checkListForItem } from '../../../hoc/CommonFunctions';
import { isFloat, isInt, isList, objectFormat } from '../../../hoc/FormFunctions';
import { basic_python_vals } from '../../../hoc/submitDataFunctions';

export function checkConfigType(configs, solver_name, param_key, type) {
  /*
  Return boolean value if type arg is found in parameter config_types constraints
  */
  if (
    configs &&
    configs[solver_name] &&
    configs[solver_name].configurable &&
    configs[solver_name].configurable[param_key] &&
    configs[solver_name].configurable[param_key].config_types &&
    configs[solver_name].configurable[param_key].config_types.includes(type)
  ) {
    return true;
  } else return false;
}

export function getAllowedValues(configs, solver_name, param_key) {
  /*
  Return allowed_values list if it exists in parameter constraints
  */
  if (
    configs &&
    configs[solver_name] &&
    configs[solver_name].configurable &&
    configs[solver_name].configurable[param_key] &&
    configs[solver_name].configurable[param_key].constraints &&
    configs[solver_name].configurable[param_key].constraints.allowed_values
  ) {
    return configs[solver_name].configurable[param_key].constraints.allowed_values;
  } else return '';
}

export function hideConfig(configs, solver_name, param_key) {
  /*
  Check if solver constraints only allow one single input option
  */
  if (
    configs &&
    configs[solver_name] &&
    configs[solver_name].configurable &&
    configs[solver_name].configurable[param_key] &&
    configs[solver_name].configurable[param_key].config_types &&
    configs[solver_name].configurable[param_key].config_types.length == 1 &&
    configs[solver_name].configurable[param_key].config_types[0] === 'NoneType'
  ) {
    // Config types only allow "NoneType", which means parameter has no other option but 'None'
    return true;
  } else return false;
}

export function translateParamKey(param_key) {
  /*
  Translate parameter keys to human readable label
  */
  const translations = {
    opt_tol: 'Optimization Tolerance',
  };

  if (translations[param_key]) {
    return translations[param_key];
  } else return param_key;
}

export function serializeMolecule(moldata, formatted_atom, fragment_type = 'basic') {
  /*
  Return structured molecule json object
  */
  let formatted_frozen_orbitals = formatBasicPyToJS(moldata.frozen_orbitals);
  if (
    objectFormat.test(formatted_frozen_orbitals) ||
    isList.test(formatted_frozen_orbitals) ||
    isInt.test(formatted_frozen_orbitals) ||
    isFloat.test(formatted_frozen_orbitals)
  ) {
    formatted_frozen_orbitals = JSON.parse(formatted_frozen_orbitals);
  }

  if (moldata.atom_input_type === 'smiles') {
    delete moldata.atom_input_type;
    moldata.atom = null;
    return {
      fragment_type: 'organic_crystal',
      frozen_orbitals: null,
      smiles_str: formatted_atom,
      options: {},
      mol_data: moldata,
      mean_field_handle: null,
      mean_field_serial: null,
    };
  } else if (moldata.atom_input_type === 'file-upload') {
    return {
      fragment_type: 'basic',
      frozen_orbitals: null,
      mean_field_handle: null,
      mean_field_serial: null,
      mol_data: {},
      options: null,
    };
  } else {
    let molecule = {
      fragment_type: fragment_type,
      frozen_orbitals: formatted_frozen_orbitals,
      options: {},
      mol_data: {
        atom: formatted_atom,
        basis: moldata.basis,
        charge: JSON.parse(moldata.charge),
        spin: JSON.parse(moldata.spin),
        cart: formatBasicPyToJS(moldata.cart),
        unit: moldata.unit,
        verbose: 4,
      },
      mean_field_handle: null,
      mean_field_serial: null,
    };

    if (moldata.mean_field_handle) {
      molecule.mean_field_handle = moldata.mean_field_handle;
    }

    return molecule;
  }
}

function formatEnumVal(param_key, enum_key, configs, solver_name) {
  /*
  Return an integer from enum key.
  */

  let enum_val = '';
  if (configs && solver_name) {
    const options = configs[solver_name].configurable[param_key].options;
    options.forEach(([key, val]) => {
      if (key === enum_key) {
        enum_val = val;
      }
    });
  } else {
    enum_val = enum_key;
  }
  return enum_val;
}

export function formatBasicPyToJS(val) {
  // Convert python to js val
  if (val === 'None') {
    return null;
  } else if (val === 'False') {
    return false;
  } else if (val === 'True') {
    return true;
  } else return val;
}

export function formatBasicJSToPy(val) {
  // Convert python to js val
  if (val === null) {
    return 'None';
  } else if (val === false) {
    return 'False';
  } else if (val === true) {
    return 'True';
  } else return val;
}

export function formatValToJS([key, val], configs = {}, solver_name = '') {
  /*
  Return a formatted js value based on the 'config' argument provided.

  Arg examples:
  ['method_of_truncation', 'NONE']
  ['truncation_order', 1]
  */
  if (basic_python_vals.includes(val)) {
    const formatted_val = formatBasicPyToJS(val);
    return formatted_val;
  } else if (
    configs &&
    configs[solver_name] &&
    configs[solver_name].configurable &&
    configs[solver_name].configurable[key] &&
    configs[solver_name].configurable[key].config_types &&
    configs[solver_name].configurable[key].config_types.includes('float')
  ) {
    return parseFloat(val);
  } else if (
    configs &&
    configs[solver_name] &&
    configs[solver_name].configurable &&
    configs[solver_name].configurable[key] &&
    configs[solver_name].configurable[key].config_types &&
    configs[solver_name].configurable[key].config_types.includes('int')
  ) {
    return parseInt(val);
  } else if (
    configs &&
    configs[solver_name] &&
    configs[solver_name].configurable &&
    configs[solver_name].configurable[key] &&
    configs[solver_name].configurable[key].config_types &&
    configs[solver_name].configurable[key].config_types.includes('enums')
  ) {
    return formatEnumVal(key, val, configs, solver_name);
  } else if (
    configs &&
    configs[solver_name] &&
    configs[solver_name].configurable &&
    configs[solver_name].configurable[key] &&
    configs[solver_name].configurable[key].config_types &&
    configs[solver_name].configurable[key].config_types.includes('dict')
  ) {
    try {
      return JSON.parse(val);
    } catch {
      return val;
    }
  } else return val;
}

export function buildSerializedSolver(configs, pipeline) {
  /*
  Return a nested serialized solver object that matches the solver parameters dict send to the apiserver via the client library.
  */
  try {
    // Construct parameters in array
    let solver_params_arr = []; // target array
    let solvers = [];
    pipeline.forEach(solver => {
      const blacklist = ['LinearResponse'];
      if (!blacklist.includes(solver.solver_name)) {
        // -- determine solvers in pipeline
        solvers.push(solver.solver_name);
        // -- solver object/dict built from client library
        let default_sol_params = configs[solver.solver_name].solver_parameters;
        // -- user configurable params
        let custom_params = Object.entries(solver.params);
        // -- override params with user customized params
        custom_params.forEach(param => {
          let formatted_val = formatValToJS(param, configs, solver.solver_name);
          default_sol_params.solver_params[param[0]] = formatted_val;
        });
        // -- remove presets param if not found
        if (!solver.params.presets && default_sol_params.solver_params.presets) {
          delete default_sol_params.solver_params.presets;
        }
        // -- add object to target array
        solver_params_arr.push(default_sol_params);
      }
    });

    // Build DFT: grids, nlcgrids
    if (solvers.includes('DFT')) {
      solver_params_arr.forEach((solver, i) => {
        if (solver.next_solver === 'DFT') {
          // Add "Grids" and "NLCGrids" to DFT solver
          solver_params_arr[i].solver_params.grids = solver_params_arr[i + 1];
          solver_params_arr[i].solver_params.nlcgrids = solver_params_arr[i + 2];

          // Remove "DFTGrid" param objects
          solver_params_arr.splice(i + 1, 2);
        }
      });
    }

    // Build CSP
    if (solvers.includes('OrganicCrystalOptimizer')) {
      // Update "organic_crystal_generator" and "conformer_generator" keys
      solver_params_arr[0]['solver_params']['organic_crystal_generator'] = solver_params_arr[1];
      solver_params_arr[0]['solver_params']['conformer_generator'] = solver_params_arr[2];
      // Make OCG ConformerGenerator same as OCO's ConformerGenerator
      solver_params_arr[0]['solver_params']['organic_crystal_generator']['solver_params']['conformer_generator'] =
        solver_params_arr[2];

      // Remove "OrganicCrystalGenerator" and "ConformerGenerator" param objects
      solver_params_arr.splice(1, 2);
    }

    // Build GNINA
    if (solvers.includes('GNINA')) {
      // Clone GNINA misc solver parameters
      const copied_params = structuredClone(solver_params_arr[0]['solver_params']);

      // Reconstruct solver parameters
      const gnina_solver_params = {};
      gnina_solver_params.misc_options = copied_params;
      gnina_solver_params.box_options = solver_params_arr[1].solver_params;
      gnina_solver_params.flex_options = solver_params_arr[2].solver_params;
      gnina_solver_params.scoring_options = solver_params_arr[3].solver_params;
      gnina_solver_params.cnn_options = solver_params_arr[4].solver_params;

      // Replace solver_params_arr[0] solver params
      solver_params_arr[0].solver_params = gnina_solver_params;
      // Only keep GNINA solver params
      solver_params_arr.splice(1, 2);
    }

    // Nest solver parameters
    if (solver_params_arr[0].next_solver !== 'GNINA') {
      let i = solver_params_arr.length - 1;
      while (i > 0) {
        if (solver_params_arr[i - 1].next_solver !== 'DFT') {
          solver_params_arr[i - 1].solver_params.solver = solver_params_arr[i];
        }
        i--;
      }
    }

    // Return Built Solver Parameters Object
    return solver_params_arr[0];
  } catch (e) {
    console.warn(e);
  }
}

export function determineMolValue(current_val, constants, solver_name, key) {
  /*
  Check if the current value is in the constraints key. 
  
  Return the current_val if it is allowed, otherwise return the first item in the constraints array.

  Constraints pulled from the "/api/solver_constants" endpoint and stored in solverConstants object in UserData.jsx
  */

  // Rename SNO
  if (solver_name === 'SNO') {
    solver_name = 'SNOGVBPP';
  }
  if (
    constants &&
    constants.solver_mol_constraints &&
    constants.solver_mol_constraints[solver_name] &&
    constants.solver_mol_constraints[solver_name][key] !== undefined
  ) {
    const item = constants.solver_mol_constraints[solver_name][key];
    // If item is a list
    if (Array.isArray(item)) {
      if (item.includes(current_val)) {
        return current_val;
      } else {
        return item[0];
      }
    }
    // If item is a string
    else if (typeof item === 'string') {
      return item;
    }
    // If item is boolean
    else if (typeof item === 'boolean') {
      return formatBasicJSToPy(item);
    }
    // Otherwise return current_val
    else return current_val;
  }
  // Return current_val if "key" is not found within the constraints object
  else return current_val;
}

export function checkAtomConstraints(current_atom, constants, solver_name) {
  /*
  Return an array of elements in the current_atom string that is not allowed for the specified solver. 
  */

  // Rename SNO
  if (solver_name === 'SNO') {
    solver_name = 'SNOGVBPP';
  }
  var not_allowed = [];

  if (
    constants &&
    constants.solver_mol_constraints &&
    constants.solver_mol_constraints[solver_name] &&
    constants.solver_mol_constraints[solver_name].allowed_atoms
  ) {
    const allowed_atoms_obj = constants.solver_mol_constraints[solver_name].allowed_atoms;
    const current_atoms = current_atom.match(/[a-zA-Z]+/g); // array

    // Check if atoms are allowed
    current_atoms.forEach(atom => {
      // Atom not in allowed list
      if (!allowed_atoms_obj[atom]) {
        not_allowed.push(atom);
      }
    });
  }

  // Return not_allowed array
  return not_allowed;
}

export function getAllowedSystemTypes(solverConstants, solverPipeline) {
  /*
  Return an array of allowed molecule system input types for the specified solver pipeline and the name of the determining solver. 
  */
  let solver_name =
    solverPipeline && solverPipeline[0] && solverPipeline[0].solver_name ? solverPipeline[0].solver_name : 'none';

  // If PD, use problem reduction constraints (FNO/SNO)
  if (solver_name === 'IncrementalDecomposition') {
    solver_name =
      solverPipeline && solverPipeline[1] && solverPipeline[1].solver_name ? solverPipeline[1].solver_name : 'none';
  }

  // Rename SNO
  if (solver_name === 'SNO') {
    solver_name = 'SNOGVBPP';
  }

  const allowed_system_type =
    solverConstants &&
    solverConstants.solver_mol_constraints &&
    solverConstants.solver_mol_constraints[solver_name] &&
    solverConstants.solver_mol_constraints[solver_name].allowed_system_type
      ? solverConstants.solver_mol_constraints[solver_name].allowed_system_type
      : ['xyz'];

  return { allowed_system_type, solver_name };
}

export function getAllowedMolValue(constants, solver_name, key) {
  /*
  Return value for solver_mol_constraint key if it exists within the constants.solver_mol_constraints[solver_name] object, otherwise return false
  */

  // Rename SNO
  if (solver_name === 'SNO') {
    solver_name = 'SNOGVBPP';
  }
  if (
    constants &&
    constants.solver_mol_constraints &&
    constants.solver_mol_constraints[solver_name] &&
    constants.solver_mol_constraints[solver_name][key] !== undefined
  ) {
    return constants.solver_mol_constraints[solver_name][key];
  } else return 'not_found';
}

export function hideContainer(solver_name) {
  /*
  Hide parent container of solver params if no customizable options available
  */
  const container = document.getElementById(`${solver_name}_container`);
  const inputs_el = document.getElementById(`${solver_name}_inputs`);

  if (inputs_el !== null && inputs_el.innerHTML === '') {
    container.style.display = 'none';
  }
}

export function formatSolverName(name) {
  /*
  Format solver name to a more legible format.
  */
  const dictionary = {
    OrganicCrystalOptimizer: 'Organic Crystal Optimizer',
    OrganicCrystalGenerator: 'Organic Crystal Generator',
    ConformerGenerator: 'Conformer Generator',
    GeometryOptimizer: 'Geometry Optimizer',
    HartreeFock: 'Hartree Fock',
    IncrementalDecomposition: 'Incremental Decomposition',
    ANI1x: 'ANI-1x',
    ANI2x: 'ANI-2x',
    ANI1ccx: 'ANI-1ccx',
    NLCGrids: 'NLC Grids',
  };
  return dictionary[name] ? dictionary[name] : name;
}

export function buildSolverMenu(disallowed_solvers) {
  /*
  Build an array for the solver type dropdown menu.
  */
  const solvers = [
    ['ani', 'ANI'],
    ['dft', 'DFT'],
    ['aqfep', 'Free Energy Perturbation', 'AQFEP'],
    ['geoopt', 'Geometry Optimizer', 'GeometryOptimizer'],
    ['gnina', 'Docking - GNINA', 'GNINA'],
    ['gvb', 'GVB'],
    ['hartreefock', 'Hartree Fock', 'HartreeFock'],
    ['ifci', 'iFCI', 'IncrementalDecomposition', 'SNO'],
    ['csp', 'Organic Crystal Optimizer', 'OrganicCrystalOptimizer'],
  ];

  let menu = [];
  solvers.forEach(solver_arr => {
    // Add solver to menu if it is not disallowed in guardrails
    try {
      if (!solver_arr.some(item => checkListForItem(item, disallowed_solvers))) {
        menu.push([solver_arr[0], solver_arr[1]]);
      }
    } catch (e) {
      console.warn(e);
    }
  });
  return menu;
}
