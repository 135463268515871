import React from 'react';
import { Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

// Components
import { LoadingBlock } from '../../../components/loading/Loading';
import { QErrorMessage } from '../../../components/notifications/Notifications';

const TransitionWrapper = props => {
  return (
    <React.Fragment>
      <div id='TransitionWrapper'>
        {!props.isLoaded ? (
          <LoadingBlock />
        ) : (
          <React.Fragment>
            {props.error ? (
              <div className='error-wrapper my-3 px-3'>
                <QErrorMessage text={<span className='bold'>{props.error.message}</span>} />
              </div>
            ) : (
              <TransitionGroup component={null} appear={true}>
                <CSSTransition
                  key={props.location.pathname}
                  classNames='sub-section-tab'
                  timeout={{
                    enter: 1600,
                    exit: 600
                  }}>
                  <Switch location={props.location}>{props.children}</Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

TransitionWrapper.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  error: PropTypes.object,
  location: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired
};

export default TransitionWrapper;
