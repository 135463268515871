import React, { useState, useEffect } from 'react';

// Components
import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
import { QSuccessMessage, QErrorMessage } from '../../../components/notifications/Notifications';
import { LoadingFilled, LoadingBlock } from '../../../components/loading/Loading';
import Subnav, { SubnavLink } from '../../../components/subnav/Subnav';
import Transactions from './Transactions';
import CreditPools from './CreditPools';

const Credits = props => {
  // Init
  const { fetchData } = useAuth();
  const { user, currentOrg, setUpdatingBalance } = useUserData();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Credits Promos
  const [fetchingPromo, setFetchingPromo] = useState(null);
  const [promoStatus, setPromoStatus] = useState(null);
  const [promoError, setPromoError] = useState(null);

  useEffect(() => {
    // Fetch promo status
    if (window.location.href.includes('promo=')) {
      let promo_partial = window.location.href.split('promo=')[1];
      let promo = promo_partial.split('#')[0];
      setFetchingPromo(true);

      fetchData(signal, '/api/grant-promo-credits?promo=' + promo, {}, 'POST', response => {
        setFetchingPromo(false);
        // if error is returned
        if (response.status === 'error') {
          setPromoError(response.error);
        }
        // if data is returned
        if (response.status === 'success') {
          setUpdatingBalance(true);
          setPromoStatus(response.data.result);
        }
      });
    }

    return () => {
      abortController.abort();
    };
  }, []);

  const promoMessages = {
    granted: 'Promotional credits have been applied to your account!',
    already_claimed: 'Promotional credits have already previously been applied to your account.',
    promo_not_found: 'Sorry, the promotion cannot be found or has already ended.',
  };

  if (currentOrg !== null) {
    return (
      <React.Fragment>
        {/* Sub Nav */}
        <Subnav>
          <SubnavLink
            link='/account/credits'
            label='Credit Transactions'
            className={window.location.href.includes('/credits') ? 'active' : ''}
          />
          <SubnavLink
            link='/account/credit-pools'
            label='Credit Pools'
            className={window.location.href.includes('/credit-pools') ? 'active' : ''}
          />
        </Subnav>
        {/* Fetching Promo */}
        {fetchingPromo ? <LoadingBlock /> : ''}
        {/* Promo Credits Message */}
        {promoError && <QErrorMessage className='mx-4 mt-4 mb-2' text={promoError.message} />}
        {promoStatus && promoStatus === 'promo_not_found' ? (
          <QErrorMessage className='mx-4 mt-4 mb-2' text={promoMessages[promoStatus]} />
        ) : (
          <React.Fragment>
            {promoStatus && <QSuccessMessage className='mx-4 mt-4 mb-2' text={promoMessages[promoStatus]} />}
          </React.Fragment>
        )}
        {window.location.href.includes('/credits') && (
          <Transactions user={user} currentOrg={currentOrg} promoStatus={promoStatus} />
        )}
        {window.location.href.includes('/credit-pools') && <CreditPools user={user} currentOrg={currentOrg} />}
      </React.Fragment>
    );
  } else return <LoadingFilled />;
};

export default Credits;
