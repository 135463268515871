import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useUserData } from '../../../hoc/UserData';
import { getAllowedMolValue } from '../functions/SubmitFunctions';
import { QAutocomplete } from '../../../components/form/FormElements';

const BasisSet = props => {
  const { solverConstants } = useUserData();
  const [basisOptions, setBasisOptions] = useState(['sto-3g', 'cc-pvdz', '631g*']);
  const [inputValue, setInputValue] = useState(props.basisSelected);

  useEffect(() => {
    const allowed_basis_sets = getAllowedMolValue(solverConstants, props.solverName, 'allowed_basis_sets');
    if (allowed_basis_sets !== 'not_found') {
      const new_basis_set = [];
      allowed_basis_sets.forEach(item => {
        new_basis_set.push([item, item]);
      });
      setBasisOptions(new_basis_set);
    }
  }, []);

  return (
    <div id='BasisSet' className='row d-flex align-items-center inline-form-group w-100'>
      {props.basisSelected ? (
        <React.Fragment>
          <label className='col-12 col-md-3 pr-3 mb-0 mb-md-3 label-sm dark'>Basis</label>
          <QAutocomplete
            id='basis-input'
            className='col-12 col-md-9 w-100 mb-3 dark'
            data-testid='basis-input'
            value={props.basisSelected} // Applied val
            onChange={(e, new_val) => props.updateBasisSet(new_val)} // Applied val
            inputValue={inputValue} // Search val
            onInputChange={(e, new_val) => setInputValue(new_val)} // Search val
            options={basisOptions}
            getOptionLabel={option => option}
            disableClearable={true}
          />
        </React.Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

BasisSet.propTypes = {
  basisSelected: PropTypes.string.isRequired,
  updateBasisSet: PropTypes.func.isRequired,
  solverName: PropTypes.string.isRequired,
};

export default BasisSet;
