import { MolFileTypes, RemoteFilesObject } from '../../../models/molecules';
import { SolverConfigType, SolverParmetersObject } from '../../../models/solvers';

function addFilesToGNINA(
  serialized_solver: SolverParmetersObject,
  type: MolFileTypes,
  files: string[]
): SolverParmetersObject {
  /*
  Add files specific to the GNINA solver parameters object

  serialized_solver = solver object with parameters
  type = file type to identify where the file should be added
  files = the remote file(s) path to add to the solver object

  Returns a solver object with remote files added.
  */
  const f = files.length > 1 ? files : files[0];

  if (serialized_solver.solver_params) {
    // Box Options
    if (type === 'gnina_autobox_ligand' && serialized_solver.solver_params.box_options) {
      serialized_solver.solver_params.box_options['autobox_ligand'] = f;
    }

    // Flex Options
    if (serialized_solver.solver_params.flex_options) {
      if (type === 'gnina_flex') {
        serialized_solver.solver_params.flex_options['flex'] = f;
      }
      if (type === 'gnina_flexdist_ligand') {
        serialized_solver.solver_params.flex_options['flexdist_ligand'] = f;
      }
    }
  }
  return serialized_solver;
}

export function addFilesToSolver(
  serialized_solver: SolverParmetersObject,
  remote_files: RemoteFilesObject,
  solver_mol_constraints: any
) {
  /*
  Add files or list of files to solver params.

  serialized_solver = solver object with parameters
  remote_files = object with specific keys to map to solver parameters

  Returns a solver object with remote files added.

  TODO: 'combo' currently does not map to any real solver function
  */

  const solver_name = serialized_solver.next_solver;

  // If solver allows file-upload
  if (
    solver_mol_constraints &&
    solver_mol_constraints[solver_name] &&
    solver_mol_constraints[solver_name].allowed_system_type &&
    solver_mol_constraints[solver_name].allowed_system_type.includes('file-upload')
  ) {
    // Map for file param key to translate to solver specific key name
    const solver_file_key_map = {
      AQFEP: {
        host: 'hosts',
        guest: 'guest',
      },
      GNINA: {
        host: 'receptor',
        guest: 'ligand',
      },
    };

    // Construct a file object for host and guest files
    let file_obj = {};

    // Add each file to their appropriate place in the solver object
    const files_arr = Object.entries(remote_files);
    files_arr.forEach(([type, files]) => {
      const f = files.length > 1 ? files : files[0]; // Convert list of 1 to string
      const file_key = solver_file_key_map[solver_name] ? solver_file_key_map[solver_name][type] : type || type; // Translate file key

      if (['host', 'guest'].includes(type)) {
        if (type === 'guest') {
          file_obj[file_key] = { path: f };
        } else if (type === 'host') {
          file_obj[file_key] = f;
        }
      } else {
        // Handle GNINA specific files
        serialized_solver = addFilesToGNINA(serialized_solver, type as MolFileTypes, files);
      }
    });

    // GNINA - nest files in "system" key
    if (solver_name === 'GNINA') {
      serialized_solver.solver_params['system'] = file_obj;
    }
    // Otherwise merge file parameters directly into solver_params
    else {
      serialized_solver.solver_params = { ...serialized_solver.solver_params, ...file_obj };
    }

    return serialized_solver;
  } else return serialized_solver;
}
