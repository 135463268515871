import React from 'react';

// Components
import QFormGroup from '../form/QFormGroup';
import QButton from '../button/Button';
import { handleInputChange } from '../../hoc/FormFunctions';

const AddMembersForm = props => (
  <React.Fragment>
    <div className='members'>
      <div className='row'>
        <QFormGroup
          containerClassName='col-12 col-md-8'
          element='text'
          id='members'
          name='members'
          label='Add Members by Email'
          onChange={e => handleInputChange(e, props, 'members', () => {})}
          type='text'
          helperText='Separate multiple email addresses with a comma.'
          includeError={true}
        />
      </div>
    </div>
    <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
      Add Members
    </QButton>
  </React.Fragment>
);

export default AddMembersForm;
