import React from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import PropTypes from 'prop-types';

// Tooltip Main Component
export const QTip = props => (
  <Tippy
    content={props.content}
    trigger={props.trigger} // Possible values: "mouseenter", "focus", "focusin", "click", "manual"
    duration={props.duration} // Possibles values: number, [number, number] = [show, hide]
    placement={props.placement} // example, "top-start" or "left-end"
    className={`q-tooltip ${props.className}`}
    followCursor={props.followCursor} // True or false
    hideOnClick={props.hideOnClick} // True or false
    offset={props.offset} // "x, y", such as "50, 20"
    interactive={props.interactive} // bool
    plugins={[followCursor]}>
    <span className='q-tooltip-wrapper'>{props.children}</span>
  </Tippy>
);

QTip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  trigger: PropTypes.string.isRequired,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  placement: PropTypes.string,
  className: PropTypes.string,
  followCursor: PropTypes.bool,
  hideOnClick: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  offset: PropTypes.arrayOf(PropTypes.number),
  interactive: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export const QTipStandard = props => (
  <QTip
    content={props.tooltipContent}
    trigger='mouseenter click'
    duration={100}
    placement='bottom-start'
    followCursor={false}
    hideOnClick={true}
    offset={[0, 0]}
    interactive={true}>
    {props.children}
  </QTip>
);

QTipStandard.propTypes = {
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired
};

export default QTip;
