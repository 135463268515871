import React, { useEffect, useRef, useState } from 'react';

import { formatStringsList } from '../../hoc/CommonFunctions';

interface Props {
  allowedFileTypes?: string[];
  className?: string;
  files?: File[];
  fileType: string;
  id: string;
  maxFiles?: number;
  multiple?: boolean;
  setFiles: Function;
  setFileError: Function;
}

export default function UploadComponent({
  allowedFileTypes = [],
  className,
  files = [],
  fileType,
  id,
  maxFiles,
  multiple = true,
  setFiles,
  setFileError,
}: Props) {
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [duplicateFile, setDuplicateFile] = useState<boolean>(false);
  const [maxFilesExceeded, setMaxFilesExceeded] = useState<boolean>(false);
  const [singleFileOnly, setSingleFileOnly] = useState<boolean>(false);
  const [formattedAllowedFiles, setFormattedAllowedFiles] = useState<string>('');
  const timers = useRef<any[]>([]);
  const fileNames = localFiles.map(f => f.name) || [];

  // Update accepted files list
  useEffect(() => {
    if (allowedFileTypes) {
      const allowed = allowedFileTypes.map(ft => (ft.startsWith('.') ? ft : '.' + ft)).join(', ');

      setFormattedAllowedFiles(allowed);
    }
  });

  // Update local files states if provided
  useEffect(() => {
    if (files.length > 0) {
      setLocalFiles(files);
    }
  }, []);

  // Clear timers when component unmounts
  useEffect(() => {
    return () => {
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  // Update parent component when this component's files state changes
  useEffect(() => setFiles(localFiles), [localFiles]);

  function handleSingleFileOnly() {
    setSingleFileOnly(true);
    const t = setTimeout(() => {
      setSingleFileOnly(false);
    }, 6000);
    timers.current.push(t);
  }

  function checkFileExtensions(file: File) {
    // Check extension
    if (allowedFileTypes.length > 0) {
      // Get file extension from file name
      const file_name_arr = file.name.split('.');
      const file_ext = file_name_arr[file_name_arr.length - 1].toLowerCase();

      if (allowedFileTypes.includes(file_ext)) {
        return true;
      } else {
        let supported_types = formatStringsList(allowedFileTypes, true, true);
        // Return error to parent component
        setFileError({ message: `Only ${supported_types} files are allowed for ${fileType} file(s) upload.` });
        return false;
      }
    } else return true;
  }

  function validateFile(file: File) {
    // Wrapper function for future additional validations
    // For now only check file extension
    const is_valid = checkFileExtensions(file);
    return is_valid;
  }

  // Handle component file states from selected/dropped files
  function handleFilesList(file_list: File[]) {
    // Reset any errors previously shown
    setFileError(null);

    // Handle files added
    if (file_list && file_list.length > 0) {
      // Show single file upload only warning when:
      // 'multiple' prop is false AND
      // more than 1 file is dropped, or there is already one file selected
      if (!multiple && (file_list.length > 1 || localFiles.length > 0)) {
        handleSingleFileOnly(); // Show warning
      }

      // If 'multiple' prop is not false OR
      // 'multiple' prop is false AND no files have been yet selected
      if (multiple || (!multiple && localFiles.length === 0)) {
        let new_files = [] as File[];

        if (!multiple) {
          file_list = [file_list[0]]; // Truncate multiple file drops
        }

        if (maxFiles) {
          // show max files exceeded
          if (file_list.length > maxFiles - localFiles.length) {
            setMaxFilesExceeded(true);
            const t = setTimeout(() => {
              setMaxFilesExceeded(false);
            }, 6000);
            timers.current.push(t);
          }
          // truncate new files list
          if (localFiles.length < maxFiles) {
            file_list = file_list.slice(0, maxFiles - localFiles.length);
          } else {
            file_list = [];
          }
        }

        file_list.forEach((file: File) => {
          if (!fileNames.some(f => file.name === f)) {
            // validate file
            const valid_file = validateFile(file);
            if (valid_file) {
              new_files.push(file);
            }
          } else {
            setDuplicateFile(true);
            const t = setTimeout(() => {
              setDuplicateFile(false);
            }, 6000);
            timers.current.push(t);
          }
        });
        setLocalFiles(prevFiles => [...prevFiles, ...new_files]);
      }
    }
  }

  // Handle files browse
  const handleFileBrowse = event => {
    const selected_files = Object.values(event.target.files) as File[];
    handleFilesList(selected_files);
  };

  // Handle dropped files
  const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    const dropped_files = Object.values(event.dataTransfer.files) as File[];
    handleFilesList(dropped_files);
  };

  // Handle remove file
  const handleRemoveFile = (filename: string, index: number) => {
    setLocalFiles(preFiles => preFiles.filter((_, i) => i !== index));
  };

  return (
    <div className={`upload-component ${className}`}>
      <div className='d-flex flex-column align-items-start justify-content-center'>
        <div
          className='w-100 p-3 mb-2 drag-drop-container'
          onDrop={(e: React.DragEvent<HTMLDivElement>) => handleDrop(e)}
          onDragOver={event => event.preventDefault()}
          data-testid={'drop-area'}>
          <label
            className='d-flex text-sm align-items-center justify-content-center m-0 p-5 drop-area'
            htmlFor={id}
            data-testid={'click-area'}>
            <div className='d-inline-flex flex-wrap align-items-center justify-content-center prompt-area'>
              <div className='mx-3 d-flex align-items-center'>
                <i className='icon-upload mr-2' />
                <span className='bold uppercase'>UPLOAD</span>
              </div>
              <input
                accept={formattedAllowedFiles}
                type='file'
                hidden
                id={id}
                onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                  (e.target as HTMLInputElement).value = '';
                }}
                onChange={handleFileBrowse}
                multiple={multiple}
                data-testid='file-upload-input'
              />
              <div className='p-2 mx-3 text-center'>Drag & Drop or Click to Browse</div>
            </div>
          </label>
        </div>
        {localFiles.length > 0 && (
          <div
            className={`d-flex w-100 flex-column align-items-start file-list ${
              localFiles.length > 15 ? 'scroll' : ''
            }`}>
            {localFiles.map((file, index) => (
              <div className='d-flex w-100 align-items-center justify-content-between mb-3 p-2 file-item' key={index}>
                <div className='file-info pl-2 pr-3'>
                  <p className='text-sm bold mb-0'>{file.name}</p>
                </div>
                <div className='file-actions'>
                  <i
                    className='icon-close-small flex-center p-2'
                    data-testid={`remove-file-icon_${index}`}
                    onClick={() => handleRemoveFile(file.name, index)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {duplicateFile ? (
          <div className='d-flex align-items-center mb-2 notification static info bold'>
            <span className='mr-3'>Duplicate files will not be uploaded.</span>
            <i className='icon-close-small flex-center duplicate' onClick={() => setDuplicateFile(false)} />
          </div>
        ) : (
          ''
        )}
        {maxFilesExceeded ? (
          <div className='d-flex align-items-center mb-2 notification static info bold'>
            <span className='mr-3'>Maximum {maxFiles} files allowed.</span>
            <i className='icon-close-small flex-center duplicate' onClick={() => setMaxFilesExceeded(false)} />
          </div>
        ) : (
          ''
        )}
        {singleFileOnly ? (
          <div className='d-flex align-items-center mb-2 notification static info bold'>
            <span className='mr-3'>Only one file may be selected.</span>
            <i className='icon-close-small flex-center duplicate' onClick={() => setSingleFileOnly(false)} />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
