import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import mixpanel from 'mixpanel-browser';

// Components
import { useAuth } from '../../hoc/Auth';
import { useUserData } from '../../hoc/UserData';
import { LoadingFilled, LoadingInline } from '../../components/loading/Loading';
import PostAuthCommonContent from './PostAuthCommonContent';
import Terms from '../../views/terms/Terms';
import { QErrorMessage } from '../../components/notifications/Notifications';
import { browserStore } from '../../hoc/BrowserStorage';

const PostAuthLayout = () => {
  // Init
  const { fetchData } = useAuth();
  const [userInfoFetched, setUserInfoFetched] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // prettier-ignore
  const { adminPath, setAdminPath, user, setUser, setGroups, orgs, setOrgs, setOrgIDs, currentOrg, setCurrentOrg, adminGroups, setAdminGroups, setProjects, setRefreshToken } = useUserData();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET User Info
  const [error, setError] = useState(null);
  // Sidebar
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [mobileSidebarExpanded, setMobileSidebarExpanded] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1199px)' });
  // Other
  const [transitionOut, setTransitionOut] = useState(false);
  const [userIdentified, setUserIdentified] = useState(false);
  const [workspaceUpdating, setWorkspaceUpdating] = useState(false);
  // Terms
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    if (!userInfoFetched) {
      function handleWorkspace(org_groups, user_id) {
        if (org_groups.length > 0) {
          const stored_value = browserStore('get', `${user_id}.workspace`);
          if (stored_value === null) {
            updateCurrentOrg(org_groups[0], user_id);
          } else {
            setCurrentOrg(stored_value);
          }
        } else updateCurrentOrg({}, user_id);
      }

      fetchData(signal, '/userinfo', null, 'GET', response => {
        // if error is returned
        if (response.status === 'error') {
          setError(response.error);
        }
        // if data is returned
        if (response.status === 'success') {
          const data = response.data;
          setUserInfoFetched(true);
          setUser(data.userinfo);
          setRefreshToken(data.refresh_token);
          // Set groups
          if (data.groups !== undefined && data.groups !== null) {
            let groups = data.groups;
            let org_groups = [];
            let org_ids = [];
            let admin_groups = [];
            setGroups(groups);
            groups.forEach(group => {
              if (group.name.indexOf('--Org') > 0 || group.name.indexOf('|Org') > 0) {
                org_groups.push(group);
                if (!org_ids.includes(group._id)) {
                  org_ids.push(group._id);
                }
              } else if (group.name.indexOf('--Admin') > 0) {
                admin_groups.push(group);
              }
              setOrgIDs(org_ids);
            });
            setOrgs(org_groups);
            setAdminGroups(admin_groups);

            // Set workspace to "Personal" if promo param in url
            if (window.location.href.includes('promo=')) {
              let promo_partial = window.location.href.split('promo=')[1];
              let promo = promo_partial.split('#')[0];

              let personal_promos = ['HOWDYPARTNER', 'DEMOCREDITS'];
              if (personal_promos.includes(promo)) {
                updateCurrentOrg({}, data.userinfo.userid);
              } else {
                handleWorkspace(org_groups, data.userinfo.userid);
              }
            } else {
              handleWorkspace(org_groups, data.userinfo.userid);
            }
          } else {
            updateCurrentOrg({}, data.userinfo.userid);
            setOrgs([]);
          }
          // Check terms
          if (data.terms === 'incomplete') {
            setShowTerms(true);
          } else {
            setShowTerms(false);
          }
        }
        setIsLoaded(true);
      });
    }

    const sidebar_expanded = browserStore('get', 'sidebarExpanded', null, 'session');
    if (sidebar_expanded === null) {
      browserStore('set', 'sidebarExpanded', sidebarExpanded, 'session');
    } else {
      const stored_value_bool = sidebar_expanded === true;
      setSidebarExpanded(stored_value_bool);
    }

    const mobile_sidebar_expanded = browserStore('get', 'mobileSidebarExpanded', null, 'session');
    if (mobile_sidebar_expanded === null) {
      browserStore('set', 'mobileSidebarExpanded', mobileSidebarExpanded, 'session');
    } else {
      let stored_value_bool = mobile_sidebar_expanded === true;
      setMobileSidebarExpanded(stored_value_bool);
    }

    const admin_path = browserStore('get', 'adminPath', null, 'session');
    if (admin_path === null) {
      const default_path = '/admin/status';
      browserStore('set', 'adminPath', default_path, 'session');
      setAdminPath(default_path);
    } else {
      setAdminPath(admin_path);
    }

    setWorkspaceUpdating(false);

    return () => {
      setUserInfoFetched(false);
      abortController.abort();
    };
  }, [currentOrg]);

  if (!userIdentified && user) {
    setUserIdentified(true);
    if (MIXPANEL_TOKEN && ENVIRON !== 'local') {
      mixpanel.identify(user.userid);
      if (user.email.includes('1qbit.com')) {
        mixpanel.set_group('company', '1qbit');
      }
      if (user.email.includes('goodchemistry.com')) {
        mixpanel.set_group('company', 'good_chemistry');
      }
      if (user.role === 'qemist_admin') {
        mixpanel.people.set('role', 'qemist_admin');
      }
    }
  }

  function logout() {
    setTransitionOut(true);
    sessionStorage.clear();
    if (MIXPANEL_TOKEN && ENVIRON !== 'local') {
      mixpanel.track('Logout');
    }
  }

  function toggleSidebar() {
    setSidebarExpanded(!sidebarExpanded);
    browserStore('set', 'sidebarExpanded', !sidebarExpanded, 'session');
  }

  function toggleSidebarMobile() {
    setMobileSidebarExpanded(!mobileSidebarExpanded);
    browserStore('set', 'mobileSidebarExpanded', !mobileSidebarExpanded, 'session');
  }

  function updateCurrentOrg(org, user_id) {
    setWorkspaceUpdating(true);
    setProjects(null);
    setCurrentOrg(org);
    if (org.name !== undefined && org.name !== null) {
      browserStore('set', `${user_id}.workspace`, org);
    } else {
      browserStore('set', `${user_id}.workspace`, {});
    }
  }

  if (user && isLoaded) {
    return (
      <React.Fragment>
        {error ? (
          <div className='full-screen-backdrop blue px-5'>
            <QErrorMessage text={<span className='bold'>{error.message}</span>} />
          </div>
        ) : (
          <React.Fragment>
            {transitionOut ? (
              <div className='full-screen-backdrop blue'>
                <LoadingInline color='light' />
              </div>
            ) : (
              <React.Fragment>
                {/* Terms */}
                {showTerms ? (
                  <React.Fragment>
                    <div className='overlay-content-wrapper d-flex align-items-center'>
                      <Terms hideTerms={() => setShowTerms(false)} />
                    </div>
                    <div className='overlay'></div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {!isTabletOrMobile ? (
                      <div
                        id='PostAuthLayout'
                        className={`${sidebarExpanded ? 'sidebar-expanded' : 'sidebar-contracted'}`}>
                        <PostAuthCommonContent
                          sidebarExpanded={sidebarExpanded}
                          showTerms={showTerms}
                          user={user}
                          orgs={orgs}
                          currentOrg={currentOrg}
                          updateCurrentOrg={org => updateCurrentOrg(org, user.userid)}
                          workspaceUpdating={workspaceUpdating}
                          adminGroups={adminGroups}
                          adminPath={adminPath}
                          toggleSidebar={() => toggleSidebar()}
                          logout={() => logout()}
                        />
                      </div>
                    ) : (
                      <div
                        id='PostAuthLayout'
                        className={`${mobileSidebarExpanded ? 'sidebar-expanded' : 'sidebar-contracted'}`}>
                        <PostAuthCommonContent
                          sidebarExpanded={sidebarExpanded}
                          showTerms={showTerms}
                          user={user}
                          orgs={orgs}
                          currentOrg={currentOrg}
                          updateCurrentOrg={org => updateCurrentOrg(org, user.userid)}
                          workspaceUpdating={workspaceUpdating}
                          adminGroups={adminGroups}
                          adminPath={adminPath}
                          toggleSidebar={() => toggleSidebarMobile()}
                          logout={() => logout()}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  } else return <LoadingFilled />;
};

export default PostAuthLayout;
