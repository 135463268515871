import React from 'react';
import PropTypes from 'prop-types';

const TabMenu = props => (
  <div id='TabMenu' className={`tab-menu d-flex w-100 ${props.className}`}>
    {props.children}
  </div>
);

TabMenu.propTypes = {
  className: PropTypes.string,
};

export const TabItem = props => (
  <div
    className={`tab-item d-flex align-items-center justify-content-center px-3 text-sm bold ${
      props.current ? 'current' : ''
    } ${props.className}`}
    onClick={props.onClick}>
    <span>{props.children}</span>
  </div>
);

TabItem.propTypes = {
  current: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

export default TabMenu;
