import React, { useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { getProjectName, charactersHelper } from '../../hoc/CommonFunctions';
import { handleInputChange, checkValueChanged } from '../../hoc/FormFunctions';
import QFormWrapper from '../../components/form/QFormWrapper';
import QFormGroup from '../../components/form/QFormGroup';
import QButton from '../../components/button/Button';
import { QErrorMessage } from '../../components/notifications/Notifications';
import { LoadingInline } from '../../components/loading/Loading';

const RenameProjectModal = props => {
  const reserveDashBang = /^((?!--!).)*$/;
  const reserveOrgKeyword = /^((?!--[O|o]rg).)*$/;
  const reserveProjectKeyword = /^((?!--[P|p]roject).)*$/;
  const reserveAdminKeyword = /^((?!--[A|a]dmin).)*$/;
  const [newVals, setNewVals] = useState({ name: props.project.name, description: props.project.description });

  function checkProjectNames(value) {
    var e;
    var no_match = true;
    try {
      props.projects.forEach(project => {
        let project_name = getProjectName(project);
        if (project_name === value) {
          no_match = false;
          throw e;
        }
      });
    } catch (e) {}
    return no_match;
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('This field is required')
      .min(2, 'Minimum 2 characters, please')
      .max(30, 'Max 30 characters, please')
      .test('reserved-word-1', '--! is a reserved word', function (value) {
        return reserveDashBang.test(value);
      })
      .test('reserved-word-2', '--Org is a reserved word', function (value) {
        return reserveOrgKeyword.test(value);
      })
      .test('reserved-word-3', '--Project is a reserved word', function (value) {
        return reserveProjectKeyword.test(value);
      })
      .test('reserved-word-4', '--Admin is a reserved word', function (value) {
        return reserveAdminKeyword.test(value);
      })
      .test('duplicate-project-name', 'You already have a project with this name', function (value) {
        return checkProjectNames(value);
      }),
    description: yup.string(),
  });

  return (
    <React.Fragment>
      <h1>Edit {props.project.name}</h1>
      {props.renameError ? (
        <QErrorMessage
          className='row mx-0 p0'
          text={
            <span className='bold'>
              Uh oh—something went wrong. Please try again later.
              <br />
              {props.renameError.message}
            </span>
          }
        />
      ) : (
        ''
      )}
      {props.loadingProjects ? (
        <LoadingInline className='transparent-bg' text='Please be patient...' />
      ) : (
        <React.Fragment>
          <QFormWrapper
            formId='RenameProjectForm'
            initialValues={{
              name: props.project.name,
              description: props.project.description,
            }}
            validationSchema={validationSchema}
            onSubmit={values => props.renameProject(values)}>
            {formProps => {
              return (
                <React.Fragment>
                  <div className='name'>
                    <div className='row'>
                      <QFormGroup
                        containerClassName='col-12 col-md-8'
                        element='text'
                        id='name'
                        name='name'
                        label='Project Name'
                        helperText={charactersHelper('name', 30)}
                        onChange={e =>
                          handleInputChange(e, formProps, 'name', e => {
                            let vals = { ...newVals };
                            vals.name = e.target.value;
                            setNewVals(vals);
                          })
                        }
                        type='text'
                        includeError={true}
                        defaultValue={props.project.name}
                      />
                    </div>
                  </div>
                  <div className='description'>
                    <div className='row'>
                      <QFormGroup
                        containerClassName='col-12 col-md-8'
                        element='text'
                        id='description'
                        name='description'
                        label='Description'
                        onChange={e =>
                          handleInputChange(e, formProps, 'description', e => {
                            let vals = { ...newVals };
                            vals.description = e.target.value;
                            setNewVals(vals);
                          })
                        }
                        type='text'
                        includeError={true}
                        defaultValue={props.project.description}
                      />
                    </div>
                  </div>
                  <QButton
                    type='submit'
                    disabled={
                      !formProps.isValid ||
                      !checkValueChanged({ name: props.project.name, description: props.project.description }, newVals)
                    }>
                    Update Project
                  </QButton>
                </React.Fragment>
              );
            }}
          </QFormWrapper>
          <QButton className='secondary mt-4' onClick={props.toggleRenameModal}>
            Cancel
          </QButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

RenameProjectModal.propTypes = {
  project: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  renameError: PropTypes.any,
  renameProject: PropTypes.func.isRequired,
  toggleRenameModal: PropTypes.func.isRequired,
  loadingProjects: PropTypes.bool.isRequired,
};

export default RenameProjectModal;
