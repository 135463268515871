import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { useUserData } from '../../../hoc/UserData';
import { paginationSetup } from '../../../hoc/ProblemsCommonFuncs';
import RequestLogComponent from '../../../components/requestLog/RequestLogComponent';

const ViewGroupRequestLog = props => {
  // Init
  const history = useHistory();
  // GET Problems
  // prettier-ignore
  const { adminDateRangeStart, setAdminDateRangeStart, adminDateRangeEnd, setAdminDateRangeEnd } = useUserData();
  const [groupID, setGroupID] = useState(null);
  const [offset, setOffset] = useState(0);
  const [setLength, setSetLength] = useState(20);

  useEffect(() => {
    // Set group id
    let url_partial = window.location.href.split('group/')[1];
    if (url_partial !== undefined) {
      let group_id = url_partial.split('/')[0];
      setGroupID(group_id);
    }

    // Handle pagination setup
    paginationSetup(new_path => {
      history.replace(new_path);
    });
  }, []);

  function updateHistory(value) {
    let page = window.location.hash.split('request-log')[1];
    if (page !== value) {
      history.push(value);
    }
  }

  return (
    <div id='ViewGroupRequestLog' className='view-user'>
      {groupID !== null ? (
        <RequestLogComponent
          setIsShown={() => function () {}}
          offset={offset}
          setOffset={value => setOffset(value)}
          setLength={setLength}
          userID={null}
          groupID={groupID}
          projectID={null}
          rangeStart={adminDateRangeStart}
          setRangeStart={value => setAdminDateRangeStart(value)}
          rangeEnd={adminDateRangeEnd}
          setRangeEnd={value => setAdminDateRangeEnd(value)}
          saveRangeName={'adminGrpRqlRanges'}
          visibilityName={'adminGrpRqlVisibility'}
          trackRequestLogError={() => function () {}}
          updateHistory={value => updateHistory(value)}
        />
      ) : (
        ''
      )}
    </div>
  );
};
export default ViewGroupRequestLog;
