export const qemist_cloud_eval_tos_22_04_09 = `# QEMIST Cloud evaluation TERMS OF SERVICE

**Last Updated: April 9, 2022**

**IMPORTANT: Carefully read these terms of service (the “Agreement”) before using the evaluation version of QEMIST Cloud.**

This Agreement creates a binding legal agreement between you (“**Customer**” or “**you**”) and Good Chemistry Inc. (“**Good Chemistry**”).

BY USING QEMIST CLOUD, YOU IRREVOCABLY ACCEPT THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT ACCEPT THIS AGREEMENT, YOU ARE NOT PERMITTED TO USE QEMIST CLOUD.

IF YOU ARE ENTERING THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THIS AGREEMENT, IN WHICH CASE, THE TERM “CUSTOMER” WILL REFER TO SUCH ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THIS AGREEMENT, YOU ARE NOT PERMITTED TO ACCEPT THIS AGREEMENT AND MAY NOT USE QEMIST CLOUD.

**IF CUSTOMER HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT WITH GOOD CHEMISTRY (AND SO LONG AS SUCH AGREEMENT IS VALID AND IN FORCE) WHERE THE PARTIES HAVE AGREED THAT USE OF QEMIST CLOUD BY CUSTOMER AND ITS END USERS IS GOVERNED BY THE PROVISIONS OF SUCH OTHER AGREEMENT, THEN THE TERMS OF THIS AGREEMENT SHALL NOT APPLY AND ARE HEREBY SUPERSEDED AND REPLACED BY THE TERMS OF THE OTHER SEPARATE WRITTEN AGREEMENT.**

Good Chemistry may update this Agreement at any time and will notify its users of such changes by posting notice on its website. Customer’s continued use of the QEMIST Cloud is irrevocable acceptance of any such updates. Customer should print or save a local copy of this Agreement.

In order to enter into this Agreement, you must have reached the legal age of majority in your jurisdiction of residence and be fully able and competent to enter into the terms, conditions, obligations, affirmations, representation and warranties set forth in this Agreement, and to abide by and comply with this Agreement. It is your responsibility to ensure that you are legally eligible to enter into this Agreement under any laws applicable to you. If you accept this Agreement, you represent that you have the capacity to be bound by it.

GOOD CHEMISTRY DOES NOT REQUIRE, AND DOES NOT WANT, ANY PERSONAL INFORMATION IN THE ACCESS OR USE OF QEMIST CLOUD. CUSTOMER AND ITS END USERS WILL NOT PROVIDE GOOD CHEMISTRY ANY PERSONAL INFORMATION IN THE ACCESS OR USE OF THE QEMIST CLOUD. THE FOREGOING IS A MATERIAL TERM OF THIS AGREEMENT.

IF GOOD CHEMISTRY BECOMES AWARE THAT CLIENT OR AN END USER HAS PROVIDED PERSONAL INFORMATION, GOOD CHEMISTRY’S ONLY OBLIGATION TO CUSTOMER WILL BE TO DELETE SUCH PERSONAL INFORMATION.

Please read our privacy policy which describes how Good Chemistry collects, uses and discloses personal information that you may provide while subscribing for QEMIST Cloud.

1. Definitions
   1. When used in this Agreement, the following capitalized terms have the following meanings unless the context otherwise requires:
      1. “**Confidential Information**” means any and all information disclosed by Good Chemistry or its affiliates or business partners, or their respective employees, contractors or agents, in written, oral or electronic form, that is not public information and that is marked “confidential” or “proprietary” or which Customer knows or ought reasonably to know is regarded by Good Chemistry as such, including without limitation any information disclosed to Customer regarding QEMIST Cloud. The problems that the Customer or its End User submit to QEMIST Cloud, and the solutions to such problems, are deemed to be the “_Confidential Information_” of Customer.
      2. “**Credentials**” means each unique user identification usernames, passwords, access tokens and keys required to access or use QEMIST Cloud by the Customer or any of its End Users.
      3. “**Customer Account**” means the online account describing Customer’s account information, including, but not limited to credits available to use QEMIST Cloud and the duration of any access to QEMIST Cloud, accessible to Customer by logging in .
      4. “**Customer Content**” means the information and material, including any data, text, audio, video, images or molecular structures, that Customer or an End User provides to QEMIST Cloud, the problems that the Customer or its End User submit to QEMIST Cloud and the solutions to such problems.
      5. “**Customer Portal**” means the online portal (and related web pages) made available to Customer to access Documentation, support policies, client library for access to APIs and other tools required for preparing calculations on , and other materials published by Good Chemistry that are related to the QEMIST Cloud.
      6. “**Documentation**” means the online materials, specifications or forms published by Good Chemistry through the Customer Portal that describe the features and functionality of QEMIST Cloud.
      7. “**End User**” means a person or entity that Customer permits to access or use QEMIST Cloud or Customer Content.
      8. “**QEMIST Cloud**” means the evaluation version of the online Good Chemistry computing services, applications and APIs for its proprietary algorithms, models and computational solutions made available to Customer via Good Chemistry’s systems, hardware, software, and other technology.
      9. “**QEMIST Credit**” means a credit provided by Good Chemistry which is required for the use of QEMIST Cloud by Customer.
      10. “**Term”** means the period of time of permitted access and use of QEMIST Cloud as set out in the Customer Account for which Customer will have access to QEMIST Cloud, as determined and may be amended by Good Chemistry in its discretion from time to time.
2. QEMIST Cloud USE
   1. Conditional on Customer: (a) having a valid Customer Account and complying with all the terms of this Agreement; (b) cooperating with the reasonable requests of Good Chemistry; and (c) procuring (at its own cost) minimum system requirements specified in the Documentation, Good Chemistry will make available QEMIST Cloud for Customer’s access and use on the terms and conditions set out in this Agreement only for evaluating the available features, benefits, functionality or services of QEMIST Cloud.
   2. Customer will access and use QEMIST Cloud only: (a) as authorized by Good Chemistry; (b) for its own internal business purposes and no other purpose; (c) in accordance with any operating instructions, policies and procedures that apply in respect of QEMIST Cloud and QEMIST Credits, as set out or referred to in any of the Documentation or as otherwise prescribed by Good Chemistry from time to time; and (d) in accordance with all applicable laws. Customer shall not have any right to enter or gain access to Good Chemistry’s or any third party’s facilities, systems, premises or records in connection with the use of QEMIST Cloud.
   3. Good Chemistry may, but is under no obligation to, provide a non-evaluation version of QEMIST Cloud to Customer under different terms and conditions. Good Chemistry reserves all rights to charge or charge additional amounts for use of such non-evaluation version.
3. QEMIST CREDITS
   1. Customer’s access and use of QEMIST Cloud to perform a particular processing transaction is subject to having sufficient number of QEMIST Credits in the Customer Account at the time Customer makes use of QEMIST Cloud.
   2. Each processing transaction on QEMIST Cloud will require that number of QEMIST Credits as then-currently described in the Documentation for such processing transaction, which amounts are subject to change without notice.
   3. When Customer performs a processing transaction on QEMIST Cloud, then the applicable number of QEMIST Credits set out in the then-current Documentation are irrevocably consumed by Customer (regardless of outcome) and an equivalent number of QEMIST Credits are deducted from the QEMIST Credits in the Customer Account.
   4. QEMIST Credits can only be issued by Good Chemistry and cannot be purchased under this Agreement. QEMIST Credits have no cash value, cannot be returned, and are not available for redemption or exchange for any other service or product of Good Chemistry. All QEMIST Credits in a Customer Account automatically expire without further notice on the earlier of (i) the expiration or termination of this Agreement for any reason, or (ii) if your Customer Account has not been used to process any transactions on QEMIST Cloud for a period of 90 days or more.
   5. Customer shall not sell, exchange, trade, give, convert, redeem or otherwise transfer QEMIST Credits outside QEMIST Cloud or to any third person (including, but not limited to any other QEMIST Cloud customer or end user). Customer does not own, and has no right, title, interest, or ownership in or to, any QEMIST Credits. QEMIST Credits represent a limited, nonexclusive, non-transferable, non-assignable and fully revocable license to use the QEMIST Credits only for the non-commercial use processing transactions on QEMIST Cloud. Good Chemistry may terminate Customer’s license to QEMIST Credits at any time and in its discretion.
   6. Good Chemistry has the unfettered right to issue, regulate, discontinue, update, modify or eliminate any QEMIST Credits at any time to any person in its sole discretion and Good Chemistry shall have no liability to Customer or anyone else. Customer will not receive any compensation, consideration or reimbursement for any QEMIST Credits upon termination of this Agreement for any reason.
4. Availability, support and Suspension
   1. Good Chemistry does not guarantee any availability of QEMIST Cloud or that any problem or issue arising out of or related to QEMIST Cloud or its use will be addressed or resolved.
   2. Good Chemistry may, but is not obligated, to (i) provide support at its discretion in accordance with then-current standard support policy for evaluation services made available on the Customer Portal; or (ii) make available additional features or functions, programming fixes, updates, upgrades or versions for QEMIST Cloud.
   3. Good Chemistry may, in its sole discretion, suspend, terminate or limit any Customer or End User access to or use of QEMIST Cloud, or any part of it, without notice for any reason. Good Chemistry will not be responsible for any loss or damages of any kind incurred by Customer as a result of any limitation, termination or suspension of QEMIST Cloud under this Section. These rights do not limit any of Good Chemistry’s other rights or remedies.
5. Intellectual Property
   1. The intellectual property rights in any material provided by Good Chemistry in connection with QEMIST Cloud are owned exclusively by Good Chemistry or its licensors (each, a “**Licensor**”). Good Chemistry retains all its rights, title and interest, including intellectual property rights, in QEMIST Cloud and the Documentation and all derivative works thereof prepared by or on behalf of Good Chemistry or its Licensors, including all software, content, information and data contained, embodied, implemented or incorporated therein, including but not limited, to as such intellectual property and intellectual property rights that arise from the exercise of Good Chemistry’s rights under Section 5.3(b) and (c). Except for the limited permission to use QEMIST Cloud expressly granted herein, nothing in this Agreement shall be deemed or interpreted to provide any transfer or license of any intellectual property rights of Good Chemistry or its Licensors to Customer.
   2. Where Customer uses software independently provided or arranged for by Customer in connection with QEMIST Cloud, Customer is solely responsible for such software and shall obtain all necessary rights and licenses, including any right to interoperate with QEMIST Cloud.
   3. Customer is the exclusive owner of the Customer Content now and in the future on a worldwide basis. Customer grants Good Chemistry a limited right to access and use the Customer Content (a) as necessary to provide QEMIST Cloud to Customer during the Term, and (b) for Good Chemistry’s internal business purposes of: (i) providing support (if any) for QEMIST Cloud; and (ii) in an aggregated form (including with other data) to train, develop and improve QEMIST Cloud and other Good Chemistry systems and proprietary technology, algorithms, and models, (c) a right to create and store such data for the purposes described in the foregoing subsection (b), (d) to comply with applicable law including to respond to any allegation of non-compliance or infringement arising from any third party in respect of the Customer Content, (e) to permit access for the purposes of an audit or regulatory or criminal investigation where access is expressly ordered by an authority of competent jurisdiction, mandatory under applicable law or regulations or required by a regulator or regulatory body to which Good Chemistry is subject from time to time, and (f) to act in a manner that Customer has otherwise expressly authorized in writing.
   4. During and following the Term, Customer may, but is not required to, provide Good Chemistry with information and feedback regarding the features and performance of QEMIST Cloud (which may include proposed features, modifications, improvements or enhancements), or information regarding any failures, errors, deficiencies or other malfunctions in QEMIST Cloud along with any associated error messages (collectively, “**Feedback**”). Such Feedback is and will be Good Chemistry’s exclusive property without any compensation or other consideration payable to Customer by Good Chemistry, and Customer provides such Feedback of its own free will and volition. Good Chemistry may or may not, in its sole discretion, use or incorporate the Feedback in whatever form or derivative into QEMIST Cloud, its software, documentation, business or other products or services, or any future versions or derivatives of the foregoing. Customer does and will hereby irrevocably and unconditionally assigns, transfers and conveys all rights in any Feedback on a worldwide basis in perpetuity to Good Chemistry and, as applicable, waives (and will cause the waiver of) any related moral rights.
6. Data & Backup
   1. Customer is solely responsible for its Customer Content, and for backing up any Customer Content. Good Chemistry will not be liable for any loss or damage to Customer Content.
   2. Customer is, at its own expense, solely responsible for (i) proper configuration and use of QEMIST Cloud, and (ii) retrieving any Customer Content processed or stored with QEMIST Cloud. Where any Customer Content remains in QEMIST Cloud after the Term, Good Chemistry may delete such Customer Content in its discretion at any time 30 days after the Term, subject to applicable laws.
7. Conduct and Acceptable Use
   1. Customer will not, and will ensure that each End User will not,
      1. use QEMIST Cloud other than strictly as permitted by this Agreement;
      2. use QEMIST Cloud in violation of any law or regulation;
      3. engage in or allow any End User to engage in any access or conduct in connection with the use QEMIST Cloud that is unlawful, fraudulent, defamatory, tortious, offensive, obscene or cause harm or damage in respect of any person, entity, network or system;
      4. copy, translate, create a derivative work of, reverse engineer, reverse assemble, disassemble, decompile, modify or adapt QEMIST Cloud or any part thereof or attempt to discover any source code of QEMIST Cloud;
      5. sell, resell, license, sublicense, rent, lease, transfer, assign, distribute, or provide time sharing, hosting, application service provider services, or otherwise commercially exploit or make QEMIST Cloud available to any third party, or make any use other than as expressly permitted under this Agreement;
      6. use QEMIST Cloud to upload, collect, transmit, store, use or process, or ask Good Chemistry to obtain from third parties, any data or Customer Content: (a) that Customer does not have the lawful right to copy, transmit, distribute and display (including any Customer Content that would violate any confidentiality or fiduciary obligations that Customer might have with respect to the Customer Content); (b) that infringes, misappropriates or otherwise violates any intellectual property or other rights of any third party, including privacy rights; or (d) that violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil or criminal liability;
      7. use QEMIST Cloud to send, store, publish, post, upload or otherwise transmit any viruses, Trojan horses, worms, time bombs, corrupted files or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any systems, data, information or property of another, or otherwise erase, destroy, corrupt, hold hostage, or modify any data, programs, materials, machine protocols, or information used or accessed by Good Chemistry;
      8. use QEMIST Cloud in a manner that interferes with or disrupts the integrity or performance of QEMIST Cloud;
      9. without the prior written consent of Good Chemistry, publish, share or distribute information to any third person regarding the access, use, problems, results, operations, features or functionality of QEMIST Cloud;
      10. attempt to gain unauthorized access to QEMIST Cloud or its related systems or networks or circumvent any security protocol or requirement, including, any probe, scan or attempt to penetrate or ascertain the security of QEMIST Cloud;
      11. use any data mining, robots or similar data gathering or extraction methods; or
      12. use or access QEMIST Cloud for purposes of: (a) competitive analysis of QEMIST Cloud; or (b) the development of a competing product or service.
   2. If Good Chemistry determines in its sole discretion that Customer or an End User has not complied with this Section 7 or this Agreement, or learns of any third party claims arising as a consequence of access or use of QEMIST Cloud by Customer or an End User in a manner that is not in compliance with this Agreement, then Good Chemistry may take any measures that it deems appropriate, without prior notice to Customer, including without limitation, deleting or blocking all or any portion of the information that was transmitted or displayed by Customer or terminating this Agreement for material breach.
8. Customer Responsibilities
   1. Customer shall be responsible for all use and management of Customer’s and End Users’ accounts and Credentials, and Good Chemistry shall not be responsible or liable in any way for any loss or damage relating to any use of such accounts or Credentials by a third party. Customer shall immediately notify Good Chemistry of any loss, theft, or leak of such accounts or Credentials.
   2. Customer is primarily and fully liable for End Users’ use or misuse of Customer Content and QEMIST Cloud.
9. Term & Termination
   1. Customer’s right to use and access QEMIST Cloud is limited to the Term.
   2. A party may terminate this Agreement: (a) immediately without notice if the other party becomes subject to a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors; or (b) upon written notice if the other party breaches this Agreement.
   3. Notwithstanding any other provision of this Agreement, Good Chemistry may terminate this Agreement at any time on written notice to Customer without cost, charge or liability to Good Chemistry.
   4. Upon any expiration or termination of this Agreement, Good Chemistry will immediately cease making QEMIST Cloud available and may delete Customer Content in accordance with this Agreement.
   5. Sections 2.3, 3.4, 3.5, 3.6, 4.3, 5, 6, 7.1.9, 7.1.12, 8, 9 through 15 (inclusive) shall survive the termination or expiry of this Agreement.
10. Warranties
    1. Customer represents, warrants and covenants to Good Chemistry that in accessing and using QEMIST Cloud and in otherwise performing its obligations under this Agreement, it will comply with all applicable laws, including those relating to data and privacy protection; and that Customer has all rights necessary to provide the Customer Content for use in accordance with the terms of this Agreement.
    2. EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION 9: (A) QEMIST CLOUD AND DOCUMENTATION IS PROVIDED “AS IS”, “AS AVAILABLE”, WITH ALL FAULTS AND WITHOUT ANY WARRANTIES, REPRESENTATIONS OR CONDITIONS OF ANY KIND; (B) Good Chemistry HEREBY DISCLAIMS ALL EXPRESS, IMPLIED, COLLATERAL OR STATUTORY WARRANTIES, REPRESENTATIONS OR CONDITIONS, WHETHER WRITTEN OR ORAL, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, SECURITY, RELIABILITY, COMPLETENESS, ACCURACY, QUALITY, INTEGRATION OR FITNESS FOR A PARTICULAR PURPOSE; AND (C) Good Chemistry DOES NOT WARRANT THAT QEMIST CLOUD WILL OPERATE WITHOUT INTERRUPTION OR BE ERROR FREE.
    3. Customer acknowledges and agrees that Good Chemistry exercises no control over, and accepts no responsibility for, any content passing through the Internet or for Internet connectivity to QEMIST Cloud. Customer acknowledges that the Internet is inherently risky, and Customer assumes sole responsibility for its access to and use of QEMIST Cloud over the Internet.
11. Confidential Information
    1. Customer may use the Confidential Information of Good Chemistry only in connection with its use of QEMIST Cloud as permitted under this Agreement. Good Chemistry will use the Confidential Information of Customer as expressly permitted under this Agreement. A receiving party will not disclose any Confidential Information of the disclosing party during the Term or at any time thereafter. Each receiving party will take all reasonable measures to avoid disclosure, dissemination or unauthorized use of the disclosing party’s Confidential Information.
12. indemnification
    1. Customer will defend and indemnify Good Chemistry from and against any claim, suit, costs, expenses or damages awarded (including reasonable attorneys’ fees) to the extent arising from: (a) Customer’s use of QEMIST Cloud; (b) Customer’s breach of any of its obligations, representations or warranties under this Agreement; or (c) a claim that the Customer Content infringes or violates any intellectual property or other rights of a third party.
    2. Good Chemistry will promptly notify Customer of any claim and give Customer control over the defense of such claim. Customer may only settle or compromise a claim without Good Chemistry’s prior approval if (i) such settlement involves no finding or admission of any breach by Good Chemistry to any third party, (ii) such settlement has no effect on any other claim that may be made against Good Chemistry or any defense that Good Chemistry may assert in any such claim, and (iii) the sole relief provided in connection with such settlement is monetary damages that are paid in full by Customer. Upon the Customer’s assumption of the defense of such claim, Good Chemistry will cooperate reasonably with the Customer in such defense, at the Customer’s expense. Notwithstanding the foregoing, Good Chemistry will have sole control over any action, claim or proceeding related to its intellectual property rights.
13. Liability
    1. NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT: (i) IN NO EVENT WILL GOOD CHEMISTRY’S TOTAL LIABILITY FOR ANY AND ALL DAMAGES OF ANY KIND OF CLAIM IN AGGREGATE IN CONNECTION WITH OR UNDER THIS AGREEMENT EXCEED $1,000 USD; (ii) IN NO EVENT SHALL GOOD CHEMISTRY BE LIABLE TO THE CUSTOMER FOR ANY (A) SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES; (B) LOST SAVINGS, PROFIT, DATA, USE OR GOODWILL; (C) BUSINESS INTERRUPTION; OR (D) PERSONAL OR PROPERTY DAMAGE ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT, EVEN IF NOTIFIED IN ADVANCE OR REASONABLY FORESEEABLE, REGARDLESS OF CAUSE OF ACTION OR THE THEORY OF LIABILITY, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE, GROSS NEGLIGENCE, FUNDAMENTAL BREACH, BREACH OF A FUNDAMENTAL TERM) OR OTHERWISE; AND (iii) IN NO EVENT SHALL Good Chemistry BE LIABLE FOR PROCUREMENT OR COSTS OF SUBSTITUTE PRODUCTS OR SERVICES.
14. Export Controls
    1. Customer is responsible for complying with all applicable technology control or export laws and regulations in respect of the Customer Content, and access and use of QEMIST Cloud. Customer shall not access or export any technical data acquired from Good Chemistry in connection with access to or use of QEMIST Cloud (or any products, including software, incorporating any such data), or use or access QEMIST Cloud, in breach of any applicable laws or regulations applicable to Good Chemistry or QEMIST Cloud, including Canadian and United States export laws and regulations and related ordinances, to any person or jurisdiction for which the government or any government agency, at the time of export, or requires an export license or other governmental approval without first obtaining such license or approval or forbids such export or access.
15. General 
    1. **Changes**: Good Chemistry may change and discontinue at its discretion any part of QEMIST Cloud and change or remove features or functionality of any part of QEMIST Cloud at any time to time without notice. Good Chemistry may update this Agreement at any time, with reasonable notice to Customer which update will become effective on the date indicated in the notice. Customer’s continued use of QEMIST Cloud after the reasonable notice period will be deemed irrevocable acceptance of any such revisions. Before you continue, you should print or save a local copy of this Agreement for your records. 
    2. **Injunctive Relief**: Customer acknowledges that irreparable harm may result if it breaches its obligations under Section 5 (Intellectual Property) or 11 (Confidential Information). Customer acknowledges that such a breach would not be properly compensable by an award of damages and that, in addition to any other available remedies, Good Chemistry shall be entitled to seek injunctive relief to prevent a threatened or actual breach of its intellectual property rights or the misuse, threatened misuse, disclosure or threatened disclosure of its Confidential Information. 
    3. **Choice of Law**: This Agreement and any action related thereto shall be governed by and construed in accordance with the laws of the Province of British Columbia and the federal laws of Canada applicable therein, without regard to conflicts of law principles. The U.N. Convention on Contracts for the International Sale of Goods will not apply to this Agreement. 
    4. **Relationship**: Nothing in this Agreement will be construed to create any relationship between the Customer and Good Chemistry, other than an independent contractor relationship. Neither party is authorized to bind the other to any liability or obligation or to represent that it has this authority. 
    5. **Assignment**: Customer shall not assign, transfer, or sublicense this Agreement or any right or obligation it has in this Agreement without the prior written consent of Good Chemistry. 
    6. **Force Majeure**: A party is not responsible for delays in or failures to fulfill its non-monetary obligations due to causes beyond its reasonable control including, by way of example, attacks and illegal acts by any third parties or delays or failures arising from natural disasters, war, riots, pandemics, conflicts, acts of terrorism, power outages or defects or malfunctions in networks, equipment or software. 
    7. **Notices**: All notices under the Agreement must be in writing and sent to the email addresses designated by a party’s authorized representative in writing. Each party consents to exchange of emails between authorized representatives for as adequate for written notice and agreement in writing, unless this Agreement expressly prescribes otherwise. Notices provided by email will become effective upon sending unless otherwise stated in the email. It is Customer’s responsibility to keep its email address valid, as Customer will be deemed to receive any email sent to the email address associated with its Customer Account. 
    8. **Enforceability**: If at any time any provision is found by any court or other competent authority to be illegal, invalid or unenforceable in whole or in part, this will not affect or impair the legality, validity or enforceability of any and all other provisions. 
    9. **Entire Agreement**: The Agreement is the entire legal and contractual relationship between the parties and supersedes and replaces all prior or contemporaneous agreements with respect to this Agreement’s subject matter. Each party acknowledges that it has not relied upon any statements or representations other than as expressly set out in this Agreement. No power or right arising under this Agreement will be waived or any breach or default excused unless a waiver or excuse is given in writing and signed by an authorized representative for the party giving it. 
    10. **Interpretation**: In this Agreement: (i) words denoting the singular include the plural and vice versa and words denoting any gender include all genders; (ii) all usage of the word “including” or the phrase “e.g.,” in this Agreement shall mean “including, without limitation,” throughout this Agreement; (iii) all dollar amounts are expressed in US dollars. Headings and the division of this Agreement into sections are for convenience of reference only and shall not affect the interpretation hereof.
`;
