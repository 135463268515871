import React from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
SyntaxHighlighter.registerLanguage('json', json);
import syntaxStyles from './SyntaxStyles';

export function formatToJSON(data) {
  return (
    <SyntaxHighlighter language='json' showLineNumbers={true} style={syntaxStyles}>
      {JSON.stringify(data, null, 2)}
    </SyntaxHighlighter>
  );
}
