import React from 'react';
import QTip from '../tooltip/Tooltip';

// Components
import {convertToSciNote} from '../../hoc/CommonFunctions';

// Add internal solver params to blacklist
export function constructBlacklist(solver_parameters, hide_params, slots) {
  let blacklist_mod = structuredClone(hide_params);

  const solver_param_keys =
    solver_parameters && solver_parameters.solver_params ? Object.keys(solver_parameters.solver_params) : [];
  // Add keys that begin with an underscore (marked as internal) in the Solver class __slots__ attribute to the blacklist
  solver_param_keys.forEach(key => {
    if (!slots.includes(key)) {
      blacklist_mod.push(key);
    }
  });

  return blacklist_mod;
}

// Filter out params that are blacklisted,
// or has value that is a function, object, symbol, or undefined
export function filterSolverParams(solver_param_arr, blacklist) {
  const show_solver_params = [];
  solver_param_arr.forEach(param => {
    const disallowed_types = ['function', 'object', 'symbol'];
    if (!blacklist.includes(param[0]) && typeof param[1] !== undefined && !disallowed_types.includes(typeof param[1])) {
      show_solver_params.push(param);
    }
  });
  return show_solver_params;
}

// Format key: val to display as human-friendly text
function formatParamText(param) {
  // change boolean value to string
  if (typeof param === 'boolean') {
    if (param === true) {
      return 'True';
    } else return 'False';
  } else if (typeof param === 'string') {
    // replace underscore with space
    let underscores = /_/g;
    let formatted_param = param.replace(underscores, ' ');

    // transform specific abbreviations to uppercase
    const abbrvs = ['scf', 'diis', 'rms', 'xc', 'rdm'];
    abbrvs.forEach(abbrv => {
      if (formatted_param.includes(abbrv)) {
        formatted_param = formatted_param.replace(abbrv, abbrv.toUpperCase());
      }
    });

    // transform truncated 'iter' to 'iterations'
    if (formatted_param.includes('iter') && !formatted_param.includes('iterations')) {
      formatted_param = formatted_param.replace('iter', 'iterations');
    }

    return formatted_param;
  } else return param;
}

// Construct human readable key: val pair for solver parameter
export function formatParam([p_key, p_val], solver_enums) {
  const param_key = formatParamText(p_key);
  let param_val = formatParamText(p_val);

  // Translate enum int to its string val
  if (solver_enums && Object.keys(solver_enums).includes(p_key)) {
    param_val = solver_enums[p_key][p_val];
  }

  return (
    <React.Fragment>
      {param_key}: {param_val}
    </React.Fragment>
  );
}

// Show params list
export function paramsList(visible_solver_params, solver_enums = null) {
  const valid_datatype = ['string', 'number', 'boolean', 'bigint'];
  return Array.from(visible_solver_params).map((param, index) => {
    if (valid_datatype.includes(typeof param[1])) {
      return (
        <React.Fragment key={index}>
          <div className='param'>{formatParam(param, solver_enums)}</div>
        </React.Fragment>
      );
    }
  });
}

export function listSolverParams(solver_params, blacklist, solver_enums = null) {
  // blacklist = list of properties not to be shown as individual solver parameters

  // convert solver parameter object to array
  const solver_param_arr = Object.entries(solver_params);

  // and weed out blacklisted solver parameter properties
  const visible_solver_params = filterSolverParams(solver_param_arr, blacklist);

  if (visible_solver_params.length > 0) {
    return paramsList(visible_solver_params, solver_enums);
  } else return '';
}

export function showParams(solver, blacklist, solver_enums) {
  if (solver !== undefined && solver !== null && solver.solver_params !== undefined && solver.solver_params !== null) {
    const params_list = listSolverParams(solver.solver_params, blacklist, solver_enums);

    if (params_list !== '') {
      return <div className='solver-details'>{params_list}</div>;
    } else return '';
  } else return '';
}

export function solverTooltip(solver_name, solver_params, class_names) {
  return (
    <React.Fragment>
      {solver_params !== '' ? (
        <QTip
          className='solver-params-tooltip'
          content={solver_params}
          trigger='mouseenter click'
          hideOnClick={true}
          offset={[0, 5]}
          placement='top-start'>
          <span className={`solver has-params ${class_names}`}>{solver_name}</span>
        </QTip>
      ) : (
        <span className='solver'>{solver_name}</span>
      )}
    </React.Fragment>
  );
}

// Translate enum values
export function translateEnum(obj, key_val, values, label) {
  if (obj.solver_params !== undefined && obj.solver_params !== null && obj.solver_params[key_val] !== undefined) {
    return (
      <React.Fragment>
        <span>
          {label}: {values[obj.solver_params[key_val]]}
        </span>
        <br />
      </React.Fragment>
    );
  } else return '';
}

// Translate key value pair
export function transformKeyValPair(obj, key_val, label, sci_note) {
  if (obj.solver_params !== undefined && obj.solver_params !== null && obj.solver_params[key_val] !== undefined) {
    return (
      <React.Fragment>
        <span>
          {label}:{' '}
          {sci_note ? (
            convertToSciNote(obj.solver_params[key_val])
          ) : (
            <React.Fragment>{obj.solver_params[key_val]}</React.Fragment>
          )}
        </span>
        <br />
      </React.Fragment>
    );
  } else return '';
}

// Determine problem type
export function showBadge(problem) {
  const ref = {
    AQFEP: 'AQFEP',
    CCSD: 'CCSD',
    ConformerGenerator: 'Conf Gen',
    DFT: 'DFT',
    Docking: 'Docking',
    FNO: 'FNO',
    GeometryOptimizer: 'Geo Opt',
    GNINA: 'GNINA',
    GrowingStringMethod: 'RPP',
    GVB: 'GVB',
    HartreeFock: 'HF',
    OrganicCrystalGenerator: 'OC Gen',
    OrganicCrystalOptimizer: 'OC Opt',
    SNOGVBPP: 'SNO',
    PWDFT: 'PWDFT'
  };

  if (problem.solver_parameters) {
    if (problem.solver_parameters.next_solver && ref[problem.solver_parameters.next_solver] !== undefined) {
      return ref[problem.solver_parameters.next_solver];
    } else if (problem.solver_parameters.next_solver && problem.solver_parameters.next_solver.includes('ANI')) {
      return 'ML';
    } else if (
      problem.solver_parameters.next_solver &&
      problem.solver_parameters.next_solver === 'IncrementalDecomposition'
    ) {
      if (
        problem.solver_parameters.solver_params &&
        problem.solver_parameters.solver_params.solver &&
        problem.solver_parameters.solver_params.solver.next_solver === 'SNOGVBPP'
      ) {
        return 'iFCI';
      } else if (
        problem.solver_parameters.solver_params &&
        problem.solver_parameters.solver_params.solver &&
        problem.solver_parameters.solver_params.solver.next_solver === 'FNO'
      ) {
        return 'MI-FNO';
      } else return 'Other';
    } else if (problem.fragment_type && problem.fragment_type === 'QuantumCircuit') {
      return 'Quantum Circuit';
    } else return 'Other';
  }
}
