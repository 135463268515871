import React from 'react';
import PropTypes from 'prop-types';

import { QErrorMessage } from '../notifications/Notifications';

const SupportMessage = props => {
  return (
    <React.Fragment>
      <QErrorMessage className={props.errorClasses} text={<span className='bold'>{props.error}</span>} />
      <div className={`something-went-wrong ${props.messageClasses}`}>
        <h3>Hmmm... Something went wrong.</h3>
        <p>
          Please try refreshing this page shortly. If the problem persists, please report this using the
          Support/Feedback widget at the bottom right of the window.
        </p>
      </div>
    </React.Fragment>
  );
};

SupportMessage.propTypes = {
  errorClasses: PropTypes.string,
  messageClasses: PropTypes.string,
  error: PropTypes.string
};

export default SupportMessage;
