export function browserStore(action, name, value = null, type = 'local') {
  // Standardized browser storage function to simplify usage in other parts of the frontend code
  if (type === 'session') {
    if (action === 'get') {
      return JSON.parse(sessionStorage.getItem(`qemist.${name}`));
    }
    if (action === 'set') {
      return sessionStorage.setItem(`qemist.${name}`, JSON.stringify(value));
    }
    if (action === 'remove') {
      return sessionStorage.removeItem(`qemist.${name}`);
    }
  } else if (type === 'local') {
    if (action === 'get') {
      return JSON.parse(localStorage.getItem(`qemist.${name}`));
    }
    if (action === 'set') {
      return localStorage.setItem(`qemist.${name}`, JSON.stringify(value));
    }
    if (action === 'remove') {
      return localStorage.removeItem(`qemist.${name}`);
    }
  }
}
