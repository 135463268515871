export const defaultBasis = 'cc-pvdz';

export const defaultMolFiles = {
  host: [],
  guest: [],
  combo: [],
  gnina_autobox_ligand: [],
  gnina_flex: [],
  gnina_flexdist_ligand: [],
};

export const solversFileMap = {
  AQFEP: {
    host: {
      allowedFileTypes: ['pdb', 'sdf'],
      label: 'Hosts',
      description: '',
      numFilesAllowed: 1,
    },
    guest: {
      allowedFileTypes: ['sdf'],
      label: 'Guest',
      description: '',
      numFilesAllowed: 1,
    },
  },
  GNINA: {
    host: {
      allowedFileTypes: ['pdb'],
      label: 'Receptor',
      description: 'The file containing the receptor structure.',
      numFilesAllowed: 1,
    },
    guest: {
      allowedFileTypes: ['sdf'],
      label: 'Ligand',
      description: 'The file(s) containing the ligand structure(s)',
      numFilesAllowed: 100,
    },
    gnina_autobox_ligand: {
      allowedFileTypes: ['sdf'],
      label: 'Pocket/Ligand Box',
      description: 'The ligand to use to specify the automatic search box.',
      numFilesAllowed: 1,
    },
    gnina_flex: {
      allowedFileTypes: ['pdbqt'],
      label: 'Flexible Residues',
      description: 'The PDBQT file to use to specify the flexible residues.',
      numFilesAllowed: 1,
    },
    gnina_flexdist_ligand: {
      allowedFileTypes: ['sdf'],
      label: 'Flexible Distance',
      description: 'The distance from the ligand after which residues will not be considered flexible.',
      numFilesAllowed: 1,
    },
  },
};
