import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class SortByToggle extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      pointing_down: true
    };
  }

  toggle() {
    this.setState(prevState => ({
      pointing_down: !prevState.pointing_down
    }));
  }

  render(props) {
    return (
      <React.Fragment>
        <div
          id='SortByToggle'
          className={`${this.props.className} ${this.props.active === true ? 'active' : ''}`}
          onClick={this.props.onClick}>
          <i className={`icon-dropdown ${this.props.direction === true ? 'asc' : 'desc'}`} />
        </div>
      </React.Fragment>
    );
  }
}

SortByToggle.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  direction: PropTypes.bool.isRequired
};

export default SortByToggle;
