import React, { useState, useEffect } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import ProvideSubmitData from '../../hoc/SubmitData';
import { useUserData } from '../../hoc/UserData';
import { getOrgName, getAdminGroupName, formatCurrency } from '../../hoc/CommonFunctions';
import { LoadingFilled, LoadingInline } from '../../components/loading/Loading';
import QDropdown, { Toggle, Menu, Item, Header, Divider } from '../../components/dropdown/Dropdown';
import AdminLayout from '../AdminLayout';
import GroupAdmin from '../../views/groupAdmin/GroupAdmin';
import RequestLog from '../../views/RequestLog';
import Projects from '../../views/projects/Projects';
import Download from '../../views/Download';
import Tangelo from '../../views/tangelo/Tangelo';
import TestComponents from '../../debug/TestComponents';
import Documentation from '../../views/Documentation';
import SubmitProblem from '../../views/submitProblem/SubmitProblem';
import Settings from '../../views/account/Settings';
import Announcements from '../../components/announcements/Announcements';
import AnnouncementsView from '../../views/Announcements';
// Overlay Content Components
import SingleProblem from '../../components/requestLog/SingleProblem';
import CreditPools from '../../views/admin/components/CreditPools';
import CreditPoolDetails from '../../components/credits/CreditPoolDetails';
import ProjectDetails from '../../views/admin/components/projects/ProjectDetails';
import ProjectsDropdown from '../../components/projects/ProjectsDropdown';

const PostAuthCommonContent = props => {
  // Init
  const { balance, setUpdatingBalance, overlayLevel1, setOverlayLevel1, projectUpdating } = useUserData();
  const [timers, setTimers] = useState([]);
  const [adminAccess, setAdminAccess] = useState(false);
  const [currentAdminGroup, setCurrentAdminGroup] = useState(null);
  const [updateAnnouncements, setUpdateAnnouncements] = useState(false);
  const [overlayLevel1Shown, setOverlayLevel1Shown] = useState(false);

  useEffect(() => {
    if (props.currentOrg !== null) {
      // Get Org's Group Name without the Suffix
      let current_org_name = getOrgName(props.currentOrg);
      // 1. Look through user's Admin Groups
      // 2. If Admin Group name matches Current Org Group name then user has admin access to the current org
      if (props.adminGroups && props.adminGroups.length > 0) {
        props.adminGroups.forEach(group => {
          let group_name = getAdminGroupName(group);
          if (current_org_name === group_name) {
            setAdminAccess(true);
            setCurrentAdminGroup(group);
          }
        });
      } else setCurrentAdminGroup({});
      setUpdatingBalance(true);
    }

    if (overlayLevel1 !== null) {
      setOverlayLevel1Shown(true);
    }

    return () => {
      setAdminAccess(false);
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, [props.currentOrg, props.workspaceUpdating, overlayLevel1]);

  function listOrgs(orgs) {
    return Array.from(orgs).map((org, index) => (
      <div key={index}>
        {org._id === props.currentOrg._id ? (
          <div className='org-name'>
            <span className='mr-2'>{getOrgName(org)}</span>
            <span className='badge current'>Current</span>
          </div>
        ) : (
          <Item className='org-name' onClick={() => props.updateCurrentOrg(org)}>
            {getOrgName(org)}
          </Item>
        )}
      </div>
    ));
  }

  function closeOverlayLevel1() {
    setOverlayLevel1Shown(false);
    setUpdatingBalance(true);

    let timer_item = setTimeout(() => {
      setOverlayLevel1(null);
    }, 500);
    timers.push(timer_item);
  }

  return (
    <div id='PostAuthCommonContent'>
      <div id='Sidebar'>
        {/* Top Items */}
        <div className='top-items'>
          <Link className='sidebar-item navbar-brand' to='/'>
            <span className='text-xs bold reversed-light app-name'>QEMIST Cloud</span>
          </Link>

          {/* Nav Items */}
          {/* Admin only link */}
          {props.user.role === 'qemist_admin' ? (
            <Link
              className={`${window.location.href.includes('/admin') ? 'active' : ''} sidebar-item link`}
              to={props.adminPath}>
              <i className='reversed icon-people' />
              <span className='reversed bold text' data-testid='admin-sidebar-item'>
                Admin Panel
              </span>
            </Link>
          ) : (
            ''
          )}

          {/* Global links */}
          <Link
            className={`${window.location.href.includes('/#/request-log') ? 'active' : ''} sidebar-item link`}
            to='/request-log'>
            <i className='reversed icon-dashboard' />
            <span className='reversed bold text'>Requests</span>
          </Link>
          <Link
            className={`${window.location.href.includes('/submit') ? 'active' : ''} sidebar-item link`}
            to='/submit'>
            <i className='reversed icon-solver' />
            <span className='reversed bold text'>Submit</span>
          </Link>
          <div className={`${window.location.href.includes('/docs') ? 'active' : ''} sidebar-item`}>
            <i className='reversed-light icon-documentation' />
            <span className='reversed-light bold text'>Documentation</span>
          </div>
          <div className='second-level'>
            <Link className={`${window.location.href.includes('/docs') ? 'active' : ''} sidebar-item link`} to='/docs'>
              <i className={`reversed ${props.sidebarExpanded ? 'icon-next-level' : 'icon-documentation'}`} />
              <span className='reversed bold text'>User Guide</span>
            </Link>
            <Link
              className={`${window.location.href.includes('/tangelo') ? 'active' : ''} sidebar-item link`}
              to='/tangelo'>
              <i className={`reversed ${props.sidebarExpanded ? 'icon-next-level' : 'icon-documentation'}`} />
              <span className='reversed bold text'>Tangelo</span>
            </Link>
          </div>
          <Link
            className={`${window.location.href.includes('/download') ? 'active' : ''} sidebar-item link`}
            to='/download'>
            <i className='reversed icon-download' />
            <span className='reversed bold text'>Download</span>
          </Link>
        </div>

        {/* Footer Items */}
        <div className='bottom-items'>
          <button className='sidebar-item' onClick={props.toggleSidebar}>
            <i className='icon-arrow-left' />
            <span className='text'>Hide</span>
          </button>
        </div>
      </div>

      {/* Top Bar */}
      <div id='Topbar' className='flex-sm-wrap'>
        <div className='left-content d-flex align-items-center px-3'>
          <div className='d-flex align-items-center flex-md-nowrap workspace'>
            <span className='label-sm reversed-light'>Workspace:</span>
            {/* Dropdown visible if user belongs to an org */}
            {props.orgs.length > 0 ? (
              <QDropdown
                className={`${
                  window.location.href.includes('/group-admin') ? 'active' : ''
                } reversed current-org-dropdown sidebar-item`}>
                <Toggle>
                  <span className='bold reversed' data-testid='workspace-dropdown-item'>
                    {props.currentOrg._id !== undefined ? getOrgName(props.currentOrg) : 'Personal'}
                  </span>
                </Toggle>
                <Menu data-testid='org-dropdown-menu' className='light'>
                  <Header>Workspace</Header>
                  {listOrgs(props.orgs)}
                  <Item className='org-name' onClick={() => props.updateCurrentOrg({})}>
                    <span className='mr-1'>Personal</span>
                    {props.currentOrg._id === undefined ? <span className='badge current'>Current</span> : ''}
                  </Item>
                  {/* Manage link only visible if user has group admin access */}
                  {adminAccess ? (
                    <React.Fragment>
                      <Divider />
                      <Link to={`/group-admin/${props.currentOrg._id}/manage`}>
                        <Item>Manage Access</Item>
                      </Link>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </Menu>
              </QDropdown>
            ) : (
              <React.Fragment>
                {/* Otherwise only show "Personal" */}
                <div
                  className='sidebar-item current-org-name bold reversed-light ml-1'
                  data-testid='workspace-dropdown-item'>
                  Personal
                </div>
              </React.Fragment>
            )}
          </div>
          <div className='balance reversed d-flex align-items-center'>
            <span className='label-sm reversed-light ml-3 mr-2'>Credits:</span>
            {props.workspaceUpdating ? (
              <LoadingInline size='sm' color='light' />
            ) : (
              <Link to='/account/credits#1'>
                <span className={`bold accent ${balance <= 0 && 'danger'}`}>{formatCurrency(balance)}</span>
              </Link>
            )}
          </div>
        </div>
        <div className='right-content pl-3'>
          {/* Projects Dropdown */}
          <div className='reversed d-flex align-items-center'>
            <span className='label-sm reversed-light'>Project:</span>
            <ProjectsDropdown workspaceUpdating={props.workspaceUpdating} />
          </div>

          {/* Announcements */}
          <Announcements
            update={updateAnnouncements}
            updateAnnouncements={() => setUpdateAnnouncements(!updateAnnouncements)}
          />
          {/* Profile/Settings */}
          <QDropdown className='reversed'>
            <Toggle dataTestId={'dropdown-toggle'}>
              <i className='icon-profile' data-testid='profile-icon' />
            </Toggle>
            <Menu>
              <div className='item border-bottom'>
                <span className='reversed'>{props.user.email}</span>
              </div>
              <Link to='/account/credits#1'>
                <Item>Credits</Item>
              </Link>
              <Link to='/account/token'>
                <Item>API Token</Item>
              </Link>
              <Link to='/account/profile'>
                <Item>Profile</Item>
              </Link>
              <a href='/logout' onClick={props.logout}>
                <Item>Sign Out</Item>
              </a>
            </Menu>
          </QDropdown>
        </div>
      </div>

      {/* Content Area */}
      {props.workspaceUpdating || projectUpdating ? (
        <LoadingFilled />
      ) : (
        <React.Fragment>
          {!props.showTerms && (
            <Switch>
              {/* <Route path='/test' render={() => <TestComponents />} /> */}
              <Route path='/admin' render={() => <AdminLayout />} />
              <Route path='/group-admin' render={() => <GroupAdmin adminGroup={currentAdminGroup} />} />
              <Route path='/request-log' render={() => <RequestLog />} />
              <Route path='/projects' render={() => <Projects />} />
              <Route path='/download' render={() => <Download />} />
              <Route path='/docs' render={() => <Documentation />} />
              <Route path='/tangelo' render={() => <Tangelo />} />
              <Route
                path='/submit'
                render={() => (
                  <ProvideSubmitData>
                    <SubmitProblem />
                  </ProvideSubmitData>
                )}
              />
              <Route
                path='/announcements'
                render={() => (
                  <AnnouncementsView
                    update={updateAnnouncements}
                    updateAnnouncements={() => setUpdateAnnouncements(!updateAnnouncements)}
                  />
                )}
              />
              <Route path='/account/*' render={() => <Settings />} />
              <Route path='/'>
                <Redirect to='/request-log' />
              </Route>
            </Switch>
          )}
        </React.Fragment>
      )}

      {/* Overlay Level 1 */}
      {overlayLevel1 !== null ? (
        <div id='OverlayLevel1'>
          {/* Content */}
          <div className='overlay-content-wrapper'>
            <div
              className={`overlay-content content-panel ${overlayLevel1.content_type} ${
                overlayLevel1Shown ? 'show' : 'hide'
              }`}>
              {/* Show Single Problem */}
              {overlayLevel1.content_type === 'problem' && (
                <SingleProblem problemHandle={overlayLevel1.problem_handle} projectId={overlayLevel1.project_id} />
              )}
              {/* Show Credit Pools */}
              {overlayLevel1.content_type === 'credit-pools' && <CreditPools id={overlayLevel1.credit_pool_id} />}
              {/* Show Credit Pool Details */}
              {overlayLevel1.content_type === 'credit-pool-details' && (
                <CreditPoolDetails poolId={overlayLevel1.pool_id} />
              )}
              {/* Show Project Details */}
              {overlayLevel1.content_type === 'project-details' && (
                <ProjectDetails
                  selectedProject={overlayLevel1.selected_project}
                  projects={overlayLevel1.projects}
                  user={overlayLevel1.user}
                />
              )}
            </div>
            <div
              onClick={() => closeOverlayLevel1()}
              className={`${overlayLevel1Shown ? 'show' : 'hide'} bold close-tab no-mobile p-3`}>
              Close
            </div>
            <div className='overlay' onClick={() => closeOverlayLevel1()}></div>
          </div>
          <div className='overlay' onClick={() => closeOverlayLevel1()}></div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

PostAuthCommonContent.propTypes = {
  sidebarExpanded: PropTypes.bool.isRequired,
  showTerms: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  orgs: PropTypes.array.isRequired,
  currentOrg: PropTypes.object.isRequired,
  updateCurrentOrg: PropTypes.func.isRequired,
  workspaceUpdating: PropTypes.bool.isRequired,
  adminGroups: PropTypes.array,
  adminPath: PropTypes.string,
  toggleSidebar: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default PostAuthCommonContent;
