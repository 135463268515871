import { evaluate } from 'mathjs';

export const emailFormatting =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
export const stringFormat = /^.+$/;
export const mathFormat = /^[\(\)\^\/\*\+\-\d\s\.e]+$/;
export const problemHandleFormat = /^[\d]{16,}$/;
export const numberFormat = /^-{0,}[\d]+$/;
export const objectFormat = /^\{(.|\n)*\}$/gm;
export const listFormat = /^\[.*\]$/;
// prettier-ignore
export const isTuple = /^(\((((\s*-?[\d]+\.?[\d]*)|(\s*"[^,\\'"]*"))(\s*,\s*)(?!\)))*((\s*-?[\d]+\.?[\d]*)|(\s*"[^,\\'"]*"))\s*\))|(\(((\s*-?[\d]+\.?[\d]*)|(\s*"[^,\\'"]*"))\s*,\s*\))$/;
export const isFloat = /^-{0,}[\d]{1,}\.{0,1}[\d]{0,}$/;
// prettier-ignore
export const isList = /^(\[(((\s*-?[\d]+\.?[\d]*)|(\s*"[^,\\'"]*"))(\s*,\s*)(?!\]))*((\s*-?[\d]+\.?[\d]*)|(\s*"[^,\\'"]*"))*\s*\])$/;
export const isInt = /^-{0,}[\d]+$/;

export function handleInputChange(e, props, name, callback) {
  e.persist();
  // Persist cursor position
  const pointer = e.target.selectionStart;
  const element = e.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = pointer;
    element.selectionEnd = pointer;
  });

  // Handle Formik Form validations
  // https://formik.org/docs/api/formik
  props.handleChange(e);
  props.setFieldTouched(name, true, false);
  callback(e);
}

export function isValidAdjustment(value, callback) {
  try {
    const adjustment_amount = evaluate(value);
    return callback(adjustment_amount);
  } catch (e) {
    return callback(0);
  }
}

export function isValidObject(value) {
  if (objectFormat.test(value)) {
    try {
      // If parsing JSON is valid, then the input value is a valid JSON object
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export function isValidFloat(value) {
  if (mathFormat.test(value)) {
    try {
      evaluate(value);
      return true;
    } catch (e) {
      return false;
    }
  } else return false;
}

export function constructSelectMenu(arr) {
  /* 
  Format a list that can be consumed by a select element.

  Arg formats:
    • [['ENUM KEY', 0], ['ENUM KEY', 1], 'option name 1', 'option name 2']

  Returns:
    [
      ['ENUM KEY', 'ENUM KEY'],
      ['option 1', 'option 1'],
      ['option 2', 'option 2'],
    ]
  */
  let menu = [];
  arr.forEach(option => {
    if (Array.isArray(option)) {
      const item = option[0];
      menu.push([item, item]);
    } else if (typeof option === 'string') {
      menu.push([option, option]);
    } else if (typeof option === 'number') {
      menu.push([option.toString(), option.toString()]);
    }
  });
  return menu;
}

export function checkValueChanged(original_obj, new_obj) {
  /*
  Check whether at least one key value pair has changed in the objects provided.

  Args:
    original_obj and new_obj must contain the same keys

  Returns:
    true if any value in the new_obj does not match the values in the original object
    otherwise false. 
  */
  if (Object.keys(new_obj).some(key => original_obj[key] !== new_obj[key])) {
    return true;
  } else return false;
}
