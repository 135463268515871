import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../hoc/Auth';
import { QModal } from './Modal';
import { LoadingBlock } from '../loading/Loading';
import QButton from '../button/Button';
import { QErrorMessage } from '../notifications/Notifications';
import { QCheckbox } from '../form/FormElements';
import SearchForm from '../form/SearchForm';
import { resetSearchForm } from '../../hoc/FilterFuncs';

const SelectUserModal = props => {
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [timers, setTimers] = useState([]);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Get user emails
  const [emails, setEmails] = useState([]);
  const [fetchUserError, setFetchUserError] = useState(null);
  // Search emails
  const [query, setQuery] = useState('');
  const [filteredEmails, setFilteredEmails] = useState([]);
  // Select emails
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailString, setEmailString] = useState('');

  useEffect(() => {
    // Fetch users list
    fetchData(signal, '/api/users-via-ext', {}, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setFetchUserError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const users = response.data.users;

        let email_list = [];
        users.forEach(user => {
          email_list.push(user.email);
        });

        setEmails(email_list);
        setFilteredEmails(email_list);
      }
      setIsLoaded(true);
    });

    return () => {
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, []);

  function handleSearch(event) {
    event.preventDefault();

    // Update filtered email list
    let filtered_list = [];
    emails.forEach(email => {
      if (email.includes(query)) {
        filtered_list.push(email);
      }
    });

    setFilteredEmails(filtered_list);
  }

  function updateEmailString(list) {
    // Build email string
    let emails_string = '';
    for (let i = 0; i < list.length; i++) {
      if (i < list.length - 1) {
        emails_string = emails_string + list[i] + ', ';
      } else {
        emails_string = emails_string + list[i];
      }
    }

    setEmailString(emails_string);
  }

  function updateSelectedEmails(list) {
    setSelectedEmails(list);
    updateEmailString(list);
  }

  function removeEmail(email) {
    let modified_list = selectedEmails;
    const index = selectedEmails.indexOf(email);

    if (index !== -1) {
      modified_list.splice(index, 1);
    }

    setSelectedEmails(modified_list);
    updateEmailString(modified_list);
  }

  const selected_email_badges = Array.from(selectedEmails).map((email, index) => (
    <div className='email-badge mr-2 my-1' key={index}>
      <span className='d-flex align-items-center badge grey-badge'>
        {email}
        <i className='icon-close-small ml-1' onClick={() => removeEmail(email)} />
      </span>
    </div>
  ));

  return (
    <QModal
      onHide={props.toggleSelectUserModal}
      backdrop={true}
      show={props.selectUserModalOpen}
      size={'lg'}
      id={'SelectUserModal'}>
      {!isLoaded ? (
        <LoadingBlock className='transparent-bg' />
      ) : (
        <React.Fragment>
          {fetchUserError ? (
            <QErrorMessage className='row p0' text={<span className='bold'>{fetchUserError.message}</span>} />
          ) : (
            <React.Fragment>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <h2 className='mr-5'>Filter By User</h2>
                  <SearchForm
                    className='pt-0 mr-5'
                    handleSubmit={event => handleSearch(event)}
                    resetForm={event =>
                      resetSearchForm(emails, 'email', function (list) {
                        setQuery('');
                        setFilteredEmails(list);
                      })
                    }
                    updateQuery={event => setQuery(event.target.value)}
                    searchField='Emails'
                    query={query}
                  />
                </div>
                <QButton onClick={() => props.applyUserEmails(selectedEmails)} disabled={selectedEmails.length === 0}>
                  Apply
                </QButton>
              </div>
              {selectedEmails.length > 0 && (
                <div className='d-flex flex-wrap my-2 selected-emails info'>
                  <span className='mr-2 my-1'>Selected emails:</span>
                  {selected_email_badges}
                </div>
              )}
              <EmailList
                filteredEmails={filteredEmails}
                selectedEmails={selectedEmails}
                setSelectedEmails={list => updateSelectedEmails(list)}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </QModal>
  );
};

SelectUserModal.propTypes = {
  toggleSelectUserModal: PropTypes.func.isRequired,
  selectUserModalOpen: PropTypes.bool.isRequired,
  applyUserEmails: PropTypes.func.isRequired
};

const EmailList = props => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    setEmails(props.selectedEmails);
  }, [props.filteredEmails, props.selectedEmails]);

  function handleChange(element, email) {
    let selected_emails = emails;

    if (element.checked) {
      if (!emails.includes(email)) {
        selected_emails.push(email);
      }
    } else if (!element.checked) {
      const index = emails.indexOf(email);

      if (index !== -1) {
        selected_emails.splice(index, 1);
      }
    }

    props.setSelectedEmails(selected_emails);
  }

  const emails_list = Array.from(props.filteredEmails).map((email, index) => (
    <QCheckbox
      checked={emails.includes(email)}
      handleChange={element => handleChange(element, email)}
      label={email}
      key={index}
    />
  ));

  return <div className='d-flex flex-column align-items-start'>{emails_list}</div>;
};

EmailList.propTypes = {
  filteredEmails: PropTypes.array.isRequired,
  selectedEmails: PropTypes.array.isRequired,
  setSelectedEmails: PropTypes.func.isRequired
};

export default SelectUserModal;
