const $3Dmol = require('3dmol/build/3Dmol-nojquery.js');

export function formatAtomString(data) {
  let atom_string = data;

  // If data is not a string
  if (typeof atom_string !== 'string') {
    // If data is an array
    if (Array.isArray(data)) {
      let formatted_atom_string = '';

      function getCoordinates(coordinates) {
        let string = '';
        coordinates.forEach(coordinate => {
          string = string + '   ' + coordinate;
        });
        return string;
      }
      data.forEach(atom => {
        formatted_atom_string = formatted_atom_string + atom[0] + getCoordinates(atom[1]) + '\n';
      });

      atom_string = formatted_atom_string;
    } else {
      atom_string = `${data}`;
    }
  }

  // Replace tabs with spaces
  if (atom_string.indexOf('\t') > 0) {
    let tabs = /\t/g;
    let format_tabs = atom_string.replace(tabs, '    ');
    atom_string = format_tabs;
  }

  // Replace windows enter key with newline
  if (atom_string.indexOf('\r\n') > 0) {
    let cr = /\r\n/g;
    let format_enter_key = atom_string.replace(cr, '\n');
    atom_string = format_enter_key;
  }

  // Remove all spaces before atom char
  let spaces_before_atom = /\s+(?=[A-Za-z])/gm;
  let format_spacing = atom_string.replace(spaces_before_atom, '\n');
  atom_string = format_spacing;

  // Remove line breaks, tabs, numbers and spaces before first atom
  let throwaway_first_chars = /^[\\t\\r\\n\s\d]+(?=[A-Za-z])/gm;
  let format_first_line = atom_string.replace(throwaway_first_chars, '');
  atom_string = format_first_line;

  // Remove line breaks, tabs and spaces after last atom coordinate
  let throwaway_last_chars = /[\\t\\r\\n\s]+(?=$)/gm;
  let format_last_line = atom_string.replace(throwaway_last_chars, '');
  atom_string = format_last_line;

  // *****
  // Reconstruct atom string to remove indices
  // 1. Convert lines into array
  let lines = atom_string.split(/\n/g);
  // 2. Create placeholder for edited lines
  var new_lines = [];
  // 3. Insert edited lines into new_lines
  lines.forEach(line => {
    let atom_with_indices = /([A-Z]+)\d+/g;
    let match = atom_with_indices.exec(line);
    if (match !== null) {
      let edited_line = line.replace(match[0], match[1]);
      new_lines.push(edited_line);
    } else {
      new_lines.push(line);
    }
  });
  // 4. Join all strings in edited lines array
  let format_indices = new_lines.join('\n');
  atom_string = format_indices;

  // Add number of atoms count
  let formatted_atom_string;
  let num_of_atoms = atom_string.replace(/[^\n]/g, '').length + 1;
  formatted_atom_string = num_of_atoms + '\n\n' + atom_string;

  return formatted_atom_string;
}

// prettier-ignore
export function visualizeMolecule(problems) {
  if (problems !== null && problems !== '') {
    Array.from(problems).map(problem => {
      if (problem !== null && problem.fragment_data && problem.fragment_data.mol_data) {
        let id = problem.problem_handle;

        let element = document.getElementById(id);
        let config = { backgroundColor: '#3169B0' };
        // Call 3dmol.js
        try {
          let viewer = $3Dmol.createViewer(element, config);
          if (viewer !== undefined) {
            // Add molecule visualization
            viewer.addModel(formatAtomString(problem.fragment_data.mol_data.atom),'xyz');
            viewer.setStyle({}, {
              stick: { colorscheme: 'default', hidden: false, opacity: 1 },
              sphere: { colorscheme: 'default', hidden: false, opacity: 1, radius: 0.25 }
            });
            viewer.zoomTo();
            viewer.render();
            viewer.rotate(45, { x: 1, y: 1, z: 1 });
            viewer.zoom(1.2,1000);
          }
        }
        catch(err) {
          return element.innerHTML = "<span class='reversed text-xs'>Molecule visualization unavailable.</span>";
        }
      }
    });
  }
}

// prettier-ignore
export function visualizeSingleMolecule(problem, id) {
  if (problem && problem.fragment_data && problem.fragment_data.mol_data) {
    let element = document.getElementById(id);
    let config = { backgroundColor: '#3169B0' };
    // Call 3dmol.js
    try {
      let viewer = $3Dmol.createViewer(element, config);
      if (viewer !== undefined) {
        // Add molecule visualization
        viewer.addModel(formatAtomString(problem.fragment_data.mol_data.atom),'xyz');
        viewer.setStyle({}, {
          stick: { colorscheme: 'default', hidden: false, opacity: 1 },
          sphere: { colorscheme: 'default', hidden: false, opacity: 1, radius: 0.25 }
        });
        viewer.zoomTo();
        viewer.render();
        viewer.rotate(45, { x: 1, y: 1, z: 1 });
        viewer.zoom(1.2,1000);
      }
    }
    catch(err) {
      return element.innerHTML = "<span class='reversed text-xs'>Molecule visualization unavailable.</span>";
    }
  }
}

export function visualizeSmileStr(str, element = 'smiles-container', callback = function () {}) {
  var mol = RDKit.get_mol(str);
  var dest = document.getElementById(element);
  var svg;
  try {
    if (element.includes('enlarged')) {
      svg = mol.get_svg(500, 400);
    } else {
      svg = mol.get_svg();
    }
    if (dest !== null) {
      dest.innerHTML = "<div id='drawing'>" + svg + '</div>';
    }
  } catch (e) {
    dest.innerHTML = ''; // Remove any innerHTML content if error caught
    callback('error');
  }
}
