import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { getProjectName } from '../../hoc/CommonFunctions';
import { handleInputChange } from '../../hoc/FormFunctions';
import QFormWrapper from '../../components/form/QFormWrapper';
import QFormGroup from '../../components/form/QFormGroup';
import QButton from '../../components/button/Button';
import { QErrorMessage } from '../../components/notifications/Notifications';

const CreateNewProjectModal = props => {
  const reserveDashBang = /^((?!--!).)*$/;
  const reserveOrgKeyword = /^((?!--[O|o]rg).)*$/;
  const reserveProjectKeyword = /^((?!--[P|p]roject).)*$/;
  const reserveAdminKeyword = /^((?!--[A|a]dmin).)*$/;

  function checkProjectNames(value) {
    var e;
    var no_match = true;
    try {
      props.projects.forEach(project => {
        let project_name = getProjectName(project);
        if (project_name === value) {
          no_match = false;
          throw e;
        }
      });
    } catch (e) {}
    return no_match;
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('This field is required')
      .min(2, 'Minimum 2 characters, please')
      .max(30, 'Max 30 characters, please')
      .test('reserved-word-1', '--! is a reserved word', function (value) {
        return reserveDashBang.test(value);
      })
      .test('reserved-word-2', '--Org is a reserved word', function (value) {
        return reserveOrgKeyword.test(value);
      })
      .test('reserved-word-3', '--Project is a reserved word', function (value) {
        return reserveProjectKeyword.test(value);
      })
      .test('reserved-word-4', '--Admin is a reserved word', function (value) {
        return reserveAdminKeyword.test(value);
      })
      .test('duplicate-project-name', 'You already have a project with this name', function (value) {
        return checkProjectNames(value);
      }),
    description: yup.string(),
  });

  return (
    <React.Fragment>
      <h1>Create New Project</h1>
      {props.createProjectError ? (
        <QErrorMessage
          className='row mx-0 p0'
          text={
            <span className='bold'>
              Uh oh—something went wrong. Please try again later.
              <br />
              {props.createProjectError.message}
            </span>
          }
        />
      ) : (
        ''
      )}
      <QFormWrapper
        formId='CreateNewProjectForm'
        initialValues={{
          name: '',
          description: '',
        }}
        initialErrors={{
          name: true,
          description: true,
        }}
        validationSchema={validationSchema}
        onSubmit={values => props.createNewProject(values)}>
        {formProps => <CreateNewProjectForm {...formProps} />}
      </QFormWrapper>
      <QButton className='secondary mt-4' onClick={props.toggleNewProjectModal}>
        Cancel
      </QButton>
    </React.Fragment>
  );
};

const CreateNewProjectForm = props => (
  <React.Fragment>
    <div className='name'>
      <div className='row'>
        <QFormGroup
          containerClassName='col-12 col-md-8'
          element='text'
          id='name'
          name='name'
          label='Project Name'
          onChange={e => handleInputChange(e, props, 'name', () => {})}
          type='text'
          includeError={true}
        />
      </div>
    </div>
    <div className='description'>
      <div className='row'>
        <QFormGroup
          containerClassName='col-12 col-md-8'
          element='text'
          id='description'
          name='description'
          label='Description'
          onChange={e => handleInputChange(e, props, 'description', () => {})}
          type='text'
          includeError={true}
        />
      </div>
    </div>
    <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
      Add New Project
    </QButton>
  </React.Fragment>
);

CreateNewProjectModal.propTypes = {
  projects: PropTypes.array.isRequired,
  createProjectError: PropTypes.any,
  toggleNewProjectModal: PropTypes.func.isRequired,
  createNewProject: PropTypes.func.isRequired,
};

export default CreateNewProjectModal;
