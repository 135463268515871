import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { emailFormatting } from '../../hoc/FormFunctions';
import AddMembersForm from '../../components/form/AddMembersForm';
import QFormWrapper from '../../components/form/QFormWrapper';
import QButton from '../../components/button/Button';
import { QErrorMessage } from '../../components/notifications/Notifications';

const AddMembersModal = props => {
  const validationSchema = yup.object().shape({
    members: yup
      .string()
      .required('This field is required')
      .test('email-format', 'Invalid email format', function (value) {
        if (value !== undefined) {
          let new_value = value.replace(/\s+/, '');
          let emails = new_value.split(',');
          // evaluate each entry
          let verdicts = [];
          emails.forEach(email => {
            if (email !== '') {
              let verdict = emailFormatting.test(email);
              verdicts.push(verdict);
            }
          });
          // evaluate all entries
          if (verdicts.every((val, i, arr) => val === true)) {
            return true;
          } else return false;
        }
      }),
  });

  return (
    <React.Fragment>
      <h1>Add Members to Project</h1>
      {props.userNotFound ? (
        <React.Fragment>
          <h2>Email Address Not Found</h2>
          <p>
            One or more email addresses you entered could not be found in our database and could not be added to your
            project.
          </p>
          <p> Please verify your team member's email address and try again.</p>
          <QButton className='mt-4' onClick={props.toggleAddMembersModal}>
            Ok
          </QButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            Collaborate with other team members by inviting them to <span className='bold'>{props.projectName}</span>.
          </p>
          <p>They will be granted the same permissions you have (ability to add and remove members).</p>
          {props.addMembersError ? (
            <QErrorMessage
              className='row p0'
              text={
                <span className='bold'>
                  Uh oh—something went wrong. Please try again later.
                  <br />
                  {props.addMembersError.message}
                </span>
              }
            />
          ) : (
            ''
          )}
          <QFormWrapper
            formId='AddMembersForm'
            initialValues={{
              members: '',
            }}
            initialErrors={{
              members: true,
            }}
            validationSchema={validationSchema}
            onSubmit={values => props.addMembers(values)}>
            {formProps => <AddMembersForm {...formProps} />}
          </QFormWrapper>
          <QButton className='secondary mt-4' onClick={props.toggleAddMembersModal}>
            Cancel
          </QButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

AddMembersModal.propTypes = {
  projectName: PropTypes.string.isRequired,
  addMembersError: PropTypes.any,
  userNotFound: PropTypes.bool.isRequired,
  toggleAddMembersModal: PropTypes.func.isRequired,
  addMembers: PropTypes.func.isRequired,
};

export default AddMembersModal;
