import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const Pagination = props => {
  const [inputValue, setInputValue] = useState(1);

  useEffect(() => {
    if (props.currentPage !== inputValue) {
      setInputValue(props.currentPage);
    }
  }, [props.currentPage]);

  // Handle pagination update
  function updatePagination(value) {
    if (value !== props.currentPage) {
      props.updatePage(value);
      // Sync update pagination default inputs (top & bottom of problem list)
      let pagination_input_1 = document.getElementsByClassName('pagination-input')[0];
      let pagination_input_2 = document.getElementsByClassName('pagination-input')[1];
      if (pagination_input_1 !== undefined) {
        pagination_input_1.value = value;
      }
      if (pagination_input_2 !== undefined) {
        pagination_input_2.value = value;
      }
    }
  }

  // Handle pagination prev/next button clicks
  function changePage(direction) {
    let new_value;
    if (direction === 'add') {
      new_value = parseInt(props.currentPage, 10) + 1;
    } else {
      new_value = parseInt(props.currentPage, 10) - 1;
    }
    if (new_value < 1) {
      new_value = 1;
    }
    if (new_value > props.pages) {
      new_value = props.pages;
    }
    setInputValue(new_value);
    updatePagination(new_value);
  }

  // Handle pagination input change
  function changeInputValue(e) {
    let value = e.target.value;
    let num = /^\d+$/;
    // If value is not a number
    if (num.test(value) === false) {
      setInputValue('');
    }
    // Replace input with max page number
    else if (value >= props.pages) {
      setInputValue(props.pages);
    }
    // Replace input with min page number
    else if (value <= 1) {
      setInputValue(1);
    }
    // Else set user input
    else {
      setInputValue(value);
    }
  }

  // Handle pagination input submit
  function updateData(e) {
    e.preventDefault();
    if (inputValue === '') {
      setInputValue(1);
      updatePagination(1);
    } else {
      updatePagination(inputValue);
    }
  }

  return (
    <div id='Pagination' className='d-flex align-items-center'>
      {/* Pagination */}
      <div className='total-sets mr-2'>
        Page{' '}
        {props.pages > 1 ? (
          <React.Fragment>
            <form onSubmit={e => updateData(e)}>
              <input className='pagination-input' onChange={e => changeInputValue(e)} value={inputValue} />
            </form>{' '}
          </React.Fragment>
        ) : (
          '1 '
        )}
        of {props.pages}
        {props.pages > 1 && (
          <React.Fragment>
            <span className='pagination-arrow' onClick={() => changePage('subtract')}>
              <i className='icon-arrow-left' />
            </span>
            <span className='pagination-arrow' onClick={() => changePage('add')}>
              <i className='icon-arrow-right' />
            </span>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export const PaginationSummary = props => {
  return (
    <div id='PaginationSummary' className='d-flex align-items-center'>
      {/* Showing #-# of Total */}
      <div className={`mr-4 total-data`}>
        {props.dataTotal ? (
          <div className='numbers'>
            <span className='mr-1'>Showing</span>
            <span className='highlight bold'>
              {props.dataStart + 1} - {props.dataEnd + 1 <= props.dataTotal ? props.dataEnd + 1 : props.dataTotal}
            </span>
            <span className='mx-1'>of</span>
            <span className='highlight bold'>{props.dataTotal}</span>
            <span className='ml-1'>{props.dataType}</span>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updatePage: PropTypes.func.isRequired,
};

PaginationSummary.propTypes = {
  dataTotal: PropTypes.number,
  dataStart: PropTypes.number.isRequired,
  dataEnd: PropTypes.number.isRequired,
  dataType: PropTypes.string.isRequired,
};

export default Pagination;
