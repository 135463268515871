import React, { useState, useEffect } from 'react';
import moment from 'moment';
// prettier-ignore
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';

// Components
// prettier-ignore
import { getOrgName, getOrgsList, getDataTotal, convertSecondstoTime,  initRangeStart, getRangeEnd, allRangeStart } from '../../../hoc/CommonFunctions';
import { useAuth } from '../../../hoc/Auth';
import { LoadingFilled, LoadingBlock, LoadingInline } from '../../../components/loading/Loading';
import QButton from '../../../components/button/Button';
import DatePicker, { ShowAllButton } from '../../../components/datepicker/DatePicker';
import { QSelect } from '../../../components/form/FormElements';
import SelectUserModal from '../../../components/modal/SelectUserModal';

const ViewMetrics = props => {
  // Init
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [timers, setTimers] = useState([]);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Data
  const [dataType, setDataType] = useState('problems_sent');
  const [aggregateBy, setAggregateBy] = useState('interval_by_day');
  const [dataKey, setDataKey] = useState('count');
  const [dataKey2, setDataKey2] = useState('');
  const [chartColor, setChartColor] = useState('#3169B0');
  const [chartColor2, setChartColor2] = useState('#3169B0');
  const [rangeStart, setRangeStart] = useState(initRangeStart);
  const [rangeEnd, setRangeEnd] = useState(getRangeEnd());
  const [data, setData] = useState([]);
  const [countTotal, setCountTotal] = useState(null);
  const [convertedTime, setConvertedTime] = useState('');
  const [userCostTotal, setUserCostTotal] = useState('');
  const [runCostTotal, setRunCostTotal] = useState('');
  const [totalProfit, setTotalProfit] = useState('');
  const [error, setError] = useState(null);
  // Filters
  const [filterBy, setFilterBy] = useState('none');
  const [updatingFilter, setUpdatingFilter] = useState(false);
  // Filter by org
  const [orgs, setOrgs] = useState([]);
  const [orgsOptions, setOrgsOptions] = useState([['all', 'All']]);
  const [selectedOrg, setSelectedOrg] = useState({ select_option: 'all', _id: null });
  // Filter by users
  const [usersModalOpen, setUsersModalOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailString, setEmailString] = useState('');
  // Update Data
  const [updatingMetrics, setUpdatingMetrics] = useState(false);
  const [updatingOrg, setUpdatingOrg] = useState(false);
  const [updatingAggregate, setUpdatingAggregate] = useState(false);

  function fetchMetrics(data_type, org_id = null, user_emails = null) {
    const body = {
      range_start: rangeStart.toFixed(0),
      range_end: rangeEnd.toFixed(0),
      metrics_type: data_type,
      org_id: org_id,
      user_emails: user_emails,
      interval: aggregateBy,
    };

    fetchData(signal, '/api/metrics', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setIsLoaded(true);
        setData(response.data);

        if (data_type === 'problems_sent') {
          // Get total count for range
          let count_total = getDataTotal(response.data, 'count');
          setCountTotal(count_total);
        }

        if (data_type === 'solve_time') {
          // Get total seconds for range
          let time_total = getDataTotal(response.data, 'total_solve_time');
          convertSecondstoTime(time_total, timestring => setConvertedTime(timestring));
        }

        if (data_type === 'cost_profit') {
          // Get total amount for range
          let user_cost_total = getDataTotal(response.data, 'price');
          setUserCostTotal(user_cost_total.toFixed(2));
          let run_cost_total = getDataTotal(response.data, 'cost');
          setRunCostTotal(run_cost_total.toFixed(2));
          setTotalProfit((user_cost_total - run_cost_total).toFixed(2));

          // Convert cost data to two decimal places
          let data = [...response.data];
          for (let i = 0; i < data.length; i++) {
            data[i].price = data[i].price.toFixed(2);
            data[i].cost = data[i].cost.toFixed(2);
          }
          setData(data);
        }
      }
      setUpdatingOrg(false);
      setUpdatingMetrics(false);
      setUpdatingFilter(false);
    });
  }

  useEffect(() => {
    fetchMetrics(dataType, selectedOrg._id, selectedEmails);

    return () => {
      abortController.abort();
      setError(null);
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, [rangeStart, rangeEnd, dataKey, selectedOrg, aggregateBy, selectedEmails]);

  function fetchOrgs() {
    fetchData(signal, '/api/get-orgs', null, 'GET', response => {
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        let orgs_list = getOrgsList(response.data);
        let orgs_options_list = [['all', 'All']];

        orgs_list.forEach(item => {
          // Construct options list in the format:
          // [['option_value_1', 'Option Label 1'], ['option_value_2', 'Option Label 2']]

          // Construct option in the format:
          // ['option_value', 'Option Label']
          let org_name = getOrgName(item);
          // Construct value, replace space with underscores
          let value = org_name.replace(' ', '_');
          // Add option_value to object
          item.select_option = value.toLowerCase();
          // Construct options entry
          let select_option = [];
          select_option.push(value.toLowerCase());
          select_option.push(org_name);
          // Append options entry orgs_options_list
          orgs_options_list.push(select_option);
        });
        setOrgs(orgs_list);
        setOrgsOptions(orgs_options_list);
      }
      setUpdatingFilter(false);
    });
  }

  function updateFilter(value) {
    setUpdatingFilter(true);

    if (value === 'user') {
      setUsersModalOpen(true);
    }

    if (value === 'org') {
      fetchOrgs();
    } else {
      const t = setTimeout(() => setUpdatingFilter(false), 500);
      timers.push(t);
    }

    setFilterBy(value);
  }

  function resetFilter(filter) {
    setUpdatingFilter(true);

    const t = setTimeout(() => {
      setFilterBy('none');

      if (filter === 'org') {
        setSelectedOrg({ select_option: 'all', _id: null });
      }

      if (filter === 'user') {
        setEmailString('');
        setSelectedEmails([]);
      }

      setUpdatingFilter(false);
    }, 1000);
    timers.push(t);
  }

  function handleCallback(start, end) {
    const range_start = moment(start).unix();
    const range_end = moment(end).unix();

    setIsLoaded(false), setRangeStart(range_start), setRangeEnd(range_end);
  }

  function showAll() {
    const range_start = allRangeStart;
    const range_end = getRangeEnd();
    setRangeStart(range_start);
    setRangeEnd(range_end);
  }

  function updateChart(value) {
    // Update colours
    if (value === 'solve_time') {
      setChartColor('#BB75E7');
      setDataKey('total_solve_time');
      setDataKey2('');
    } else if (value === 'cost_profit') {
      setChartColor('#EEA623');
      setDataKey('price');
      setDataKey2('cost');
    } else {
      setChartColor('#3169B0');
      setDataKey('count');
      setDataKey2('');
    }
  }

  function updateOrg(value) {
    setUpdatingOrg(true);
    if (value === 'all') {
      setSelectedOrg({ select_option: 'all', _id: null });
    } else {
      orgs.some(org => {
        if (org.select_option === value) {
          setSelectedOrg(org);
        }
      });
    }
  }

  function toggleUsersModal() {
    setUsersModalOpen(!usersModalOpen);
    resetFilter('user');
  }

  function applyUserEmails(list) {
    setUpdatingFilter(true);
    setUsersModalOpen(!usersModalOpen);
    setSelectedEmails(list);

    // Build email string
    let emails_string = '';
    for (let i = 0; i < list.length; i++) {
      if (i < list.length - 1) {
        emails_string = emails_string + list[i] + ', ';
      } else {
        emails_string = emails_string + list[i];
      }
    }

    setEmailString(emails_string);
  }

  function updateMetricsType(value) {
    setUpdatingMetrics(true);
    setDataType(value);
    updateChart(value);
  }

  function updateAggregate(value) {
    setUpdatingAggregate(true);
    const t = setTimeout(() => {
      setAggregateBy(value);
      setUpdatingAggregate(false);
    }, 1000);
    timers.push(t);
  }

  function handleClick(props) {
    // console.log(props);
    // { index: num, value: value } = props;
  }

  const renderCustomizedLabel = props => {
    const { x, y, value } = props;
    const width = 80;
    const height = 26;
    function getLabelText(val) {
      if (dataType === 'solve_time') {
        return <React.Fragment>{val} sec.</React.Fragment>;
      } else if (dataType === 'cost_profit') {
        return <React.Fragment>${val}</React.Fragment>;
      } else {
        return <React.Fragment>{val}</React.Fragment>;
      }
    }

    return (
      <g onClick={() => handleClick(props)}>
        <rect x={x - width / 2} y={y - height / 2} width={width} height={height} fill={chartColor} rx='6' />
        <text x={x} y={y} fill='#fff' textAnchor='middle' dominantBaseline='central'>
          {getLabelText(value)}
        </text>
      </g>
    );
  };

  const renderCustomizedLabel2 = props => {
    const { x, y, value } = props;
    const width = 80;
    const height = 26;

    return (
      <g onClick={() => handleClick(props)}>
        <rect x={x - width / 2} y={y - height / 2} width={width} height={height} fill={chartColor2} rx='6' />
        <text x={x} y={y} fill='#fff' textAnchor='middle' dominantBaseline='central'>
          ${value}
        </text>
      </g>
    );
  };

  return (
    <div id='ViewMetrics'>
      {!isLoaded ? (
        <LoadingFilled className='with-subnav' />
      ) : (
        <div className='view-user container-fluid'>
          <div className='row'>
            <div className='col-12 content-container'>
              {/* Filters */}
              <div className='d-flex align-items-center flex-wrap mb-2 mb-lg-0 filters'>
                {/* Select Metrics Type */}
                <div className='mr-3 my-2 d-flex align-items-center'>
                  {updatingMetrics ? (
                    <LoadingInline />
                  ) : (
                    <QSelect
                      id='SelectDataType'
                      inputLabel={'Data Set'}
                      className='my-0'
                      onChange={e => updateMetricsType(e.target.value)}
                      defaultValue={dataType}
                      value={dataType}
                      inputProps={{ 'data-testid': 'select-data-type-input' }}
                      menu={[
                        ['problems_sent', 'Problems Sent'],
                        ['solve_time', 'Solve Time'],
                        ['cost_profit', 'Price vs Cost'],
                      ]}
                    />
                  )}
                </div>
                {/* Select Aggregate */}
                <div className='mr-3 my-2 d-flex align-items-center'>
                  {updatingAggregate ? (
                    <LoadingInline />
                  ) : (
                    <QSelect
                      id='SelectAggregate'
                      inputLabel={'Group Data By'}
                      className='my-0'
                      onChange={e => updateAggregate(e.target.value)}
                      defaultValue={aggregateBy}
                      value={aggregateBy}
                      inputProps={{ 'data-testid': 'select-aggregate-input' }}
                      menu={[
                        ['interval_by_hour', 'Hour'],
                        ['interval_by_day', 'Day'],
                        ['interval_by_month', 'Month'],
                        ['interval_by_year', 'Year'],
                      ]}
                    />
                  )}
                </div>
                {filterBy === 'none' ? (
                  <React.Fragment>
                    {/* Select Filter By */}
                    <div className='mr-3 my-2 d-flex align-items-center'>
                      {updatingFilter ? (
                        <LoadingInline />
                      ) : (
                        <QSelect
                          id='SelectFilter'
                          inputLabel={'Filter By'}
                          className='my-0'
                          onChange={e => {
                            setUpdatingFilter(true);
                            updateFilter(e.target.value);
                          }}
                          value={filterBy}
                          inputProps={{ 'data-testid': 'select-filter-input' }}
                          menu={[
                            ['none', '--'],
                            ['org', 'Org'],
                            ['user', 'User'],
                          ]}
                        />
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {updatingFilter ? (
                      <LoadingInline />
                    ) : (
                      <React.Fragment>
                        {/* Filter by org */}
                        {filterBy === 'org' ? (
                          <React.Fragment>
                            {updatingOrg ? (
                              <LoadingInline />
                            ) : (
                              <div className='d-flex align-items-center'>
                                <QSelect
                                  id='SelectOrg'
                                  inputLabel={'Filter by Organization'}
                                  className='my-0 org'
                                  onChange={e => updateOrg(e.target.value)}
                                  defaultValue={selectedOrg.select_option}
                                  value={selectedOrg.select_option}
                                  inputProps={{ 'data-testid': 'select-org-input' }}
                                  menu={orgsOptions}
                                />
                                <i
                                  className='icon-circle-ex ml-2 mr-3'
                                  data-testid='reset-filter-icon'
                                  onClick={() => resetFilter('org')}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {/* Filter by user */}
                            {filterBy === 'user' ? (
                              <React.Fragment>
                                <SelectUserModal
                                  selectUserModalOpen={usersModalOpen}
                                  toggleSelectUserModal={() => toggleUsersModal()}
                                  applyUserEmails={list => applyUserEmails(list)}
                                />
                                <div className='d-flex flex-wrap align-items-center w-100'>
                                  <span className='text-xs my-1 mr-2'>Showing data for users:</span>
                                  <span className='bold text-xs my-1 mr-2'>{emailString}</span>
                                  <QButton className='xsmall my-1' onClick={() => resetFilter('user')}>
                                    Reset
                                  </QButton>
                                </div>
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {/* Date Range Picker */}
                <div className='DateRangePicker mr-3 my-2'>
                  <div className='d-flex align-items-center filter-container'>
                    <DatePicker
                      rangeStart={rangeStart}
                      rangeEnd={rangeEnd}
                      handleCallback={(start, end) => handleCallback(start, end)}
                    />
                    <ShowAllButton onClick={() => showAll()} />
                  </div>
                </div>
                {/* Summary */}
                <div className='d-flex align-items-center mr-3 my-2 total-count'>
                  <span className='mono md bold mr-2'>
                    {dataType === 'problems_sent' && countTotal} {dataType === 'solve_time' && convertedTime}{' '}
                    {dataType === 'cost_profit' && <span>${userCostTotal}</span>}{' '}
                  </span>
                  <span className='text-xs'>
                    total {dataType === 'problems_sent' && 'problems submitted'}
                    {dataType === 'solve_time' && 'solve time'} {dataType === 'cost_profit' && 'price'} for this period
                  </span>
                </div>
                {/* Summary 2 */}
                {dataKey2 ? (
                  <React.Fragment>
                    <div className='d-flex align-items-center mr-3 my-2 total-count'>
                      <span className='mono md bold mr-2'>
                        <span>${runCostTotal}</span>{' '}
                      </span>
                      <span className='text-xs'>total cost for this period</span>
                    </div>
                    <div className='d-flex align-items-center my-2 total-count'>
                      <span className='mono md bold mr-2'>
                        <span>${totalProfit}</span>{' '}
                      </span>
                      <span className='text-xs'>earned for this period</span>
                    </div>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </div>
              {/* Chart */}
              {updatingMetrics ? (
                <LoadingBlock className='my-3' />
              ) : (
                <div className='chart-container'>
                  <ResponsiveContainer>
                    <AreaChart
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}>
                      <defs>
                        <linearGradient id='graphColor' x1='0' y1='0' x2='0' y2='1'>
                          <stop offset='5%' stopColor={chartColor} stopOpacity={0.8} />
                          <stop offset='95%' stopColor={chartColor} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id='graphColor2' x1='0' y1='0' x2='0' y2='1'>
                          <stop offset='5%' stopColor={chartColor2} stopOpacity={0.8} />
                          <stop offset='95%' stopColor={chartColor2} stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey={'interval'} />
                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign='bottom' height={36} />
                      <Area
                        type='monotone'
                        dataKey={dataKey}
                        stroke={chartColor}
                        fillOpacity={1}
                        fill='url(#graphColor)'></Area>
                      {/* Comparison data */}
                      {dataKey2 ? (
                        <Area
                          type='monotone'
                          dataKey={dataKey2}
                          stroke={chartColor2}
                          fillOpacity={1}
                          fill='url(#graphColor2)'></Area>
                      ) : (
                        ''
                      )}
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewMetrics;
