import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Announcements from '../components/announcements/Announcements';

const AnnouncementsView = props => {
  useEffect(() => {}, [props.update]);

  return (
    <div id='AnnouncementsView'>
      <div className='container-fluid content-container full-page'>
        <div className='row'>
          <div className='col-lg-1'></div>
          <div className='col-12 col-lg-6'>
            <Announcements
              className='full-page'
              update={props.update}
              updateAnnouncements={() => props.updateAnnouncements()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AnnouncementsView.propTypes = {
  update: PropTypes.bool.isRequired,
  updateAnnouncements: PropTypes.func.isRequired
};

export default AnnouncementsView;
