import React, { useState, useEffect, useContext, createContext } from 'react';

import { useAuth } from './Auth';
import { browserStore } from './BrowserStorage';
import { buildDefaultParameters, buildDefaultPipeline } from './submitDataFunctions';

// Initialize Context for submitDataContext
const submitDataContext = createContext({});

// ProvideSubmitData wrapper component
// Initiated in index.js
function ProvideSubmitData({ children }) {
  const data = useProvideSubmitData();
  return <submitDataContext.Provider value={data}>{children}</submitDataContext.Provider>;
}

// User object accessible through:
// const submitData = useSubmitData();
// Update user state in child component via:
// const { submitData, setSubmitData } = useSubmitData();
export const useSubmitData = () => {
  return useContext(submitDataContext);
};

// Create initial auth object for ProvideAuth wrapper
function useProvideSubmitData() {
  // Init
  const { fetchData } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [submitDataLoaded, setSubmitDataLoaded] = useState(null);
  // Get configs
  const [solverConfigs, setSolverConfigs] = useState(null);
  const [solverConfigsError, setSolverConfigsError] = useState(null);
  const [defaultParams, setDefaultParams] = useState(null);
  // Methods for solver type
  const [ANISolvers, setANISolvers] = useState(null);
  // Default pipelines
  const [defaultIFCIPip, setDefaultIFCIPip] = useState(false);
  const [defaultANIPip, setDefaultANIPip] = useState(false);
  const [defaultDFTPip, setDefaultDFTPip] = useState(false);
  const [defaultGeoOptPip, setDefaultGeoOptPip] = useState(false);
  const [defaultCSPPip, setDefaultCSPPip] = useState(false);
  const [defaultHFPip, setDefaultHFPip] = useState(false);
  const [defaultGVBPip, setDefaultGVBPip] = useState(false);
  const [defaultAQFEPPip, setDefaultAQFEPPip] = useState(false);
  const [defaultGNINAPip, setDefaultGNINAPip] = useState(false);

  // Get solver parameters & configs
  function getSolverConfigs() {
    fetchData(signal, '/api/get-solver-configs', null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setSolverConfigsError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const data = response.data;

        // Build solver configs object
        const configs = {
          ...data.ifci,
          ...data.ani,
          ...data.dft,
          ...data.geoopt,
          ...data.csp,
          ...data.hf,
          ...data.gvb,
          ...data.aqfep,
          ...data.gnina,
        };
        setSolverConfigs(configs);
        setANISolvers(Object.keys(data.ani));

        // Build solver parameter objects
        const default_params = buildDefaultParameters(configs);
        setDefaultParams(default_params);

        // Build default pipelines
        let default_pipeline = [];
        if (data.ifci) {
          // Compile defaults
          default_pipeline = buildDefaultPipeline(configs, default_params, ['IncrementalDecomposition', 'SNO', 'HBCI']);
          setDefaultIFCIPip(default_pipeline);
        }

        if (data.ani) {
          // Compile defaults
          let defaults = buildDefaultPipeline(configs, default_params, ['ANI1x']);
          setDefaultANIPip(defaults);
        }
        if (data.dft) {
          // Compile defaults
          let defaults = buildDefaultPipeline(configs, default_params, ['DFT', 'Grids', 'NLCGrids']);
          setDefaultDFTPip(defaults);
        }
        if (data.geoopt) {
          // Compile defaults
          let defaults = buildDefaultPipeline(configs, default_params, ['GeometryOptimizer', 'ANI1x']);
          setDefaultGeoOptPip(defaults);
        }
        if (data.csp) {
          // Compile defaults
          let defaults = buildDefaultPipeline(configs, default_params, [
            'OrganicCrystalOptimizer',
            'OrganicCrystalGenerator',
            'ConformerGenerator',
          ]);
          setDefaultCSPPip(defaults);
        }
        if (data.hf) {
          // Compile defaults
          let defaults = buildDefaultPipeline(configs, default_params, ['HartreeFock']);
          setDefaultHFPip(defaults);
        }
        if (data.gvb) {
          // Compile defaults
          let defaults = buildDefaultPipeline(configs, default_params, ['GVB']);
          setDefaultGVBPip(defaults);
        }
        if (data.aqfep) {
          // Compile defaults
          let defaults = buildDefaultPipeline(configs, default_params, ['AQFEP']);
          setDefaultAQFEPPip(defaults);
        }
        if (data.gnina) {
          // Compile defaults
          let defaults = buildDefaultPipeline(configs, default_params, [
            'GNINA',
            'BoxOptions',
            'FlexOptions',
            'ScoringOptions',
            'CNNOptions',
          ]);
          setDefaultGNINAPip(defaults);
        }

        setSubmitDataLoaded(true);
      }
    });
  }

  useEffect(() => {
    getSolverConfigs();

    return () => {
      abortController.abort();
    };
  }, []);

  return {
    solverConfigs,
    solverConfigsError,
    defaultParams,
    ANISolvers,
    defaultIFCIPip,
    defaultANIPip,
    defaultDFTPip,
    defaultGeoOptPip,
    defaultCSPPip,
    defaultHFPip,
    defaultGVBPip,
    defaultAQFEPPip,
    defaultGNINAPip,
    submitDataLoaded,
  };
}

export function getSubmitData(id) {
  const store_key = `${id}.submit_data`;
  return browserStore('get', store_key);
}

export function updateSubmitData(id, data) {
  /*
  Update localStorage with user's submit data
  */
  // For debugging:
  // console.log('data updated:');
  // console.log(data);
  const store_key = `${id}.submit_data`;
  browserStore('set', store_key, data);
}

export default ProvideSubmitData;
