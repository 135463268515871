import React, { useState, useEffect, useContext, createContext } from 'react';

// Initialize Context for authContext
const authContext = createContext({});

// ProvideAuth wrapper component
// Initiated in index.js
function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Auth object accessible through:
// const auth = useAuth();
// Update auth state in child component via:
// const { isAuthenticated, setIsAuthenticated } = useAuth();
export const useAuth = () => {
  return useContext(authContext);
};

// Create initial auth object for ProvideAuth wrapper
function useProvideAuth() {
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState('false');
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [serviceFailed, setServiceFailed] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;

  function fetchData(componentSignal, url, body, method, callback) {
    let content = {
      signal: componentSignal,
      method: method,
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    if (method === 'GET') {
      content = {
        signal: componentSignal,
      };
    }

    fetch(url, content)
      .then(response => {
        if (response.status === 401) {
          setSessionTimeout(true), setIsAuthenticated('false');
        }
        // Allow frontend InternalException (5001)
        // to return the json body as an error object
        else if (!response.ok && response.status !== 5001) {
          const error = response.status + ' ' + response.statusText;
          throw Error(error);
        }
        return response;
      })
      .then(res => res.json())
      .then(data => {
        // Frontend server raises an error
        if (data.name && data.name.includes('Error')) {
          if (data.name === 'AuthError') {
            setSessionTimeout(true);
            setIsAuthenticated('false');
          } else if (data.name === 'ServiceError') {
            setServiceFailed(true);
            setIsAuthenticated('false');
          } else {
            callback({ status: 'error', error: data });
          }
        }
        // Frontend server returns data
        else {
          callback({ status: 'success', data: data });
        }
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          callback({ status: 'error', error: error });
        }
      });
  }

  // Check authentication when called
  useEffect(() => {
    fetchData(signal, '/checkauth', null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setIsAuthenticated(response.data.auth);
      }
    });

    return () => {
      abortController.abort();
    };
  }, []);

  function fetchCredits(componentSignal, id, range_start, range_end, offset, set_length, callback) {
    let formatted_range_start = range_start;
    let formatted_range_end = range_end;
    if (range_start) {
      formatted_range_start = range_start.toFixed(0);
    }
    if (range_end) {
      formatted_range_end = range_end.toFixed(0);
    }

    let url = '/api/get-credits';
    const body = {
      range_start: formatted_range_start,
      range_end: formatted_range_end,
      offset: offset,
      limit: set_length,
      group_or_user_id: id,
    };

    fetchData(componentSignal, url, body, 'POST', response => {
      // returns data as {'credits': [credits], 'total': total}
      callback(response);
    });
  }

  function fetchBalance(componentSignal, id, callback) {
    const body = {
      group_or_user_id: id,
    };
    fetchData(componentSignal, '/api/get-balance', body, 'POST', response => {
      callback(response);
    });
  }

  function fetchProblems(
    componentSignal,
    range_start,
    range_end,
    filters,
    offset,
    set_length,
    user_id,
    group_id,
    project_id,
    visibility = null,
    callback
  ) {
    // Format timestamp ranges
    let formatted_range_start = range_start;
    let formatted_range_end = range_end;
    if (range_start) {
      formatted_range_start = range_start.toFixed(0);
    }
    if (range_end) {
      formatted_range_end = range_end.toFixed(0);
    }
    // Format visibility
    let visibility_array = ['visible', 'starred', 'hidden'];
    if (visibility !== null) {
      if (visibility.all === false) {
        let vis_entries = Object.entries(visibility);
        let update_vis = [];
        vis_entries.forEach(entry => {
          if (entry[1] === true) {
            update_vis.push(entry[0]);
          }
        });
        visibility_array = update_vis;
      }
    }

    const body = {
      range_start: formatted_range_start,
      range_end: formatted_range_end,
      filters: filters,
      offset: offset,
      limit: set_length,
      user_id: user_id,
      group_id: group_id,
      project_id: project_id,
      visibility: visibility_array,
    };

    fetchData(componentSignal, '/api/get-problems', body, 'POST', response => {
      callback(response);
    });
  }

  function updateProblem(componentSignal, payload, callback) {
    let note;
    if (payload.note !== null && payload.note === '') {
      note = '-';
    } else {
      note = payload.note;
    }

    const body = {
      problem_handle: payload.problem_handle,
      problem_name: payload.problem_name || null,
      project_id: payload.project_id || null,
      new_state: payload.new_state || null,
      note: note || null,
    };
    fetchData(componentSignal, '/api/update-problem', body, 'PUT', response => {
      // if error is returned
      if (response.status === 'error') {
        callback(response);
      }
      // if data is returned
      if (response.status === 'success') {
        callback('changes_successful');
      }
    });
  }

  function fetchResults(componentSignal, problem_handle, project_id, callback) {
    let url = `/api/get-results?problem_handle=${problem_handle}&project_id=${project_id}`;
    fetchData(componentSignal, url, null, 'GET', response => callback(response));
  }

  function fetchUserProjects(componentSignal, user_id, org_id, org_ids, callback) {
    const body = {
      user_id: user_id,
      org_id: org_id,
      org_ids: org_ids,
    };
    fetchData(componentSignal, '/api/get-projects', body, 'POST', response => {
      callback(response);
    });
  }

  return {
    isAuthenticated,
    sessionTimeout,
    serviceFailed,
    error,
    fetchData,
    fetchCredits,
    fetchBalance,
    fetchProblems,
    updateProblem,
    fetchResults,
    fetchUserProjects,
  };
}

export default ProvideAuth;
