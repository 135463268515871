import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../../../hoc/Auth';
import { getProjectName } from '../../../../hoc/CommonFunctions';
import { LoadingFilled, LoadingBlock } from '../../../../components/loading/Loading';
import CopyToClipboard, { copyToClipboard } from '../../../../components/copyToClipboard/CopyToClipboard';
import QDropdown, { Toggle, Menu, Item } from '../../../../components/dropdown/Dropdown';
import { QModalText } from '../../../../components/modal/Modal';
import RenameProjectModal from '../../../../views/projects/RenameProject';
import DeleteProjectModal from '../../../../views/projects/DeleteProject';
import ProjectRequestLog from './ProjectRequestLog';
import ProjectMembers from './ProjectMembers';

const ProjectDetails = props => {
  // Init
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Project
  const [viewing, setViewing] = useState('request-log');
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState('');
  // Rename Project
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [renameProjectModalOpen, setRenameProjectModalOpen] = useState(false);
  const [renameError, setRenameError] = useState(null);
  const [projectRenamed, setProjectRenamed] = useState(false);
  const [renameBadgeHidden, setRenameBadgeHidden] = useState(true);
  // Delete Project
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    setProjects(props.projects);

    const project_name = getProjectName(props.selectedProject);
    setProjectName(project_name);

    setIsLoaded(true);

    return () => {
      abortController.abort();
    };
  }, []);

  function renameProject(values) {
    setRenameError(null);
    setLoadingProjects(true);

    const body = {
      user_id: props.user.user_id || null,
      group_id: props.selectedProject._id,
      new_name: values.name,
      description: values.description,
    };

    fetchData(signal, '/api/update-project', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingProjects(false);
        setRenameError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setProjects(response.data);
        setProjectRenamed(true);
        setRenameBadgeHidden(false);
        setLoadingProjects(false);
        response.data.forEach(project => {
          if (project['_id'] === props.selectedProject._id) {
            let project_name = getProjectName(project);
            setProjectName(project_name);
          }
        });
        toggleRenameModal();
        setTimeout(() => {
          setRenameBadgeHidden(true);
          setProjectRenamed(false);
        }, 8000);
      }
    });
  }

  function deleteProject() {
    setLoadingProjects(true);

    const body = {
      user_id: props.user.user_id || null,
      group_id: props.selectedProject._id,
      name: props.selectedProject.name,
      description: props.selectedProject.description,
    };

    fetchData(signal, '/api/delete-project', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingProjects(false);
        setDeleteError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setLoadingProjects(false);
        toggleDeleteModal();
        setProjectName(null);
        props.closePanel();
      }
    });
  }

  function toggleRenameModal() {
    setRenameProjectModalOpen(!renameProjectModalOpen);
  }

  function toggleDeleteModal() {
    setDeleteProjectModalOpen(!deleteProjectModalOpen);
  }

  return (
    <div id='ProjectDetails' className='content-area container-fluid p-0'>
      {!isLoaded ? (
        <LoadingFilled />
      ) : (
        <React.Fragment>
          {/* Top Nav */}
          <div className='project-top-nav'>
            <div className='left-content'>
              <div className='project-name'>
                <h2>{projectName}</h2>
                {projectRenamed ? <span className={`renamed ${renameBadgeHidden ? 'hidden' : ''}`}>Renamed!</span> : ''}
              </div>
              <div className='project-id text-xs'>
                <span className='project-id-label'>Project ID: </span>
                <span className='no-break'>
                  <input value={props.selectedProject._id} readOnly />{' '}
                  <CopyToClipboard onClick={() => copyToClipboard(props.selectedProject._id)} />
                </span>
              </div>
              <div className='project-description text-xs'>
                <i className='icon-info' /> {props.selectedProject.description}
              </div>
            </div>
            <div className='options'>
              <div className='links'>
                <div className='item'>
                  <div onClick={() => setViewing('request-log')} className={viewing === 'request-log' ? 'current' : ''}>
                    <span>Requests</span>
                  </div>
                </div>
                <div className='item'>
                  <div onClick={() => setViewing('members')} className={viewing === 'members' ? 'current' : ''}>
                    <span>Members</span>
                  </div>
                </div>
              </div>
              <div className='more' data-testid='more-dropdown'>
                <QDropdown>
                  <Toggle>
                    <i className='icon-more' data-testid='more-icon' />
                  </Toggle>
                  <Menu>
                    <Item>
                      <QModalText
                        text='Rename'
                        textclassname='bold'
                        show={renameProjectModalOpen}
                        size='lg'
                        id='RenameProjectModal'
                        onHide={() => toggleRenameModal()}>
                        {loadingProjects ? (
                          <LoadingBlock className='transparent-bg' text='Please be patient...' />
                        ) : (
                          <RenameProjectModal
                            project={props.selectedProject}
                            projects={projects}
                            renameError={renameError}
                            renameProject={values => renameProject(values)}
                            toggleRenameModal={() => toggleRenameModal()}
                            loadingProjects={loadingProjects}
                          />
                        )}
                      </QModalText>
                    </Item>
                    <Item>
                      <QModalText
                        text='Delete'
                        textclassname='bold'
                        show={deleteProjectModalOpen}
                        size='lg'
                        id='DeleteProjectModal'
                        onHide={() => toggleDeleteModal()}>
                        {loadingProjects ? (
                          <LoadingBlock className='transparent-bg' text='Please be patient...' />
                        ) : (
                          <DeleteProjectModal
                            project={props.selectedProject}
                            deleteError={deleteError}
                            deleteProject={() => deleteProject()}
                            toggleDeleteModal={() => toggleDeleteModal()}
                            loadingProjects={loadingProjects}
                          />
                        )}
                      </QModalText>
                    </Item>
                  </Menu>
                </QDropdown>
              </div>
            </div>
          </div>
          {/* Content */}
          {viewing === 'request-log' && <ProjectRequestLog projectData={props.selectedProject} />}
          {viewing === 'members' && <ProjectMembers projectName={projectName} projectData={props.selectedProject} />}
        </React.Fragment>
      )}
    </div>
  );
};

ProjectDetails.propTypes = {
  user: PropTypes.object,
  projects: PropTypes.array.isRequired,
  selectedProject: PropTypes.object.isRequired,
};

export default ProjectDetails;
