import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Collapse = props => {
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (props.defaultCollapsed === true) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [props.defaultCollapsed]);

  function toggleCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <React.Fragment>
      <div className={`collapseComponent ${collapsed} ${props.className}`}>
        <div className='header' onClick={() => toggleCollapse()}>
          <div className='header-text'>{props.header}</div>
        </div>
        <div className='collapsible-content'>{props.content}</div>
      </div>
    </React.Fragment>
  );
};

Collapse.propTypes = {
  header: PropTypes.any.isRequired,
  content: PropTypes.any,
  className: PropTypes.string,
  defaultCollapsed: PropTypes.bool
};

export default Collapse;
