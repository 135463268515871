import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

const QFormWrapper = props => {
  return (
    <Formik
      innerRef={props.innerRef}
      initialValues={props.initialValues}
      initialErrors={props.initialErrors}
      validationSchema={props.validationSchema}
      onSubmit={props.onSubmit}>
      {formProps => <Form id={props.formId}>{props.children(formProps)}</Form>}
    </Formik>
  );
};

QFormWrapper.propTypes = {
  formId: PropTypes.string,
  innerRef: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export default QFormWrapper;
