import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// Import hooks & hoc
import { useAuth } from '../../hoc/Auth';
import { browserStore } from '../../hoc/BrowserStorage';
import { convertSecondstoTime, initRangeStart, getRangeEnd, allRangeStart } from '../../hoc/CommonFunctions';
import { filterProblemsVis } from '../../hoc/ProblemsCommonFuncs';
import { scrollToTop } from '../../hoc/ScrollFunctions';

// Import data models
import { ErrorObject, ResponseObject } from '../../models/data';
import {
  ProblemFiltersObject,
  ProblemObject,
  ProblemTotalsObject,
  ProblemVisibilityObject,
} from '../../models/problems';
import { defaultFilters, defaultProblemTotals, defaultVisibility } from '../../constants/problemDefaults';

// Import components
import SupportMessage from '../support/SupportMessage';
import { LoadingFilled, LoadingBlock } from '../loading/Loading';

// Import local components
import RequestLogFilters from './filters/RequestLogFilters';
import Problems from './Problems';

interface Props {
  setIsShown: Function;
  setLength: number;
  userID?: string;
  groupID?: string;
  offset: number;
  setOffset: Function;
  projectID?: string;
  rangeStart: number;
  setRangeStart: Function;
  rangeEnd: number;
  setRangeEnd: Function;
  saveRangeName: string;
  visibilityName: string;
  trackRequestLogError: Function;
  updateHistory: Function;
}

const RequestLogComponent = ({
  setIsShown,
  setLength,
  userID,
  groupID,
  offset,
  setOffset,
  projectID,
  rangeStart,
  setRangeStart,
  rangeEnd,
  setRangeEnd,
  saveRangeName,
  visibilityName,
  trackRequestLogError,
  updateHistory,
}: Props) => {
  // Init
  const { fetchProblems, updateProblem }: any = useAuth();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const timers = useRef<any[]>([]);
  // GET Problems
  const [problemsData, setProblemsData] = useState<ProblemObject[]>([]);
  const [problemsTotals, setProblemsTotals] = useState<ProblemTotalsObject>(defaultProblemTotals);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [visibility, setVisibility] = useState<ProblemVisibilityObject>(defaultVisibility);
  const [filters, setFilters] = useState<ProblemFiltersObject>(defaultFilters);
  const [error, setError] = useState<ErrorObject | null>(null);
  const [totalSolveTime, setTotalSolveTime] = useState<number | null>(null);
  const [totalCost, setTotalCost] = useState<number | null>(null);
  const [rangeSaved, setRangeSaved] = useState<boolean>(false);
  // Refreshing
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [problemsLoaded, setProblemsLoaded] = useState<boolean | null>(null);

  const history = useHistory();

  function handleFetchCallback(response: ResponseObject) {
    // if error is returned
    if (response.error) {
      setError(response.error);
      trackRequestLogError(response.error);
    }
    // if data is returned
    if (response.status === 'success') {
      setProblemsData(response.data.problems);
      setProblemsTotals(response.data.totals);
      // Get total solve time for range
      if (response.data.total_solve_time <= 0 || response.data.total_solve_time === null) {
        setTotalSolveTime(0);
      } else {
        convertSecondstoTime(response.data.total_solve_time, time_string => {
          setTotalSolveTime(time_string);
        });
      }
      // Get total costs for range
      if (response.data.total_cost === null) {
        setTotalCost(0);
      } else {
        setTotalCost(response.data.total_cost);
      }
      setIsShown(true);
      setProblemsLoaded(true);
      let problems_loaded = setTimeout(() => setProblemsLoaded(false), 2000);
      timers.current.push(problems_loaded);
    }
    setIsRefreshing(false);
    setIsLoaded(true);
  }

  useEffect(() => {
    const stored_save_range = browserStore('get', saveRangeName + '.saved', null, 'session');
    const stored_ranges = browserStore('get', saveRangeName, null, 'session');

    // Set date ranges from sessionStorage
    if (stored_save_range === 'true') {
      // If ranges cannot be found in sessionStorage, set defaults
      if (stored_ranges === null) {
        // prettier-ignore
        browserStore('set', saveRangeName, { 
          rangeStart: initRangeStart,
          rangeEnd: getRangeEnd()
        }, 'session');
      }
      // Otherwise get stored values
      else {
        setRangeStart(stored_ranges.rangeStart);
        setRangeEnd(stored_ranges.rangeEnd);
      }

      setRangeSaved(true);
    }

    // Set visibility from sessionStorage
    let vis_ = visibility;
    const stored_visibility = browserStore('get', visibilityName, null, 'session');
    if (stored_visibility !== null) {
      vis_ = stored_visibility;
      setVisibility(stored_visibility);
    }

    // Set filters from sessionStorage
    let filters_ = filters;
    const stored_filters = browserStore('get', 'problemFilters', null, 'session');
    if (stored_filters !== null) {
      filters_ = stored_filters;
      setFilters(stored_filters);
    }

    // Delay fetching and allow rangeStart and rangeEnd to populate
    let start_fetch = setTimeout(() => {
      // prettier-ignore
      fetchProblems(
        signal,
        rangeStart,
        rangeEnd,
        filters_,
        offset,
        setLength,
        userID,
        groupID,
        projectID,
        vis_,
        (response: ResponseObject) => handleFetchCallback(response)
      );
    }, 500);
    timers.current.push(start_fetch);

    return () => {
      abortController.abort();
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, [rangeStart, rangeEnd, offset, setLength, projectID]);

  // DateRangePicker
  function handleDateCallback(start: number, end: number) {
    const range_start = moment(start).unix();
    const range_end = moment(end).unix();

    setIsRefreshing(true);
    setIsLoaded(false);
    setRangeStart(range_start);
    setRangeEnd(range_end);
    if (rangeSaved) {
      browserStore('set', saveRangeName, { rangeStart: range_start, rangeEnd: range_end }, 'session');
    }
  }

  function showAllTransactions() {
    const range_start = allRangeStart;
    const range_end = getRangeEnd();

    setIsLoaded(false);
    setRangeStart(range_start);
    setRangeEnd(range_end);
    if (rangeSaved) {
      browserStore('set', saveRangeName, { rangeStart: range_start, rangeEnd: range_end }, 'session');
    }
    // prettier-ignore
    fetchProblems(
      signal,
      range_start,
      range_end,
      filters,
      offset,
      setLength,
      userID,
      groupID,
      projectID,
      visibility,
      (response: ResponseObject) => handleFetchCallback(response)
    );
    updateHistory('#1');
  }

  function refreshProblems(flash_problems = false) {
    setError(null);
    if (flash_problems) {
      setIsRefreshing(true);
    }
    // prettier-ignore
    fetchProblems(
      signal,
      rangeStart,
      rangeEnd,
      filters,
      0,
      setLength,
      userID,
      groupID,
      projectID,
      visibility,
      (response: ResponseObject) => handleFetchCallback(response)
    );
    updateHistory('#1');
  }

  function handleUpdateProblem(response) {
    // if error object is returned
    if (response.status === 'error') {
      setError(response.error);
      scrollToTop();
    }
    // if changes are successful
    if (response === 'changes_successful') {
      // prettier-ignore
      fetchProblems(
        signal,
        rangeStart,
        rangeEnd,
        filters,
        offset,
        setLength,
        userID,
        groupID,
        projectID,
        visibility,
        (response: ResponseObject) => handleFetchCallback(response)
      );
    }
  }

  function handleFilters(filter: any, value: any) {
    // Clear errors
    setError(null);

    // Update filters object
    let updatedFilters = structuredClone(filters) as ProblemFiltersObject;
    updatedFilters[filter] = value;
    setFilters(updatedFilters);
    browserStore('set', 'problemFilters', updatedFilters, 'session');

    // Reset to page 1 when filters are changed
    setOffset(0);
    history.push('#1');
    // Fetch problems
    // prettier-ignore
    fetchProblems(
      signal,
      rangeStart,
      rangeEnd,
      updatedFilters,
      offset,
      setLength,
      userID,
      groupID,
      projectID,
      visibility,
      (response: ResponseObject) => handleFetchCallback(response)
    );
  }

  function handleVisibleProblems(mod_visibility: ProblemVisibilityObject) {
    setVisibility(mod_visibility);

    // Save visibility selection in sessionStorage
    browserStore('set', visibilityName, mod_visibility, 'session');

    // Update problems shown
    // prettier-ignore
    fetchProblems(
      signal,
      rangeStart,
      rangeEnd,
      filters,
      offset,
      setLength,
      userID,
      groupID,
      projectID,
      mod_visibility,
      (response: ResponseObject) => handleFetchCallback(response)
    );

    updateHistory('#1');
  }

  function saveDateRange() {
    const stored_save_range = browserStore('get', saveRangeName + '.saved', null, 'session');
    // Save range if not yet saved
    if (stored_save_range === null) {
      setRangeSaved(true);
      browserStore('set', saveRangeName + '.saved', 'true', 'session');
      browserStore('set', saveRangeName, { rangeStart: rangeStart, rangeEnd: rangeEnd }, 'session');
    }
    // Otherwise delete range in sessionStorage
    else {
      setRangeSaved(false);

      browserStore('remove', saveRangeName + '.saved', null, 'session');
      browserStore('remove', saveRangeName, null, 'session');
    }
  }

  return (
    <div id='RequestLogComponent'>
      {!isLoaded ? (
        <React.Fragment>
          {window.location.href.includes('/projects') ? <LoadingBlock /> : <LoadingFilled />}
        </React.Fragment>
      ) : (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 order-2 order-lg-1' id='ProblemsContainer'>
              {/* Summary */}
              <RequestLogFilters
                rangeStart={rangeStart}
                rangeEnd={rangeEnd}
                handleDateCallback={(start, end) => handleDateCallback(start, end)}
                showAllTransactions={() => showAllTransactions()}
                isRefreshing={isRefreshing}
                problemsLoaded={problemsLoaded}
                refreshProblems={flash_problems => refreshProblems(flash_problems)}
                autoRefresh={autoRefresh}
                toggleAutoRefresh={() => setAutoRefresh(!autoRefresh)}
                saveDateRange={() => saveDateRange()}
                rangeSaved={rangeSaved}
                visibility={visibility}
                filterProblems={element =>
                  filterProblemsVis(element, visibility, (callback: ProblemVisibilityObject) =>
                    handleVisibleProblems(callback)
                  )
                }
                filters={filters}
                updateFilters={(filter, value) => {
                  handleFilters(filter, value);
                }}
              />
              {/* Requests */}
              {error ? (
                <div className='request-log'>
                  <SupportMessage error={error.message} />
                </div>
              ) : (
                <div className='request-log'>
                  <Problems
                    currentPage={currentPage}
                    setCurrentPage={(num: number) => setCurrentPage(num)}
                    problems={problemsData}
                    problemsTotals={problemsTotals}
                    totalSolveTime={totalSolveTime}
                    totalCost={totalCost}
                    offset={offset}
                    setOffset={(num: number) => setOffset(num)}
                    setLength={setLength}
                    isRefreshing={isRefreshing}
                    // prettier-ignore
                    fetchProblems={(_offset: number = offset, visibility: ProblemVisibilityObject) =>
                      fetchProblems(signal, rangeStart, rangeEnd, filters, _offset, setLength, userID, groupID, projectID, visibility, (response: ResponseObject) => handleFetchCallback(response))}
                    // prettier-ignore
                    updateProblem={(payload: any) =>
                      updateProblem(signal, payload, (response: ResponseObject) => handleUpdateProblem(response))}
                    visibility={visibility}
                  />
                  {isLoaded && problemsData.length === 0 ? <p className='no-requests'>No requests to display.</p> : ''}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestLogComponent;
