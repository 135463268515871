import React from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

export function copyToClipboard(str) {
  /* 
  Accessing navigator.clipboard requires a secure origin,
  therefore this function will not work without https
  */
  try {
    navigator.clipboard.writeText(str);
  } catch (err) {
    console.warn('Copy To Clipboard', err);
  }
}

export const CopyToClipboard = props => (
  <Tippy
    content='Copied!'
    trigger='click'
    offset={[6, 8]}
    duration={[300, 500]}
    placement='bottom-start'
    followCursor={true}
    plugins={[followCursor]}
    className='copied'
    onShow={instance =>
      setTimeout(function () {
        instance.hide();
      }, 1500)
    }>
    <Tippy
      content='Copy to Clipboard'
      trigger='mouseenter'
      offset={[6, 8]}
      followCursor={false}
      plugins={[followCursor]}
      placement='bottom-start'
      className='copy-to-clipboard'>
      <i className='icon-duplicate' data-testid='icon-duplicate' {...props} />
    </Tippy>
  </Tippy>
);

export default CopyToClipboard;
