import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import AutoRefreshButton from '../../autoRefresh/AutoRefresh';
import RefreshButton from '../../refreshButton/RefreshButton';
import { QTextInput, QCheckbox, QSelect } from '../../form/FormElements';
import DatePicker, { ShowAllButton } from '../../datepicker/DatePicker';

export const RequestLogFilters = props => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (props.filters.search_query !== null) {
      setQuery(props.filters.search_query);
    }
  }, []);

  function handleSearch(event) {
    event.preventDefault();
    props.updateFilters('search_query', query);
  }

  return (
    <div id='RequestLogFilters'>
      {/* Date Time Picker */}
      <div className='row datetimepicker d-flex align-items-center'>
        <div className='col-12 d-flex flex-fill flex-wrap align-items-center DateRangePicker py-2 mr-2'>
          <div className='d-flex flex-column flex-md-row mr-2'>
            <div className='d-flex align-items-center'>
              <DatePicker
                rangeStart={props.rangeStart}
                rangeEnd={props.rangeEnd}
                handleCallback={(start, end) => props.handleDateCallback(start, end)}
              />
              <ShowAllButton className='mr-3' onClick={() => props.showAllTransactions()} />
            </div>
            <QCheckbox
              label='Remember this date range'
              className='mb-0'
              id='save-date-range'
              handleChange={props.saveDateRange}
              checked={props.rangeSaved}
            />
          </div>
          {/* Problem Search */}
          <div className='mr-3'>
            <form
              id='ProblemSearchForm'
              className='d-flex align-items-center'
              onSubmit={event => handleSearch(event)}
              onReset={() => {
                setQuery('');
                props.updateFilters('search_query', null);
              }}>
              <QTextInput
                id='searchByHandleOrName'
                className='dark'
                placeholder='Search by handle or name'
                onChange={event => setQuery(event.target.value)}
                value={query}
              />
              <button type='submit' data-testid='search-button'>
                <i className='icon-search dark' />
              </button>
              {props.filters.search_query ? (
                <button type='reset' className='clear-filter'>
                  <span className='mr-2'>Clear</span>
                  <i className='icon-close-small' />
                </button>
              ) : (
                ''
              )}
            </form>
          </div>
        </div>
      </div>
      <div className='row controls-container'>
        {/* Filters */}
        <div className='d-flex flex-column align-items-start flex-grow pt-1 pl-3'>
          <div className='d-flex flex-wrap align-items-center justify-content-start dropdown-filters'>
            {/* Problem Status */}
            <div className='d-flex align-items-center mr-3'>
              <i className='icon-show mr-2' />
              <QSelect
                id='problem-status-select'
                onChange={e => {
                  props.updateFilters('problem_status', e.target.value);
                }}
                value={props.filters.problem_status}
                inputProps={{ 'data-testid': 'problem-status-input' }}
                menu={[
                  ['all', 'All problems'],
                  ['complete', 'Complete problems only'],
                  ['in_progress', 'In Progress problems only'],
                  ['ready', 'Queued problems only'],
                  ['errored', 'Errored problems only'],
                ]}
              />
            </div>
            {/* Order By */}
            <div className='d-flex align-items-center mr-2'>
              <i className='icon-sort mr-2' />
              <QSelect
                id='order-by-select'
                onChange={e => {
                  props.updateFilters('order_by', e.target.value);
                }}
                value={props.filters.order_by}
                inputProps={{ 'data-testid': 'order-by-input' }}
                menu={[
                  ['submit_time', 'Order by submit time'],
                  ['complete_time', 'Order by complete time'],
                ]}
              />
            </div>
            {/* ASC/DSC */}
            <div className='mr-3'>
              <QSelect
                id='order-dir-select'
                onChange={e => {
                  props.updateFilters('order_dir', e.target.value);
                }}
                value={props.filters.order_dir}
                inputProps={{ 'data-testid': 'order-dir-input' }}
                menu={[
                  ['DESC', 'Newest'],
                  ['ASC', 'Oldest'],
                ]}
              />
            </div>
          </div>
          <div className='d-flex flex-wrap flex-fill align-items-center justify-content-between py-1 controls'>
            {/* Visibility Checkboxes */}
            <div className='d-flex flex-nowrap problem-vis-boxes'>
              <React.Fragment>
                <QCheckbox
                  className='mr-2'
                  label='ALL'
                  id='all-problems'
                  checked={props.visibility.all}
                  handleChange={element => props.filterProblems(element)}
                />
                <QCheckbox
                  className='mr-2'
                  label='Standard'
                  id='visible-problems'
                  checked={props.visibility.visible}
                  handleChange={element => props.filterProblems(element)}
                />
                <QCheckbox
                  className='mr-2'
                  label={
                    <span className='d-flex align-items-center'>
                      <i className='icon-star-outline mr-1' />
                      Starred
                    </span>
                  }
                  id='starred-problems'
                  checked={props.visibility.starred}
                  handleChange={element => props.filterProblems(element)}
                />
                <QCheckbox
                  className='mr-2'
                  label={
                    <span className='d-flex align-items-center'>
                      <i className='icon-archive mr-1' />
                      Archived
                    </span>
                  }
                  id='hidden-problems'
                  checked={props.visibility.hidden}
                  handleChange={element => props.filterProblems(element)}
                />
              </React.Fragment>
            </div>
          </div>
        </div>
        {/* Refresh Buttons */}
        <div className='d-flex align-items-end pb-2 px-3 refresh-container'>
          <QCheckbox
            className='mr-3 mb-0'
            label='Auto refresh'
            id='auto-refresh-problems'
            checked={props.autoRefresh}
            handleChange={() => props.toggleAutoRefresh()}
          />
          {props.autoRefresh ? (
            <AutoRefreshButton
              isRefreshing={props.isRefreshing}
              dataLoaded={props.problemsLoaded}
              refresh={flash_problems => props.refreshProblems(flash_problems)}
            />
          ) : (
            <RefreshButton
              isRefreshing={props.isRefreshing}
              dataLoaded={props.problemsLoaded}
              refresh={flash_problems => props.refreshProblems(flash_problems)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

RequestLogFilters.propTypes = {
  rangeStart: PropTypes.number.isRequired,
  rangeEnd: PropTypes.number.isRequired,
  handleDateCallback: PropTypes.func.isRequired,
  showAllTransactions: PropTypes.func.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
  problemsLoaded: PropTypes.bool,
  refreshProblems: PropTypes.func.isRequired,
  saveDateRange: PropTypes.func.isRequired,
  rangeSaved: PropTypes.bool.isRequired,
  visibility: PropTypes.object.isRequired,
  filterProblems: PropTypes.func.isRequired,
  autoRefresh: PropTypes.bool.isRequired,
  toggleAutoRefresh: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default RequestLogFilters;
