import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
import { convertTime, formatStatus } from '../../../hoc/CommonFunctions';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import { LoadingFilled } from '../../../components/loading/Loading';
import RefreshButton from '../../../components/refreshButton/RefreshButton';

const StatusDashboard = props => {
  // Init
  const { fetchData } = useAuth();
  const { setOverlayLevel1 } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [timers, setTimers] = useState([]);
  // Get Ready and In_Progress Problems
  const [readyProblems, setReadyProblems] = useState(0);
  const [inprogressProblems, setInprogressProblems] = useState(0);
  const [problems, setProblems] = useState([]);
  const [fetchProblemsError, setFetchProblemsError] = useState(null);
  // Refresh Problem
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  function fetchStatus() {
    fetchData(signal, '/api/get-problems-in-queue', null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setFetchProblemsError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        if (response.data.counts) {
          setReadyProblems(response.data.counts.ready);
          setInprogressProblems(response.data.counts.in_progress);
        }
        if (response.data.problems) {
          setProblems(response.data.problems);
        }
      }
      setIsLoaded(true);
      setIsRefreshing(false);
      setDataLoaded(true);
      const timer = setTimeout(() => setDataLoaded(false), 2000);
      timers.push(timer);
    });
  }

  useEffect(() => {
    fetchStatus();

    return () => {
      setIsLoaded(false);
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, []);

  function selectProblem(problem_handle, project_id) {
    const obj = {
      content_type: 'problem',
      problem_handle: problem_handle,
      project_id: project_id,
    };
    setOverlayLevel1(obj);
  }

  const problems_list = Array.from(problems).map((problem, index) => (
    <div
      key={index}
      className='row list item'
      data-testid='list-item'
      onClick={() => selectProblem(problem.problem_handle, problem.project_id)}>
      <div className='col-6 col-lg-3'>{problem.problem_handle}</div>
      <div className={`col-6 col-lg-2 bold warning ${problem.problem_status === 'in_progress' ? 'inprogress' : ''}`}>
        {formatStatus(problem.problem_status)}
      </div>
      <div className='col-6 col-lg-4'>{problem.user_email}</div>
      <div className='col-6 col-lg-3'>{convertTime(problem.submit_time)}</div>
    </div>
  ));

  return (
    <div id='StatusDashboard'>
      {!isLoaded ? (
        <LoadingFilled className='with-subnav' />
      ) : (
        <div className='container-fluid pb-6'>
          <div className='row pt-3'>
            <div className='col-12'>
              <h2>QEMIST Cloud Current Status</h2>
              <p className='text-xs'>
                Problems in the database that has status of either "ready" or "in progress". <br />
                This count does not include sub-problems.
              </p>
            </div>
          </div>
          {fetchProblemsError ? (
            <QErrorMessage text={<span className='bold'>{fetchProblemsError.message}</span>} />
          ) : (
            <React.Fragment>
              {/* Problems Counts */}
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex justify-content-between align-items-end'>
                    <div className='d-flex'>
                      <ProblemCount
                        className='in-progress'
                        problemCount={inprogressProblems}
                        problemLabel='In Progress'
                      />
                      <ProblemCount problemCount={readyProblems} problemLabel='Ready' />
                    </div>
                    <RefreshButton
                      className='mb-3 mr-1'
                      isRefreshing={isRefreshing}
                      dataLoaded={dataLoaded}
                      refresh={() => {
                        setIsRefreshing(true);
                        fetchStatus();
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Problems List */}
              <div className='problems-list'>
                <div className='row list header'>
                  <div className='col-6 col-lg-3'>Problem Handle</div>
                  <div className='col-6 col-lg-2'>Status</div>
                  <div className='col-6 col-lg-4'>User Email</div>
                  <div className='col-6 col-lg-3'>Submit Time</div>
                </div>
                {problems_list}
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

const ProblemCount = props => {
  return (
    <div className={`mb-3 mr-2 d-flex align-items-center problem-count-container ${props.className}`}>
      <div className='count text-lg bold'>{props.problemCount}</div>
      <div className='px-3 py-2 d-flex align-items-center count-label text-xs'>{props.problemLabel}</div>
    </div>
  );
};

ProblemCount.propTypes = {
  className: PropTypes.string,
  problemCount: PropTypes.number.isRequired,
  problemLabel: PropTypes.string.isRequired,
};

export default StatusDashboard;
