import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useSubmitData } from '../../../hoc/SubmitData';
import { constructSelectMenu } from '../../../hoc/FormFunctions';
import { LoadingBlock } from '../../../components/loading/Loading';
import InlineFormGroup from '../../../components/form/InlineFormGroup';
import QFormWrapper from '../../../components/form/QFormWrapper';
import { SubmitGroupLabel, SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';
import {
  buildValidationObj,
  getValidationKeys,
  getInitialFormValues,
  buildErrorMessage,
  validateParam,
  buildParamKeys,
} from '../functions/SubmitFormFunctions';
import { formatSolverName } from '../functions/SubmitFunctions';
import ShowInputs from '../components/ShowInputs';

const SubmitCSP = props => {
  // Init
  const { solverConfigs, defaultParams, ANISolvers } = useSubmitData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationObj, setValidationObj] = useState({});
  const [validationKeys, setValidationKeys] = useState([]);
  const timers = useRef([]);
  // Edit Params
  const [pipeline, setPipeline] = useState([]);
  const blacklist = ['solver', 'cluster', 'filter_population', 'presets'];
  // Presets
  const [presetMenu, setPresetMenu] = useState([]);
  const [presetVal, setPresetVal] = useState('Custom');
  // Refs for form validation
  const [formLoaded, setFormLoaded] = useState(false);
  const formRef = useRef(null);

  const oc_optimizer_name = props.solverPipeline[0] ? props.solverPipeline[0].solver_name : '';
  const oc_generator_name = props.solverPipeline[1] ? props.solverPipeline[1].solver_name : '';
  const conf_generator_name = props.solverPipeline[2] ? props.solverPipeline[2].solver_name : '';

  const csp_validation_items = {
    min_overlap__2: yup.string().test(
      'min_overlap',
      value => buildErrorMessage(value.value, 'min_overlap', oc_optimizer_name, solverConfigs),
      value => validateParam(value, 'min_overlap', oc_optimizer_name, solverConfigs)
    ),
    monitor_size__2: yup.string().test(
      'monitor_size',
      value => buildErrorMessage(value.value, 'monitor_size', oc_optimizer_name, solverConfigs),
      value => validateParam(value, 'monitor_size', oc_optimizer_name, solverConfigs)
    ),
    opt_tol__2: yup.string().test(
      'opt_tol',
      value => buildErrorMessage(value.value, 'opt_tol', oc_optimizer_name, solverConfigs),
      value => validateParam(value, 'opt_tol', oc_optimizer_name, solverConfigs)
    ),
    patience__2: yup.string().test(
      'patience',
      value => buildErrorMessage(value.value, 'patience', oc_optimizer_name, solverConfigs),
      value => validateParam(value, 'patience', oc_optimizer_name, solverConfigs)
    ),
    probability_crossover__2: yup.string().test(
      'probability_crossover',
      value => buildErrorMessage(value.value, 'probability_crossover', oc_optimizer_name, solverConfigs),
      value => validateParam(value, 'probability_crossover', oc_optimizer_name, solverConfigs)
    ),
    seed__2: yup.string().test(
      'seed',
      value => buildErrorMessage(value.value, 'seed', oc_optimizer_name, solverConfigs),
      value => validateParam(value, 'seed', oc_optimizer_name, solverConfigs)
    ),
    tournament_size__2: yup.string().test(
      'tournament_size',
      value => buildErrorMessage(value.value, 'tournament_size', oc_optimizer_name, solverConfigs),
      value => validateParam(value, 'tournament_size', oc_optimizer_name, solverConfigs)
    ),
    num_generate__1: yup.string().test(
      'num_generate',
      value => buildErrorMessage(value.value, 'num_generate', oc_generator_name, solverConfigs),
      value => validateParam(value, 'num_generate', oc_generator_name, solverConfigs)
    ),
    num_filter__1: yup.string().test(
      'num_filter',
      value => buildErrorMessage(value.value, 'num_filter', oc_generator_name, solverConfigs),
      value => validateParam(value, 'num_filter', oc_generator_name, solverConfigs)
    ),
    num_cluster__1: yup.string().test(
      'num_cluster',
      value => buildErrorMessage(value.value, 'num_cluster', oc_generator_name, solverConfigs),
      value => validateParam(value, 'num_cluster', oc_generator_name, solverConfigs)
    ),
    opt_tol__1: yup.string().test(
      'opt_tol',
      value => buildErrorMessage(value.value, 'opt_tol', oc_generator_name, solverConfigs),
      value => validateParam(value, 'opt_tol', oc_generator_name, solverConfigs)
    ),
    seed__1: yup.string().test(
      'seed',
      value => buildErrorMessage(value.value, 'seed', oc_generator_name, solverConfigs),
      value => validateParam(value, 'seed', oc_generator_name, solverConfigs)
    ),
    num_confs__0: yup.string().test(
      'num_confs',
      value => buildErrorMessage(value.value, 'num_confs', conf_generator_name, solverConfigs),
      value => validateParam(value, 'num_confs', conf_generator_name, solverConfigs)
    ),
    seed__0: yup.string().test(
      'seed',
      value => buildErrorMessage(value.value, 'seed', conf_generator_name, solverConfigs),
      value => validateParam(value, 'seed', conf_generator_name, solverConfigs)
    ),
  };

  function getPresetsMenus() {
    if (
      solverConfigs &&
      solverConfigs['CSP'] &&
      solverConfigs['CSP'].configurable &&
      solverConfigs['CSP'].configurable.preset &&
      solverConfigs['CSP'].configurable.preset.options
    ) {
      const options = solverConfigs['CSP'].configurable.preset.options;
      const menu = [['Custom', 'Custom']];
      options.forEach(option => {
        menu.push([option[0], option[0]]);
      });
      setPresetMenu(menu);
    }
  }

  function getPresetInputVals() {
    // Update presetVal to presets param string if it exists
    if (props.solverPipeline[0].params.presets !== undefined) {
      setPresetVal(props.solverPipeline[0].params.presets);
    }
    // Otherwise set to 'Custom'
    else {
      setPresetVal('Custom');
    }
  }

  useEffect(() => {
    setPipeline(structuredClone(props.solverPipeline).reverse());

    if (solverConfigs !== null && defaultParams !== null && props.solverPipeline[0] && props.solverPipeline[0].params) {
      // Solver params
      const param_keys = buildParamKeys(structuredClone(props.solverPipeline).reverse());
      setValidationObj(buildValidationObj(csp_validation_items, param_keys));
      setValidationKeys(getValidationKeys(csp_validation_items, param_keys));

      // Set presets
      getPresetsMenus();
      getPresetInputVals();

      setIsLoaded(true);
    }
  }, [props.solverPipeline, solverConfigs, defaultParams]);

  function checkFormValid() {
    // Delay to allow formRef validations to finish
    const t = setTimeout(() => {
      props.setParamsValid(formRef.current.isValid);
    }, 200);
    timers.current.push(t);
  }

  useEffect(() => {
    // Trigger form validations after MoleculeParamsForm element is loaded
    if (formRef && formRef.current !== null) {
      let fields = {};
      validationKeys.forEach(param => {
        fields[param] = true;
      });
      formRef.current.setTouched(fields);
      checkFormValid();
    }
  }, [formLoaded]);

  useEffect(() => {
    // Update paramsForm in parent component whenever form validity changes
    if (formRef.current) {
      checkFormValid();
    }
  }, [formRef.current]);

  useEffect(() => {
    // Component cleanup
    return () => {
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function handlePageLoad() {
    /*
    This function should be called at the end of the html elements
    load, as it looks for a specific element in the DOM and triggers
    a component state change
    */
    if (!formLoaded) {
      // Delay to allow html elements to load
      const timer = setTimeout(() => {
        const el = document.getElementById('CSPForm');
        if (el !== undefined && el !== null) {
          setFormLoaded(true);
        }
      }, 1000);
      timers.current.push(timer);
    }
  }

  const validationSchema = yup.object().shape(validationObj);

  function handleConditionalParams(pip, callback) {
    let pip_mod = structuredClone(pip);
    if (pip[1]) {
      const ocg = pip[1];
      function updateParams(key, param) {
        if (ocg.params && ocg.params[key] && ocg.params[key] != 'None') {
          pip_mod[1].params[param] = 'True';
        } else {
          pip_mod[1].params[param] = 'False';
        }
      }
      updateParams('num_cluster', 'cluster');
      updateParams('num_filter', 'filter_population');
    }
    callback(pip_mod);
  }

  function applyParamChanges(pip = null) {
    // Check if preset params have been modified
    let pipeline_mod = structuredClone(pip || pipeline);

    const oco = pipeline_mod[pipeline_mod.length - 1];
    if (oco && oco.params && oco.params.presets !== undefined) {
      const selected_preset = oco.params.presets;

      pipeline_mod.forEach((solver, i) => {
        if (
          solver.params &&
          solverConfigs &&
          solverConfigs['CSP'] &&
          solverConfigs['CSP'].configurable &&
          solverConfigs['CSP'].configurable.preset &&
          solverConfigs['CSP'].configurable.preset.options
        ) {
          const options = solverConfigs['CSP'].configurable.preset.options;
          let preset_params_arr = [];
          options.forEach(([preset_name, params]) => {
            if (selected_preset === preset_name) {
              preset_params_arr = Object.entries(params);
            }
          });

          const changes = preset_params_arr.some(([param_key, param_val]) => {
            if (solver.params[param_key] && solver.params[param_key] != param_val) {
              return true;
            }
          });

          // Revert to 'Custom' if any parameter doesn't match preset param
          if (changes) {
            delete pipeline_mod[pipeline_mod.length - 1].params.presets;
          }
        }
      });
    }

    // Update parent solver pipeline
    const rereverse_pip = pipeline_mod.reverse();
    props.updatePipeline(rereverse_pip);
  }

  function handleSolverChange(i, val) {
    let pip = structuredClone(pipeline);
    const solver = solverConfigs[val].solver_parameters || {};

    pip[i].params.solver.next_solver = solver.next_solver || '';
    pip[i].params.solver.solver_params = solver.solver_params || {};

    const rereverse_pip = pip.reverse();
    props.updatePipeline(rereverse_pip);
  }

  // function getANIBlacklist(solver_name) {
  //   if (solver_name === 'ANI1ccx') {
  //     return ['dispatcher', 'auto_mode'];
  //   } else return ['dispatcher'];
  // }

  function handlePresetChange(val) {
    let pipeline_mod = structuredClone(pipeline);

    // Add "presets" param to OCO
    pipeline_mod[pipeline_mod.length - 1].params.presets = val;

    pipeline_mod.forEach((solver, i) => {
      // Update solver parameters with presets
      if (
        solverConfigs &&
        solverConfigs['CSP'] &&
        solverConfigs['CSP'].configurable &&
        solverConfigs['CSP'].configurable.preset &&
        solverConfigs['CSP'].configurable.preset.options
      ) {
        if (val !== 'Custom') {
          const options = solverConfigs['CSP'].configurable.preset.options;
          const solver_param_keys = Object.keys(defaultParams[solver.solver_name].params);
          options.forEach(([option_name, option_params]) => {
            if (option_name === val) {
              const params = Object.entries(option_params);
              // Modify each param in preset
              params.forEach(([param_key, param_val]) => {
                if (solver_param_keys.includes(param_key)) {
                  pipeline_mod[i].params[param_key] = param_val;
                }
              });
            }
          });
          // Update mutation operations string
          const mutation_ops_str = val.split('_')[1] || 'not_found';
          if (pipeline_mod[i].params.mutation_operations) {
            pipeline_mod[i].params.mutation_operations = mutation_ops_str;
          }
        }
      }
    });

    // Update component state
    setPresetVal(val);
    setPipeline(pipeline_mod);
    applyParamChanges(pipeline_mod);
  }

  function showSolverParams(formProps) {
    return Array.from(pipeline).map((solver, index) => {
      return (
        <React.Fragment key={index}>
          <SubmitInputGroup>
            <SubmitGroupLabel className='w-100 mb-0' text={`${formatSolverName(solver.solver_name)} Parameters`} />
            {/* Inputs */}
            {ShowInputs(solver, index, formProps, solverConfigs, blacklist, validationKeys, pipeline, pipeline_mod => {
              if (index === 1) {
                handleConditionalParams(pipeline_mod, pip => applyParamChanges(pip));
              } else {
                applyParamChanges(pipeline_mod);
              }
            })}
            {/* ANI Solvers */}
            {solver.params && solver.params.solver && solver.params.solver.next_solver ? (
              <React.Fragment>
                <InlineFormGroup
                  element='select'
                  id='solver-input'
                  className='dark mr-3'
                  containerClassName='w-100'
                  data-testid='solver-input'
                  label='Solver'
                  value={solver.params.solver.next_solver}
                  onChange={e => handleSolverChange(index, e.target.value)}
                  selectMenu={constructSelectMenu(ANISolvers)}
                  includeError={false}
                />
                {/* <div className='container px-0 mb-3 mx-0'>
                {/* Select Solver 
                <div className='pr-3 mb-3'>
                </div>
                Solver Params 
                {ShowInputs(
                  props.solverPipeline,
                  pythonifySolver(solverConfigs, {
                    solver_name: solver.params.solver.next_solver,
                    params: solver.params.solver.solver_params,
                  }),
                  0,
                  formProps,
                  solverConfigs,
                  getANIBlacklist(solver.params.solver.next_solver),
                  validationKeys,
                  [
                    pythonifySolver(solverConfigs, {
                      solver_name: solver.params.solver.next_solver,
                      params: solver.params.solver.solver_params,
                    }),
                  ],
                  ani_mod => {
                    let pip = structuredClone(pipeline);
                    const params = Object.entries(ani_mod[0].params) || [];
                    params.forEach(([key, val]) => {
                      pip[index].params.solver.solver_params[key] = formatBasicPyToJS(val);
                    });
                    setPipeline(pip);
                  }
                )} 
              </div>*/}
              </React.Fragment>
            ) : (
              ''
            )}
          </SubmitInputGroup>
        </React.Fragment>
      );
    });
  }

  return (
    <div id='SubmitCSP'>
      {isLoaded && pipeline[0] ? (
        <QFormWrapper
          innerRef={formRef}
          formId='CSPForm'
          validationSchema={validationSchema}
          initialValues={getInitialFormValues(pipeline, validationKeys, solverConfigs)}
          onSubmit={() => {}}>
          {formProps => {
            return (
              <React.Fragment>
                <SubmitInputGroup>
                  <SubmitGroupLabel className='w-100' text='Organic Crystal Optimizer Presets' />
                  {/* Presets */}
                  <InlineFormGroup
                    element='select'
                    id='preset-input'
                    className='dark mr-5 w-100'
                    containerClassName='w-100'
                    data-testid='preset-input'
                    label='Choose Preset (Optional)'
                    value={presetVal}
                    onChange={e => handlePresetChange(e.target.value)}
                    selectMenu={presetMenu}
                    includeError={false}
                  />
                </SubmitInputGroup>
                {showSolverParams(formProps)}
                <div className='w-100 mb-3'>
                  <ResetDropdown
                    resetChanges={() => {
                      formProps.resetForm();
                      props.resetPipeline();
                    }}
                  />
                </div>
                {handlePageLoad()}
              </React.Fragment>
            );
          }}
        </QFormWrapper>
      ) : (
        <LoadingBlock text='Loading parameters...' className='mb-3' />
      )}
    </div>
  );
};

SubmitCSP.propTypes = {
  currentOrg: PropTypes.object,
  solverPipeline: PropTypes.array.isRequired,
  updatePipeline: PropTypes.func.isRequired,
  resetPipeline: PropTypes.func.isRequired,
};

export default SubmitCSP;
