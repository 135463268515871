import React from 'react';

// Components
import Login from '../views/Login';
import PromoClosed from '../views/promos/PromoClosed';
import Promo from '../views/promos/Promo';

const PreAuthLayout = props => (
  <React.Fragment>
    <div id='PreAuthLayout'>
      <div className='container'>
        {window.location.href.includes('promo-closed') ? (
          <PromoClosed />
        ) : (
          <React.Fragment>{window.location.href.includes('promo-open') ? <Promo /> : <Login />}</React.Fragment>
        )}
      </div>
    </div>
  </React.Fragment>
);

export default PreAuthLayout;
