import React from 'react';
import PropTypes from 'prop-types';

import { formatToJSON } from '../../hoc/FormatSyntax';
import { downloadFile } from '../../hoc/CommonFunctions';
import CopyToClipboard, { copyToClipboard } from '../copyToClipboard/CopyToClipboard';
import { QTipStandard } from '../tooltip/Tooltip';

const CodeBlock = props => {
  return (
    <div id='CodeBlock' className={`code-block-container d-flex ${props.className}`} data-testid='code-block'>
      <div className='code-output'>{formatToJSON(props.data.data)}</div>
      {props.actions && (
        <div className='d-flex flex-column align-items-center justify-content-between code-block-actions'>
          {props.hideCodeBlock && (
            <QTipStandard tooltipContent='Close'>
              <i className='icon-close' data-testid='icon-close' onClick={() => props.hideCodeBlock()} />
            </QTipStandard>
          )}
          <div className='d-flex flex-column align-items-center'>
            <CopyToClipboard onClick={() => copyToClipboard(JSON.stringify(props.data.data))} />
            <QTipStandard tooltipContent='Download JSON'>
              <i
                className='icon-download'
                data-testid='icon-download'
                onClick={() => downloadFile(JSON.stringify(props.downloadData), `${props.downloadFilename}`, 'json')}
              />
            </QTipStandard>
          </div>
        </div>
      )}
    </div>
  );
};

CodeBlock.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  hideCodeBlock: PropTypes.func,
  actions: PropTypes.bool.isRequired,
  downloadData: PropTypes.object.isRequired,
  downloadFilename: PropTypes.string.isRequired,
};

export const DisplayCode = props => (
  <div className='display-code'>
    <pre>
      <code>{props.children}</code>
    </pre>
  </div>
);

DisplayCode.propTypes = {
  children: PropTypes.any,
};

export const InlineCode = props => <code className='inline'>{props.children}</code>;

InlineCode.propTypes = {
  children: PropTypes.text,
};

export default CodeBlock;
