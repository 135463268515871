import React, { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

const Tangelo = props => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(true);
    if (MIXPANEL_TOKEN) {
      mixpanel.track('Tangelo Component: Mounted');
    }

    return () => {
      setIsShown(false);
    };
  }, []);

  const tangelo_link = `https://goodchemistryco.github.io/Tangelo-Examples/examples/hardware_experiments/qemist_cloud_hardware_experiments_braket.html`;

  return (
    <div id='Tangelo' className={`d-flex ${isShown ? 'show' : 'hide'}`}>
      <iframe src={tangelo_link} data-testid='tangelo-iframe'></iframe>
      <div className='external-link p-3'>
        <a href={tangelo_link} target='_blank'>
          <i className='icon-new-window reversed' />
        </a>
      </div>
    </div>
  );
};

export default Tangelo;
