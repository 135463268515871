import React, { useState, useEffect } from 'react';

// Components
import { useAuth } from '../../../hoc/Auth';
import { formatCurrency } from '../../../hoc/CommonFunctions';
import { LoadingInline } from '../../../components/loading/Loading';

const CreditsReportSummary = props => {
  // Init
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Get Credits Summary
  const [creditsSummary, setCreditsSummary] = useState(null);

  function fetchCreditsSummary() {
    const body = {};
    fetchData(signal, '/api/get-credits-summary', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setCreditsSummary(response.data);
      }
      setIsLoaded(true);
    });
  }

  useEffect(() => {
    fetchCreditsSummary();

    return () => {
      setIsLoaded(false);
      abortController.abort();
    };
  }, []);

  return (
    <div id='CreditsReportSummary' className='row green-bg py-2 border-top border-bottom'>
      <div className='col-12 d-flex align-items-center'>
        {!isLoaded ? (
          <LoadingInline size='sm' className='my-2' />
        ) : (
          <div className='d-flex w-100 align-items-center justify-content-between'>
            {/* Left Content */}
            <div className='d-flex align-items-center'>
              <div className='mr-4'>
                QEMIST Cloud Credit Groups:<span className='bold ml-2'>{creditsSummary.groups_or_pools}</span>
              </div>
              <div>
                Total Outstanding Credits:
                <span className='bold ml-2'>{formatCurrency(creditsSummary.credits_outstanding)}</span>
              </div>
            </div>
            {/* Right Content */}
            <div className='d-flex align-items-center'></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditsReportSummary;
