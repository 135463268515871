import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../hoc/Auth';
import { LoadingInline } from '../../components/loading/Loading';
import QDropdown, { Toggle, Menu } from '../../components/dropdown/Dropdown';

const Announcements = props => {
  // Init
  const { fetchData } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [isLoaded, setIsLoaded] = useState(false);
  // Get announcements
  const [announcements, setAnnouncements] = useState([]);
  const [unread, setUnread] = useState(false);
  const [error, setError] = useState(null);

  const history = useHistory();

  function getAnnouncements() {
    fetchData(signal, '/api/announcements', {}, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        let ancmts = response.data.announcements;
        // Sort announcements by date (newest to oldest)
        function compare(a, b) {
          if (a.timestamp > b.timestamp) {
            return -1;
          }
          if (a.timestamp < b.timestamp) {
            return 1;
          }
          return 0;
        }
        ancmts.sort(compare);
        setAnnouncements(ancmts);
        setUnread(response.data.unread);
      }
      setIsLoaded(true);
    });
  }

  useEffect(() => {
    // Get announcements
    getAnnouncements();

    return () => {
      abortController.abort();
    };
  }, [props.update]);

  function markAsRead(timestamps) {
    // Update read receipts
    const body = {
      message_type: 'announcements',
      read_ids: timestamps,
    };
    fetchData(signal, '/api/read-receipt', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        getAnnouncements();
        props.updateAnnouncements();
      }
    });
  }

  function markAllAsRead() {
    let timestamps = [];
    announcements.forEach(ancmt => {
      timestamps.push(ancmt.timestamp);
    });
    markAsRead(timestamps);
  }

  function ancmtItems(ancmts) {
    return Array.from(ancmts).map((ancmt, index) => (
      <div key={index} className={`announcement ${ancmt.status === 'unread' && 'unread'}`}>
        <p className='mb-2 d-flex align-items-center justify-content-between'>
          <span className='d-flex align-items-center'>
            {ancmt.status === 'unread' && <span className='unread-indicator mr-2'></span>}
            <span className='label-sm'>{moment.unix(ancmt.timestamp).format('MMM D, YYYY')}</span>
          </span>
          {ancmt.status === 'unread' && (
            <span className='mark-as-read text-xs' onClick={() => markAsRead([ancmt.timestamp])}>
              Mark as read
            </span>
          )}
        </p>
        <h3>{ancmt.title}</h3>
        <p className='text-xs'>{ancmt.message}</p>
      </div>
    ));
  }

  function showAnnouncements() {
    return (
      <React.Fragment>
        <div className='d-flex align-items-center justify-content-between mb-2'>
          {props.className && props.className.includes('full-page') ? (
            <h2 className='my-3'>Announcements</h2>
          ) : (
            <h4>Announcements</h4>
          )}
          {unread ? (
            <span className='mark-as-read all text-xs' onClick={() => markAllAsRead()}>
              Mark all as read
            </span>
          ) : (
            ''
          )}
        </div>
        {props.className && props.className.includes('full-page') ? (
          <React.Fragment>
            {/* Show all announcements on announcement page */}
            {ancmtItems(announcements)}
            {announcements.length > 0 && props.className.includes('full-page') && (
              <p className='my-5 py-4 border-top text-xs p-text-light'>-- That's all --</p>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* Show latest 3 announcements for dropdown view */}
            {ancmtItems(announcements.slice(0, 3))}
            {/* Show "See all" link to go to announcements page */}
            {announcements.length > 3 && (
              <div className='show-more text-xs link' onClick={() => history.push('/announcements')}>
                See all ({announcements.length})
              </div>
            )}
          </React.Fragment>
        )}
        {announcements.length === 0 && (
          <p className='py-4 border-top text-xs p-text-light'>-- You're all caught up. --</p>
        )}
      </React.Fragment>
    );
  }

  return (
    <div id='Announcements' className={props.className}>
      {!isLoaded ? (
        <LoadingInline size='sm' color='white' className='mr-3' />
      ) : (
        <React.Fragment>
          {props.className && props.className.includes('full-page') ? (
            <React.Fragment>{showAnnouncements()}</React.Fragment>
          ) : (
            <QDropdown className='announcements-dropdown reversed no-arrow'>
              <Toggle dataTestId='announcements-toggle'>
                <i className='icon-announcement ml-3' data-testid='announcement-icon' />
                {unread ? (
                  <div className='d-flex align-items-center justify-content-center badge-unread-indicator'>
                    {unread}
                  </div>
                ) : (
                  ''
                )}
              </Toggle>
              <Menu className='p-3'>{showAnnouncements()}</Menu>
            </QDropdown>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

Announcements.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
  update: PropTypes.bool.isRequired,
  updateAnnouncements: PropTypes.func.isRequired,
};

export default Announcements;
