import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../../hoc/Auth';
import { browserStore } from '../../../hoc/BrowserStorage';
import { useUserData } from '../../../hoc/UserData';
import { getOrgsList, getOrgName } from '../../../hoc/CommonFunctions';
import { handleInputChange } from '../../../hoc/FormFunctions';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import SearchForm from '../../../components/form/SearchForm';
import QFormWrapper from '../../../components/form/QFormWrapper';
import QFormGroup from '../../../components/form/QFormGroup';
import QButton from '../../../components/button/Button';
import QModalBtn from '../../../components/modal/Modal';
import { LoadingBlock } from '../../../components/loading/Loading';

const GroupsList = props => {
  // Init
  const { fetchData } = useAuth();
  const [isShown, setIsShown] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Groups
  const [orgGroups, setOrgGroups] = useState([]);
  const [error, setError] = useState(null);
  // Create Org
  const [createOrgModalOpen, setCreateOrgModalOpen] = useState(false);
  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [createOrgError, setCreateOrgError] = useState(null);
  // Admin
  const { setAdminPath } = useUserData();
  // Filter Orgs
  const [filteredOrgs, setFilteredOrgs] = useState([]);
  const [query, setQuery] = useState('');

  function handleFetchCallback(response) {
    // if error is returned
    if (response.status === 'error') {
      setIsLoaded(true);
      setError(response.error);
    }
    // if data is returned
    if (response.status === 'success') {
      setIsLoaded(true);
      const orgs_list = getOrgsList(response.data);
      setOrgGroups(orgs_list);
      setFilteredOrgs(orgs_list);
    }
  }

  function fetchOrgs() {
    setIsLoaded(false);
    fetchData(signal, '/api/get-orgs', null, 'GET', response => handleFetchCallback(response));
  }

  useEffect(() => {
    setIsShown(true);
    fetchOrgs();

    return () => {
      abortController.abort();
    };
  }, []);

  function updateAdminPath(link) {
    browserStore('set', 'adminPath', link, 'session');
    setAdminPath(link);
  }

  function showList(filteredOrgs) {
    if (filteredOrgs !== undefined) {
      return Array.from(filteredOrgs).map((org, index) => (
        <Link
          to={`/admin/group/${org['_id']}/credits`}
          onClick={() => updateAdminPath(`/admin/group/${org['_id']}/credits`)}
          key={index}
          className='group-row-container'
          data-testid='groups-list-item'>
          <div className='row list item'>
            <div className='col-6 col-lg-4 org-name'>{getOrgName(org)}</div>
            <div className='col-6 col-lg-4 members'>{org.members.length}</div>
          </div>
        </Link>
      ));
    }
  }

  function checkOrgNames(value_name, callback) {
    let org_name_match = false;

    orgGroups.forEach(org => {
      let org_name = getOrgName(org);
      if (org_name === value_name) {
        setLoadingOrgs(false);
        setCreateOrgError({ message: 'This org name already exists. Try a different name.' });
        org_name_match = true;
      }
    });
    callback(org_name_match);
  }

  function handleCreateOrgCallback(response) {
    // if error is returned
    if (response.status === 'error') {
      setLoadingOrgs(false);
      setCreateOrgError(response.error);
    }
    // if data is returned
    if (response.status === 'success') {
      toggleCreateOrgModal();
      setGroups(response.data);
      setLoadingOrgs(false);
    }
  }

  function createOrg(values) {
    setCreateOrgError(null);
    setLoadingOrgs(true);
    checkOrgNames(values.name, function (org_name_match) {
      if (org_name_match === false) {
        const body = {
          name: values.name,
        };
        fetchData(signal, '/api/create-org', body, 'POST', response => handleCreateOrgCallback(response));
      }
    });
  }

  function toggleCreateOrgModal() {
    setCreateOrgModalOpen(!createOrgModalOpen);
    fetchOrgs();
  }

  function filterOrgs(query_str) {
    // Show all orgs if search query is empty
    if (query_str === null) {
      setFilteredOrgs(orgGroups);
    }
    // Otherwise show only orgs that include query string
    else {
      let filtered_orgs = [];
      orgGroups.forEach(group => {
        let group_name = group.name.toUpperCase();
        let formatted_query_str = query_str.toUpperCase();
        if (group_name.includes(formatted_query_str)) {
          filtered_orgs.push(group);
        }
      });
      setFilteredOrgs(filtered_orgs);
    }
  }

  function updateQuery(event) {
    setQuery(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    filterOrgs(query);
  }

  function resetForm(event) {
    setQuery('');
    filterOrgs(null);
  }

  return (
    <div id='GroupsList' className={isShown ? 'show' : 'hidden'}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-1'></div>
          <div className='col-12 col-lg-10 pt-2 groups-list-container'>
            {/* Summary */}
            <div className='row summary mb-2'>
              <div className='col-12'>
                <SearchForm
                  handleSubmit={event => handleSubmit(event)}
                  resetForm={event => resetForm(event)}
                  updateQuery={event => updateQuery(event)}
                  searchField='Org Name'
                  query={query}
                />
              </div>
            </div>
            {/* Heading */}
            <div className='row total'>
              <div className='col-6'>
                Showing {filteredOrgs.length} of {orgGroups.length}
              </div>
              <div className='col-6 pr-lg-0 create-org'>
                <QModalBtn
                  btnClassName='small'
                  buttonText='Create Org'
                  onHide={() => toggleCreateOrgModal()}
                  show={createOrgModalOpen}
                  id='CreateOrgModal'
                  size='lg'>
                  {loadingOrgs ? (
                    <LoadingBlock className='transparent-bg' text='Please be patient...' />
                  ) : (
                    <CreateOrgModal
                      createOrgError={createOrgError}
                      createOrg={values => createOrg(values)}
                      toggleCreateOrgModal={() => toggleCreateOrgModal()}
                    />
                  )}
                </QModalBtn>
              </div>
            </div>
            <div className='row list header'>
              <div className='col-6 col-lg-4 email'>Organization</div>
              <div className='col-6 col-lg-4 name'>Members</div>
            </div>
            {/* Loading */}
            {!isLoaded ? (
              <LoadingBlock />
            ) : (
              <React.Fragment>
                {/* Error */}
                {error ? (
                  <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
                ) : (
                  <React.Fragment>
                    {/* Groups List */}
                    {showList(filteredOrgs)}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          <div className='col-lg-1'></div>
        </div>
      </div>
    </div>
  );
};

const CreateOrgModal = props => {
  const reserveDashBang = /^((?!--!).)*$/;
  const reserveOrgKeyword = /^((?!--[O|o]rg).)*$/;
  const reserveProjectKeyword = /^((?!--[P|p]roject).)*$/;
  const reserveAdminKeyword = /^((?!--[A|a]dmin).)*$/;

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('This field is required')
      .min(2, 'Minimum 2 characters, please')
      .max(45, 'Max 45 characters, please')
      .test('reserved-word-1', '!-- is reserved for project serials', function (value) {
        return reserveDashBang.test(value);
      })
      .test('reserved-word-2', '--Org will be automatically added', function (value) {
        return reserveOrgKeyword.test(value);
      })
      .test('reserved-word-3', '--Project is reserved for projects', function (value) {
        return reserveProjectKeyword.test(value);
      })
      .test('reserved-word-4', '--Admin is reserved for admin groups', function (value) {
        return reserveAdminKeyword.test(value);
      }),
  });

  return (
    <React.Fragment>
      <h1>Create New Organization</h1>
      {props.createOrgError ? (
        <QErrorMessage
          className='row mx-0 p0'
          text={
            <span className='bold'>
              Uh oh—something went wrong. Please try again later.
              <br />
              {props.createOrgError.message}
            </span>
          }
        />
      ) : (
        ''
      )}
      <QFormWrapper
        formId='CreateOrgForm'
        initialValues={{
          name: '',
        }}
        initialErrors={{
          name: true,
        }}
        validationSchema={validationSchema}
        onSubmit={values => props.createOrg(values)}>
        {formProps => <CreateOrgForm {...formProps} />}
      </QFormWrapper>
      <QButton className='secondary mt-4' onClick={props.toggleCreateOrgModal}>
        Cancel
      </QButton>
    </React.Fragment>
  );
};

const CreateOrgForm = props => (
  <React.Fragment>
    <div className='name'>
      <div className='row'>
        <QFormGroup
          containerClassName='col-12 col-md-8'
          element='text'
          id='name'
          name='name'
          label='Org Name'
          onChange={e => handleInputChange(e, props, 'name', () => {})}
          type='text'
          includeError={true}
        />
      </div>
    </div>
    <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
      Add New Organization
    </QButton>
  </React.Fragment>
);

CreateOrgModal.propTypes = {
  createOrgError: PropTypes.any,
  toggleCreateOrgModal: PropTypes.func.isRequired,
  createOrg: PropTypes.func.isRequired,
};

export default GroupsList;
