/*
This module contains functions related to form validation.
*/
import { checkConfigType } from './SubmitFunctions';
import { isInt, isValidFloat, stringFormat, constructSelectMenu, objectFormat } from '../../../hoc/FormFunctions';

export function buildValidationObj(schema, param_keys, static_schema = {}) {
  /*
  Build validation object for Formik form validation.

  Args:
    • schema = object: { key_1: yup_schema, key_2: yup_schema }
    • param_keys = array: ['key_1']

  Returns:
    • validation_obj = object: { key_1: yup_schema }
  */
  let validation_obj = {};

  param_keys.forEach(param_key => {
    if (schema[param_key]) {
      validation_obj[param_key] = schema[param_key];
    }
  });

  return { ...validation_obj, ...static_schema };
}

export function getValidationKeys(schema, param_keys, static_schema = {}) {
  /*
  Get configurable solver params that require form validation as they require open input fields.

  Args:
    • schema = object: { key_1: yup_schema, key_2: yup_schema }
    • param_keys = array: ['key_1']

  Returns:
    • validation_keys = array: ['key_1']
  */
  let validation_keys = [];

  param_keys.forEach(param_key => {
    if (schema[param_key]) {
      validation_keys.push(param_key);
    }
  });

  return validation_keys.concat(Object.keys(static_schema));
}

export function buildParamKeys(pipeline) {
  /*
  Construct a list of parameters that require validation.

  Args:
    • pipeline = array: ordered list of the solver pipeline

  Returns:
    • List: of parameters that require form validation
  */
  let param_keys = [];
  pipeline.forEach((solver, i) => {
    const params = Object.keys(solver.params);
    params.forEach(param => {
      param_keys.push(`${param}__${i}`);
    });
  });
  return param_keys;
}

export function getInitialFormValues(pipeline, validation_keys, configs) {
  /*
  Construct a list of form validation objects.

  Args:
    • pipeline = array: list of solver objects (see buildDefaultParameters() for structure)
    • validation_keys = array: ['key_1'] parameters that need form validation

  Returns:
    • An object of initial values for form validation:
    {'method_of_truncation_threshold': 'None', 'increment_screening_threshold': 'None'}
  */
  let initial_values = [];

  let obj = {};
  validation_keys.forEach(key => {
    const formatted_key = key.split('__')[0];
    const solver_index = key.split('__')[1];
    const dict_type_found = checkConfigType(configs, pipeline[solver_index].solver_name, formatted_key, 'dict');

    if (pipeline[solver_index].params[formatted_key] !== undefined) {
      if (dict_type_found) {
        obj[key] = JSON.stringify(pipeline[solver_index].params[formatted_key]);
      } else {
        obj[key] = pipeline[solver_index].params[formatted_key];
      }
    }
  });
  initial_values.push(obj);

  const merged_vals = Object.assign({}, ...initial_values);
  return merged_vals;
}

export function getSelectMenu(configs, solver_name, param_key) {
  /*
  Get list of select options from the configs object and return a formatted list that can be consumed by a select element.

  Args:
    • configs = object: containing all solver configs
    • solver_name = string: e.g. 'IncrementalDecomposition'
    • param_key = string: e.g. 'calc_type'

  Returns:
    • constructSelectMenu(): array
    • Empty array: if options cannot be found for parameter specified
  */
  const select_options =
    configs &&
    configs[solver_name] &&
    configs[solver_name].configurable &&
    configs[solver_name].configurable[param_key] &&
    configs[solver_name].configurable[param_key].options;
  if (select_options) {
    // Add 'None' to dropdown list if NoneType is allowed
    const allowed_types = configs[solver_name].configurable[param_key].constraints.type;
    if (allowed_types && allowed_types.includes('NoneType')) {
      let formatted_options = [].concat(select_options);
      formatted_options.unshift('None');
      return constructSelectMenu(formatted_options);
    } else return constructSelectMenu(select_options);
  } else return [];
}

function checkParamType(value, config_types) {
  /*
  Compare value to allowed input types.

  Args:
    • value = string: input value
    • config_types = array: list of allowed input types

  Returns:
    • Boolean: if value matches at least one allowed input type
  */
  if (config_types) {
    let matches_type = [];
    if (config_types.includes('NoneType')) {
      matches_type.push(value === 'None');
    }
    if (config_types.includes('int')) {
      matches_type.push(isInt.test(value));
    }
    if (config_types.includes('float')) {
      const is_valid_float = isValidFloat(value);
      matches_type.push(is_valid_float);
    }
    if (config_types.includes('dict')) {
      try {
        value = value.replaceAll('\\n', ' ');
        // Convert JSON string and escaped quotes to regular string
        value = value.replaceAll('\\"', '"');
        const arr = value.split('');
        if (arr[0] === '"' && arr[arr.length - 1] === '"') {
          value = value.substring(1, arr.length - 1);
        }
        // Try to parse value to verify JSON is valid
        if (objectFormat.test(value)) {
          JSON.parse(value);
          matches_type.push(true);
        } else {
          matches_type.push(false);
        }
      } catch {
        matches_type.push(false);
      }
    }
    if (config_types.includes('str')) {
      matches_type.push(stringFormat.test(value));
    }
    return matches_type.some(item => item === true);
  } else {
    // Do not block form submission if config_types is not found.
    return true;
  }
}

function checkMinMax(value, constraints) {
  /*
  Compare value to see if it falls within min_val and max_val constraints.

  Args:
    • value = string: input value
    • constraints = object: solver constraints derived from constants.py

  Returns:
    • Boolean: if value satisfies min/max constraints
  */
  if (constraints !== undefined && constraints.min_val !== undefined && constraints.max_val !== undefined) {
    let min_val = constraints.min_val;
    let max_val = constraints.max_val;
    if (max_val === 'infinity') {
      max_val = Number.POSITIVE_INFINITY;
    }
    return value >= min_val && value <= max_val;
  }
  // If min constraint only
  else if (constraints !== undefined && constraints.min_val !== undefined) {
    return value >= constraints.min_val;
  }
  // If max constraint only
  else if (constraints !== undefined && constraints.max_val !== undefined) {
    return value <= constraints.max_val;
  }
  // If no min/max constraints found
  else {
    return true;
  }
}

export function buildErrorMessage(value, param_key, solver, solver_configs) {
  /*
  Return a dynamic error message depending on solver allowed input types and min/max constraints.

  Args:
    • value = string: input value
    • param_key = string: the parameter key being checked
    • solver = string: the name of the solver the param key is nested in
    • solver_configs = object: the solver constraints object to search in

  Returns:
    • Error message = string
  */
  try {
    const config_types = solver_configs[solver].configurable[param_key].config_types;
    const constraints = solver_configs[solver].configurable[param_key].constraints;

    // Return empty input error
    if (value === undefined || value === null || value === '') {
      return `This field is required`;
    }

    // Return type error
    let types = '';
    config_types.forEach(type => {
      let t = type;
      if (type === 'NoneType') {
        t = '"None"';
      }
      if (types === '') {
        types = types + t;
      } else {
        types = types + `, ` + t;
      }
    });
    if (!checkParamType(value, config_types)) {
      return `Must be a valid input type: [${types}]`;
    }

    // Return min/max error
    if (!checkMinMax(value, constraints)) {
      // min only
      if (constraints.min_val && constraints.max_val === undefined) {
        return `Must be greater than or equal to ${constraints.min_val}`;
      }
      // max only
      else if (constraints.min_val === undefined && constraints.max_val) {
        return `Must be less than or equal to ${constraints.max_val}`;
      }
      // min & max
      else {
        return `Must be between ${constraints.min_val} and ${constraints.max_val}`;
      }
    }
  } catch (e) {}
}

export function validateParam(value, param_key, solver, solver_configs) {
  /*
  Validate whether the value meets the allowed config types and solver constraints.

  Args:
    • value = string: input value
    • param_key = string: the parameter key being checked
    • solver = string: the name of the solver the param key is nested in
    • solver_configs = object: the solver constraints object to search in

  Returns:
    • Boolean: if value meets constraints
  */
  try {
    let is_valid = [];
    const config_types =
      solver_configs &&
      solver_configs[solver] &&
      solver_configs[solver].configurable &&
      solver_configs[solver].configurable[param_key] &&
      solver_configs[solver].configurable[param_key].config_types;
    const constraints =
      solver_configs &&
      solver_configs[solver] &&
      solver_configs[solver].configurable &&
      solver_configs[solver].configurable[param_key] &&
      solver_configs[solver].configurable[param_key].constraints;

    // Check if no input
    is_valid.push(value !== undefined && value !== null && value !== '');

    // Check allowed types
    is_valid.push(checkParamType(value, config_types));

    // Check min/max constraints
    if (value !== 'None') {
      is_valid.push(checkMinMax(value, constraints));
    }
    return !is_valid.some(item => item === false);
  } catch (e) {
    console.warn(e);
    // Do not block form submission if constraints not found. Can let apiserver handle those.
    return true;
  }
}

export function checkValueChanged(original_pipeline, new_pipeline) {
  // Gather solver params values from entire solver pipeline
  // and create string arrays for comparison

  function getVals(arr, params) {
    if (params !== null) {
      const vals = Object.values(params);
      vals.forEach(val => {
        if (typeof val === 'object') {
          arr = arr.concat(getVals([], val));
        } else {
          arr = arr.concat([val.toString()]);
        }
      });
    }
    return arr;
  }

  let original_vals = [];
  original_pipeline.forEach(orig_pip => {
    original_vals = original_vals.concat(getVals([], orig_pip.params));
  });

  let new_vals = [];
  new_pipeline.forEach(new_pip => {
    new_vals = new_vals.concat(getVals([], new_pip.params));
  });

  // Return true if any val in the new array does not match its val in the original array
  return new_vals.some((val, i) => original_vals[i] !== val);
}
