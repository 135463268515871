import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useSubmitData } from '../../../hoc/SubmitData';
import { problemHandleFormat } from '../../../hoc/FormFunctions';
import { LoadingBlock } from '../../../components/loading/Loading';
import QFormWrapper from '../../../components/form/QFormWrapper';
import { SubmitGroupLabel, SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';
import {
  buildValidationObj,
  getValidationKeys,
  getInitialFormValues,
  buildErrorMessage,
  validateParam,
  buildParamKeys,
} from '../functions/SubmitFormFunctions';
import { hideContainer, formatSolverName } from '../functions/SubmitFunctions';
import { defaultBasis } from '../constants/SubmitConst';
import MeanFieldHandle from '../components/MeanFieldHandle';
import ShowInputs from '../components/ShowInputs';
import BasisSet from '../components/BasisSet';

const SubmitIFCI = props => {
  // Init
  const { solverConfigs, defaultParams } = useSubmitData();
  const [isLoaded, setIsLoaded] = useState(false);
  // Form Validation
  const [validationObj, setValidationObj] = useState({});
  const [validationKeys, setValidationKeys] = useState([]);
  // Edit Params
  const [pipeline, setPipeline] = useState([]);
  const [mfHandle, setMFHandle] = useState(null);
  const [basis, setBasis] = useState(props.basis);
  const blacklist = ['solver'];
  const timers = useRef([]);
  // Refs for form validation
  const [formLoaded, setFormLoaded] = useState(false);
  const formRef = useRef(null);

  const outer_solver_name = props.solverPipeline[0] ? props.solverPipeline[0].solver_name : '';
  const second_solver_name = props.solverPipeline[1] ? props.solverPipeline[1].solver_name : '';
  const base_solver_name = props.solverPipeline[2] ? props.solverPipeline[2].solver_name : '';

  const validation_items = {
    method_of_truncation_threshold__0: yup.string().test(
      'method_of_truncation_threshold',
      value => buildErrorMessage(value.value, 'method_of_truncation_threshold', outer_solver_name, solverConfigs),
      value => validateParam(value, 'method_of_truncation_threshold', outer_solver_name, solverConfigs)
    ),
    increment_screening_threshold__0: yup.string().test(
      'increment_screening_threshold',
      value => buildErrorMessage(value.value, 'increment_screening_threshold', outer_solver_name, solverConfigs),
      value => validateParam(value, 'increment_screening_threshold', outer_solver_name, solverConfigs)
    ),
    method_of_truncation_threshold__1: yup.string().test(
      'method_of_truncation_threshold',
      value => buildErrorMessage(value.value, 'method_of_truncation_threshold', second_solver_name, solverConfigs),
      value => validateParam(value, 'method_of_truncation_threshold', second_solver_name, solverConfigs)
    ),
    method_of_truncation_threshold__2: yup.string().test(
      'method_of_truncation_threshold',
      value => buildErrorMessage(value.value, 'method_of_truncation_threshold', base_solver_name, solverConfigs),
      value => validateParam(value, 'method_of_truncation_threshold', base_solver_name, solverConfigs)
    ),
  };

  const static_validation_items = {
    meanfield_handle__0: yup
      .string()
      .nullable()
      .test('meanfield_handle', 'Must be a valid problem handle format', value => {
        return problemHandleFormat.test(value) || value === undefined || value === null;
      }),
  };

  useEffect(() => {
    setPipeline(props.solverPipeline);
    setMFHandle(props.meanFieldHandle);

    if (solverConfigs !== null && defaultParams !== null && props.solverPipeline[0] && props.solverPipeline[0].params) {
      const param_keys = buildParamKeys(props.solverPipeline);
      setValidationObj(buildValidationObj(validation_items, param_keys, static_validation_items));
      setValidationKeys(getValidationKeys(validation_items, param_keys, static_validation_items));
      setIsLoaded(true);
    }

    timers.current.push(
      setTimeout(() => {
        props.solverPipeline.forEach(solver => {
          hideContainer(solver.solver_name);
        });
      }, 500)
    );
  }, [props.solverPipeline, solverConfigs, defaultParams]);

  function checkFormValid() {
    // Delay to allow formRef validations to finish
    const t = setTimeout(() => {
      props.setParamsValid(formRef.current.isValid);
    }, 200);
    timers.current.push(t);
  }

  useEffect(() => {
    // Trigger form validations after MoleculeParamsForm element is loaded
    if (formRef && formRef.current !== null) {
      let fields = {};
      validationKeys.forEach(param => {
        fields[param] = true;
      });
      formRef.current.setTouched(fields);
      checkFormValid();
    }
  }, [formLoaded]);

  useEffect(() => {
    // Update paramsForm in parent component whenever form validity changes
    if (formRef.current) {
      checkFormValid();
    }
  }, [formRef.current]);

  useEffect(() => {
    // Component cleanup
    return () => {
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function handlePageLoad() {
    /*
    This function should be called at the end of the html elements
    load, as it looks for a specific element in the DOM and triggers
    a component state change
    */
    if (!formLoaded) {
      // Delay to allow html elements to load
      const timer = setTimeout(() => {
        const el = document.getElementById('IFCIForm');
        if (el !== undefined && el !== null) {
          setFormLoaded(true);
        }
      }, 1000);
      timers.current.push(timer);
    }
  }

  const validationSchema = yup.object().shape(validationObj);

  function showSolverParams(formProps) {
    return Array.from(pipeline).map((solver, index) => {
      return (
        <React.Fragment key={index}>
          <SubmitInputGroup id={`${solver.solver_name}_container`}>
            <SubmitGroupLabel className='w-100 mb-0' text={`${formatSolverName(solver.solver_name)} Parameters`} />
            <div id={`${solver.solver_name}_inputs`}>
              {index === 0 && (
                <div className='d-flex flex-wrap'>
                  {/* Mean Field Handle */}
                  <MeanFieldHandle
                    inputName='meanfield_handle__0'
                    handle={mfHandle}
                    setHandle={handle => {
                      setMFHandle(handle);
                      props.updateMoldata([['mean_field_handle', handle]]);
                    }}
                    formProps={formProps}
                  />
                  {/* Basis */}
                  <BasisSet
                    solverName={solver.solver_name}
                    basisSelected={basis}
                    updateBasisSet={val => {
                      setBasis(val);
                      props.updateMoldata([['basis', val]]);
                    }}
                  />
                </div>
              )}
              {/* Inputs */}
              {ShowInputs(solver, index, formProps, solverConfigs, blacklist, validationKeys, pipeline, pipeline_mod =>
                props.updatePipeline(pipeline_mod)
              )}
            </div>
          </SubmitInputGroup>
        </React.Fragment>
      );
    });
  }

  return (
    <div id='SubmitIFCI'>
      {/* Outer Solver */}
      {isLoaded && pipeline[0] ? (
        <QFormWrapper
          innerRef={formRef}
          formId='IFCIForm'
          validationSchema={validationSchema}
          initialValues={{
            ...getInitialFormValues(pipeline, validationKeys, solverConfigs),
            ...{ meanfield_handle__0: mfHandle },
          }}
          onSubmit={() => {}}>
          {formProps => {
            return (
              <React.Fragment>
                {showSolverParams(formProps)}
                {/* Controls */}
                <div className='w-100 mb-3'>
                  <ResetDropdown
                    resetChanges={() => {
                      formProps.resetForm();
                      setBasis(defaultBasis);
                      setMFHandle(null);
                      props.resetPipeline();
                    }}
                  />
                </div>
                {handlePageLoad()}
              </React.Fragment>
            );
          }}
        </QFormWrapper>
      ) : (
        <LoadingBlock text='Loading paramaters...' className='mb-3' />
      )}
    </div>
  );
};

SubmitIFCI.propTypes = {
  currentOrg: PropTypes.object,
  solverPipeline: PropTypes.array.isRequired,
  updatePipeline: PropTypes.func.isRequired,
  resetPipeline: PropTypes.func.isRequired,
  meanFieldHandle: PropTypes.string,
  updateMoldata: PropTypes.func.isRequired,
  basis: PropTypes.string.isRequired,
};

export default SubmitIFCI;
