import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
import { getOrgName, formatCurrency, initRangeStart, getRangeEnd, allRangeStart } from '../../../hoc/CommonFunctions';
import { paginationSetup } from '../../../hoc/CreditsCommonFuncs';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import { LoadingFilled, LoadingInline } from '../../../components/loading/Loading';
import CreditTransactions, { checkMathSign } from '../../../components/credits/Credits';
import DatePicker, { ShowAllButton } from '../../../components/datepicker/DatePicker';

const Transactions = props => {
  const { fetchCredits, fetchBalance } = useAuth();
  const { setOverlayLevel1 } = useUserData();
  const [isShown, setIsShown] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Performance Metrics
  const [firstRender, setFirstRender] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);
  // GET Credits
  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const [credits, setCredits] = useState([]);
  const [creditsTotal, setCreditsTotal] = useState(0);
  const [creditsLoading, setCreditsLoading] = useState(true);
  const [fetchCreditsError, setFetchCreditsError] = useState(null);
  const [id, setId] = useState(null);
  const [offset, setOffset] = useState(0);
  const [setLength, setSetLength] = useState(20);
  // GET Balance
  const [balance, setBalance] = useState([]);
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [fetchBalanceError, setFetchBalanceError] = useState(null);

  function trackCreditsLoaded(return_callback) {
    if (
      document.querySelector('div.data-sets-summary') !== null ||
      document.querySelector('div.row.info.empty') !== null
    ) {
      return_callback();
    } else {
      let rootElement = document.getElementById('Credits');
      if (rootElement) {
        const callback = function (mutationsList, observer) {
          for (const mutation of mutationsList) {
            if (
              // User has credits
              (mutation.target === document.querySelector('div#CreditTransactionsComponent') &&
                mutation.previousSibling === document.querySelector('div.data-sets-summary')) ||
              // User has no credits
              (mutation.target === document.querySelector('div.col-12.bold') &&
                mutation.previousSibling === document.querySelector('div.row.info.empty'))
            ) {
              observer.disconnect();
              return_callback();
            }
          }
        };

        const observer = new MutationObserver(callback);
        const config = { attributes: false, childList: true, subtree: true };
        observer.observe(rootElement, config);
      }
    }
  }

  function handleCreditsCallback(response) {
    // if error is returned
    if (response.status === 'error') {
      setIsLoaded(true), setCreditsLoading(false), setFetchCreditsError(response.error);
      if (MIXPANEL_TOKEN) {
        mixpanel.track('Account Credits Component: Load', {
          Result: 'Error',
          Error: response.error,
          'Org ID': props.currentOrg.id,
          'Org Name': props.currentOrg.name,
        });
      }
    }
    // if credits data is returned
    if (response.status === 'success') {
      setCredits(response.data.credits);
      setCreditsTotal(response.data.total);
      setCreditsLoading(false);
      setIsLoaded(true);

      // MIXPANEL tracking
      if (MIXPANEL_TOKEN) {
        if (!contentLoaded) {
          trackCreditsLoaded(() => {
            mixpanel.track('Account Credits Component: Load', {
              Result: 'Loaded',
              'Org ID': props.currentOrg.id,
              'Org Name': props.currentOrg.name,
            });
            setContentLoaded(true);
          });
        }
      }
    }
  }

  function handleBalanceCallback(response) {
    // if balance data is returned
    if (response.status === 'success') {
      setIsLoaded(true), setBalanceLoading(false);
      if (response !== null && response.data.length > 0) {
        setBalance(response.data[0]);
      } else setBalance(0);
    }
    // error object is returned
    if (response.status === 'error') {
      setIsLoaded(true), setBalanceLoading(false), setFetchBalanceError(response.error);
    }
  }

  useEffect(() => {
    setIsShown(true);

    if (sessionStorage.getItem('qemist.credRangeStart') === null) {
      sessionStorage.setItem('qemist.credRangeStart', initRangeStart);
      setRangeStart(initRangeStart);
    } else {
      let stored_value = sessionStorage.getItem('qemist.credRangeStart');
      let stored_value_num = parseInt(stored_value, 10);
      setRangeStart(stored_value_num);
    }

    const range_end = getRangeEnd();
    // prettier-ignore
    if (sessionStorage.getItem('qemist.credRangeEnd') === null) {
      sessionStorage.setItem('qemist.credRangeEnd', range_end);
      setRangeEnd(range_end);
    } else {
      let stored_value = sessionStorage.getItem('qemist.credRangeEnd');
      let stored_value_num = parseInt(stored_value, 10);
      setRangeEnd(stored_value_num);
    }

    paginationSetup(new_path => {
      history.replace(new_path);
    });

    // Set offset & fetch
    let post_hash = window.location.href.split('/credits')[1];
    let page_num = post_hash.split('#')[1];
    let start_fetch;
    const this_offset = page_num * setLength - setLength;

    if (page_num !== undefined) {
      setOffset(this_offset);
      // Delay fetching and allow currentOrg to populate
      start_fetch = setTimeout(() => {
        if (props.currentOrg !== null) {
          let this_id;
          if (props.currentOrg._id !== undefined && props.currentOrg._id !== null) {
            setId(props.currentOrg._id);
            this_id = props.currentOrg._id;
          } else {
            setId(props.user.userid);
            this_id = props.user.userid;
          }
          // prettier-ignore
          fetchCredits(signal, this_id, rangeStart, rangeEnd, this_offset, setLength, response => handleCreditsCallback(response));
          fetchBalance(signal, this_id, response => handleBalanceCallback(response));
        }
      }, 500);
    }

    // Event Tracking
    if (MIXPANEL_TOKEN) {
      if (firstRender) {
        mixpanel.time_event('Account Credits Component: Load');
        mixpanel.track('Account Credits Component: Mounted');
      }
    }
    setFirstRender(false);
    return () => {
      if (MIXPANEL_TOKEN) {
        if (document.querySelector('div.full-screen-backdrop')) {
          mixpanel.track('Account Credits Component: Load', { Result: 'Spinner still shown' });
        }
      }
      setContentLoaded(false);
      setFirstRender(true);
      clearTimeout(start_fetch);
      abortController.abort();
    };
  }, [rangeStart, rangeEnd, offset, props.promoStatus]);

  // DateRangePicker
  function handleCallback(start, end) {
    const range_start = moment(start).unix();
    const range_end = moment(end).unix();

    setCreditsLoading(true), setRangeStart(range_start), setRangeEnd(range_end);
    sessionStorage.setItem('qemist.credRangeStart', range_start);
    sessionStorage.setItem('qemist.credRangeEnd', range_end);
  }

  function showAllTransactions() {
    const range_start = allRangeStart;
    const range_end = getRangeEnd();

    setCreditsLoading(true), setRangeStart(range_start), setRangeEnd(range_end);
    sessionStorage.setItem('qemist.credRangeStart', range_start);
    sessionStorage.setItem('qemist.credRangeEnd', range_end);
    fetchCredits(signal, id, range_start, range_end, offset, setLength, response => handleCreditsCallback(response));
    fetchBalance(signal, id, response => handleBalanceCallback(response));
    history.push('#1');
  }

  return (
    <React.Fragment>
      {!isLoaded ? (
        <LoadingFilled className='with-subnav' />
      ) : (
        <div id='Credits' className={`sub-content container-fluid ${isShown ? 'show' : 'hide'}`}>
          <div className='row'>
            <div className='col-12 content-container pt-0'>
              {/* Summary */}
              <div className='row org-summary my-3'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12 col-md-6 org-group'>
                      <p className='label-sm'>Organization</p>
                      <p className='bold org-name mb-0'>
                        {props.currentOrg._id !== undefined ? getOrgName(props.currentOrg) : 'Personal'}
                      </p>
                    </div>
                    <div className='col-12 col-md-6'>
                      <p className='label-sm'>Credits Available</p>
                      {balanceLoading ? (
                        <LoadingInline />
                      ) : (
                        <p
                          className={`${
                            checkMathSign(balance) === 'negative' ? 'negative' : 'positive'
                          } mono md credit-amount mb-0`}>
                          {formatCurrency(balance)}
                        </p>
                      )}
                    </div>
                  </div>
                  {props.currentOrg._id !== undefined ? (
                    <div className='row no-mobile'>
                      <div className='col-12 col-md-10 pl-3 helper-text'>
                        <i className='icon-light-bulb' />
                        <span className='text-xs'>
                          Requests sent using your <Link to='/account/token'>API token</Link> will deduct shared credits
                          from this organization.
                          <br /> Please contact your system administrator if you need to make any changes.
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {/* Error */}
              {fetchBalanceError ? (
                <QErrorMessage
                  className='row p0 mb-3'
                  text={<span className='bold'>GET Balance Error: {fetchBalanceError.message}</span>}
                />
              ) : (
                ''
              )}
              {/* Date Picker */}
              <div className='row'>
                <div className='col-12 d-flex align-items-center DateRangePicker'>
                  <DatePicker
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    handleCallback={(start, end) => handleCallback(start, end)}
                  />
                  <ShowAllButton onClick={() => showAllTransactions()} />
                </div>
              </div>
              {fetchCreditsError ? (
                <QErrorMessage
                  className='row p0 mb-3'
                  text={<span className='bold'>GET Credits Error: {fetchCreditsError.message}</span>}
                />
              ) : (
                <React.Fragment>
                  {!creditsLoading ? (
                    <CreditTransactions
                      credits={credits}
                      creditsTotal={creditsTotal}
                      creditsLoading={creditsLoading}
                      rangeStart={rangeStart}
                      rangeEnd={rangeEnd}
                      setLength={setLength}
                      setOffset={value => setOffset(value)}
                      // prettier-ignore
                      fetchCredits={new_offset => {
                        fetchCredits(signal, id, rangeStart, rangeEnd, new_offset, setLength, response => handleCreditsCallback(response));
                        fetchBalance(signal, id, response => handleBalanceCallback(response));
                      }}
                      showCreditPoolDetails={id => {
                        const obj = {
                          content_type: 'credit-pool-details',
                          pool_id: id,
                        };
                        setOverlayLevel1(obj);
                      }}
                    />
                  ) : (
                    <LoadingInline className='mt-3' />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Transactions.propTypes = {
  user: PropTypes.object,
  currentOrg: PropTypes.object,
  promoStatus: PropTypes.any,
};

export default Transactions;
