import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import mixpanel from 'mixpanel-browser';

// Import hooks & hoc
import { useAuth } from '../../hoc/Auth';
import { useUserData } from '../../hoc/UserData';
// prettier-ignore
import { getOrgName, getAdminGroupName, getPaginationSets } from '../../hoc/CommonFunctions';
import { handleSearchFormSubmit, resetSearchForm } from '../../hoc/FilterFuncs';
import { scrollToTop } from '../../hoc/ScrollFunctions';

// Import components
import { LoadingFilled, LoadingBlock } from '../../components/loading/Loading';
import CopyToClipboard, { copyToClipboard } from '../../components/copyToClipboard/CopyToClipboard';
import QButton from '../../components/button/Button';
import QModalBtn from '../../components/modal/Modal';
import SearchForm from '../../components/form/SearchForm';
import { QErrorMessage } from '../../components/notifications/Notifications';
import AddMembersToOrgModal from '../../components/modal/AddMembersToOrg';
import RemoveMemberModal from '../../components/modal/RemoveMembersFromOrg';
import ChangeRoleForm from '../../components/form/ChangeRole';
import Pagination, { PaginationSummary } from '../../components/pagination/Pagination';

const GroupAdmin = props => {
  // Init
  const { fetchData } = useAuth();
  const { user, currentOrg, setCurrentOrg, setGroups, setOrgs, setAdminGroups } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [orgName, setOrgName] = useState('');
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Group Members
  const [setLength, setSetLength] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(4);
  const [pages, setPages] = useState(1);
  const [members, setMembers] = useState({ total: '', users: '' });
  const [error, setError] = useState(null);
  // GET Admin Group Members
  const [admins, setAdmins] = useState([]);
  const [adminEmails, setAdminEmails] = useState([]);
  const [getAdminsError, setGetAdminsError] = useState(null);
  // Add Members to Org
  const [addMembersToOrgModalOpen, setAddMembersToOrgModalOpen] = useState(false);
  const [addMembersToOrgError, setAddMembersToOrgError] = useState(null);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [usersAdded, setUsersAdded] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [emailsNotFound, setEmailsNotFound] = useState([]);
  // Remove Members from Org
  const [removeUser, setRemoveUser] = useState({ email: '', user_id: '' });
  const [removeMemberModalOpen, setRemoveMemberModalOpen] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [removeMemberError, setRemoveMemberError] = useState(false);
  const [self, setSelf] = useState(false);
  // Performance Metrics
  const [firstRender, setFirstRender] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);
  // Filter Members
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [query, setQuery] = useState('');

  let history = useHistory();

  function trackContentLoaded(return_callback) {
    if (document.querySelector('div.users-list') !== null) {
      return_callback();
    } else {
      let rootElement = document.getElementById('GroupAdmin');
      if (rootElement) {
        const callback = function (mutationsList, observer) {
          for (const mutation of mutationsList) {
            if (
              mutation.target === document.querySelector('div.users-list') &&
              mutation.addedNodes[0] === document.querySelector('div.user-row-container')
            ) {
              observer.disconnect();
              return_callback();
            }
          }
        };

        const observer = new MutationObserver(callback);
        const config = { attributes: false, childList: true, subtree: true };
        observer.observe(rootElement, config);
      }
    }
  }

  function fetchMembersData(org_id, page) {
    let url = '/api/get-group-members?group_id=' + org_id + '&page=' + page;
    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
        if (MIXPANEL_TOKEN) {
          mixpanel.track('Group Admin Component: Load', { Result: 'Error', Error: response.error });
        }
      }
      // if data is returned
      if (response.status === 'success') {
        const data = response.data;
        setMembers(data);
        setFilteredMembers(data.users);

        // Get pagination sets
        getPaginationSets(data.total, setLength, num => {
          setPages(num);
          setDataStart(page * setLength - setLength);
          setDataEnd(page * setLength - 1);
        });

        // MIXPANEL tracking
        if (MIXPANEL_TOKEN) {
          if (!contentLoaded) {
            trackContentLoaded(() => {
              mixpanel.track('Group Admin Component: Load', { Result: 'Loaded' });
              setContentLoaded(true);
            });
          }
        }
      }

      setIsLoaded(true);
      scrollToTop('GroupAdmin');
    });
  }

  function fetchAdminGroupMembers(group_id) {
    let url = '/api/get-group-members?group_id=' + group_id + '&page=' + currentPage;
    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setGetAdminsError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const data = response.data;
        setAdmins(data);

        // Populate admin emails array
        let admin_emails = [];

        data.users.forEach(member => {
          admin_emails.push(member.email);
        });
        setAdminEmails(admin_emails);

        let url_partial = window.location.href.split('group-admin/')[1];
        let org_id = url_partial.split('/')[0];
        // Redirect user if the user's current org id does not match url path's group id
        if (currentOrg._id === org_id) {
          fetchMembersData(org_id, currentPage);
        } else history.push('/projects');
      }
    });
  }

  useEffect(() => {
    if (MIXPANEL_TOKEN) {
      if (firstRender) {
        mixpanel.time_event('Group Admin Component: Load');
        mixpanel.track('Group Admin Component: Mounted');
      }
    }
    setFirstRender(false);

    if (currentOrg !== null && props.adminGroup) {
      // Redirect if user's admin group name (if any) does not match current org name
      let org_group_name = getOrgName(currentOrg);
      let admin_group_name = getAdminGroupName(props.adminGroup);
      if (org_group_name === admin_group_name) {
        // Redirect if user does not have an admin group passed through React props
        if (props.adminGroup._id !== undefined) {
          fetchAdminGroupMembers(props.adminGroup._id);
          setOrgName(org_group_name);
        } else history.push('/projects');
      } else history.push('/projects');
    }

    return () => {
      if (MIXPANEL_TOKEN) {
        if (document.querySelector('div.full-screen-backdrop')) {
          mixpanel.track('Group Admin Component: Load', { Result: 'Spinner still shown' });
        }
      }
      setMembers({ total: '', users: '' });
      setIsLoaded(false);
      setFirstRender(true);
      abortController.abort();
    };
  }, [props.adminGroup]);

  function formatLastLogin(string) {
    if (string) {
      let date = string.split('T')[0];
      let time_1 = string.split('T')[1];
      let time_2 = time_1.split('.')[0];
      let time_3 = time_2.split(':');
      let time = time_3[0] + ':' + time_3[1];
      return date + ' ' + time;
    } else return '--';
  }

  function fetchUserinfo() {
    fetchData(signal, '/userinfo', null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const data = response.data;
        // Set groups
        if (data.groups !== undefined && data.groups !== null) {
          let groups = data.groups;
          let orgGroups = [];
          let adminGroups = [];
          setGroups(groups);
          groups.forEach(group => {
            if (group.name.indexOf('--Org') > 0 || group.name.indexOf('|Org') > 0) {
              orgGroups.push(group);
            } else if (group.name.indexOf('--Admin') > 0) {
              adminGroups.push(group);
            }
          });
          setOrgs(orgGroups);
          setAdminGroups(adminGroups);
          if (orgGroups.length > 0) {
            setCurrentOrg(orgGroups[0]);
          } else setCurrentOrg({});
        } else setCurrentOrg({});
      }
    });
  }

  function addMembers(values) {
    let url_partial = window.location.href.split('group-admin/')[1];
    let group_id = url_partial.split('/')[0];
    setAddMembersToOrgError(null);
    setLoadingMembers(true);

    const body = {
      group_id: group_id,
      members: values.members,
    };
    fetchData(signal, '/api/add-group-members', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingMembers(false);
        setAddMembersToOrgError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setLoadingMembers(false);
        if (response.data.user_not_found) {
          setUserNotFound(true);
          setUsersAdded(response.data.users_added);
          setEmailsNotFound(response.data.emails_not_found);
        } else {
          toggleAddMembersToOrgModal();
        }
      }
    });
  }

  function toggleAddMembersToOrgModal() {
    let url_partial = window.location.href.split('group-admin/')[1];
    let org_id = url_partial.split('/')[0];
    setAddMembersToOrgModalOpen(!addMembersToOrgModalOpen);
    fetchMembersData(org_id, currentPage);
    setUserNotFound(false);
    setUsersAdded(false);
  }

  function removeMember(values) {
    let url_partial = window.location.href.split('group-admin/')[1];
    let group_id = url_partial.split('/')[0];
    let admin_group_id = props.adminGroup._id;
    setRemoveMemberError(null);
    setRemoving(true);

    const body = {
      group_id: group_id,
      admin_group_id: admin_group_id,
      user_id: values.user_id,
    };
    fetchData(signal, '/api/remove-member', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setRemoving(false);
        setRemoveMemberError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setRemoving(false);
        if (values.user_id === user.userid) {
          setRemoveSuccess(true);
          fetchUserinfo();
          setTimeout(() => {
            history.push('/projects');
          }, 3000);
        } else {
          setRemoveSuccess(true);
          setTimeout(() => {
            toggleRemoveMemberModal(removeUser);
            setRemoveSuccess(false);
          }, 3000);
        }
      }
    });
  }

  function toggleRemoveMemberModal(member) {
    if (member.user_id === user.userid) {
      setSelf(true);
    } else {
      setSelf(false);
    }
    setRemoveUser(member);
    setRemoveMemberModalOpen(!removeMemberModalOpen);
    let url_partial = window.location.href.split('group-admin/')[1];
    let org_id = url_partial.split('/')[0];
    fetchMembersData(org_id, currentPage);
  }

  function updatePage(page) {
    setCurrentPage(page);
    fetchMembersData(currentOrg._id, page);
  }

  const users_list = Array.from(filteredMembers).map((member, index) => (
    <div key={index} className='user-row-container'>
      <div className='row list item user-details'>
        <div className='col-12 col-sm-6 col-lg-3 email'>
          <input value={member.email} data-testid='member-email-input' readOnly />
        </div>
        <div className='col-12 col-sm-6 col-lg-3 connection'>
          <input value={member.identities[0].connection} data-testid='member-connection-input' readOnly />
        </div>
        {/* Role */}
        <div className='col-12 col-sm-6 col-lg-2 pr-0 role'>
          {member.email !== user.email ? (
            <ChangeRoleForm
              index={index}
              member={member}
              user={user}
              adminEmails={adminEmails}
              adminGroup={props.adminGroup}
            />
          ) : (
            <span className='p-text-light'>{adminEmails.includes(member.email) ? 'Group Admin' : 'User'}</span>
          )}
        </div>
        {/* Actions */}
        <div className='col-12 col-sm-6 col-lg-2 actions'>
          <QButton className='button btn-link' onClick={() => toggleRemoveMemberModal(member)}>
            Remove
          </QButton>
        </div>
        <div className='col-12 col-sm-6 col-lg-2 last-login'>{formatLastLogin(member.last_login)}</div>
      </div>
    </div>
  ));

  if (currentOrg !== null) {
    return (
      <div id='GroupAdmin' className='container-fluid'>
        <div className='row topbar'>
          <div className='col'>
            <h2>{orgName}</h2>
            <p className='group-id text-xs'>
              <span className='group-id-label'>Group ID: </span>
              <span className='no-break'>
                <input value={currentOrg._id} data-testid='org-id' readOnly />{' '}
                <CopyToClipboard onClick={() => copyToClipboard(currentOrg._id)} />
              </span>
            </p>
          </div>
        </div>
        <div className='row content-container pt-0'>
          <div className='col-12 pt-2'>
            {/* Heading */}
            <div className='row total'>
              <div className='d-flex align-items-center col-6 col-lg-10 text-xs'>
                <div className='d-flex flex-wrap align-items-center data-sets-summary'>
                  <Pagination
                    pages={pages}
                    currentPage={currentPage}
                    updatePage={value => updatePage(value)}
                    updating={value => {
                      if (value === true) {
                        setIsLoaded(false);
                      } else {
                        setIsLoaded(true);
                      }
                    }}
                  />
                  <PaginationSummary
                    dataTotal={members.total ? parseInt(members.total) : null}
                    dataStart={dataStart}
                    dataEnd={dataEnd}
                    dataType={'members'}
                  />
                  <SearchForm
                    handleSubmit={event =>
                      handleSearchFormSubmit(event, members.users, 'email', query, list => setFilteredMembers(list))
                    }
                    resetForm={event =>
                      resetSearchForm(members.users, 'email', function (list) {
                        setQuery('');
                        setFilteredMembers(list);
                      })
                    }
                    updateQuery={event => setQuery(event.target.value)}
                    searchField='Emails'
                    query={query}
                  />
                </div>
              </div>
              <div className='col-6 col-lg-2 mb-2 text-right text-sm-left'>
                <span className='right'>
                  <QModalBtn
                    btnClassName='small'
                    buttonText='Add Members'
                    onHide={() => toggleAddMembersToOrgModal()}
                    show={addMembersToOrgModalOpen}
                    id='AddMembersModal'
                    size='lg'>
                    {loadingMembers ? (
                      <LoadingBlock className='transparent-bg' text='Please be patient...' />
                    ) : (
                      <AddMembersToOrgModal
                        addMembersToOrgError={addMembersToOrgError}
                        addMembers={values => addMembers(values)}
                        usersAdded={usersAdded}
                        userNotFound={userNotFound}
                        emailsNotFound={emailsNotFound}
                        toggleAddMembersToOrgModal={() => toggleAddMembersToOrgModal()}
                        orgName={orgName}
                      />
                    )}
                  </QModalBtn>
                </span>
              </div>
            </div>
            <div className='row list header'>
              <div className='col-12 col-sm-6 col-lg-3 email'>Email</div>
              <div className='col-12 col-sm-6 col-lg-3 connection'>Connection</div>
              <div className='col-12 col-sm-6 col-lg-2 role'>Role</div>
              <div className='col-12 col-sm-6 col-lg-2 actions'>Actions</div>
              <div className='col-12 col-sm-6 col-lg-2 last-login'>Last Login</div>
            </div>
            {/* Loading */}
            {!isLoaded ? (
              <LoadingBlock />
            ) : (
              <React.Fragment>
                {/* Error */}
                {error ? (
                  <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
                ) : (
                  <div className='users-list'>
                    {/* Users List */}
                    {users_list}
                    {/* Remove Member Modal */}
                    <Modal
                      show={removeMemberModalOpen}
                      onHide={() => toggleRemoveMemberModal(removeUser)}
                      backdrop='static'
                      centered
                      size='lg'
                      id='RemoveMemberModal'
                      dialogClassName='q-modal-component'>
                      <Modal.Body>
                        <div className='q-modal-body'>
                          <i className='icon-close' onClick={() => toggleRemoveMemberModal(removeUser)} />
                          {removing ? (
                            <LoadingBlock className='transparent-bg' text='Please be patient...' />
                          ) : (
                            <RemoveMemberModal
                              member={removeUser}
                              self={self}
                              orgName={orgName}
                              removeMemberError={removeMemberError}
                              toggleRemoveMemberModal={() => toggleRemoveMemberModal(removeUser)}
                              removeMember={values => removeMember(values)}
                              removeSuccess={removeSuccess}
                            />
                          )}
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  } else return <LoadingFilled />;
};

GroupAdmin.propTypes = {
  adminGroup: PropTypes.object,
};

export default GroupAdmin;
