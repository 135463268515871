import React from 'react';
import PropTypes from 'prop-types';

// Components
import { showParams, solverTooltip } from '../ProblemsCommonFunctions';

const CSPProblem = props => {
  const solver_enums = props.solverConstants && props.solverConstants.enums ? props.solverConstants.enums.CSP : null;

  const blacklist = [];

  function parentSolver(solver_parameters) {
    var nested_solver;

    function nestedSolver(solver) {
      if (solver.solver_params !== undefined && solver.solver_params !== null) {
        // recursively iterate through nested "solver" objects in solver_parameters
        Object.keys(solver.solver_params).forEach(key => {
          if (key === 'solver') {
            nested_solver = nestedSolver(solver.solver_params[key]);
          }
        });

        const solver_params = showParams(solver, blacklist, solver_enums);
        return (
          <React.Fragment>
            <span>
              <i className='icon-next' />
              {solver.solver_params !== {} ? (
                <React.Fragment>{solverTooltip(solver.next_solver, solver_params)}</React.Fragment>
              ) : (
                <span className='solver'>{solver.next_solver}</span>
              )}
            </span>
            {nested_solver}
          </React.Fragment>
        );
      }
    }

    const solver_params = showParams(solver_parameters, blacklist, solver_enums);

    return (
      <div className='solvers'>
        {solver_params !== '' ? (
          <React.Fragment>{solverTooltip(solver_parameters.next_solver, solver_params)}</React.Fragment>
        ) : (
          <span className='solver'>{solver_parameters.next_solver}</span>
        )}
        {solver_parameters.solver_params !== undefined &&
          solver_parameters.solver_params !== null &&
          solver_parameters.solver_params.solver !== undefined &&
          solver_parameters.solver_params.solver !== null && (
            <React.Fragment>{nestedSolver(solver_parameters.solver_params.solver)}</React.Fragment>
          )}
      </div>
    );
  }

  return (
    <div id='CSPProblem' className='row details problem-type-a'>
      <div className='col-12'>
        {/* SOLVERS */}
        {props.solverParameters !== undefined &&
          props.solverParameters !== null &&
          props.solverParameters.next_solver !== undefined && (
            <div className='row solvers'>
              <div className='col-12 d-flex'>
                <span className='label-sm mr-2'>Solvers: </span>
                <div className='d-flex flex-column align-items-start solvers-data'>
                  {/* Organic Crystal Optimizer */}
                  <div className='d-flex align-items-center solvers-block'>{parentSolver(props.solverParameters)}</div>
                  {/* Organic Crystal Generator */}
                  {props.solverParameters.solver_params &&
                    props.solverParameters.solver_params.organic_crystal_generator && (
                      <div className='d-flex align-items-center solvers-block'>
                        {parentSolver(props.solverParameters.solver_params.organic_crystal_generator)}
                      </div>
                    )}
                  {/* Conformer Generator */}
                  {props.solverParameters.solver_params && props.solverParameters.solver_params.conformer_generator && (
                    <div className='d-flex align-items-center solvers-block'>
                      {parentSolver(props.solverParameters.solver_params.conformer_generator)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

CSPProblem.propTypes = {
  solverParameters: PropTypes.object.isRequired,
  solverConstants: PropTypes.object.isRequired,
};

export default CSPProblem;
