import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../../hoc/Auth';
import { LoadingInline } from '../../loading/Loading';

const ProblemName = props => {
  // Init
  const { updateProblem } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Edit Name
  const [editProblemHandle, setEditProblemHandle] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [newProblemName, setNewProblemName] = useState('');
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [updateNameError, setUpdateNameError] = useState(null);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [props.problemHandle]);

  function editProblemName(problem_handle, problem_name) {
    setEditProblemHandle(problem_handle);
    if (problem_name === null) {
      setNewProblemName(problem_handle);
    } else {
      setNewProblemName(problem_name);
    }
    setIsEditingName(true);
  }

  function handleNameFormSubmit(e) {
    e.preventDefault();

    if (newProblemName !== '') {
      setIsUpdatingName(true);
      setUpdateNameError(null);
      // prettier-ignore
      updateProblem(signal, { problem_handle: editProblemHandle, problem_name: newProblemName, project_id: props.projectId }, response => {
        if (response === 'changes_successful') {
          props.fetchProblems();
          setIsEditingName(false);
          setEditProblemHandle('');
          setNewProblemName('');
        } else if (response.status === 'error') {
          setUpdateNameError(response.error);
        }
        setIsUpdatingName(false);
      });
    } else {
      setUpdateNameError({ message: 'Cannot be blank' });
    }
  }

  function cancelUpdateName() {
    setIsEditingName(false);
    setEditProblemHandle('');
    setNewProblemName('');
    setUpdateNameError(null);
  }

  function showProblemName(value) {
    return (
      <div className='d-flex align-items-center text-xs bold'>
        {value}
        <i className='d-flex align-items-center icon-button-edit ml-1' />
      </div>
    );
  }

  return (
    <div id='ProblemName' className='d-flex flex-wrap align-items-center w-100 pr-4'>
      {isEditingName && editProblemHandle === props.problemHandle ? (
        <React.Fragment>
          <form
            id='ProblemNameForm'
            className='d-flex w-100 align-items-center'
            onSubmit={event => handleNameFormSubmit(event)}>
            <div className='d-flex w-100 flex-grow flex-column align-items-start mr-1'>
              <input
                className='text-xs mr-1 w-100 flex-grow'
                value={newProblemName}
                data-testid='new-name-input'
                onChange={event => setNewProblemName(event.target.value)}
                autoFocus
              />
              {/* Error Message */}
              {updateNameError && <span className='text-xs danger'>{updateNameError.message}</span>}
            </div>
            {isUpdatingName ? (
              <LoadingInline size='sm' />
            ) : (
              <React.Fragment>
                <button type='submit' data-testid='submit-name-button' className='d-flex mr-1'>
                  <i className='icon-circle-check' />
                </button>
                <i data-testid='cancel-name-button' className='icon-circle-ex' onClick={() => cancelUpdateName()} />
              </React.Fragment>
            )}
          </form>
        </React.Fragment>
      ) : (
        <div
          className='d-flex align-self-center col p-0 problem-name-wrapper'
          data-testid='edit-name-trigger'
          onClick={() => editProblemName(props.problemHandle, props.problemName)}>
          {props.problemName ? (
            <React.Fragment>{showProblemName(props.problemName)}</React.Fragment>
          ) : (
            <React.Fragment>{showProblemName(props.problemHandle)}</React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

ProblemName.propTypes = {
  problemHandle: PropTypes.string.isRequired,
  problemName: PropTypes.string,
  projectId: PropTypes.string,
  fetchProblems: PropTypes.func.isRequired,
};

export default ProblemName;
