import React from 'react';
import PropTypes from 'prop-types';

// Components
import { showParams, solverTooltip } from '../ProblemsCommonFunctions';

export function dftMethods(solver_parameters, solver_constants) {
  const solver_enums = solver_constants && solver_constants.enums ? solver_constants.enums.DFT : null;

  const blacklist = [];

  // DFTGrids
  function showGrids(grids) {
    if (grids.solver_params !== undefined && grids.solver_params !== null) {
      const solver_params = showParams(grids, blacklist, solver_enums);
      return <React.Fragment>{solverTooltip('Grid', solver_params, 'dft grids')}</React.Fragment>;
    }
  }

  const solver_params = showParams(solver_parameters, blacklist, solver_enums);

  return (
    <div className='solvers'>
      {solverTooltip(solver_parameters.next_solver, solver_params, 'dft')}
      {solver_parameters.solver_params !== undefined &&
        solver_parameters.solver_params !== null &&
        solver_parameters.solver_params.grids !== undefined &&
        solver_parameters.solver_params.grids !== null && (
          <React.Fragment>{showGrids(solver_parameters.solver_params.grids)}</React.Fragment>
        )}
    </div>
  );
}

const DFTProblem = props => {
  return (
    <div id='DFTProblem' className='row details problem-type-a'>
      <div className='col-12'>
        {/* SOLVERS */}
        {props.solverParameters !== undefined &&
          props.solverParameters !== null &&
          props.solverParameters.next_solver !== undefined && (
            <div className='row solvers'>
              <div className='col-12 solvers-data'>
                <span className='label-sm'>Method: </span>
                <div className='solvers-block'>{dftMethods(props.solverParameters, props.solverConstants)}</div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

DFTProblem.propTypes = {
  solverParameters: PropTypes.object.isRequired,
  solverConstants: PropTypes.object.isRequired,
};

export default DFTProblem;
