import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

// Import hooks & hoc
import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
import { getOrgName, getPaginationSets } from '../../../hoc/CommonFunctions';
import { handleSearchFormSubmit, resetSearchForm } from '../../../hoc/FilterFuncs';
import { scrollToTop } from '../../../hoc/ScrollFunctions';

// Import components
import { QErrorMessage } from '../../../components/notifications/Notifications';
import QButton from '../../../components/button/Button';
import { LoadingBlock, LoadingInline } from '../../../components/loading/Loading';
import QModalBtn from '../../../components/modal/Modal';
import SearchForm from '../../../components/form/SearchForm';
import AddMembersToOrgModal from '../../../components/modal/AddMembersToOrg';
import RemoveMemberModal from '../../../components/modal/RemoveMembersFromOrg';
import ChangeRoleForm from '../../../components/form/ChangeRole';
import Pagination, { PaginationSummary } from '../../../components/pagination/Pagination';

const ViewGroupMembers = props => {
  // Init
  const { fetchData } = useAuth();
  const { user } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [orgName, setOrgName] = useState('-');
  const [adminGroupID, setAdminGroupID] = useState('');
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Group Members
  const [groupId, setGroupId] = useState(5);
  const [setLength, setSetLength] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(24);
  const [pages, setPages] = useState(1);
  const [members, setMembers] = useState({ total: '', users: '' });
  const [adminEmails, setAdminEmails] = useState([]);
  const [groupAdminsLoaded, setGroupAdminsLoaded] = useState(false);
  const [error, setError] = useState(null);
  // Add Members to Org
  const [addMembersToOrgModalOpen, setAddMembersToOrgModalOpen] = useState(false);
  const [addMembersToOrgError, setAddMembersToOrgError] = useState(null);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [usersAdded, setUsersAdded] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [emailsNotFound, setEmailsNotFound] = useState([]);
  // Remove Members from Org
  const [removeUser, setRemoveUser] = useState({ email: '', user_id: '' });
  const [removeMemberModalOpen, setRemoveMemberModalOpen] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [removeMemberError, setRemoveMemberError] = useState(false);
  const [self, setSelf] = useState(false);
  // Filter Members
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [query, setQuery] = useState('');

  function fetchMembersData(group_id, page) {
    let url = '/api/get-group-members?group_id=' + group_id + '&page=' + page;
    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setMembers(response.data);
        setFilteredMembers(response.data.users);
        setTimeout(() => {
          setIsLoaded(true);
          scrollToTop('ViewGroupMembers');
        }, 500);
        // Get pagination sets
        getPaginationSets(response.data.total, setLength, num => {
          setPages(num);
          setDataStart(page * setLength - setLength);
          setDataEnd(page * setLength - 1);
        });
      }
    });
  }

  function fetchGroupAdminsData(admin_group_id) {
    let url = '/api/get-group-members?group_id=' + admin_group_id + '&page=' + currentPage;
    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setGroupAdminsLoaded(true);
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        let users = response.data.users;
        let admin_emails = [];
        users.forEach(user => {
          admin_emails.push(user.email);
        });
        setAdminEmails(admin_emails);
        setGroupAdminsLoaded(true);
      }
    });
  }

  useEffect(() => {
    let url_partial = window.location.href.split('group/')[1];
    let group_id = url_partial.split('/')[0];
    setGroupId(group_id);

    let resolveGroup = new Promise(resolve => {
      if (props.group.name !== undefined) {
        let group_name = getOrgName(props.group);
        setOrgName(group_name);
        resolve();
      }
    });

    let resolveAdminGroup = new Promise(resolve => {
      if (props.adminGroup._id !== undefined) {
        setAdminGroupID(props.adminGroup._id);
        resolve(props.adminGroup._id);
      }
    });

    resolveGroup.then(function () {
      resolveAdminGroup.then(function (admin_group_id) {
        fetchMembersData(group_id, currentPage), fetchGroupAdminsData(admin_group_id);
      });
    });

    return () => {
      setIsLoaded(false);
      setGroupAdminsLoaded(false);
      abortController.abort();
    };
  }, [props.group, props.adminGroup]);

  function addMembers(values) {
    let url_partial = window.location.href.split('admin/group/')[1];
    let group_id = url_partial.split('/')[0];
    setAddMembersToOrgError(null);
    setLoadingMembers(true);

    const body = {
      group_id: group_id,
      members: values.members,
    };
    fetchData(signal, '/api/add-group-members', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingMembers(false);
        setAddMembersToOrgError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setLoadingMembers(false);
        setUsersAdded(response.data.users_added);
        if (response.data.user_not_found) {
          setUserNotFound(true);
          setEmailsNotFound(response.data.emails_not_found);
        } else {
          setIsLoaded(false);
          fetchGroupAdminsData(adminGroupID);
          fetchMembersData(groupId, currentPage);
          toggleAddMembersToOrgModal();
        }
      }
    });
  }

  function toggleAddMembersToOrgModal() {
    setAddMembersToOrgModalOpen(!addMembersToOrgModalOpen);
    setUserNotFound(false);
    setUsersAdded(false);
  }

  function removeMember(values) {
    let url_partial = window.location.href.split('admin/group/')[1];
    let group_id = url_partial.split('/')[0];
    let admin_group_id = props.adminGroup._id;
    setRemoveMemberError(null);
    setRemoving(true);

    const body = {
      group_id: group_id,
      admin_group_id: admin_group_id,
      user_id: values.user_id,
    };
    fetchData(signal, '/api/remove-member', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setRemoving(false);
        setRemoveMemberError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setRemoving(false);
        setRemoveSuccess(true);
        setTimeout(() => {
          setIsLoaded(false);
          fetchMembersData(groupId, currentPage);
          toggleRemoveMemberModal(removeUser);
          setRemoveSuccess(false);
        }, 3000);
      }
    });
  }

  function toggleRemoveMemberModal(member) {
    if (member.user_id === user.userid) {
      setSelf(true);
    } else {
      setSelf(false);
    }
    setRemoveUser(member);
    setRemoveMemberModalOpen(!removeMemberModalOpen);
  }

  function formatLastLogin(string) {
    if (string) {
      let date = string.split('T')[0];
      let time_1 = string.split('T')[1];
      let time_2 = time_1.split('.')[0];
      let time_3 = time_2.split(':');
      let time = time_3[0] + ':' + time_3[1];
      return date + ' ' + time;
    } else return '--';
  }

  function updatePage(page) {
    setCurrentPage(page);
    fetchMembersData(groupId, page);
  }

  const users_list = Array.from(filteredMembers).map((member, index) => (
    <div key={index} className='user-row-container'>
      <div className='row list item user-details'>
        <div className='col-12 col-sm-6 col-lg-3 email'>
          <Link to={`/admin/user/${member.user_id}/credits`}>
            <span className='bold'>{member.email}</span>
          </Link>
        </div>
        <div className='col-12 col-sm-6 col-lg-3 connection'>
          <input value={member.identities[0].connection} data-testid='member-connection-input' readOnly />
        </div>
        {/* Role */}
        <div className='col-12 col-sm-6 col-lg-2 pr-0 role'>
          {groupAdminsLoaded ? (
            <ChangeRoleForm
              index={index}
              member={member}
              user={user}
              adminEmails={adminEmails}
              adminGroup={props.adminGroup}
            />
          ) : (
            <LoadingInline size='sm' />
          )}
        </div>
        {/* Actions */}
        <div className='col-12 col-sm-6 col-lg-2 actions'>
          <QButton className='button btn-link' onClick={() => toggleRemoveMemberModal(member)}>
            Remove
          </QButton>
        </div>
        <div className='col-12 col-sm-6 col-lg-2 last-login'>{formatLastLogin(member.last_login)}</div>
      </div>
    </div>
  ));

  return (
    <div id='ViewGroupMembers' className='view-user container-fluid'>
      <div className='row'>
        <div className='col-12 content-container'>
          {/* Users List */}
          <div className='row pre-header'>
            <div className='d-flex align-items-center col-12 col-sm-6 col-lg-10 total'>
              <div className='d-flex flex-wrap align-items-center data-sets-summary'>
                <Pagination
                  pages={pages}
                  currentPage={currentPage}
                  updatePage={value => updatePage(value)}
                  updating={value => {
                    if (value === true) {
                      setIsLoaded(false);
                    } else {
                      setIsLoaded(true);
                    }
                  }}
                />
                <PaginationSummary
                  dataTotal={members.total ? parseInt(members.total) : null}
                  dataStart={dataStart}
                  dataEnd={dataEnd}
                  dataType={'members'}
                />
                <SearchForm
                  handleSubmit={event =>
                    handleSearchFormSubmit(event, members.users, 'email', query, list => setFilteredMembers(list))
                  }
                  resetForm={event =>
                    resetSearchForm(members.users, 'email', function (list) {
                      setQuery('');
                      setFilteredMembers(list);
                    })
                  }
                  updateQuery={event => setQuery(event.target.value)}
                  searchField='Emails'
                  query={query}
                />
              </div>
            </div>
            <div className='d-flex align-items-center col-12 col-sm-6 col-lg-2 add-members'>
              <QModalBtn
                btnClassName='small'
                buttonText='Add Members'
                onHide={() => toggleAddMembersToOrgModal()}
                show={addMembersToOrgModalOpen}
                id='AddMembersModal'
                size='lg'>
                {loadingMembers ? (
                  <LoadingBlock className='transparent-bg' text='Please be patient...' />
                ) : (
                  <AddMembersToOrgModal
                    addMembersToOrgError={addMembersToOrgError}
                    addMembers={values => addMembers(values)}
                    usersAdded={usersAdded}
                    userNotFound={userNotFound}
                    emailsNotFound={emailsNotFound}
                    toggleAddMembersToOrgModal={() => toggleAddMembersToOrgModal()}
                    orgName={orgName}
                  />
                )}
              </QModalBtn>
            </div>
          </div>
          <div className='row list header'>
            <div className='col-12 col-sm-6 col-lg-3 email'>Email</div>
            <div className='col-12 col-sm-6 col-lg-3 connection'>Connection</div>
            <div className='col-12 col-sm-6 col-lg-2 role'>Role</div>
            <div className='col-12 col-sm-6 col-lg-2 actions'>Actions</div>
            <div className='col-12 col-sm-6 col-lg-2 last-login'>Last Login</div>
          </div>
          {!isLoaded ? (
            <LoadingBlock />
          ) : (
            <React.Fragment>
              {error ? (
                <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
              ) : (
                <React.Fragment>
                  {users_list}
                  {members.total === 0 && (
                    <div className='row empty info'>
                      <div className='col-12 bold'>No members in this group.</div>
                    </div>
                  )}
                  {/* Remove Member Modal */}
                  <Modal
                    show={removeMemberModalOpen}
                    onHide={() => toggleRemoveMemberModal(removeUser)}
                    backdrop='static'
                    centered
                    size='lg'
                    id='RemoveMemberModal'
                    className='q-modal-component'>
                    <Modal.Body>
                      <div className='q-modal-body'>
                        <i className='icon-close' onClick={() => toggleRemoveMemberModal(removeUser)} />
                        {removing ? (
                          <LoadingBlock className='transparent-bg' text='Please be patient...' />
                        ) : (
                          <RemoveMemberModal
                            member={removeUser}
                            self={self}
                            orgName={orgName}
                            removeMemberError={removeMemberError}
                            toggleRemoveMemberModal={() => toggleRemoveMemberModal(removeUser)}
                            removeMember={values => removeMember(values)}
                            removeSuccess={removeSuccess}
                          />
                        )}
                      </div>
                    </Modal.Body>
                  </Modal>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

ViewGroupMembers.propTypes = {
  group: PropTypes.object.isRequired,
  adminGroup: PropTypes.object.isRequired,
};

export default ViewGroupMembers;
