import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div className='d-flex flex-column align-items-center justify-content-center full-screen-backdrop blue'>
          <h2 className='reversed'>{this.props.headline}</h2>
          <p className='reversed'>{this.props.message}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
