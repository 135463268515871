import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSubmitData } from '../../../hoc/SubmitData';
import { constructSelectMenu } from '../../../hoc/FormFunctions';
import { QSelect } from '../../../components/form/FormElements';
import { LoadingBlock } from '../../../components/loading/Loading';
import { SubmitGroupLabel, SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';
import { formatSolverName } from '../functions/SubmitFunctions';
import { pythonifySolver } from '../../../hoc/submitDataFunctions';

const SubmitANI = props => {
  // Init
  const { solverConfigs, defaultParams } = useSubmitData();
  const [isLoaded, setIsLoaded] = useState(false);
  // Edit Params
  const [pipeline, setPipeline] = useState([]);

  useEffect(() => {
    setPipeline(props.solverPipeline);

    if (solverConfigs !== null && defaultParams !== null && props.solverPipeline[0] && props.solverPipeline[0].params) {
      setIsLoaded(true);
    }
  }, [props.solverPipeline, solverConfigs, defaultParams]);

  function handleSolverChange(i, value) {
    // Modify pipeline
    let pipeline_mod = structuredClone(pipeline);
    pipeline_mod[i] = defaultParams[value];
    // Convert js values to python
    pipeline_mod[i] = pythonifySolver(solverConfigs, pipeline_mod[i]);
    // Update parent solver pipeline
    props.updatePipeline(pipeline_mod);
  }

  function handleSolverParamChange(i, param, value) {
    // Modify pipeline
    let pipeline_mod = structuredClone(pipeline);
    if (pipeline_mod[i].params) {
      pipeline_mod[i].params[param] = value;
    }
    // Update pipeline
    props.updatePipeline(pipeline_mod);
  }

  function showInputs(solver, solver_index) {
    const params = Object.entries(solver.params);

    function formatInputLabel(solver_name) {
      // Replace underscore with space
      let formatted_solver_name = solver_name.replace('_', ' ');
      // Capitalize each word
      const split_words = formatted_solver_name.split(' ');
      split_words.forEach((word, i) => {
        split_words[i] = word[0].toUpperCase() + word.substr(1);
      });
      // Join words
      formatted_solver_name = split_words.join(' ');
      return formatted_solver_name;
    }

    return Array.from(params).map(([param_name, param_val], index) => (
      <QSelect
        key={index}
        id={`${param_name}-input`}
        className='dark mr-3 SubmitInputSelect'
        data-testid={`${param_name}-input`}
        inputLabel={formatInputLabel(param_name)}
        value={param_val}
        onChange={e => handleSolverParamChange(solver_index, param_name, e.target.value)}
        menu={constructSelectMenu(solverConfigs[solver.solver_name].configurable[param_name].options)}
      />
    ));
  }

  const show_solvers_params = Array.from(pipeline).map((solver, index) => {
    return (
      <SubmitInputGroup key={index}>
        {/* Select Solver */}
        <QSelect
          id='solver-input'
          className='dark pr-3 w-100'
          data-testid='solver-input'
          inputLabel='Choose ANI Solver'
          value={solver.solver_name}
          onChange={e => handleSolverChange(index, e.target.value)}
          menu={constructSelectMenu(props.ANISolvers)}
        />
        {/* Inputs */}
        <SubmitGroupLabel className='w-100' text={`${formatSolverName(solver.solver_name)} Parameters`} />
        <div className='d-flex w-100'>{showInputs(solver, index)}</div>
      </SubmitInputGroup>
    );
  });

  return (
    <div id='SubmitANI'>
      {/* Outer Solver */}
      {isLoaded && pipeline[0] ? (
        <React.Fragment>
          {show_solvers_params}
          <div className='mb-3'>
            {/* Controls */}
            <div className='w-100 mb-3'>
              <ResetDropdown
                resetChanges={() => {
                  formProps.resetForm();
                  props.resetPipeline();
                }}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <LoadingBlock text='Loading paramaters...' className='mb-3' />
      )}
    </div>
  );
};

SubmitANI.propTypes = {
  currentOrg: PropTypes.object,
  ANISolvers: PropTypes.array.isRequired,
  solverPipeline: PropTypes.array.isRequired,
  updatePipeline: PropTypes.func.isRequired,
  resetPipeline: PropTypes.func.isRequired,
};

export default SubmitANI;
