export function paginationSetup(callback) {
  const url = window.location.hash;
  var page_num = url.split('request-log#')[1];
  var admin_panel_proj = false;

  if (url.includes('/view-projects')) {
    page_num = url.split('view-projects#')[1];
    admin_panel_proj = true;
  }

  const num_only = /^\d+$/;

  let stored_session_page = sessionStorage.getItem('qemist.probPage');

  function checkSessionStorage() {
    // If page num exists in session storage
    if (stored_session_page !== null) {
      callback('#' + stored_session_page);
    }
    // Else if page num does not exist in session storage
    else if (sessionStorage.getItem('qemist.probPage') === null) {
      sessionStorage.setItem('qemist.probPage', 1);
      callback('#1');
    }
  }

  // If page num exists in url
  if (page_num !== undefined) {
    // If page num in url is not a number
    if (num_only.test(page_num) === false) {
      callback('#1');
    }
    // Else if viewing main Requests log
    else if (!admin_panel_proj && url.includes('#/request-log')) {
      callback('#' + page_num);
      sessionStorage.setItem('qemist.probPage', page_num);
    }
    // Else if viewing other request log (project or admin panel)
    else {
      callback('#' + page_num);
    }
  }

  // Of page num does not exist in url
  if (page_num === undefined) {
    // If viewing main Requests log
    if (!admin_panel_proj && url.includes('#/request-log')) {
      checkSessionStorage();
    }
    // Else if viewing other request log (project or admin panel)
    else {
      callback('#1');
    }
  }
}

export function filterProblemsVis(element, visibility, callback) {
  let new_state = element.checked;
  let checkbox = element.id.replace('-problems', '');
  let mod_visibility = {
    all: visibility.all,
    visible: visibility.visible,
    starred: visibility.starred,
    hidden: visibility.hidden,
  };

  // If checkbox is All, turn off all other checkboxes
  if (checkbox === 'all') {
    mod_visibility = {
      all: true,
      visible: false,
      starred: false,
      hidden: false,
    };
  } else {
    // Otherwise set All as false
    mod_visibility.all = false;
    // Count amount of checked boxes
    let checked_boxes = [mod_visibility.visible, mod_visibility.starred, mod_visibility.hidden];
    let true_count = 0;
    checked_boxes.forEach(item => {
      if (item === true) {
        true_count = true_count + 1;
      }
    });
    // Only update visibility if not trying to uncheck the only checked box
    if (mod_visibility[checkbox] == true && true_count == 1) {
    } else {
      mod_visibility[checkbox] = new_state;
    }
  }
  callback(mod_visibility);
}
