import React from 'react';
import PropTypes from 'prop-types';

// Components
import { constructBlacklist, showParams, solverTooltip } from '../ProblemsCommonFunctions';

const SelfConsistentProblem = props => {
  const solver_enums =
    props.solverConstants && props.solverConstants.enums ? props.solverConstants.enums[props.solverName] : null;
  const solver_slots =
    props.solverConstants && props.solverConstants.slots ? props.solverConstants.slots[props.solverName] : [];

  const params_to_hide = [];
  const blacklist = constructBlacklist(props.solverParameters, params_to_hide, solver_slots);
  const solver_params = showParams(props.solverParameters, blacklist, solver_enums);

  return (
    <div id='SelfConsistentProblem' className='row details problem-type-a'>
      <div className='col-12'>
        {/* SOLVERS */}
        {props.solverParameters !== undefined &&
          props.solverParameters !== null &&
          props.solverParameters.next_solver !== undefined && (
            <div className='row solvers'>
              <div className='col-12 solvers-data'>
                <span className='label-sm'>Solvers: </span>
                <div className='solvers-block'>
                  <div className='solvers'>{solverTooltip(props.solverParameters.next_solver, solver_params)}</div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

SelfConsistentProblem.propTypes = {
  solverParameters: PropTypes.object.isRequired,
  solverConstants: PropTypes.object.isRequired,
  solverName: PropTypes.string.isRequired,
};

export default SelfConsistentProblem;
