import React from 'react';
import PropTypes from 'prop-types';

// Components
import { convertToSciNote } from '../../../hoc/CommonFunctions';
import { constructBlacklist, listSolverParams, paramsList, solverTooltip } from '../ProblemsCommonFunctions';
import { LoadingInline } from '../../loading/Loading';
import { QErrorMessage } from '../../notifications/Notifications';

const MIProblem = props => {
  const solver_enums =
    props.solverConstants && props.solverConstants.enums ? props.solverConstants.enums[props.problemType] : null;
  const solver_slots =
    props.solverConstants && props.solverConstants.slots ? props.solverConstants.slots.IncrementalDecomposition : [];

  const params_to_hide = [
    'solver',
    'iterative_reduction',
    'occupancy_by_percentage',
    'occupancy',
    'percent',
    'count',
    'localization',
    'gvb_params',
    'readout_level',
    'ci_thresh',
    'pt_thresh',
    'resume_handle',
    'index',
    'target_electron_state', // hbci
    'semi',
    'triplet_sno',
  ];

  const blacklist = constructBlacklist(props.solverParameters, params_to_hide, solver_slots);

  // Legacy
  function translateIterativeReduction(obj) {
    let iterative_method = '';
    let iterations = '';
    let index = '';
    let threshold = '';

    function translateIterativeMethod(obj) {
      let ref_vals = ['None', 'Occupancy by Percentage', 'Occupancy', 'Percent', 'Count'];
      return <div>Iterative Method: {ref_vals[obj.IterativeMethod]}</div>;
    }

    function translateIterations(obj) {
      return <div>Iterations: {obj.iterations}</div>;
    }

    function translateIndex(obj) {
      return <div>Index: {obj.index}</div>;
    }

    function translateThreshold(obj) {
      return <div>Threshold: {obj.threshold.toFixed(1)}</div>;
    }

    if (obj.IterativeMethod !== undefined && obj.IterativeMethod !== null) {
      iterative_method = translateIterativeMethod(obj);
    }

    if (obj.iterations !== undefined && obj.iterations !== null) {
      iterations = translateIterations(obj);
    }

    if (obj.index !== undefined && obj.index !== null) {
      index = translateIndex(obj);
    }

    if (obj.threshold !== undefined && obj.threshold !== null) {
      threshold = translateThreshold(obj);
    }

    if (iterative_method !== '' || iterations !== '' || index !== '' || threshold !== '') {
      return (
        <React.Fragment>
          {iterative_method}
          {iterations}
          {index}
          {threshold}
        </React.Fragment>
      );
    } else return '';
  }

  // Legacy
  function translateOccupancy(solver_params) {
    let obj = solver_params;
    if (obj.occupancy_by_percentage !== null && obj.occupancy_by_percentage !== undefined) {
      return (
        <React.Fragment>
          <span>Occupancy by Percentage: {obj.occupancy_by_percentage}%</span>
          <br />
        </React.Fragment>
      );
    } else if (obj.occupancy !== null && obj.occupancy !== undefined) {
      return (
        <React.Fragment>
          <span>Occupancy: {convertToSciNote(obj.occupancy)}</span>
          <br />
        </React.Fragment>
      );
    } else if (obj.percent !== null && obj.percent !== undefined) {
      return (
        <React.Fragment>
          <span>Percent: {obj.percent}%</span>
          <br />
        </React.Fragment>
      );
    } else if (obj.count !== null && obj.count !== undefined) {
      return (
        <React.Fragment>
          <span>Count: {obj.count}</span>
          <br />
        </React.Fragment>
      );
    } else return '';
  }

  // Legacy
  function translateLocalization(solver_params) {
    let ref_vals = ['NONE', 'BOYS', 'PM'];
    if (solver_params.gvb_params === undefined) {
      return <div>Localization: {ref_vals[solver_params.localization]}</div>;
    } else return '';
  }

  // Legacy
  function translateGVBParams(gvb_params) {
    if (gvb_params.n_pair !== undefined && gvb_params.n_pair !== null) {
      return <div>Number of Pairs: {gvb_params.n_pair}</div>;
    } else return '';
  }

  // Legacy
  function translateThresholds(solver_params) {
    let obj = solver_params;
    let ci_thresh = '';
    let pt_thresh = '';

    function translateCIThresh(obj) {
      return <div>CI Threshold: {convertToSciNote(obj.ci_thresh)} Ha</div>;
    }

    function translatePTThresh(obj) {
      return <div>PT Threshold: {convertToSciNote(obj.pt_thresh)} Ha</div>;
    }

    if (obj.ci_thresh !== undefined && obj.ci_thresh !== null) {
      ci_thresh = translateCIThresh(obj);
    }

    if (obj.pt_thresh !== undefined && obj.pt_thresh !== null) {
      pt_thresh = translatePTThresh(obj);
    }

    if (ci_thresh !== '' || pt_thresh !== '') {
      return (
        <React.Fragment>
          {ci_thresh}
          {pt_thresh}
        </React.Fragment>
      );
    } else return '';
  }

  // Legacy
  function translateTripletSNO(val) {
    let ref_vals = ['LOWEST', 'SUM'];
    return <div>Triplet SNO: {ref_vals[val]}</div>;
  }

  function showParams(solver, blacklist) {
    let params_list = '';
    let iterative_reduction = '';
    let occupancy = '';
    let localization = '';
    let gvb_params = '';
    let thresholds = '';
    let triplet_sno = '';
    let semi = '';

    if (solver !== undefined && solver !== null) {
      const solver_params = solver.solver_params;

      if (solver_params !== undefined && solver_params !== null) {
        // 1. Iterate through individual solver parameters
        params_list = listSolverParams(solver_params, blacklist, solver_enums);

        // 2. Show iterative method
        // (occupancy by percentage / occupancy / percent / count / none),
        // b. iterations, c. index, ** THEN ** d. threshold
        const iterative_reduction_obj = solver_params.iterative_reduction;
        if (iterative_reduction_obj !== undefined && iterative_reduction_obj !== null) {
          iterative_reduction = translateIterativeReduction(iterative_reduction_obj);
        }

        // 3. Show Occupancy by
        // a. occupancy by percentage
        // ** OR ** b. occupancy ** OR ** c. percent
        // ** OR ** d. count
        occupancy = translateOccupancy(solver_params);

        // 4. Show GVB Params
        const gvb_params_obj = solver_params.gvb_params;
        if (gvb_params_obj !== undefined && gvb_params_obj !== null) {
          gvb_params = translateGVBParams(gvb_params_obj);
        }

        // 5. Format thresholds and add Ha unit
        thresholds = translateThresholds(solver_params);

        // 6. Format triplet sno
        const triplet_sno_val = solver_params.triplet_sno;
        if (triplet_sno_val !== undefined && triplet_sno_val !== null) {
          triplet_sno = translateTripletSNO(triplet_sno_val);
        }
      }

      // Show Localization (boys / pm / none)
      if (
        solver.next_solver !== 'FNO' &&
        solver.next_solver !== 'SNOGVBPP' &&
        solver_params.localization !== undefined &&
        solver_params.localization !== null
      ) {
        localization = translateLocalization(solver_params);
      }

      // Show Semi
      if (solver.next_solver !== 'FNO' && solver_params.semi !== undefined && solver_params.semi !== null) {
        semi = paramsList([['semi', solver_params.semi]]);
      }

      // prettier-ignore
      if (
        params_list !== '' ||
        iterative_reduction !== '' ||
        occupancy !== '' ||
        localization !== '' ||
        gvb_params !== '' ||
        thresholds !== '' ||
        triplet_sno !== '' ||
        semi !== ''
      ) {
        return (
          <div className='solver-details'>
            {params_list}
            {iterative_reduction}
            {occupancy}
            {localization}
            {gvb_params}
            {thresholds}
            {triplet_sno}
            {semi}
          </div>
        );
      } else return '';
    } else return '';
  }

  function parentSolver(solver_parameters) {
    function nestedSolver(solver) {
      if (solver.solver_params !== undefined && solver.solver_params !== null) {
        let nested_solver;

        // recursively iterate through nested "solver" objects in solver_parameters
        Object.keys(solver.solver_params).forEach(key => {
          if (key === 'solver') {
            nested_solver = nestedSolver(solver.solver_params[key]);
          }
        });

        const reduction_name = props.problemType === 'MI-FNO' ? 'FNO' : 'SNO';
        const solver_slots =
          props.solverConstants && props.solverConstants.slots ? props.solverConstants.slots[reduction_name] : [];
        const blacklist = constructBlacklist(solver.solver_params, params_to_hide, solver_slots);
        const solver_params = showParams(solver, blacklist);

        return (
          <React.Fragment>
            <span>
              <i className='icon-next' />
              {solver.solver_params && Object.keys(solver.solver_params).length > 0 ? (
                <React.Fragment>{solverTooltip(solver.next_solver, solver_params)}</React.Fragment>
              ) : (
                <span className='solver'>{solver.next_solver}</span>
              )}
            </span>
            {nested_solver}
          </React.Fragment>
        );
      }
    }

    const solver_params = showParams(solver_parameters, blacklist);

    return (
      <div className='solvers'>
        {solverTooltip(solver_parameters.next_solver, solver_params)}
        {solver_parameters.solver_params !== undefined &&
          solver_parameters.solver_params !== null &&
          solver_parameters.solver_params.solver !== undefined &&
          solver_parameters.solver_params.solver !== null && (
            <React.Fragment>{nestedSolver(solver_parameters.solver_params.solver)}</React.Fragment>
          )}
      </div>
    );
  }

  function showMolData(moldata) {
    if (moldata !== undefined && moldata !== null) {
      // Only show following mol_data attributes:
      const show_mol_data = ['charge', 'spin', 'basis'];

      function checkDataType(data) {
        if (['string', 'number'].includes(typeof data)) {
          return data;
        } else {
          return `datatype is ${typeof data}. See input.`;
        }
      }

      // Return data
      return show_mol_data.map((item, index) => {
        return (
          <span className='text-xs' key={index}>
            {index !== 0 && <React.Fragment> |</React.Fragment>} {item.charAt(0).toUpperCase() + item.slice(1)}:&nbsp;
            {checkDataType(moldata[item])}
          </span>
        );
      });
    } else return '';
  }

  return (
    <div id='MIProblem' className='row details problem-type-a'>
      <div className='col-12'>
        <div className='row sub-problems'>
          {/* SUBPROBLEMS */}
          <div className='col-12 sub-problem'>
            <span className='label-sm'>Sub-Problems: </span>
            {!props.fetchingSubproblems && props.fetchSubproblemsError ? (
              <QErrorMessage
                className='row m-3'
                text={<span className='bold'>GET PROBLEM ERROR: {props.fetchSubproblemsError.message}</span>}
              />
            ) : (
              ''
            )}
            {!props.fetchingSubproblems && props.subproblems ? (
              <React.Fragment>
                {props.subproblems.ready > 0 && (
                  <span className='text-xs pending bold'>{props.subproblems.ready} queued • </span>
                )}
                {props.subproblems.inprogress > 0 && (
                  <span className='text-xs pending bold'>{props.subproblems.inprogress} in progress • </span>
                )}
                {props.subproblems.complete > 0 && (
                  <span className='text-xs good bold'>{props.subproblems.complete} complete </span>
                )}
                {props.subproblems.ready !== 0 && props.subproblems.inprogress !== 0 && props.subproblems.count > 0 && (
                  <span className='text-xs bold'>({props.subproblems.count} total)</span>
                )}
                {props.subproblems.count === 0 && <span className='text-xs'>None</span>}
              </React.Fragment>
            ) : (
              <LoadingInline size='sm' className='d-inline-flex mx-2' />
            )}
          </div>

          {/* MOLECULE DATA */}
          {props.fragment_data && props.fragment_data.mol_data ? (
            <div className='col-12 mol-data'>
              <p className='molecule-data'>
                <span className='label-sm'>Molecule Data: </span>
                {showMolData(props.fragment_data.mol_data)}
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
        {/* SOLVERS */}
        {props.solver_parameters !== undefined &&
          props.solver_parameters !== null &&
          props.solver_parameters.next_solver !== undefined && (
            <div className='row solvers'>
              <div className='col-12 solvers-data'>
                <span className='label-sm'>Solvers: </span>
                <div className='solvers-block'>{parentSolver(props.solver_parameters)}</div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

MIProblem.propTypes = {
  subproblems: PropTypes.object,
  fetchingSubproblems: PropTypes.bool.isRequired,
  fetchSubproblemsError: PropTypes.object,
  solver_parameters: PropTypes.object.isRequired,
  mol_data: PropTypes.any,
  solverConstants: PropTypes.object.isRequired,
  problemType: PropTypes.string.isRequired,
};

export default MIProblem;
