import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useAuth } from '../../../hoc/Auth';
import { handleInputChange } from '../../../hoc/FormFunctions';
import QButton from '../../../components/button/Button';
import QFormWrapper from '../../../components/form/QFormWrapper';
import QFormGroup from '../../../components/form/QFormGroup';
import { QSuccessMessage, QErrorMessage } from '../../../components/notifications/Notifications';
import { LoadingBlock } from '../../../components/loading/Loading';

const ConsolidateAliasesModal = props => {
  // Init
  const { fetchData } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Add Credit Pool
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const validationSchema = yup.object().shape({
    confirmation: yup.string().matches('CONSOLIDATE').required('This field is required'),
  });

  useEffect(() => {
    return () => {
      setSuccess(null);
      setError(null);
    };
  }, []);

  function processConsolidation() {
    setProcessing(true);

    const body = {
      group_or_user_id: props.id,
      owner_name: props.ownerName,
    };

    fetchData(signal, '/api/consolidate-owner-names', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setProcessing(false);
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        setProcessing(false);
        setSuccess(true);
        setTimeout(() => {
          props.toggleConsolidateModal();
        }, 2000);
      }
    });
  }

  function formatAliases() {
    if (props.names) {
      let string = '';
      props.names.forEach(str => {
        if (string === '') {
          string = str;
        } else {
          string = string + ', ' + str;
        }
      });
      return string;
    } else return '';
  }

  return (
    <div className='container-fluid'>
      <h1>Consolidate Aliases</h1>
      <p>
        Multiple names (<strong>{formatAliases()}</strong>) have been found for credit pools with group id: {props.id}{' '}
      </p>
      <p>
        The actual org name for this id is currently: <strong>{props.ownerName}</strong>
      </p>
      <p>Use this modal to update all owner_name values belonging to this id with the current org name.</p>
      {processing ? (
        <LoadingBlock />
      ) : (
        <React.Fragment>
          {/* Success Message */}
          {success ? (
            <QSuccessMessage text='Success!' className='my-4' />
          ) : (
            <React.Fragment>
              {/* Error Message */}
              {error ? (
                <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
              ) : (
                <React.Fragment>
                  <QFormWrapper
                    formId='ConsolidateForm'
                    initialValues={{
                      confirmation: '',
                    }}
                    initialErrors={{
                      confirmation: true,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={() => processConsolidation()}>
                    {formProps => <ConsolidateForm {...formProps} />}
                  </QFormWrapper>
                  <QButton className='secondary mt-4' onClick={props.toggleConsolidateModal}>
                    Cancel
                  </QButton>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ConsolidateAliasesModal.propTypes = {
  id: PropTypes.string.isRequired,
  names: PropTypes.array.isRequired,
  ownerName: PropTypes.string.isRequired,
  toggleConsolidateModal: PropTypes.func.isRequired,
};

const ConsolidateForm = props => (
  <React.Fragment>
    <div className='row'>
      {/* Confirmation */}
      <QFormGroup
        containerClassName='col-12 col-md-8'
        element='text'
        id='confirmation'
        name='confirmation'
        label='Type CONSOLIDATE'
        inputProps={{ 'data-testid': 'confirmation-input' }}
        onChange={e => handleInputChange(e, props, 'confirmation', () => {})}
        type='text'
        includeError={true}
      />
    </div>
    <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
      Consolidate
    </QButton>
  </React.Fragment>
);

export default ConsolidateAliasesModal;
