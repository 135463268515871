import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

// Components
import ProvideAuth from './hoc/Auth';
import ProvideUser from './hoc/UserData';
import RootLayout from './layouts/RootLayout';

ReactDOM.render(
  <ProvideAuth>
    <ProvideUser>
      <HashRouter>
        <RootLayout />
      </HashRouter>
    </ProvideUser>
  </ProvideAuth>,
  document.getElementById('content')
);
