import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import QButton from '../button/Button';
import { LoadingInline } from '../loading/Loading';

const AutoRefreshButton = props => {
  const [reset, setReset] = useState(true);
  const [manualRefresh, setManualRefresh] = useState(false);
  const [autoRefreshPaused, setAutoRefreshPaused] = useState(false);
  const [autoRefreshTimer, setAutoRefreshTimer] = useState(null);

  useEffect(() => {
    setReset(false);
    clearTimeout(autoRefreshTimer);

    document.addEventListener('visibilitychange', () => {
      let local_timer = null;
      if (document.visibilityState === 'visible') {
        // Restart auto refresh countdown if browser window becomes visible
        local_timer = setTimeout(() => {
          setAutoRefreshPaused(false);
        }, 1000);
      }
      // Otherwise pause auto refresh countdown
      else if (document.visibilityState === 'hidden') {
        local_timer = setTimeout(() => {
          setAutoRefreshPaused(true);
          setReset(true);
        }, 1000);
      }
    });

    if (!reset && !autoRefreshPaused) {
      // Timer to refresh every 30 seconds
      setAutoRefreshTimer(
        setTimeout(() => {
          setReset(true);
        }, 30000)
      );

      // Refresh problems on rerender after reset is true
      if (manualRefresh) {
        setManualRefresh(false);
      } else {
        props.refresh(false);
      }
    }

    return () => {
      setReset(true);
      clearTimeout(autoRefreshTimer);
    };
  }, [reset, autoRefreshPaused]);

  function handleManualRefresh() {
    setReset(true);
    setManualRefresh(true);
    props.refresh(true);
  }

  return (
    <div id='AutoRefreshButton' className={`${props.className}`}>
      {!autoRefreshPaused ? (
        <React.Fragment>
          {props.isRefreshing ? (
            <LoadingInline size='sm' className='m-3' />
          ) : (
            <React.Fragment>
              {props.dataLoaded ? (
                <div className='d-flex justify-content-center align-items-center data-loaded'>
                  <i className='icon-circle-check' />
                  Loaded
                </div>
              ) : (
                <React.Fragment>
                  <QButton
                    className={`small d-flex align-items-center refresh-btn`}
                    onClick={() => handleManualRefresh()}
                    dataTestId='auto-refresh-btn'>
                    Refresh
                    <div className='d-flex align-items-center justify-content-center ml-1 counter'>
                      <svg className='circle-stroke base' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                        <circle cx='10' cy='10' r='5' />
                      </svg>
                      <svg
                        className={`circle-stroke fill ${reset ? 'reset' : 'running'}`}
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'>
                        <circle cx='10' cy='10' r='5' />
                      </svg>
                    </div>
                  </QButton>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

AutoRefreshButton.propTypes = {
  className: PropTypes.string,
  dataLoaded: PropTypes.bool,
  isRefreshing: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired
};

export default AutoRefreshButton;
