import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Components
import { useAuth } from '../../../hoc/Auth';
import { browserStore } from '../../../hoc/BrowserStorage';
import { useUserData } from '../../../hoc/UserData';
import { getPaginationSets } from '../../../hoc/CommonFunctions';
import SearchForm from '../../../components/form/SearchForm';
import SortByToggle from '../../../components/sortByToggle/SortByToggle';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import Pagination, { PaginationSummary } from '../../../components/pagination/Pagination';
import { LoadingBlock } from '../../../components/loading/Loading';

const UsersList = props => {
  // Init
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Pagination
  const [setLength, setSetLength] = useState(50);
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(49);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // GET Users
  const [data, setData] = useState({ users: [], total: 0 });
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('email');
  const [emailOrder, setEmailOrder] = useState(true);
  const [nameOrder, setNameOrder] = useState(true);
  const [query, setQuery] = useState('');
  const [isUpdating, setIsUpdating] = useState(true);
  // Admin
  const { setAdminPath } = useUserData();

  useEffect(() => {
    setIsShown(true);

    if (isUpdating) {
      getUsers();
    }

    return () => {
      abortController.abort();
    };
  }, [isUpdating]);

  function getUsers() {
    let sort_by = 'email:1';
    if (sortBy === 'email' && emailOrder === false) {
      sort_by = 'email:-1';
    } else if (sortBy === 'name' && nameOrder === true) {
      sort_by = 'name:1';
    } else if (sortBy === 'name' && nameOrder === false) {
      sort_by = 'name:-1';
    }

    const url = '/api/users?sort=' + sort_by + '&page=' + currentPage + '&query=' + query;

    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
        setIsLoaded(true);
      }
      // if data is returned
      if (response.status === 'success') {
        setIsUpdating(false);
        setData(response.data);

        getPaginationSets(response.data.total, setLength, num => {
          if (num === 0) {
            setPages(1);
          } else {
            setPages(num);
          }
          setDataStart(currentPage * setLength - setLength);
          setDataEnd(currentPage * setLength - 1);
        });

        setIsLoaded(true);
      }
    });
  }

  function updateAdminPath(link) {
    browserStore('set', 'adminPath', link, 'session');
    setAdminPath(link);
  }

  function updateQuery(event) {
    if (event.target.value.length >= 3) {
      setQuery('*' + event.target.value + '*');
    } else {
      setQuery(event.target.value);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    setCurrentPage(1);
    setDataStart(1 * setLength - setLength);
    setDataEnd(1 * setLength - 1);
    setIsUpdating(true);
  }

  function resetForm() {
    setQuery('');
    setCurrentPage(1);
    setDataStart(1 * setLength - setLength);
    setDataEnd(1 * setLength - 1);
    setIsUpdating(true);
  }

  function updatePage(page) {
    setCurrentPage(page);
    setIsUpdating(true);
  }

  function updateSortBy(string) {
    if (string === 'email') {
      setSortBy('email');
      setEmailOrder(!emailOrder);
      setIsUpdating(true);
    } else if (string === 'name') {
      setSortBy('name');
      setNameOrder(!nameOrder);
      setIsUpdating(true);
    }
  }

  const users = data.users;
  const users_list = Array.from(users).map((user, index) => (
    <Link
      to={`/admin/user/${user.user_id}/credits`}
      onClick={() => updateAdminPath(`/admin/user/${user.user_id}/credits`)}
      key={index}
      className='user-row-container'
      data-testid='users-list-item'>
      <div className='row list item'>
        <div className='col-12 col-sm-6 col-lg-4 email'>{user.email}</div>
        <div className='col-12 col-sm-6 col-lg-4 name'>{user.name}</div>
      </div>
    </Link>
  ));

  return (
    <div id='UsersList' className={isShown ? 'show' : 'hidden'}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-1'></div>
          <div className='col-12 col-lg-10 pt-2 users-list-container'>
            {/* Summary */}
            <div className='row summary'>
              <div className='col-12'>
                <SearchForm
                  handleSubmit={event => handleSubmit(event)}
                  resetForm={event => resetForm(event)}
                  updateQuery={event => updateQuery(event)}
                  searchField='Emails'
                  query={query}
                />
              </div>
            </div>
            {/* Heading */}
            <div className='d-flex align-items-center my-3'>
              <Pagination
                pages={pages}
                currentPage={currentPage}
                updatePage={value => updatePage(value)}
                updating={value => setIsUpdating(value)}
              />
              <PaginationSummary dataTotal={data.total} dataStart={dataStart} dataEnd={dataEnd} dataType={'users'} />
            </div>
            <div className='row list header'>
              <div className='col-12 col-sm-6 col-lg-4 email'>
                User Email
                <SortByToggle
                  direction={emailOrder}
                  active={sortBy === 'email' ? true : false}
                  onClick={() => updateSortBy('email')}
                  className='no-mobile'
                />
              </div>
              <div className='col-12 col-sm-6 col-lg-4 name'>
                Name
                <SortByToggle
                  direction={nameOrder}
                  active={sortBy === 'name' ? true : false}
                  onClick={() => updateSortBy('name')}
                  className='no-mobile'
                />
              </div>
            </div>
            {/* Loading */}
            {!isLoaded ? (
              <LoadingBlock />
            ) : (
              <React.Fragment>
                {/* Error */}
                {error ? (
                  <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
                ) : (
                  <React.Fragment>
                    {/* Users List */}
                    {users_list}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          <div className='col-lg-1'></div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
