import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';

// Components
import QTip from '../../components/tooltip/Tooltip';
import CopyToClipboard, { copyToClipboard } from '../../components/copyToClipboard/CopyToClipboard';
import { QModalText } from '../../components/modal/Modal';
import RevokeToken from './RevokeToken';
import { QErrorMessage } from '../../components/notifications/Notifications';
import { LoadingFilled, LoadingInline } from '../../components/loading/Loading';

const APIToken = props => {
  const [isShown, setIsShown] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // Performance Metrics
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
    setIsShown(true);

    if (props.revokeToken) {
      setIsLoaded(true);
    }

    if (MIXPANEL_TOKEN) {
      if (firstRender) {
        mixpanel.track('Account API Token Component: Mounted');
      }
    }

    return () => {
      setFirstRender(true);
    };
  }, [props.refreshToken]);

  var revoke_token_help =
    'Revoke all previous tokens to disable access immediately. Regain access by generating a new token. All old tokens will remain permanently disabled.';

  if (isLoaded) {
    return (
      <React.Fragment>
        {props.refreshToken === 'none' ? (
          <div className='auth-credentials container-fluid'>
            <div className='row'>
              <div className='col-lg-1'></div>
              <div className='col-12 col-lg-10 content-container'>
                <h2>Token Revoked</h2>
                <p className='bold'>Please sign in again to get a new token:</p>
                <a className='btn' href='/logout'>
                  Sign Out
                </a>
              </div>
              <div className='col-lg-1'></div>
            </div>
          </div>
        ) : (
          <div id='APIToken' className={`sub-content container-fluid ${isShown ? 'show' : 'hide'}`}>
            <div className='row'>
              <div className='col-lg-1'></div>
              <div className='col-12 col-lg-10 content-container'>
                <div className='row'>
                  <div className='col-12 col-lg-10'>
                    <div className='api-info-container'>
                      {window.location.href.includes('token-redirect=true') && (
                        <div className='row'>
                          {/* Redirect Message */}
                          <div className='col-12'>
                            <div className='notification static alert bold redirect'>
                              Your API token expires after 7 days for security purposes. Try replacing the current token
                              used in your QEMIST Cloud API request with this token and try again.
                            </div>
                          </div>
                        </div>
                      )}
                      <div className='row'>
                        {/* Refresh Token */}
                        <p className='col-12 bold'>Token:</p>
                        <div className='col-12'>
                          <div className='auth0-info'>
                            {props.revokeTokenLoading ? (
                              <LoadingInline />
                            ) : (
                              <textarea rows='3' readOnly className='token-field' value={props.refreshToken}></textarea>
                            )}
                            <CopyToClipboard onClick={() => copyToClipboard(props.refreshToken)} />
                            <span className={`notification ${props.revokeTokenSuccess ? 'show' : ''}`}>
                              Token revoked!
                            </span>
                          </div>
                        </div>
                        {/* Revoke Token */}
                        <div className='col-12 revoke-token-group'>
                          <QModalText
                            text='Revoke Token'
                            textclassname='text-xs'
                            onHide={props.toggleRevokeTokenModal}
                            show={props.revokeTokenModalVisible}
                            size='lg'
                            id='RevokeTokenModal'>
                            <RevokeToken revokeToken={props.revokeToken} toggleModal={props.toggleRevokeTokenModal} />
                          </QModalText>
                          <QTip
                            content={revoke_token_help}
                            trigger='mouseenter click'
                            hideOnClick={true}
                            offset={[0, 0]}
                            placement='top-end'
                            className='tooltip-helper'>
                            <i className='icon-help' data-testid='help-icon' />
                          </QTip>
                        </div>
                        {props.revokeTokenError && (
                          <QErrorMessage text={<span className='bold'>{props.revokeTokenError.message}</span>} />
                        )}
                        {/* Disclaimer */}
                        {!window.location.href.includes('token-redirect=true') && (
                          <div className='col-12'>
                            <div className='notification static disclaimer mt-3'>
                              For added security, this token only lasts for 7 days. Come back to this page each week to
                              grab a new API token for your QEMIST Cloud requests.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-1'></div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  } else return <LoadingFilled />;
};

APIToken.propTypes = {
  revokeToken: PropTypes.func.isRequired,
  revokeTokenError: PropTypes.object,
  revokeTokenSuccess: PropTypes.bool.isRequired,
  revokeTokenLoading: PropTypes.bool.isRequired,
  toggleRevokeTokenModal: PropTypes.func.isRequired,
  revokeTokenModalVisible: PropTypes.bool.isRequired
};

export default APIToken;
