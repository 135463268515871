import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { browserStore } from '../../../hoc/BrowserStorage';
import { useUserData } from '../../../hoc/UserData';

const ViewUserTopNav = props => {
  const { setAdminPath } = useUserData();

  function updateAdminPath(link) {
    browserStore('set', 'adminPath', link, 'session');
    setAdminPath(link);
  }

  return (
    <div id='ViewUserTopNav' className='container-fluid'>
      <div className='row'>
        <div className='col-12 col-md-6 left-col'>
          <Link to='/admin/users' onClick={() => updateAdminPath('/admin/users')}>
            <i className='icon-prev' />
            <h2 className='text-lg'>{props.user.email}</h2>
          </Link>
        </div>
        <div className='col-12 col-md-6 right-col'>
          <div className='links'>
            <Link
              to={`/admin/user/${props.user_id}/credits`}
              onClick={() => updateAdminPath(`/admin/user/${props.user_id}/credits`)}
              className={`${window.location.href.includes('/credits') ? 'active' : ''}`}>
              <span className='text'>Credits</span>
            </Link>
            <Link
              to={`/admin/user/${props.user_id}/view-projects`}
              onClick={() => updateAdminPath(`/admin/user/${props.user_id}/view-projects`)}
              className={`${window.location.href.includes('/view-projects') ? 'active' : ''}`}>
              <span className='text'>Projects</span>
            </Link>
            <Link
              to={`/admin/user/${props.user_id}/view-request-log`}
              onClick={() => updateAdminPath(`/admin/user/${props.user_id}/view-request-log`)}
              className={`${window.location.href.includes('/view-request-log') ? 'active' : ''}`}>
              <span className='text'>Requests</span>
            </Link>
            <Link
              to={`/admin/user/${props.user_id}/profile`}
              onClick={() => updateAdminPath(`/admin/user/${props.user_id}/profile`)}
              className={`${window.location.href.includes('/profile') ? 'active' : ''}`}>
              <span className='text'>Profile</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewUserTopNav.propTypes = {
  user: PropTypes.object.isRequired,
  user_id: PropTypes.string.isRequired,
};

export default ViewUserTopNav;
