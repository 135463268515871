import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// Components
import { emailFormatting } from '../../hoc/FormFunctions';
import AddMembersForm from '../../components/form/AddMembersForm';
import QFormWrapper from '../../components/form/QFormWrapper';
import QButton from '../../components/button/Button';
import { QSuccessMessage, QErrorMessage } from '../../components/notifications/Notifications';

const AddMembersToOrgModal = props => {
  const validationSchema = yup.object().shape({
    members: yup
      .string()
      .required('This field is required')
      .test('email-format', 'Invalid email format', function (value) {
        if (value !== undefined) {
          let new_value = value.replace(/\s+/, '');
          let emails = new_value.split(',');
          // evaluate each entry
          let verdicts = [];
          emails.forEach(email => {
            if (email !== '') {
              let verdict = emailFormatting.test(email);
              verdicts.push(verdict);
            }
          });
          // evaluate all entries
          if (verdicts.every((val, i, arr) => val === true)) {
            return true;
          } else return false;
        }
      }),
  });

  function listEmails(arr) {
    let formatted_string = '';
    let i;
    for (i = 0; i < arr.length; i++) {
      if (formatted_string === '') {
        formatted_string = arr[i];
      } else {
        formatted_string = formatted_string + ', ' + arr[i];
      }
    }
    return formatted_string;
  }

  return (
    <React.Fragment>
      <h1>Add Members</h1>
      {props.usersAdded ? <QSuccessMessage text='User(s) successfully added!' className='mb-4' /> : ''}
      {props.userNotFound ? (
        <React.Fragment>
          <p>
            These email addresses <strong>could not be found</strong> in our database:
          </p>
          <p className='bold'>{listEmails(props.emailsNotFound)}</p>
          {/*
          <p className='mb-5'>Would you like to invite them to your organization?</p>
          <div className='row mt-4'>
            <div className='col-12'>
              <QButton onClick={props.toggleAddMembersToOrgModal}>
                Yes
              </QButton>
              <QButton className='secondary' onClick={props.toggleAddMembersToOrgModal}>
                No
              </QButton>
            </div>
          </div>
          */}
          <p className='mb-4'>Please verify the spelling and try again.</p>
          <QButton onClick={props.toggleAddMembersToOrgModal}>Ok</QButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>Add team members to {props.orgName}.</p>
          {props.addMembersToOrgError ? (
            <QErrorMessage
              className='row p0'
              text={
                <span className='bold'>
                  Uh oh—something went wrong. Please try again later.
                  <br />
                  {props.addMembersToOrgError.message}
                </span>
              }
            />
          ) : (
            ''
          )}
          <QFormWrapper
            initialValues={{
              members: '',
            }}
            initialErrors={{
              members: true,
            }}
            validationSchema={validationSchema}
            onSubmit={values => props.addMembers(values)}>
            {formProps => <AddMembersForm {...formProps} />}
          </QFormWrapper>
          <QButton className='secondary mt-4' onClick={props.toggleAddMembersToOrgModal}>
            Cancel
          </QButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

AddMembersToOrgModal.propTypes = {
  orgName: PropTypes.string.isRequired,
  addMembersToOrgError: PropTypes.any,
  usersAdded: PropTypes.bool.isRequired,
  userNotFound: PropTypes.bool.isRequired,
  emailsNotFound: PropTypes.array,
  toggleAddMembersToOrgModal: PropTypes.func.isRequired,
  addMembers: PropTypes.func.isRequired,
};

export default AddMembersToOrgModal;
