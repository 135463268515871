import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
import { getProjectName } from '../../../hoc/CommonFunctions';
import { LoadingBlock } from '../../../components/loading/Loading';
import { QErrorMessage } from '../../../components/notifications/Notifications';

const ViewGroupProjects = props => {
  // Init
  const { fetchUserProjects } = useAuth();
  const { setOverlayLevel1 } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET projects
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  function fetchProjectsList() {
    fetchUserProjects(signal, null, props.group._id, null, response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
        setIsLoaded(true);
      }
      // if data is returned
      if (response.status === 'success') {
        setProjects(response.data);
        setIsLoaded(true);
      }
    });
  }

  useEffect(() => {
    if (props.group) {
      fetchProjectsList();
    }

    return () => {
      setIsLoaded(false);
      abortController.abort();
    };
  }, [props.group]);

  const projects_list = Array.from(projects).map((project, index) => (
    <div
      onClick={() => {
        const obj = {
          content_type: 'project-details',
          selected_project: project,
          projects: projects,
          user: null
        };
        setOverlayLevel1(obj);
      }}
      key={index}
      data-testid='project-list-item'>
      <div className='row list item border-bottom projects-list'>
        <div className='col-12 col-sm-6 col-md-3 bold'>{getProjectName(project)}</div>
        <div className='col-12 col-sm-6 col-md-3'>{project.description}</div>
        <div className='col-12 col-sm-6 col-md-4'>{project._id}</div>
        <div className='col-12 col-sm-6 col-md-2'>{project.members.length}</div>
      </div>
    </div>
  ));

  return (
    <div id='ViewGroupProjects' className='view-user container-fluid'>
      {isLoaded ? (
        <React.Fragment>
          {error ? (
            <QErrorMessage className='row mx-2 my-4' text={error.message} />
          ) : (
            <React.Fragment>
              <div className='row'>
                <div className='col-12 content-container pt-0'>
                  {projects.length > 0 ? (
                    <React.Fragment>
                      {/* Header */}
                      <div className='row list header'>
                        <div className='col-12 col-sm-6 col-md-3'>Project Name</div>
                        <div className='col-12 col-sm-6 col-md-3'>Description</div>
                        <div className='col-12 col-sm-6 col-md-4'>Project ID</div>
                        <div className='col-12 col-sm-6 col-md-2'>Members</div>
                      </div>
                      {/* List */}
                      {projects_list}
                    </React.Fragment>
                  ) : (
                    <div className='my-3'>No projects found.</div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <LoadingBlock />
      )}
    </div>
  );
};

ViewGroupProjects.propTypes = {
  group: PropTypes.object.isRequired
};

export default ViewGroupProjects;
