import React from 'react';
import PropTypes from 'prop-types';

// Components
import QButton from '../button/Button';
import { LoadingInline } from '../loading/Loading';

export const RefreshButton = props => {
  return (
    <div id='RefreshButton' className={`${props.className}`}>
      {props.isRefreshing ? (
        <LoadingInline size='sm' className='m-3' />
      ) : (
        <React.Fragment>
          {props.dataLoaded ? (
            <div className='d-flex justify-content-center align-items-center data-loaded'>
              <i className='icon-circle-check' />
              Loaded
            </div>
          ) : (
            <React.Fragment>
              <QButton
                className={`small d-flex align-items-center refresh-btn`}
                onClick={() => props.refresh(true)}
                dataTestId='refresh-btn'>
                <i className='icon-refresh mr-1' />
                Refresh
              </QButton>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

RefreshButton.propTypes = {
  className: PropTypes.string,
  isRefreshing: PropTypes.bool.isRequired,
  dataLoaded: PropTypes.bool,
  refresh: PropTypes.func.isRequired
};

export default RefreshButton;
