import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

// Components
import { useUserData } from '../hoc/UserData';
import { initRangeStart, getRangeEnd } from '../hoc/CommonFunctions';
import { paginationSetup } from '../hoc/ProblemsCommonFuncs';
import RequestLogComponent from '../components/requestLog/RequestLogComponent';
import { ErrorObject } from '../models/data';

declare var MIXPANEL_TOKEN: string | undefined;

const RequestLog = () => {
  // Init
  const { user, currentOrg, currentProject }: any = useUserData();
  const [isShown, setIsShown] = useState(false);
  const history = useHistory();
  // GET Problems
  const [offset, setOffset] = useState<number>(0);
  const setLength = 20 as number;
  const [rangeStart, setRangeStart] = useState<number>(initRangeStart);
  const [rangeEnd, setRangeEnd] = useState<number>(getRangeEnd());
  // Performance Metrics
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    // Handle pagination setup
    paginationSetup((new_path: string) => {
      history.replace(new_path);
    });

    // Set offset & date range
    let page_num = window.location.href.split('/request-log#')[1];
    if (!!page_num) {
      try {
        setOffset(Number(page_num) * setLength - setLength);
      } catch (e) {
        // `page_num` possibly not a number
        console.warn(e);
      }
    }

    // Event Tracking
    if (MIXPANEL_TOKEN) {
      if (firstRender) {
        mixpanel.time_event('RequestLog Component: Load');
        mixpanel.track('RequestLog Component: Mounted');
      }
    }
    setFirstRender(false);

    return () => {
      if (MIXPANEL_TOKEN) {
        if (document.querySelector('div.full-screen-backdrop')) {
          mixpanel.track('RequestLog Component: Load', { Result: 'Spinner still shown' });
        }
      }
      setFirstRender(true);
    };
  }, [currentOrg]);

  function trackRequestLogError(error) {
    if (MIXPANEL_TOKEN) {
      mixpanel.track('RequestLog Component: Load', { Result: 'Error', Error: error });
    }
  }

  function updateHistory(value) {
    let page = window.location.hash.split('/request-log')[1];
    if (page !== value) {
      history.push(value);
    }
  }

  return (
    <div id='RequestLog' className={isShown ? 'show' : 'hide'}>
      {user !== undefined && user.userid !== undefined ? (
        <RequestLogComponent
          setIsShown={(value: boolean) => setIsShown(value)}
          offset={offset}
          setOffset={(value: number) => setOffset(value)}
          setLength={setLength}
          userID={user.userid}
          groupID={currentOrg._id || user.userid}
          projectID={currentProject ? currentProject._id : null}
          rangeStart={rangeStart}
          setRangeStart={(value: number) => setRangeStart(value)}
          rangeEnd={rangeEnd}
          setRangeEnd={(value: number) => setRangeEnd(value)}
          saveRangeName={'rqlRanges'}
          visibilityName={'rqlVisibility'}
          trackRequestLogError={(error: ErrorObject) => trackRequestLogError(error)}
          updateHistory={(value: string) => updateHistory(value)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default RequestLog;
