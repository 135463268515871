import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import { useUserData } from '../../hoc/UserData';
import { useAuth } from '../../hoc/Auth';
import Credits from './credits/Credits';
import APIToken from './APIToken';
import Profile from './Profile';

const Settings = props => {
  // Init
  const { user, refreshToken, setRefreshToken } = useUserData();
  const { fetchData } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Revoke Token
  const [revokeTokenLoading, setRevokeTokenLoading] = useState(false);
  const [revokeTokenError, setRevokeTokenError] = useState(null);
  const [revokeTokenSuccess, setRevokeTokenSuccess] = useState(false);
  const [revokeTokenModalVisible, setRevokeTokenModalVisible] = useState(false);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [user, refreshToken]);

  function revokeToken() {
    function doFirst(callback) {
      setRevokeTokenModalVisible(!revokeTokenModalVisible);
      setRevokeTokenLoading(true);
      callback();
    }

    doFirst(() => {
      fetchData(signal, '/api/revoke-token', {}, 'POST', response => {
        // if error is returned
        if (response.status === 'error') {
          setRevokeTokenLoading(false);
          setRevokeTokenError(response.error);
        }
        // if data is returned
        if (response.status === 'success') {
          setRefreshToken('none');
          setRevokeTokenLoading(false);
          setRevokeTokenSuccess(true);
        }
      });
    });
  }

  function toggleRevokeTokenModal() {
    setRevokeTokenModalVisible(!revokeTokenModalVisible);
  }

  return (
    <React.Fragment>
      <div id='Settings'>
        <Switch>
          <Route path='/account/credit*'>
            <Credits />
          </Route>
          <Route path='/account/token'>
            <APIToken
              refreshToken={refreshToken}
              revokeToken={() => revokeToken()}
              revokeTokenError={revokeTokenError}
              revokeTokenSuccess={revokeTokenSuccess}
              revokeTokenLoading={revokeTokenLoading}
              toggleRevokeTokenModal={() => toggleRevokeTokenModal()}
              revokeTokenModalVisible={revokeTokenModalVisible}
            />
          </Route>
          <Route path='/account/profile'>
            <Profile user={user} />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default Settings;
