import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Import hooks & hoc
import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
// prettier-ignore
import { getPaginationSets, formatCurrency, convertTimestampToDate, exportToCsv, initRangeStart, getRangeEnd, allRangeStart } from '../../../hoc/CommonFunctions';
import { determineExpiry } from '../../../hoc/CreditsCommonFuncs';
import { handleSearchFormSubmit, resetSearchForm } from '../../../hoc/FilterFuncs';
import { scrollToTop } from '../../../hoc/ScrollFunctions';

// Import global components
import SearchForm from '../../../components/form/SearchForm';
import Pagination, { PaginationSummary } from '../../../components/pagination/Pagination';
import { LoadingBlock, LoadingInline } from '../../../components/loading/Loading';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import DatePicker, { ShowAllButton } from '../../../components/datepicker/DatePicker';
import QButton from '../../../components/button/Button';
import RefreshButton from '../../../components/refreshButton/RefreshButton';

// Import local components
import CreditsReportSummary from './CreditsReportSummary';

const ViewCreditsReport = props => {
  // Init
  const { fetchData } = useAuth();
  const { setOverlayLevel1 } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [timers, setTimers] = useState([]);
  // Get Credit Groups
  const [setLength, setSetLength] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(49);
  const [rangeStart, setRangeStart] = useState(initRangeStart);
  const [rangeEnd, setRangeEnd] = useState(getRangeEnd());
  const [pages, setPages] = useState(1);
  const [creditGroups, setCreditGroups] = useState({ total: '', credit_groups: '' });
  const [error, setError] = useState(null);
  // Filtered Groups
  const [filteredGroups, setFilteredGroups] = useState([
    { group_or_user_id: '', owner_name: '', amount_remaining: '', amount_granted: '', upcoming_exp: '' },
  ]);
  const [query, setQuery] = useState('');
  // GET Credit Pools
  // Export
  const [exporting, setExporting] = useState(false);
  // Refresh Credit Groups
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  function fetchCreditGroups(page, range_start, range_end) {
    let formatted_range_start = range_start;
    let formatted_range_end = range_end;
    if (range_start) {
      formatted_range_start = range_start.toFixed(0);
    }
    if (range_end) {
      formatted_range_end = range_end.toFixed(0);
    }
    const body = {
      range_start: formatted_range_start,
      range_end: formatted_range_end,
      offset: page - 1,
      limit: setLength,
    };

    fetchData(signal, '/api/get-credit-groups', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setIsRefreshing(false);
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const data = response.data;
        setCreditGroups(data);
        setFilteredGroups(data.credit_groups);
        let timer_item_1 = setTimeout(() => {
          setIsLoaded(true);
          setDataLoaded(true);
          setIsRefreshing(false);
          scrollToTop('ViewCreditsReport');
          let timer_item_2 = setTimeout(() => setDataLoaded(false), 2000);
          timers.push(timer_item_2);
        }, 500);
        timers.push(timer_item_1);

        // Get pagination sets
        getPaginationSets(data.total, setLength, num => {
          if (num === 0) {
            setPages(1);
          } else {
            setPages(num);
          }
          setDataStart(page * setLength - setLength);
          setDataEnd(page * setLength - 1);
        });
      }
    });
  }

  useEffect(() => {
    if (!isLoaded) {
      fetchCreditGroups(currentPage, rangeStart, rangeEnd);
    }

    return () => {
      setIsLoaded(false);
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, []);

  function updatePage(page) {
    setCurrentPage(page);
    fetchCreditGroups(page, rangeStart, rangeEnd);
  }

  // DateRangePicker
  function handleDateCallback(start, end) {
    const range_start = moment(start).unix();
    const range_end = moment(end).unix();

    setIsLoaded(false), setRangeStart(range_start), setRangeEnd(range_end);
    fetchCreditGroups(currentPage, range_start, range_end);
  }

  function showAllTransactions() {
    const range_start = allRangeStart;
    const range_end = getRangeEnd();

    setIsLoaded(false), setRangeStart(range_start), setRangeEnd(range_end);
    fetchCreditGroups(currentPage, range_start, range_end);
  }

  function exportData() {
    setExporting(true);

    // 1. Fetch all credit pools for time period
    let formatted_range_start = rangeStart;
    let formatted_range_end = rangeEnd;
    if (rangeStart) {
      formatted_range_start = rangeStart.toFixed(0);
    }
    if (rangeEnd) {
      formatted_range_end = rangeEnd.toFixed(0);
    }
    const body = {
      range_start: formatted_range_start,
      range_end: formatted_range_end,
    };

    fetchData(signal, '/api/get-credit-pools', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        let credit_groups_data = response.data.credit_pools;

        // 2. Format for CSV
        let rows = [
          [
            'credit_pool_id',
            'credit_pool_name',
            'group_or_user_id',
            'owner_name',
            'date_granted',
            'expiration',
            'amount_granted',
            'amount_remaining',
            'credits_type',
            'notes',
          ],
        ];

        if (credit_groups_data) {
          credit_groups_data.forEach(row => {
            let row_data = [
              row.credit_pool_id,
              row.credit_pool_name,
              row.group_or_user_id,
              row.owner_name,
              moment.unix(row.date_granted).format('MMMM D YYYY h:mm:ss a'),
              moment.unix(row.expiration).format('MMMM D YYYY h:mm:ss a'),
              row.amount_granted,
              row.amount_remaining,
              row.credits_type,
              row.notes,
            ];
            rows.push(row_data);
          });
        }

        exportToCsv(rows, rangeStart, rangeEnd);
        setExporting(false);
      }
    });
  }

  function refreshCreditGroups() {
    setIsRefreshing(true);
    fetchCreditGroups(currentPage, rangeStart, rangeEnd);
  }

  const credit_groups_list = Array.from(filteredGroups).map((pool, index) => (
    <div key={index} className='row-container'>
      <div
        className='row list item'
        onClick={() => {
          const obj = {
            content_type: 'credit-pools',
            credit_pool_id: pool.group_or_user_id,
          };
          setOverlayLevel1(obj);
        }}>
        {/* Group / User Name */}
        <div className='col-6 col-lg-4 bold pl-md-4'>{pool.owner_name ? pool.owner_name : ''}</div>
        {/* Amount Granted */}
        <div className='col-6 col-lg-2'>
          {pool.amount_granted !== undefined && pool.amount_granted !== null ? formatCurrency(pool.amount_granted) : ''}
        </div>
        {/* Amount Remaining */}
        <div
          className={`col-6 col-lg-2 bold ${
            pool.amount_remaining <= 0 ? 'danger' : pool.amount_remaining <= 100 ? 'warning' : ''
          }`}>
          {pool.amount_remaining !== undefined ? formatCurrency(pool.amount_remaining) : ''}
        </div>
        {/* Expiration */}
        <div
          className={`col-6 col-lg-2 ${
            determineExpiry(pool.upcoming_exp) === 'expires_in_2_weeks' ? 'bold warning' : ''
          }`}>
          {pool.upcoming_exp ? convertTimestampToDate(pool.upcoming_exp) : ''}
        </div>
      </div>
    </div>
  ));

  return (
    <React.Fragment>
      <div id='ViewCreditsReport' className='container-fluid'>
        <CreditsReportSummary />
        <div className='row'>
          <div className='col-12 d-flex flex-wrap align-items-center pt-3'>
            <span className='mr-2'>Show groups where credits were granted between:</span>
            <div className='d-flex align-items-center'>
              <DatePicker
                rangeStart={rangeStart}
                rangeEnd={rangeEnd}
                handleCallback={(start, end) => handleDateCallback(start, end)}
              />
              <ShowAllButton className='mr-3' onClick={() => showAllTransactions()} />
            </div>
            <RefreshButton
              className='mr-2 mt-2 mt-sm-0'
              isRefreshing={isRefreshing}
              dataLoaded={dataLoaded}
              refresh={() => refreshCreditGroups()}
            />
            {exporting ? (
              <div className='p-2' data-testid='exporting-spinner'>
                <LoadingInline size='sm' />
              </div>
            ) : (
              <QButton className='btn-link no-wrap p-2' onClick={() => exportData()}>
                Export All Credit Pools
              </QButton>
            )}
          </div>
          <div className='col-12 d-flex align-items-center'>
            <Pagination
              pages={pages}
              currentPage={currentPage}
              updatePage={value => updatePage(value)}
              updating={value => {
                if (value === true) {
                  setIsLoaded(false);
                } else {
                  setIsLoaded(true);
                }
              }}
            />
            <PaginationSummary
              dataTotal={creditGroups.total ? parseInt(creditGroups.total) : null}
              dataStart={dataStart}
              dataEnd={dataEnd}
              dataType={'credit groups'}
            />
            <SearchForm
              handleSubmit={event =>
                handleSearchFormSubmit(event, creditGroups.credit_groups, 'owner_name', query, list =>
                  setFilteredGroups(list)
                )
              }
              resetForm={event =>
                resetSearchForm(creditGroups.credit_groups, 'owner_name', function (list) {
                  setQuery('');
                  setFilteredGroups(list);
                })
              }
              updateQuery={event => setQuery(event.target.value)}
              searchField='Names'
              query={query}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 w-100'>
            <div className='credit-pools'>
              {/* Header */}
              <div className='row list header mt-0'>
                <div className='col-6 col-lg-4 pl-md-4'>Group/User</div>
                <div className='col-6 col-lg-2'>Granted</div>
                <div className='col-6 col-lg-2'>Remaining</div>
                <div className='col-6 col-lg-2'>Upcoming Exp</div>
              </div>
              {/* List */}
              {!isLoaded ? (
                <LoadingBlock />
              ) : (
                <React.Fragment>
                  {error ? (
                    <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
                  ) : (
                    <div className='credit-groups-list'>
                      {credit_groups_list}
                      {creditGroups.total === 0 && (
                        <div className='row empty info'>
                          <div className='col-12 bold'>No credit pools for this period.</div>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewCreditsReport;
