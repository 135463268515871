import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';

// Dropdown Main Componenet
const QDropdown = props => (
  <Dropdown id={props.id} className={`QDropdownComponent ${props.className}`}>
    {props.children}
  </Dropdown>
);

// Dropdown Toggle
// prettier-ignore
export const Toggle = props => (
  <Dropdown.Toggle
    className={props.className}
    data-testid={props.dataTestId}>
    {props.children}
  </Dropdown.Toggle>
);

// Dropdown Menu
export const Menu = props => <Dropdown.Menu {...props} data-testid={'dropdown-menu'} align={props.align} />;

// Dropdown Header
export const Header = props => <Dropdown.Header>{props.children}</Dropdown.Header>;

// Dropdown Item
export const Item = props => <Dropdown.Item {...props} as={'span'} />;

// Dropdown Divider
export const Divider = props => <Dropdown.Divider />;

QDropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

Toggle.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.any,
};

Menu.propTypes = {
  align: PropTypes.string,
};

Header.propTypes = {
  children: PropTypes.any,
};

export default QDropdown;
