import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Components
import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
// prettier-ignore
import { getOrgName, formatCurrency, initRangeStart, getRangeEnd, allRangeStart } from '../../../hoc/CommonFunctions';
import { paginationSetup } from '../../../hoc/CreditsCommonFuncs';
import { QButton } from '../../../components/button/Button';
import RefreshButton from '../../../components/refreshButton/RefreshButton';
import { LoadingBlock } from '../../../components/loading/Loading';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import { QSelect } from '../../../components/form/FormElements';
import CreditTransactions from '../../../components/credits/Credits';
import DatePicker, { ShowAllButton } from '../../../components/datepicker/DatePicker';

const ViewUserCredits = props => {
  // Init
  const { fetchData, fetchCredits } = useAuth();
  const { setOverlayLevel1 } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [timers, setTimers] = useState([]);
  // GET Credit Pools
  const [creditSummary, setCreditSummary] = useState({ credits_outstanding: null, groups_or_pools: null });
  const [creditSummaryError, setCreditSummaryError] = useState(null);
  const [showCreditPools, setShowCreditPools] = useState(false);
  // GET Users Groups
  const [usersGroups, setUsersGroups] = useState(null);
  const [fetchUsersGroupsError, setFetchUsersGroupsError] = useState(null);
  const [currentOrg, setCurrentOrg] = useState(null);
  // GET Credits
  const { adminDateRangeStart, setAdminDateRangeStart } = useUserData();
  const { adminDateRangeEnd, setAdminDateRangeEnd } = useUserData();
  const [credits, setCredits] = useState([]);
  const [creditsTotal, setCreditsTotal] = useState(0);
  const [creditsLoading, setCreditsLoading] = useState(null);
  const [fetchCreditsError, setFetchCreditsError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [setLength, setSetLength] = useState(20);
  // Refresh Credits
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  function fetchCreditSummary() {
    const body = {
      group_or_user_id: currentOrg._id,
    };

    fetchData(signal, '/api/get-credits-summary', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setCreditSummaryError(response.error);
      }
      // if data is returned
      if (response.data) {
        setCreditSummary(response.data);
      }
    });
  }

  function fetchUsersGroups() {
    const body = {
      user_id: props.user.user_id,
    };

    fetchData(signal, '/api/get-users-groups', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setFetchUsersGroupsError(response.error);
      }
      // if data is returned
      if (response.data) {
        let groups = [];
        response.data.forEach(group => {
          if (group.name.match(/--org/i)) {
            groups.push(group);
          }
        });
        let personal = {
          _id: props.user.user_id,
          name: props.user.email,
        };
        groups.push(personal);
        setUsersGroups(groups);
        setCurrentOrg(personal);
      }
    });
  }

  function handleCreditsCallback(response) {
    // if error is returned
    if (response.status === 'error') {
      setIsRefreshing(false);
      setCreditsLoading(false);
      setFetchCreditsError(response.error);
    }
    // if credits data is returned
    if (response.data) {
      setDataLoaded(true);
      setIsRefreshing(false);
      setCreditsLoading(false);
      setCredits(response.data.credits);
      setCreditsTotal(response.data.total);
      let timer_item = setTimeout(() => setDataLoaded(false), 2000);
      timers.push(timer_item);
    }
    setIsLoaded(true);
  }

  useEffect(() => {
    if (sessionStorage.getItem('qemist.adminDateRangeStart') === null) {
      sessionStorage.setItem('qemist.adminDateRangeStart', initRangeStart);
      setAdminDateRangeStart(initRangeStart);
    } else {
      let stored_value = sessionStorage.getItem('qemist.adminDateRangeStart');
      let stored_value_num = parseInt(stored_value, 10);
      setAdminDateRangeStart(stored_value_num);
    }

    const range_end = getRangeEnd();
    if (sessionStorage.getItem('qemist.adminDateRangeEnd') === null) {
      sessionStorage.setItem('qemist.adminDateRangeEnd', range_end);
      setAdminDateRangeEnd(range_end);
    } else {
      let stored_value = sessionStorage.getItem('qemist.adminDateRangeEnd');
      let stored_value_num = parseInt(stored_value, 10);
      setAdminDateRangeEnd(stored_value_num);
    }

    if (usersGroups === null && props.user !== undefined && props.user.user_id) {
      fetchUsersGroups();
    }

    paginationSetup(new_path => {
      history.replace(new_path);
    });

    // Set offset & fetch
    let page_num = window.location.href.split('/credits#')[1];
    let start_fetch;
    const this_offset = page_num * setLength - setLength;

    if (page_num !== undefined) {
      setOffset(this_offset);
      if (usersGroups !== null && currentOrg !== null) {
        start_fetch = setTimeout(() => {
          fetchCreditSummary();
          // prettier-ignore
          fetchCredits(signal, currentOrg._id, adminDateRangeStart, adminDateRangeEnd, this_offset, setLength, response => handleCreditsCallback(response));
        }, 500);
      }
    }

    return () => {
      clearTimeout(start_fetch);
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, [usersGroups, currentOrg, adminDateRangeStart, adminDateRangeEnd, creditsLoading, props.user]);

  // DateRangePicker
  function handleCallback(start, end) {
    const range_start = moment(start).unix();
    const range_end = moment(end).unix();

    setCreditsLoading(true);
    setAdminDateRangeStart(range_start), setAdminDateRangeEnd(range_end);
    sessionStorage.setItem('qemist.adminDateRangeStart', range_start);
    sessionStorage.setItem('qemist.adminDateRangeEnd', range_end);
  }

  function showAllTransactions() {
    const range_start = allRangeStart;
    const range_end = getRangeEnd();

    setCreditsLoading(true);
    setAdminDateRangeStart(range_start), setAdminDateRangeEnd(range_end);
    sessionStorage.setItem('qemist.adminDateRangeStart', range_start);
    sessionStorage.setItem('qemist.adminDateRangeEnd', range_end);
    let page = window.location.hash.split('/credits')[1];
    if (page !== '#1') {
      history.push('#1');
    }
  }

  function refreshCredits() {
    setIsRefreshing(true);
    setCreditsLoading(true);
  }

  function getMenuList() {
    if (usersGroups !== null) {
      let list = [];
      usersGroups.forEach(group => {
        let org_name = getOrgName(group);
        list.push([group._id, org_name]);
      });
      return list;
    }
  }

  function changeCurrentOrg(event) {
    usersGroups.forEach(group => {
      if (event.target.value === group._id) {
        setCurrentOrg(group);
      }
    });
  }

  return (
    <div id='ViewUserCredits'>
      {fetchUsersGroupsError ? (
        <div className='view-user container-fluid'>
          <QErrorMessage
            className='row p0 mb-3'
            text={<span className='bold'>GET Users Groups Error: {fetchUsersGroupsError.message}</span>}
          />
        </div>
      ) : (
        <React.Fragment>
          {!isLoaded ? (
            <LoadingBlock className='transparent-bg' />
          ) : (
            <div className='view-user container-fluid'>
              <div className='row'>
                <div className='col-12 content-container'>
                  {/* Select Credit Group (Green Area) */}
                  <div className='row py-2 mb-3 green-bg border-top'>
                    <div className='col-12 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-center justify-content-md-start'>
                      <span className='text-xs no-wrap mr-2'>Viewing Transactions for Credits Group:</span>
                      <QSelect
                        id='select-credit-group'
                        className='mb-0 mr-2'
                        onChange={e => changeCurrentOrg(e)}
                        value={currentOrg._id}
                        menu={getMenuList()}></QSelect>
                      {/* Show Credit Pools */}
                      <QButton
                        className='small'
                        onClick={() => {
                          const obj = {
                            content_type: 'credit-pools',
                            credit_pool_id: currentOrg._id,
                          };
                          setOverlayLevel1(obj);
                        }}>
                        See Credit Pools
                      </QButton>
                    </div>
                    <div className='col-12 d-flex w-100 flex-wrap align-items-center justify-content-between'>
                      <div className='d-flex flex-wrap align-items-center'>
                        {/* Group / User ID */}
                        <div className='d-flex align-items-center mr-3'>
                          <span className='text-xs p-text-light mr-2'>Group/User ID:</span>
                          <span>
                            <input
                              className='text-xs group_or_user_id'
                              value={currentOrg._id}
                              data-testid='group-or-user-id-input'
                              readOnly
                            />
                          </span>
                        </div>
                        {/* Total Credits Remaining */}
                        <div className='d-flex align-items-center'>
                          <span className='text-xs p-text-light mr-2'>Balance:</span>
                          <span
                            className={`bold ${
                              creditSummary.credits_outstanding <= 0
                                ? 'danger'
                                : creditSummary.credits_outstanding < 100
                                ? 'warning'
                                : 'good'
                            }`}>
                            {formatCurrency(creditSummary.credits_outstanding)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Date Picker */}
                  <div className='row'>
                    <div className='col-12 flex-wrap d-flex align-items-center justify-content-between mr-2'>
                      <div className='d-flex flex-wrap align-items-center DateRangePicker'>
                        <DatePicker
                          rangeStart={adminDateRangeStart}
                          rangeEnd={adminDateRangeEnd}
                          handleCallback={(start, end) => handleCallback(start, end)}
                        />
                        <ShowAllButton onClick={() => showAllTransactions()} />
                      </div>
                      <RefreshButton
                        className='mt-2 mt-sm-0'
                        isRefreshing={isRefreshing}
                        dataLoaded={dataLoaded}
                        refresh={() => refreshCredits()}
                      />
                    </div>
                  </div>
                  {/* Error */}
                  {creditSummaryError && (
                    <QErrorMessage
                      className='row p0'
                      text={<span className='bold'>GET Credit Summary Error: {creditSummaryError.message}</span>}
                    />
                  )}
                  {fetchCreditsError ? (
                    <QErrorMessage
                      className='row p0'
                      text={<span className='bold'>GET Credits Error: {fetchCreditsError.message}</span>}
                    />
                  ) : (
                    <React.Fragment>
                      {creditsLoading ? (
                        <LoadingBlock className='mt-3' />
                      ) : (
                        <CreditTransactions
                          credits={credits}
                          creditsTotal={creditsTotal}
                          creditsLoading={creditsLoading}
                          rangeStart={adminDateRangeStart}
                          rangeEnd={adminDateRangeStart}
                          setLength={setLength}
                          setOffset={value => setOffset(value)}
                          // prettier-ignore
                          fetchCredits={new_offset => {
                            fetchCredits(signal, currentOrg._id, adminDateRangeStart, adminDateRangeEnd, new_offset, setLength, response => handleCreditsCallback(response));
                          }}
                          showCreditPoolDetails={id => {
                            const obj = {
                              content_type: 'credit-pool-details',
                              pool_id: id,
                            };
                            setOverlayLevel1(obj);
                          }}
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ViewUserCredits.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ViewUserCredits;
