import React, { useState, useEffect, useRef } from 'react';

// Import hooks & hoc
import { useAuth } from '../../hoc/Auth';
import { scrollToBottom, scrollToTop } from '../../hoc/ScrollFunctions';
import { useUserData } from '../../hoc/UserData';
import { useSubmitData, getSubmitData, updateSubmitData } from '../../hoc/SubmitData';
import { browserStore } from '../../hoc/BrowserStorage';
import { trackAnalytics, listenForElement } from '../../hoc/Analytics';

// Import functions and constants
import { defaultXYZ, defaultList, defaultSmiles } from './constants/Molecules';
import { defaultBasis, defaultMolFiles } from './constants/SubmitConst';
import { addFilesToSolver } from './functions/AddFilesToSolver';
import {
  buildSerializedSolver,
  buildSolverMenu,
  serializeMolecule,
  determineMolValue,
  checkAtomConstraints,
} from './functions/SubmitFunctions';

// Import global components
import { LoadingFilled, LoadingInline } from '../../components/loading/Loading';
import QButton from '../../components/button/Button';
import { QSelect } from '../../components/form/FormElements';
import { QErrorMessage } from '../../components/notifications/Notifications';
import TabMenu, { TabItem } from '../../components/tabMenu/TabMenu';
import { QTip } from '../../components/tooltip/Tooltip';

// Import submit components
import SubmitMolecule from './customize/SubmitMolecule';
import { SubmitInputGroup } from './components/SubmitElements';
import SubmitIFCI from './customize/SubmitIFCI';
import SubmitANI from './customize/SubmitANI';
import SubmitDFT from './customize/SubmitDFT';
import SubmitGeoOpt from './customize/SubmitGeoOpt';
import SubmitCSP from './customize/SubmitCSP';
import SubmitHF from './customize/SubmitHF';
import SubmitGVB from './customize/SubmitGVB';
import SubmitAQFEP from './customize/SubmitAQFEP';
import SubmitGNINA from './customize/SubmitGNINA';

const SubmitProblem = props => {
  // Init
  const { fetchData } = useAuth();
  const {
    solverConfigs,
    defaultIFCIPip,
    defaultANIPip,
    defaultDFTPip,
    defaultGeoOptPip,
    defaultCSPPip,
    defaultHFPip,
    defaultGVBPip,
    defaultAQFEPPip,
    defaultGNINAPip,
    ANISolvers,
    submitDataLoaded,
  } = useSubmitData();
  const [firstRender, setFirstRender] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    refreshToken,
    balance,
    setUpdatingBalance,
    user,
    orgs,
    currentOrg,
    setOverlayLevel1,
    solverConstants,
    currentProject,
  } = useUserData();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const timers = useRef([]);
  const [fetchError, setFetchError] = useState(null);
  // Browser Storage
  const [storeSubmitContentName, setStoreSubmitContentName] = useState('');
  // Submit Data
  // -- molecule states
  const [moldata, setMoldata] = useState({
    atom: defaultXYZ,
    atom_input_type: 'xyz',
    basis: defaultBasis,
    charge: 0,
    spin: 0,
    cart: 'False',
    unit: 'Angstrom',
    frozen_orbitals: [],
    mean_field_handle: null,
  });
  const [molFiles, setMolFiles] = useState(defaultMolFiles);
  const [molWarning, setMolWarning] = useState(null);
  const [molError, setMolError] = useState(null);
  const [serializedMols, setSerializedMols] = useState(defaultXYZ);
  // -- customizable parameters states
  const [solverType, setSolverType] = useState('');
  const [solverPipeline, setSolverPipeline] = useState([]);
  const [serializedSolver, setSerializedSolver] = useState({});
  // Visibility
  const [showContent, setShowContent] = useState('solver');
  // Submit Problem
  const [paramsValid, setParamsValid] = useState(false);
  const [submitStoreId, setSubmitStoreId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [probValidation, setProbValidation] = useState('');
  const [warnings, setWarnings] = useState([]);
  const [submitProblemError, setSubmitProblemError] = useState(null);
  const [problemSubmitted, setProblemSubmitted] = useState(false);
  const [problemHandle, setProblemHandle] = useState(null);
  // Performance Metrics
  const [contentLoaded, setContentLoaded] = useState(false);
  // Guardrails
  const [guardrails, setGuardrails] = useState(null);
  const [solverMenu, setSolverMenu] = useState([]);

  function handlePipeline(pip) {
    // Update solver pipeline object
    setSolverPipeline(pip);

    // Construct serialized solver
    const serialized_solver = buildSerializedSolver(solverConfigs, pip);
    setSerializedSolver(serialized_solver);
  }

  function handleMolConstraints(
    pip,
    mol,
    submit_store_id = submitStoreId,
    solver_type = solverType,
    reset_moldata = false
  ) {
    setMolError(null);
    let mol_data = structuredClone(mol);

    // Reset moldata params
    if (reset_moldata) {
      mol_data.mean_field_handle = null;
      mol_data.basis = defaultBasis;
      setMolFiles(defaultMolFiles);
    }

    let solver_name = pip[0].solver_name;
    if (solver_type === 'ifci') {
      solver_name = pip[1].solver_name;
    }

    // Molecule system type constraints
    const mol_val = determineMolValue(mol_data.atom_input_type, solverConstants, solver_name, 'allowed_system_type');
    mol_data.atom_input_type = mol_val;

    if (mol_data.atom_input_type !== mol.atom_input_type) {
      if (mol_data.atom_input_type === 'xyz') {
        mol_data.atom = defaultXYZ;
        setMolWarning('Solver chosen only allows xyz coordinates for molecule input.');
      }
      if (mol_data.atom_input_type === 'file-upload') {
        mol_data.atom = '';
        setMolWarning('Solver chosen only allows file(s) upload.');
      }
      if (mol_data.atom_input_type === 'smiles') {
        mol_data.atom = defaultSmiles;
        setMolWarning('Solver chosen only allows SMILES string for molecule input.');
      }
    }

    if (mol_data.atom_input_type === 'xyz') {
      // Atom constraints
      const atom_errors = checkAtomConstraints(mol_data.atom, solverConstants, solver_name);
      if (atom_errors.length > 0) {
        const not_allowed_atoms = atom_errors.toString().replace(',', ', ');
        setMolError({ message: 'These molecule elements are not allowed for this solver type: ' + not_allowed_atoms });
      }

      // Basis sets constraints
      mol_data.basis =
        determineMolValue(mol_data.basis, solverConstants, solver_name, 'allowed_basis_sets') || defaultBasis;

      // Cartesian constraints
      mol_data.cart = determineMolValue(mol_data.cart, solverConstants, solver_name, 'allowed_cart') || 'False';

      mol_data.charge = mol_data.charge || 0;
      mol_data.spin = mol_data.spin || 0;
      mol_data.unit = mol_data.unit || 'Angstrom';
      mol_data.frozen_orbitals = mol_data.frozen_orbitals || [];
    } else {
      // Set all mol params to null if smiles string or file upload
      mol_data.basis = null;
      mol_data.charge = null;
      mol_data.spin = null;
      mol_data.cart = null;
      mol_data.unit = null;
      mol_data.frozen_orbitals = null;
    }

    // Update React state
    setMoldata(mol_data);
    // Update submit data in localStorage
    updateSubmitData(submit_store_id, { moldata: mol_data, pipeline: pip });
  }

  useEffect(() => {
    if (!!guardrails && !!orgs && !!user && !!submitDataLoaded && !!solverConstants) {
      // Get workspace id
      let workspace_id = '';
      // if user is in an org workspace:
      if (currentOrg._id !== undefined) {
        workspace_id = currentOrg._id;
      }
      // if user is in their personal workspace:
      else {
        workspace_id = 'personal';
      }
      const id = `${user.userid}_${workspace_id}`;
      setSubmitStoreId(id);

      // Stored defaults
      const store_submit_content_name = `${user.userid}_${workspace_id}.submitContent`;
      setStoreSubmitContentName(store_submit_content_name);

      // Get & apply stored layouts
      const stored_content = browserStore('get', store_submit_content_name);
      const allowed_content_vals = ['solver', 'molecule', 'parameters'];
      if (!!stored_content && allowed_content_vals.includes(stored_content)) {
        setShowContent(stored_content);
      } else {
        browserStore('set', store_submit_content_name, showContent);
      }

      // Get submit_data from localStorage
      const stored_submit_data = getSubmitData(id);

      const mol_data = stored_submit_data !== null ? stored_submit_data.moldata : moldata;
      const pipeline = stored_submit_data !== null ? stored_submit_data.pipeline : solverPipeline;

      // If moldata exists in localStorage
      if (stored_submit_data && stored_submit_data.moldata) {
        setMoldata(mol_data);
      }

      function useDefaultPipeline() {
        setSolverType('ifci');
        handlePipeline(defaultIFCIPip);
        handleMolConstraints(defaultIFCIPip, mol_data, id);
      }

      // Set pipeline string and solver object with data stored in localStorage
      if (stored_submit_data && stored_submit_data.pipeline) {
        let pipeline_found = false;
        const outer_solvers = [
          ['ifci', ['IncrementalDecomposition']],
          ['ani', ['ANI1x', 'ANI2x', 'ANI1ccx']],
          ['dft', ['DFT']],
          ['geoopt', ['GeometryOptimizer']],
          ['csp', ['OrganicCrystalOptimizer']],
          ['hartreefock', ['HartreeFock']],
          ['gvb', ['GVB']],
          ['aqfep', ['AQFEP']],
          ['gnina', ['GNINA']],
        ];
        let solver_type = '';
        outer_solvers.forEach(([type, solvers]) => {
          if (solvers.includes(pipeline[0].solver_name)) {
            setSolverType(type);
            solver_type = type;
            pipeline_found = true;
          }
        });
        if (pipeline_found) {
          handlePipeline(pipeline);
          handleMolConstraints(pipeline, mol_data, submitStoreId, solver_type);
        } else {
          useDefaultPipeline();
        }
      }
      // Or set pipeline string and solver object with ifci
      else {
        useDefaultPipeline();
      }

      // Render component
      setIsLoaded(true);
      setIsShown(true);

      // Analytics
      if (!contentLoaded) {
        listenForElement('Submit', 'div.code-wrapper', () => {
          setContentLoaded(true);
          trackAnalytics('track', 'Submit Problem Component: Load', { Result: 'Loaded' });
        });
      }
    }

    if (firstRender) {
      // Analytics
      trackAnalytics('time_event', 'Submit Problem Component: Load');
      trackAnalytics('track', 'Submit Problem Component: Mounted');
    }
    setFirstRender(false);
  }, [orgs, user, submitDataLoaded, solverConstants, guardrails]);

  useEffect(() => {
    if (!!currentOrg) {
      fetchData(signal, `/api/get-guardrails?org_id=${currentOrg._id || 'default'}`, {}, 'GET', response => {
        if (response.status === 'error') {
          console.warn(response);
        }
        if (response.status === 'success') {
          setGuardrails(response.data);
          // Build solver menu
          const solver_menu = buildSolverMenu(response.data.disallowed_solvers);
          setSolverMenu(solver_menu);
        }
      });
    }
  }, [currentOrg]);

  useEffect(() => {
    // Component cleanup on unmount
    return () => {
      if (document.querySelector('div.full-screen-backdrop')) {
        trackAnalytics('track', 'Submit Problem Component: Load', {
          Result: 'Spinner still shown',
        });
      }
      setIsShown(false);
      setIsLoaded(false);
      setFetchError(null);
      setSubmitProblemError(null);
      setFirstRender(true);
      abortController.abort();
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function resetSubmit() {
    setProblemHandle(null);
    setProblemSubmitted(false);
    setWarnings([]);
  }

  async function uploadFile(type, file) {
    return new Promise(resolve => {
      const form_data = new FormData();
      form_data.append(file.name, file);

      fetch('/api/upload-file', {
        method: 'POST',
        // Headers deliberately omitted.
        // Ref: https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
        body: form_data,
      })
        .then(response => {
          if (!response.ok) {
            let error = response.status + ' ' + response.statusText;
            throw Error(error);
          }
          return response;
        })
        .then(res => res.json())
        .then(data => {
          const remote_filename = data.filename;
          resolve({ failed: false, type: type, remote_filename: remote_filename });
        })
        .catch(error => {
          setUploadError(error);
          resolve({ failed: true });
        });
    });
  }

  async function processFiles() {
    setUploading(true);
    // Count files in each moleFiles file types
    const files = Object.values(molFiles);
    const num_files = files.flat(Infinity).length;

    // Upload files if any files added to any molFiles array
    if (num_files > 0) {
      const mol_files = Object.entries(molFiles);
      let uploads = [];

      mol_files.forEach(([type, files]) => {
        files.forEach(file => {
          uploads.push(uploadFile(type, file));
        });
      });

      const file_upload = await Promise.all(uploads);

      // Construct mol files object from uploaded remote filenames
      let remote_files = {};
      let has_failure = false;

      file_upload.forEach(upload => {
        const type = upload.type;
        const remote_filename = upload.remote_filename;
        if (!upload.failed && type) {
          if (!remote_files[type]) {
            remote_files[type] = [remote_filename];
          } else {
            remote_files[type].push(remote_filename);
          }
        } else {
          has_failure = true;
        }
      });

      setUploading(false);
      return { failed: has_failure, remote_files: remote_files };
    } else return { failed: false, remote_files: {} };
  }

  async function submitProblem(serialized_mols = serializedMols) {
    /*
    This function is trigged when problem is validateProblem() is sucessful, 
    or user clicks "Yes" to continue problem submission after warning
    component is shown.
    */

    // Analytics
    trackAnalytics('time_event', 'Submit Sample Problem');

    const upload = await processFiles();

    if (!upload.failed) {
      const mol_files = upload.remote_files;
      let serialized_solver = structuredClone(serializedSolver);

      // Insert host and guest files into solver parameters
      if (Object.values(mol_files).some(files => files.length > 0)) {
        serialized_solver = addFilesToSolver(serialized_solver, mol_files, solverConstants.solver_mol_constraints);
      }

      const body = {
        project_id: currentProject._id,
        serialized_mols: serialized_mols,
        serialized_solver: serialized_solver,
      };
      fetchData(signal, '/api/submit-problem', body, 'POST', response => {
        // if error is returned
        if (response.status === 'error') {
          setSubmitProblemError(response.error);
          // Analytics
          trackAnalytics('track', 'Submit Sample Problem', {
            Result: 'API Response: Error',
            Error: response.error,
            Endpoint: '/api/submit-problem',
          });
        }
        // if data is returned
        if (response.status === 'success') {
          const data = response.data;
          if (!data.problem_handle) {
            let error_response = JSON.stringify(data);
            setSubmitProblemError({ message: error_response });
            // Analytics
            trackAnalytics('track', 'Submit Sample Problem', {
              Result: 'API Response: Error',
              Error: error_response,
              Endpoint: '/api/submit-problem',
            });
          } else {
            setProblemHandle(data.problem_handle);
            setWarnings(data.warnings);
            // Analytics
            trackAnalytics('track', 'Submit Sample Problem', {
              Result: 'API Response: Success',
              Endpoint: '/api/submit-problem',
            });
            // Update balance
            setUpdatingBalance(true);
          }
          setProblemSubmitted(true);
          // Scroll to bottom
          const t = setTimeout(() => scrollToBottom(), 200);
          timers.current.push(t);
        }
        setSubmitting(false);
      });
    } else {
      setSubmitting(false);
    }
  }

  function validateProblem(atom) {
    /*
    This function is called after optionally formatting
    atom input via checkMolSystem()
    */
    const serialized_mols = serializeMolecule(moldata, atom, solverType === 'ani' ? 'ml' : 'basic');
    setSerializedMols(serialized_mols);

    const body = {
      refresh_token: refreshToken,
      project_id: currentProject._id,
      serialized_mols: serialized_mols,
      serialized_solver: serializedSolver,
      org_id: !!currentOrg ? currentOrg._id : 'default',
    };

    // Validate problem
    fetchData(signal, '/api/validate-problem', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setSubmitProblemError(response.error);
        setSubmitting(false);
      }
      if (response.status === 'success') {
        // Handle warnings
        if (response.data.status && response.data.status === 'warning') {
          // Show warning to allow user to abort or continue
          setProbValidation(response.data.message);
        } else {
          // If no warnings, submit problem immediately
          submitProblem(serialized_mols);
        }
      }
    });
  }

  function formatAtomList(atom, callback) {
    fetchData(signal, '/api/validate-molecule', { molecule: JSON.parse(atom) }, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setMolError(response.error);
        callback('error');
        setSubmitting(false);
      }
      // if data is returned
      if (response.status === 'success') {
        callback(response.data.formatted_atom);
      }
    });
  }

  function checkMolSystem() {
    /* 
    This function is triggered when user clicks "Submit Problem" button
    */
    resetSubmit();
    setSubmitProblemError(null);
    setSubmitting(true);

    // Format atom list to xyz string
    if (moldata.atom_input_type === 'list') {
      formatAtomList(moldata.atom, formatted_atom => {
        if (formatted_atom !== 'error') {
          validateProblem(formatted_atom);
        }
      });
    }
    // Otherwise trigger problem validation with
    // moldata.atom
    else {
      validateProblem(moldata.atom);
    }
  }

  function handleShowContentChange(str) {
    scrollToTop();
    setShowContent(str);
    browserStore('set', storeSubmitContentName, str);
  }

  function handleMolParamChange(arr, pip = solverPipeline) {
    // Provide arr as [['param_key', param_value]]
    // Handle moldata param changes
    let mol_data = structuredClone(moldata);
    let update_atom = false;
    arr.forEach(([param_key, param_val]) => {
      mol_data[param_key] = param_val;

      if (['atom', 'atom_input_type'].includes(param_key)) {
        if (param_key === 'atom_input_type') {
          if (param_val === 'xyz') {
            mol_data.atom = defaultXYZ;
          }
          if (param_val === 'file-upload') {
            mol_data.atom = JSON.stringify('');
          }
          if (param_val === 'smiles') {
            mol_data.atom = defaultSmiles;
          }
        }
        update_atom = true;
      }
    });

    // Determine molecule parameter constraints based on
    // atom parameter input
    // Moldata component state will be updated at the end
    // of the handleMolConstraints() function
    if (update_atom) {
      handleMolConstraints(pip, mol_data);
    }
    // Otherwise update moldata states and localStorage now
    else {
      setMoldata(mol_data);
      updateSubmitData(submitStoreId, { moldata: mol_data, pipeline: pip });
    }
  }

  function resetMolAtom() {
    // Modify data object
    let mol_data = structuredClone(moldata);
    if (mol_data.atom_input_type === 'xyz') {
      mol_data.atom = defaultXYZ;
    }
    if (mol_data.atom_input_type === 'list') {
      mol_data.atom = JSON.stringify(defaultList);
    }
    if (mol_data.atom_input_type === 'smiles') {
      mol_data.atom = defaultSmiles;
    }

    handleMolConstraints(solverPipeline, mol_data);
  }

  function resetMolParams() {
    // Modify data object
    let mol_data = structuredClone(moldata);
    let solver_name = solverPipeline[0].solver_name;
    if (solverType === 'ifci') {
      solver_name = solverPipeline[1].solver_name;
    }
    // Basis sets
    mol_data.basis =
      determineMolValue(mol_data.basis, solverConstants, solver_name, 'allowed_basis_sets') || defaultBasis;
    // Cartesian
    mol_data.cart = determineMolValue(mol_data.cart, solverConstants, solver_name, 'allowed_cart') || 'False';
    mol_data.basis = defaultBasis;
    // Other
    mol_data.charge = 0;
    mol_data.spin = 0;
    mol_data.unit = 'Angstrom';
    mol_data.frozen_orbitals = [];

    handleMolConstraints(solverPipeline, mol_data);
  }

  function determinePipeline(pipeline, solver_type) {
    let pip = pipeline || defaultIFCIPip;

    if (solver_type === 'ani') {
      pip = pipeline || defaultANIPip;
    }

    if (solver_type === 'dft') {
      pip = pipeline || defaultDFTPip;
    }

    if (solver_type === 'geoopt') {
      pip = pipeline || defaultGeoOptPip;
    }

    if (solver_type === 'csp') {
      pip = pipeline || defaultCSPPip;
    }

    if (solver_type === 'hartreefock') {
      pip = pipeline || defaultHFPip;
    }

    if (solver_type === 'gvb') {
      pip = pipeline || defaultGVBPip;
    }

    if (solver_type === 'aqfep') {
      pip = pipeline || defaultAQFEPPip;
    }

    if (solver_type === 'gnina') {
      pip = pipeline || defaultGNINAPip;
    }

    return { pip };
  }

  function updateMoldata(mol_data_arr, update_store_data = false) {
    // Update moldata
    let mol_data = structuredClone(moldata);
    if (mol_data_arr) {
      mol_data_arr.forEach(param => {
        mol_data[param[0]] = param[1];
      });
      setMoldata(mol_data);

      // Update user submit data
      if (update_store_data) {
        updateSubmitData(submitStoreId, { moldata: mol_data, pipeline: solverPipeline });
      }
    }

    return mol_data;
  }

  function updatePipeline(pipeline, mol_arr) {
    // Update pipeline
    const { pip } = determinePipeline(pipeline, solverType);
    handlePipeline(pip);

    if (mol_arr) {
      // updateSubmitData will be triggered in handleMolParamChange()
      handleMolParamChange(mol_arr, pip);
    } else if (submitStoreId) {
      // Update user submit data
      updateSubmitData(submitStoreId, { moldata: moldata, pipeline: pip });
    }
  }

  function changeSolverType(type) {
    // Update pipeline
    const { pip } = determinePipeline(null, type);
    handlePipeline(pip);

    // Reset mol data
    const reset_moldata = true;

    // Update molecule constraints
    handleMolConstraints(pip, moldata, submitStoreId, type, reset_moldata);

    setSolverType(type);
  }

  const show_warnings = Array.from(warnings).map((warning, index) => (
    <p key={index} className='mono xs'>
      {warning}
    </p>
  ));

  function showSingleProblem() {
    const obj = {
      content_type: 'problem',
      problem_handle: problemHandle,
    };
    setOverlayLevel1(obj);
  }

  return (
    <div id='Submit' className={isShown ? 'show' : 'hide'}>
      {!isLoaded ? (
        <LoadingFilled />
      ) : (
        <React.Fragment>
          <div className='container-fluid'>
            <div className='row'>
              <div id='SubmitContent' className='col col-12 d-flex flex-column left-col-container px-0'>
                <TabMenu className='sticky border-bottom d-flex align-items-center flex-wrap'>
                  <TabItem
                    className={showContent === 'solver' ? 'current' : ''}
                    onClick={() => handleShowContentChange('solver')}>
                    1. Solver
                  </TabItem>
                  <i className='icon-arrow-right' />
                  <TabItem
                    className={showContent === 'molecule' ? 'current' : ''}
                    onClick={() => handleShowContentChange('molecule')}>
                    2. Molecule
                  </TabItem>
                  <i className='icon-arrow-right' />
                  <TabItem
                    className={showContent === 'parameters' ? 'current' : ''}
                    onClick={() => handleShowContentChange('parameters')}>
                    3. Solver Parameters
                  </TabItem>
                </TabMenu>
                {/* Left Col Content */}
                {/* Solver Type */}
                {showContent === 'solver' ? (
                  <div className='px-3 pt-4 pb-5'>
                    {fetchError ? <QErrorMessage className='mb-3' text={fetchError.message} /> : ''}
                    <div>
                      <SubmitInputGroup className='d-flex flex-column'>
                        <label className='label-sm dark mb-0'>Choose Solver Type</label>
                        <p className='d-flex align-items-center text-xxs mt-1 mb-0'>
                          <i className='icon-caution mr-2' />
                          Changing solver type will reset to default solver parameters.
                        </p>
                        <QSelect
                          id='solver-type-input'
                          className='dark w-50 mr-3'
                          data-testid='solver-type-input'
                          value={solverType}
                          onChange={e => {
                            changeSolverType(e.target.value);
                          }}
                          menu={solverMenu}
                        />
                      </SubmitInputGroup>
                      <QButton className='small' onClick={() => handleShowContentChange('molecule')}>
                        Next
                      </QButton>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* Molecule */}
                {showContent === 'molecule' ? (
                  <div className='px-3 pt-4 pb-5'>
                    <SubmitMolecule
                      currentOrg={currentOrg}
                      solverPipeline={solverPipeline}
                      moldata={moldata}
                      molFiles={molFiles}
                      setMolFiles={files => setMolFiles(files)}
                      handleMolParamChange={arr => handleMolParamChange(arr)}
                      resetMolAtom={() => resetMolAtom()}
                      resetMolParams={() => resetMolParams()}
                      molError={molError}
                      setMolError={val => setMolError(val)}
                      molWarning={molWarning}
                      setMolWarning={val => setMolWarning(val)}
                      prev={() => handleShowContentChange('solver')}
                      next={() => handleShowContentChange('parameters')}
                    />
                  </div>
                ) : (
                  ''
                )}
                {/* Solver Parameters */}
                {showContent === 'parameters' ? (
                  <div className='px-3 pt-4 pb-5'>
                    <span className='d-flex align-items-center text-xxs mb-3'>
                      <span className='mr-1'>Only currently customizable parameters are displayed.</span>
                      <QTip
                        content={
                          'Please reach out to your QEMIST Cloud representative if you have any questions regarding solver parameters.'
                        }
                        trigger='mouseenter click'
                        duration={100}
                        placement='bottom-start'
                        followCursor={false}
                        hideOnClick={true}
                        offset={[0, 0]}
                        interactive={true}>
                        <i data-testid='info-icon' className='icon-info' />
                      </QTip>
                    </span>
                    {solverType === 'ifci' ? (
                      <SubmitIFCI
                        currentOrg={currentOrg}
                        setParamsValid={val => setParamsValid(val)}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => {
                          updatePipeline(null, [
                            ['mean_field_handle', null],
                            ['basis', defaultBasis],
                          ]);
                        }}
                        meanFieldHandle={moldata.mean_field_handle}
                        updateMoldata={moldata_changes => updateMoldata(moldata_changes, true)}
                        basis={moldata.basis}
                      />
                    ) : (
                      ''
                    )}
                    {solverType === 'ani' ? (
                      <SubmitANI
                        currentOrg={currentOrg}
                        ANISolvers={ANISolvers}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => updatePipeline(null)}
                      />
                    ) : (
                      ''
                    )}
                    {solverType === 'dft' ? (
                      <SubmitDFT
                        currentOrg={currentOrg}
                        setParamsValid={val => setParamsValid(val)}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => {
                          updatePipeline(null, [['basis', defaultBasis]]);
                        }}
                        updateMoldata={moldata_changes => updateMoldata(moldata_changes, true)}
                        basis={moldata.basis}
                      />
                    ) : (
                      ''
                    )}
                    {solverType === 'geoopt' ? (
                      <SubmitGeoOpt
                        currentOrg={currentOrg}
                        setParamsValid={val => setParamsValid(val)}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => {
                          updatePipeline(null, [['basis', defaultBasis]]);
                        }}
                        updateMoldata={moldata_changes => updateMoldata(moldata_changes, true)}
                        basis={moldata.basis}
                      />
                    ) : (
                      ''
                    )}
                    {solverType === 'csp' ? (
                      <SubmitCSP
                        currentOrg={currentOrg}
                        setParamsValid={val => setParamsValid(val)}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => updatePipeline(null)}
                      />
                    ) : (
                      ''
                    )}
                    {solverType === 'hartreefock' ? (
                      <SubmitHF
                        currentOrg={currentOrg}
                        setParamsValid={val => setParamsValid(val)}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => {
                          updatePipeline(null, [['basis', defaultBasis]]);
                        }}
                        updateMoldata={moldata_changes => updateMoldata(moldata_changes, true)}
                        basis={moldata.basis}
                      />
                    ) : (
                      ''
                    )}
                    {solverType === 'gvb' ? (
                      <SubmitGVB
                        currentOrg={currentOrg}
                        setParamsValid={val => setParamsValid(val)}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => {
                          updatePipeline(null, [['basis', defaultBasis]]);
                        }}
                        updateMoldata={moldata_changes => updateMoldata(moldata_changes, true)}
                        basis={moldata.basis}
                      />
                    ) : (
                      ''
                    )}
                    {solverType === 'aqfep' ? (
                      <SubmitAQFEP
                        setParamsValid={val => setParamsValid(val)}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => updatePipeline(null)}
                      />
                    ) : (
                      ''
                    )}
                    {solverType === 'gnina' ? (
                      <SubmitGNINA
                        molFiles={molFiles}
                        setMolFiles={files => setMolFiles(files)}
                        setParamsValid={val => setParamsValid(val)}
                        solverPipeline={solverPipeline}
                        updatePipeline={pip => updatePipeline(pip)}
                        resetPipeline={() => updatePipeline(null)}
                      />
                    ) : (
                      ''
                    )}
                    {probValidation ? (
                      <div className='validated-problem-warnings d-fle notification info static mb-3'>
                        <p>
                          <i className='icon-caution mr-2' />
                          <span className='bold'>{probValidation}</span>
                        </p>
                        <p>Would you like to continue?</p>
                        <QButton
                          className='xsmall mr-2'
                          onClick={() => {
                            setProbValidation('');
                            submitProblem();
                          }}>
                          Yes
                        </QButton>
                        <QButton
                          className='xsmall secondary outline bold'
                          onClick={() => {
                            setProbValidation('');
                            setSubmitting(false);
                          }}>
                          No
                        </QButton>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center mb-3'>
                        {balance !== '' && balance > 0 ? (
                          <React.Fragment>
                            {submitting ? (
                              <>
                                <LoadingInline className='px-3' />
                                <span className='label dark'>{uploading ? 'Uploading...' : 'Submitting...'}</span>
                              </>
                            ) : (
                              <div className='d-flex align-items-center flex-wrap'>
                                <QButton
                                  className='small d-flex align-items-center mr-3'
                                  disabled={!paramsValid}
                                  onClick={() => checkMolSystem()}>
                                  <i className='icon-solver mr-1' /> Submit Problem
                                </QButton>
                                <span className='d-flex align-items-center text-xxs'>
                                  <i className='icon-caution mr-1'></i>This feature will consume credits.
                                </span>
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <div className='notification alert static bold p-2 mb-0'>
                            Credits are required to submit problems to QEMIST Cloud. Please contact your QEMIST Cloud
                            admin to purchase credits.
                          </div>
                        )}
                      </div>
                    )}
                    {/* molError */}
                    {molError ? <QErrorMessage className='mt-2' text={`${molError.message}`} /> : ''}
                    {/* uploadError */}
                    {uploadError ? <QErrorMessage className='mt-2' text={`${uploadError.message}`} /> : ''}
                    {/* Problem Submitted */}
                    {problemSubmitted && (
                      <div className='problem-submitted'>
                        <div className='notification success static bold p-3'>
                          <p className='bold good mb-2'>
                            Problem submission successful. <br />
                            Problem handle:{' '}
                            <span className='mono link' onClick={() => showSingleProblem()}>
                              {problemHandle}
                            </span>
                          </p>
                          {warnings && <React.Fragment>{show_warnings}</React.Fragment>}
                          <p className='mb-2 text-xxs'>Check problems submitted in the Requests log at any time.</p>
                          <QButton className='xsmall secondary outline' onClick={() => resetSubmit()}>
                            Close
                          </QButton>
                        </div>
                      </div>
                    )}
                    {/* Submit Error */}
                    {submitProblemError && (
                      <div className='error-container mt-3'>
                        <QErrorMessage
                          className='row p0 mb-1'
                          text={<span className='bold'>{submitProblemError.message}</span>}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SubmitProblem;
