import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useUserData } from '../../hoc/UserData';
// prettier-ignore
import { convertTime, formatCurrency, formatStatus } from '../../hoc/CommonFunctions';
import { showBadge } from './ProblemsCommonFunctions';
import Collapse from '../collapse/Collapse';

const ShowCompactProblems = props => {
  // Init
  const { currentProject, setOverlayLevel1, refreshProblems, setRefreshProblems } = useUserData();
  // Set Problems
  const [starredProblems, setStarredProblems] = useState([]);
  const [regularProblems, setRegularProblems] = useState([]);
  const [hiddenProblems, setHiddenProblems] = useState([]);
  // Change Problem Visibility
  const [updatingVisibility, setUpdatingVisibility] = useState([]);
  const [hoverProblem, setHoverProblem] = useState('');

  useEffect(() => {
    setUpdatingVisibility([]);

    if (refreshProblems) {
      props.fetchProblems();
      setRefreshProblems(false);
    } else {
      let starred_problems = [];
      let regular_problems = [];
      let hidden_problems = [];
      props.problems.forEach(problem => {
        if (problem.visibility === 'starred') {
          starred_problems.push(problem);
        } else if (problem.visibility === 'hidden') {
          hidden_problems.push(problem);
        } else {
          regular_problems.push(problem);
        }
      });
      setStarredProblems(starred_problems);
      setRegularProblems(regular_problems);
      setHiddenProblems(hidden_problems);
    }
  }, [props.problems, props.collapseArchived, refreshProblems]);

  function processProblemChanges(problem_handle, new_state, new_note) {
    if (new_state !== null) {
      setUpdatingVisibility([problem_handle]);
    }

    props.updateProblem({
      problem_handle: problem_handle,
      project_id: currentProject ? currentProject._id : null,
      new_state: new_state,
      note: new_note,
    });
  }

  function selectProblem(problem_handle) {
    const obj = {
      content_type: 'problem',
      problem_handle: problem_handle,
      project_id: currentProject ? currentProject._id : null,
    };
    setOverlayLevel1(obj);
  }

  function getProblemsList(problems) {
    return Array.from(problems).map((problem, index) => (
      <div key={index} className='problem-wrapper d-flex align-self-center justify-content-center'>
        <div
          className={`request-log-item ${updatingVisibility.includes(problem.problem_handle) ? 'disabled' : ''} ${
            problem.visibility === 'hidden' ? 'hidden' : ''
          } ${hoverProblem === problem.problem_handle ? 'highlight' : ''}`}
          data-testid='request-log-item'>
          <div className='problem-info'>
            <div className='container-fluid'>
              <div className='row compact'>
                <div className='d-flex align-items-center row-items'>
                  {/* Problem name */}
                  {(problem.problem_name || problem.problem_handle) && (
                    <div
                      className='d-flex flex-column justify-content-center problem-name px-2 ml-2'
                      onClick={() => selectProblem(problem.problem_handle)}
                      onMouseEnter={() => setHoverProblem(problem.problem_handle)}
                      onMouseLeave={() => setHoverProblem('')}>
                      <div className='d-flex align-items-center'>
                        {problem.problem_name ? (
                          <div className='bold text-xs' data-testid='problem-name'>
                            {problem.problem_name}
                          </div>
                        ) : (
                          <div className='bold text-xxs' data-testid='problem-handle'>
                            {problem.problem_handle}
                          </div>
                        )}
                      </div>
                      {/* Problem handle */}
                      {problem.problem_name && problem.problem_name !== problem.problem_handle && (
                        <div className='d-flex align-items-center problem-handle'>
                          <span className='text-xxs p-text-light' data-testid='problem-handle-alt'>
                            {problem.problem_handle}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {/* Submit time */}
                  {problem.submit_time && (
                    <div
                      className='d-flex align-items-center problem-date'
                      onClick={() => selectProblem(problem.problem_handle)}
                      onMouseEnter={() => setHoverProblem(problem.problem_handle)}
                      onMouseLeave={() => setHoverProblem('')}>
                      <span className='text-xxs bold' data-testid='submit-time'>
                        {convertTime(problem.submit_time)}
                      </span>
                    </div>
                  )}
                  {/* Status */}
                  {problem.problem_status && (
                    <div
                      className='d-flex align-items-center problem-status'
                      onClick={() => selectProblem(problem.problem_handle)}
                      onMouseEnter={() => setHoverProblem(problem.problem_handle)}
                      onMouseLeave={() => setHoverProblem('')}>
                      {/* Status icon */}
                      {problem.problem_status.includes('complete') ? (
                        <i className='icon-circle-check mr-1' />
                      ) : (
                        <React.Fragment>
                          {problem.problem_status.includes('ready') ||
                          problem.problem_status.includes('in_progress') ? (
                            <i className='icon-clock mr-1' />
                          ) : (
                            <i className='icon-caution mr-1' />
                          )}
                        </React.Fragment>
                      )}
                      {/* Status text */}
                      <span
                        className={`text-xxs ${
                          problem.problem_status === 'complete'
                            ? ''
                            : ['ready', 'in_progress'].includes(problem.problem_status)
                            ? 'bold'
                            : 'bold danger'
                        }`}>
                        {formatStatus(problem.problem_status)}
                      </span>
                    </div>
                  )}
                  {/* Type */}
                  <div
                    className='d-flex align-items-center type-badge'
                    onClick={() => selectProblem(problem.problem_handle)}
                    onMouseEnter={() => setHoverProblem(problem.problem_handle)}
                    onMouseLeave={() => setHoverProblem('')}>
                    <div className='badge mr-3'>{showBadge(problem)}</div>
                  </div>
                  {/* Cost */}
                  <div
                    className='d-flex align-items-center align-items-center problem-cost'
                    onClick={() => selectProblem(problem.problem_handle)}
                    onMouseEnter={() => setHoverProblem(problem.problem_handle)}
                    onMouseLeave={() => setHoverProblem('')}>
                    <i className='icon-money mr-1' />
                    <span className='text-xxs bold' data-testid='cost'>
                      {formatCurrency(problem.cost)}
                    </span>
                  </div>
                  {/* Actions */}
                  <div className={`d-flex align-self-center actions`}>
                    {problem.visibility === 'visible' && (
                      <i
                        className='icon-star-outline p-2 d-inline-flex'
                        data-testid='star-outline-icon'
                        onClick={() => processProblemChanges(problem.problem_handle, 'starred', null)}
                      />
                    )}
                    {problem.visibility === 'starred' && (
                      <i
                        className='icon-star-filled p-2 d-inline-flex'
                        data-testid='star-icon'
                        onClick={() => processProblemChanges(problem.problem_handle, 'visible', null)}
                      />
                    )}
                    {problem.visibility !== 'hidden' && (
                      <i
                        className='icon-archive p-2 d-inline-flex'
                        data-testid='archive-icon'
                        onClick={() => processProblemChanges(problem.problem_handle, 'hidden', null)}
                      />
                    )}
                  </div>
                </div>
                {/* Right arrow */}
                {problem.problem_handle && (
                  <div
                    className='d-flex align-items-center align-items-center justify-content-end px-3 arrow-right'
                    onClick={() => selectProblem(problem.problem_handle)}
                    onMouseEnter={() => setHoverProblem(problem.problem_handle)}
                    onMouseLeave={() => setHoverProblem('')}>
                    <i className='icon-arrow-right' />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {problem.visibility === 'hidden' && (
          <button
            className='xsmall mx-3 my-2 unhide'
            onClick={() => processProblemChanges(problem.problem_handle, 'visible', null)}>
            Unarchive
          </button>
        )}
      </div>
    ));
  }

  return (
    <div id='ShowCompactProblems'>
      {starredProblems.length > 0 || regularProblems.length > 0 ? (
        <React.Fragment>
          {starredProblems.length > 0 && (
            <Collapse
              className={'compact'}
              header={`Starred (${props.problemsTotals.total_starred})`}
              content={getProblemsList(starredProblems)}
            />
          )}
          {regularProblems.length > 0 && (
            <Collapse
              className={'compact'}
              header={`Standard (${props.problemsTotals.total_regular})`}
              content={getProblemsList(regularProblems)}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>{getProblemsList(regularProblems)}</React.Fragment>
      )}
      {hiddenProblems.length > 0 && (
        <Collapse
          header={'Archived Problems'}
          content={getProblemsList(hiddenProblems)}
          className={'compact hidden-problems'}
          defaultCollapsed={props.collapseArchived}
        />
      )}
    </div>
  );
};

ShowCompactProblems.propTypes = {
  problems: PropTypes.any,
  problemsTotals: PropTypes.object,
  fetchProblems: PropTypes.func.isRequired,
  updateProblem: PropTypes.func.isRequired,
  collapseArchived: PropTypes.bool.isRequired,
};

export default ShowCompactProblems;
