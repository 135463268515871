import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Components
import { useAuth } from '../../../hoc/Auth';
// prettier-ignore
import { initRangeStart, getRangeEnd, allRangeStart, getPaginationSets, formatCurrency, convertTimestampToDate } from '../../../hoc/CommonFunctions';
import { determineExpiry, formatType } from '../../../hoc/CreditsCommonFuncs';
import { LoadingFilled, LoadingBlock } from '../../../components/loading/Loading';
import DatePicker, { ShowAllButton } from '../../../components/datepicker/DatePicker';
import Pagination, { PaginationSummary } from '../../../components/pagination/Pagination';
import { QErrorMessage } from '../../../components/notifications/Notifications';

const CreditPools = props => {
  // Init
  const { fetchData } = useAuth();
  const [isShown, setIsShown] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Credit Pools
  const [setLength, setSetLength] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(49);
  const [rangeStart, setRangeStart] = useState(initRangeStart);
  const [rangeEnd, setRangeEnd] = useState(getRangeEnd());
  const [pages, setPages] = useState(1);
  const [error, setError] = useState(null);
  const [creditPools, setCreditPools] = useState({ credit_pools: [], total: '', summary: {}, owner_name: '' });
  // Update Credit Pools
  const [isUpdating, setIsUpdating] = useState(false);

  function fetchCreditPools(page, range_start, range_end) {
    let formatted_range_start = range_start.toFixed(0);
    let formatted_range_end = range_end.toFixed(0);

    let id;
    if (props.currentOrg && props.currentOrg._id !== undefined && props.currentOrg._id !== null) {
      id = props.currentOrg._id;
    } else {
      id = props.user.userid;
    }

    let body = {
      group_or_user_id: id,
      range_start: formatted_range_start,
      range_end: formatted_range_end,
      offset: page - 1,
      limit: setLength,
    };

    fetchData(signal, '/api/get-credit-pools', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        let data = response.data;
        setCreditPools(data);
        // Get pagination sets
        getPaginationSets(data.total, setLength, num => {
          if (num === 0) {
            setPages(1);
          } else {
            setPages(num);
          }
          setDataStart(page * setLength - setLength);
          setDataEnd(page * setLength - 1);
        });
        // Render content
        setTimeout(() => {
          setIsLoaded(true);
        }, 500);
      }
    });
  }

  useEffect(() => {
    setIsShown(true);
    fetchCreditPools(currentPage, rangeStart, rangeEnd);

    return () => {
      setIsShown(false);
      setIsLoaded(false);
    };
  }, []);

  function updatePage(page) {
    setCurrentPage(page);
    fetchCreditPools(page, rangeStart, rangeEnd);
  }

  // DateRangePicker
  function handleDateCallback(start, end) {
    const range_start = moment(start).unix();
    const range_end = moment(end).unix();

    setIsLoaded(false), setRangeStart(range_start), setRangeEnd(range_end);
    fetchCreditPools(currentPage, range_start, range_end);
  }

  function showAllTransactions() {
    const range_start = allRangeStart;
    const range_end = getRangeEnd();

    setIsLoaded(false), setRangeStart(range_start), setRangeEnd(range_end);
    fetchCreditPools(currentPage, range_start, range_end);
  }

  const credit_pools = Array.from(creditPools.credit_pools).map((item, index) => (
    <div key={index} className='row-container'>
      <div className={`row list item ${determineExpiry(item.expiration)}`}>
        <div className='col-6 col-sm-3 col-lg-3 flex-column align-items-start'>
          {/* Pool ID */}
          {item.credit_pool_id ? (
            <input className='text-xs' value={item.credit_pool_id} data-testid='credit-pool-id-input' readOnly />
          ) : (
            ''
          )}
          {/* Credit Pool Name */}
          {item.credit_pool_name ? (
            <input className='text-xs' value={item.credit_pool_name} data-testid='credit-pool-name-input' readOnly />
          ) : (
            ''
          )}
          {/* Determine Expiration */}
          {determineExpiry(item.expiration) === 'expired' ? (
            <span className='bold pl-1'>Expired</span>
          ) : determineExpiry(item.expiration) === 'expires_in_2_weeks' ? (
            <span className='bold pl-1'>About to expire</span>
          ) : (
            <React.Fragment>
              {/* Out of credits */}
              {item.amount_remaining <= 0 && <span className='bold pl-1'>Out of credits</span>}
              {/* Amount Running low */}
              {item.amount_remaining > 0 && item.amount_remaining < 100 && (
                <span className='bold pl-1'>Credits running low</span>
              )}
            </React.Fragment>
          )}
        </div>
        <div className='col-6 col-sm-3 col-lg-2 flex-column align-items-start justify-content-center'>
          {/* Date Granted */}
          {item.date_granted ? (
            <div className='date-granted'>{convertTimestampToDate(item.date_granted, 'medium')}</div>
          ) : (
            ''
          )}
          {/* Expiration */}
          {item.expiration ? (
            <div
              className={`bold ${
                determineExpiry(item.expiration) === 'expired'
                  ? 'danger'
                  : determineExpiry(item.expiration) === 'expires_in_2_weeks'
                  ? 'warning'
                  : ''
              }`}>
              {convertTimestampToDate(item.expiration, 'medium')}
            </div>
          ) : (
            ''
          )}
        </div>
        {/* Amount Granted */}
        <div className='col-6 col-sm-3 col-lg-2'>
          {item.amount_granted !== undefined && item.amount_granted !== null ? (
            <span className='amount-granted'>{formatCurrency(item.amount_granted)}</span>
          ) : (
            ''
          )}
        </div>
        {/* Amount Remaining */}
        <div className='col-6 col-sm-3 col-lg-2'>
          {item.amount_remaining !== undefined && item.amount_remaining !== null ? (
            <span
              className={`text-xs bold ${
                item.amount_remaining <= 0 || determineExpiry(item.expiration) === 'expired'
                  ? 'danger'
                  : item.amount_remaining < 100
                  ? 'warning'
                  : ''
              }`}>
              {formatCurrency(item.amount_remaining)}
            </span>
          ) : (
            ''
          )}
        </div>
        {/* Credits Type */}
        <div className='col-6 col-sm-3 col-lg-1'>{item.credits_type ? formatType(item.credits_type) : ''}</div>
      </div>
    </div>
  ));

  return (
    <React.Fragment>
      {!isLoaded ? (
        <LoadingFilled className='with-subnav' />
      ) : (
        <div id='AccountCreditPools' className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              {/* Datepicker */}
              <div className='d-flex flex-wrap align-items-center py-2'>
                <span className='mr-2'>Granted between:</span>
                <div className='d-flex align-items-center'>
                  <DatePicker
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    handleCallback={(start, end) => handleDateCallback(start, end)}
                  />
                  <ShowAllButton className='mr-4' onClick={() => showAllTransactions()} />
                </div>
              </div>
              {/* Pagination */}
              <div className='row'>
                <div className='col-12 d-flex align-items-center pt-3 pb-2'>
                  <Pagination
                    pages={pages}
                    currentPage={currentPage}
                    updatePage={value => updatePage(value)}
                    updating={value => setIsUpdating(value)}
                  />
                  <PaginationSummary
                    dataTotal={creditPools.total ? parseInt(creditPools.total) : null}
                    dataStart={dataStart}
                    dataEnd={dataEnd}
                    dataType={'credit pools'}
                  />
                </div>
              </div>
              {/* Credit Pools */}
              {/* Header */}
              <div className='row list header border-bottom'>
                <div className='col-6 col-sm-3 col-lg-3'>Credits ID & Name</div>
                <div className='col-6 col-sm-3 col-lg-2'>Date Granted & Expiration</div>
                <div className='col-6 col-sm-3 col-lg-2'>Amt Granted</div>
                <div className='col-6 col-sm-3 col-lg-2'>Amt Remaining</div>
                <div className='col-6 col-sm-3 col-lg-1'>Type</div>
              </div>
              {isUpdating ? (
                <LoadingBlock />
              ) : (
                <React.Fragment>
                  {error ? (
                    <QErrorMessage className='row p0' text={<span className='bold'>Error {error.message}</span>} />
                  ) : (
                    <React.Fragment>
                      {creditPools.credit_pools.length > 0 ? (
                        <div className='row'>
                          <div className='col-12'>{credit_pools}</div>
                        </div>
                      ) : (
                        <div className='row'>
                          <div className='col-12 py-2'>No credit pools found.</div>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CreditPools;
