import React from 'react';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import { handleInputChange } from '../../../hoc/FormFunctions';
import QFormWrapper from '../../form/QFormWrapper';
import QFormGroup from '../../form/QFormGroup';
import QButton from '../../button/Button';
import { QErrorMessage } from '../../notifications/Notifications';
import { LoadingBlock } from '../../loading/Loading';

const CancelProblemModal = props => {
  const validationSchema = yup.object().shape({
    confirmation: yup.string().matches('CANCEL').required('This field is required'),
  });

  return (
    <Modal
      show={props.cancelProblemModalOpen}
      onHide={() => props.toggleCancelProblemModal(props.cancelProblemObj)}
      backdrop='static'
      centered
      size='lg'
      id='CancelProblemModal'
      dialogClassName='q-modal-component'>
      <Modal.Body>
        <div className='q-modal-body'>
          <i className='icon-close' onClick={() => props.toggleCancelProblemModal(props.cancelProblemObj)} />
          {props.cancelling ? (
            <LoadingBlock text='Please be patient...' className='transparent-bg' />
          ) : (
            <React.Fragment>
              <h1>Cancel Problem</h1>
              <p>
                Are you sure you want to cancel problem handle:{' '}
                <span className='bold'>{props.cancelProblemObj.problem_handle}</span>?<br />
                <span className='danger'>This action cannot be undone.</span>
              </p>
              {props.cancelProblemError ? (
                <QErrorMessage
                  className='row mx-0 mb-3 p0'
                  text={
                    <span className='bold'>
                      Uh oh—something went wrong. Please try again later.
                      <br />
                      {props.cancelProblemError.message}
                    </span>
                  }
                />
              ) : (
                ''
              )}
              <QFormWrapper
                formId='CancelProblemForm'
                initialValues={{
                  confirmation: '',
                }}
                initialErrors={{
                  confirmation: true,
                }}
                validationSchema={validationSchema}
                onSubmit={() =>
                  props.cancelProblem(props.cancelProblemObj.problem_handle, props.cancelProblemObj.project_id)
                }>
                {formProps => <CancelProblemForm {...formProps} />}
              </QFormWrapper>
              <QButton
                className='secondary mt-4'
                onClick={() => props.toggleCancelProblemModal(props.cancelProblemObj)}>
                Nevermind
              </QButton>
            </React.Fragment>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const CancelProblemForm = props => {
  return (
    <React.Fragment>
      <div className='confirmation'>
        <p className='bold'>Type CANCEL to proceed.</p>
        <div className='row'>
          <QFormGroup
            containerClassName='col-12 col-md-8'
            element='text'
            id='confirmation'
            name='confirmation'
            label='Type Cancel'
            onChange={e => handleInputChange(e, props, 'confirmation', () => {})}
            type='text'
            includeError={true}
          />
        </div>
      </div>
      <QButton type='submit' className={props.isValid ? 'btn-danger' : 'disabled'}>
        Cancel Problem
      </QButton>
    </React.Fragment>
  );
};

CancelProblemModal.propTypes = {
  cancelProblemObj: PropTypes.object.isRequired,
  cancelProblem: PropTypes.func.isRequired,
  cancelProblemError: PropTypes.any,
  toggleCancelProblemModal: PropTypes.func.isRequired,
  cancelling: PropTypes.bool.isRequired,
};

export default CancelProblemModal;
