import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useSubmitData } from '../../../hoc/SubmitData';
import InlineFormGroup from '../../../components/form/InlineFormGroup';
import { LoadingBlock } from '../../../components/loading/Loading';
import QFormWrapper from '../../../components/form/QFormWrapper';
import { SubmitGroupLabel, SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';
import { pythonifySolver } from '../../../hoc/submitDataFunctions';
import ShowInputs from '../components/ShowInputs';
import {
  buildValidationObj,
  getValidationKeys,
  getInitialFormValues,
  getSelectMenu,
  buildErrorMessage,
  validateParam,
  buildParamKeys,
} from '../functions/SubmitFormFunctions';
import { formatValToJS, formatSolverName } from '../functions/SubmitFunctions';
import { defaultBasis } from '../constants/SubmitConst';
import DFTGrids from './DFTGrids';
import BasisSet from '../components/BasisSet';

const SubmitGeoOpt = props => {
  // Init
  const { solverConfigs, defaultParams } = useSubmitData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationObj, setValidationObj] = useState({});
  const [validationKeys, setValidationKeys] = useState([]);
  const timers = useRef([]);
  // Edit Params
  const [pipeline, setPipeline] = useState([]);
  const [basis, setBasis] = useState(props.basis);
  const blacklist = ['solver', 'td'];
  // Refs for form validation
  const [formLoaded, setFormLoaded] = useState(false);
  const formRef = useRef(null);

  const geoopt_solver_name = props.solverPipeline[0] ? props.solverPipeline[0].solver_name : '';

  const geoopt_validation_items = {
    max_iter__0: yup.string().test(
      'max_iter',
      value => buildErrorMessage(value.value, 'max_iter', geoopt_solver_name, solverConfigs),
      value => validateParam(value, 'max_iter', geoopt_solver_name, solverConfigs)
    ),
    energy_max_change__0: yup.string().test(
      'energy_max_change',
      value => buildErrorMessage(value.value, 'energy_max_change', geoopt_solver_name, solverConfigs),
      value => validateParam(value, 'energy_max_change', geoopt_solver_name, solverConfigs)
    ),
    gradient_max__0: yup.string().test(
      'gradient_max',
      value => buildErrorMessage(value.value, 'gradient_max', geoopt_solver_name, solverConfigs),
      value => validateParam(value, 'gradient_max', geoopt_solver_name, solverConfigs)
    ),
    gradient_rms_0: yup.string().test(
      'gradient_rms',
      value => buildErrorMessage(value.value, 'gradient_rms', geoopt_solver_name, solverConfigs),
      value => validateParam(value, 'gradient_rms', geoopt_solver_name, solverConfigs)
    ),
    displacement_max__0: yup.string().test(
      'displacement_max',
      value => buildErrorMessage(value.value, 'displacement_max', geoopt_solver_name, solverConfigs),
      value => validateParam(value, 'displacement_max', geoopt_solver_name, solverConfigs)
    ),
    displacement_rms__0: yup.string().test(
      'displacement_rms',
      value => buildErrorMessage(value.value, 'displacement_rms', geoopt_solver_name, solverConfigs),
      value => validateParam(value, 'displacement_rms', geoopt_solver_name, solverConfigs)
    ),
    constraints__0: yup.string().test(
      'constraints',
      value => buildErrorMessage(value.value, 'constraints', geoopt_solver_name, solverConfigs),
      value => validateParam(value, 'constraints', geoopt_solver_name, solverConfigs)
    ),
  };

  function tdSolverValidation(pip) {
    const td_solver_name = 'LinearResponse';
    if (pip[4]) {
      const td_validation_items = {
        demin__4: yup.string().test(
          'demin',
          value => buildErrorMessage(value.value, 'demin', td_solver_name, solverConfigs),
          value => validateParam(value, 'demin', td_solver_name, solverConfigs)
        ),
        max_cycle__4: yup.string().test(
          'max_cycle',
          value => buildErrorMessage(value.value, 'max_cycle', td_solver_name, solverConfigs),
          value => validateParam(value, 'max_cycle', td_solver_name, solverConfigs)
        ),
        max_space__4: yup.string().test(
          'max_space',
          value => buildErrorMessage(value.value, 'max_space', td_solver_name, solverConfigs),
          value => validateParam(value, 'max_space', td_solver_name, solverConfigs)
        ),
        nroots__4: yup.string().test(
          'nroots',
          value => buildErrorMessage(value.value, 'nroots', td_solver_name, solverConfigs),
          value => validateParam(value, 'nroots', td_solver_name, solverConfigs)
        ),
      };

      return td_validation_items;
    } else return {};
  }

  function secondSolverValidation(pip) {
    if (pip[1] && pip[1].solver_name === 'DFT') {
      const dft_solver_name = pip[1] ? pip[1].solver_name : '';
      const grids_solver_name = pip[2] ? pip[2].solver_name : '';
      const nlcgrids_solver_name = pip[3] ? pip[3].solver_name : '';

      const dft_validation_items = {
        diis_space__1: yup.string().test(
          'diis_space',
          value => buildErrorMessage(value.value, 'diis_space', dft_solver_name, solverConfigs),
          value => validateParam(value, 'diis_space', dft_solver_name, solverConfigs)
        ),
        diis_start__1: yup.string().test(
          'diis_start',
          value => buildErrorMessage(value.value, 'diis_start', dft_solver_name, solverConfigs),
          value => validateParam(value, 'diis_start', dft_solver_name, solverConfigs)
        ),
        omega__1: yup.string().test(
          'omega',
          value => buildErrorMessage(value.value, 'omega', dft_solver_name, solverConfigs),
          value => validateParam(value, 'omega', dft_solver_name, solverConfigs)
        ),
        scf_damping__1: yup.string().test(
          'scf_damping',
          value => buildErrorMessage(value.value, 'scf_damping', dft_solver_name, solverConfigs),
          value => validateParam(value, 'scf_damping', dft_solver_name, solverConfigs)
        ),
        scf_direct_tol__1: yup.string().test(
          'scf_direct_tol',
          value => buildErrorMessage(value.value, 'scf_direct_tol', dft_solver_name, solverConfigs),
          value => validateParam(value, 'scf_direct_tol', dft_solver_name, solverConfigs)
        ),
        scf_energy_tol__1: yup.string().test(
          'scf_energy_tol',
          value => buildErrorMessage(value.value, 'scf_energy_tol', dft_solver_name, solverConfigs),
          value => validateParam(value, 'scf_energy_tol', dft_solver_name, solverConfigs)
        ),
        scf_grad_tol__1: yup.string().test(
          'scf_grad_tol',
          value => buildErrorMessage(value.value, 'scf_grad_tol', dft_solver_name, solverConfigs),
          value => validateParam(value, 'scf_grad_tol', dft_solver_name, solverConfigs)
        ),
        scf_level_shift__1: yup.string().test(
          'scf_level_shift',
          value => buildErrorMessage(value.value, 'scf_level_shift', dft_solver_name, solverConfigs),
          value => validateParam(value, 'scf_level_shift', dft_solver_name, solverConfigs)
        ),
        scf_maxiter__1: yup.string().test(
          'scf_maxiter',
          value => buildErrorMessage(value.value, 'scf_maxiter', dft_solver_name, solverConfigs),
          value => validateParam(value, 'scf_maxiter', dft_solver_name, solverConfigs)
        ),
      };

      const grids_validation_items = {
        density_tol__2: yup.string().test(
          'density_tol',
          value => buildErrorMessage(value.value, 'density_tol', grids_solver_name, solverConfigs),
          value => validateParam(value, 'density_tol', grids_solver_name, solverConfigs)
        ),
        level__2: yup.string().test(
          'level',
          value => buildErrorMessage(value.value, 'level', grids_solver_name, solverConfigs),
          value => validateParam(value, 'level', grids_solver_name, solverConfigs)
        ),
        atom_grid__2: yup.string().test(
          'atom_grid',
          value => buildErrorMessage(value.value, 'atom_grid', grids_solver_name, solverConfigs),
          value => validateParam(value, 'atom_grid', grids_solver_name, solverConfigs)
        ),
      };

      const nlcgrids_validation_items = {
        density_tol__3: yup.string().test(
          'density_tol',
          value => buildErrorMessage(value.value, 'density_tol', nlcgrids_solver_name, solverConfigs),
          value => validateParam(value, 'density_tol', nlcgrids_solver_name, solverConfigs)
        ),
        level__3: yup.string().test(
          'level',
          value => buildErrorMessage(value.value, 'level', nlcgrids_solver_name, solverConfigs),
          value => validateParam(value, 'level', nlcgrids_solver_name, solverConfigs)
        ),
        atom_grid__3: yup.string().test(
          'atom_grid',
          value => buildErrorMessage(value.value, 'atom_grid', nlcgrids_solver_name, solverConfigs),
          value => validateParam(value, 'atom_grid', nlcgrids_solver_name, solverConfigs)
        ),
      };

      return {
        ...dft_validation_items,
        ...grids_validation_items,
        ...nlcgrids_validation_items,
        ...tdSolverValidation(pip),
      };
    } else return {};
  }

  function buildValidation(pip) {
    const validation_items = { ...geoopt_validation_items, ...secondSolverValidation(pip) };
    // Solver params
    const param_keys = buildParamKeys(pip);
    setValidationObj(buildValidationObj(validation_items, param_keys));
    setValidationKeys(getValidationKeys(validation_items, param_keys));
  }

  useEffect(() => {
    setPipeline(props.solverPipeline);

    if (solverConfigs !== null && defaultParams !== null && props.solverPipeline[0] && props.solverPipeline[0].params) {
      buildValidation(props.solverPipeline);
      setIsLoaded(true);
    }
  }, [props.solverPipeline, solverConfigs, defaultParams]);

  function checkFormValid() {
    // Delay to allow formRef validations to finish
    const t = setTimeout(() => {
      props.setParamsValid(formRef.current.isValid);
    }, 200);
    timers.current.push(t);
  }

  useEffect(() => {
    // Trigger form validations after MoleculeParamsForm element is loaded
    if (formRef && formRef.current !== null) {
      let fields = {};
      validationKeys.forEach(param => {
        fields[param] = true;
      });
      formRef.current.setTouched(fields);
      checkFormValid();
    }
  }, [formLoaded]);

  useEffect(() => {
    // Update paramsForm in parent component whenever form validity changes
    if (formRef.current) {
      checkFormValid();
    }
  }, [formRef.current]);

  useEffect(() => {
    // Component cleanup
    return () => {
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function handlePageLoad() {
    /*
    This function should be called at the end of the html elements
    load, as it looks for a specific element in the DOM and triggers
    a component state change
    */
    if (!formLoaded) {
      // Delay to allow html elements to load
      const timer = setTimeout(() => {
        const el = document.getElementById('GeoOptForm');
        if (el !== undefined && el !== null) {
          setFormLoaded(true);
        }
      }, 1000);
      timers.current.push(timer);
    }
  }

  const validationSchema = yup.object().shape(validationObj);

  function handleNextSolverChange(i, value) {
    setIsLoaded(false);
    // Modify pipeline
    let pipeline_mod = structuredClone(pipeline);
    pipeline_mod[i] = defaultParams[value];
    // Convert js values to python
    pipeline_mod[i] = pythonifySolver(solverConfigs, pipeline_mod[i]);

    // Add "Grids" and "NLCGrids" to pipeline if DFT selected, otherwise remove the third solver element in the pipeline (which === Grids)
    if (value === 'DFT') {
      let grids = defaultParams.Grids;
      let nlcgrids = defaultParams.NLCGrids;
      // Convert js values to python
      grids = pythonifySolver(solverConfigs, grids);
      nlcgrids = pythonifySolver(solverConfigs, nlcgrids);
      pipeline_mod.push(grids);
      pipeline_mod.push(nlcgrids);
    } else {
      pipeline_mod.splice(2, pipeline_mod.length - 1);
    }
    // Update parent solver pipeline
    props.updatePipeline(pipeline_mod);
  }

  function showSolverParams(formProps) {
    return (
      <React.Fragment>
        {Array.from(pipeline).map((solver, index) => {
          return (
            <React.Fragment key={index}>
              {index === 0 ? (
                <React.Fragment>
                  <SubmitInputGroup>
                    <SubmitGroupLabel className='w-100' text={`${formatSolverName(solver.solver_name)} Parameters`} />
                    {/* Inputs */}
                    {ShowInputs(
                      solver,
                      index,
                      formProps,
                      solverConfigs,
                      blacklist,
                      validationKeys,
                      pipeline,
                      pipeline_mod => props.updatePipeline(pipeline_mod)
                    )}
                  </SubmitInputGroup>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* Next Solvers (ANI / DFT) */}
                  {index === 1 ? (
                    <SubmitInputGroup>
                      <InlineFormGroup
                        element='select'
                        id='next-solver-input'
                        className='w-100 dark'
                        containerClassName='w-100'
                        data-testid='next-solver-input'
                        label='Choose Next Solver'
                        value={solver.solver_name}
                        onChange={e => handleNextSolverChange(index, e.target.value, formProps)}
                        selectMenu={getSelectMenu(solverConfigs, pipeline[index - 1].solver_name, 'solver')}
                        includeError={false}
                      />
                      {/* Inputs */}
                      <SubmitGroupLabel className='w-100' text={`${formatSolverName(solver.solver_name)} Parameters`} />
                      {pipeline[1].solver_name === 'DFT' ? (
                        <React.Fragment>
                          {/* Basis */}
                          <BasisSet
                            solverName={solver.solver_name}
                            basisSelected={basis}
                            updateBasisSet={val => {
                              setBasis(val);
                              props.updateMoldata([['basis', val]]);
                            }}
                          />
                        </React.Fragment>
                      ) : (
                        ''
                      )}
                      {ShowInputs(
                        solver,
                        index,
                        formProps,
                        solverConfigs,
                        blacklist,
                        validationKeys,
                        pipeline,
                        pipeline_mod => {
                          let pip = structuredClone(pipeline_mod);
                          // Show TD Params
                          if (
                            !pipeline[4] &&
                            pip[1].solver_name === 'DFT' &&
                            pip[1].params &&
                            pip[1].params.calc_type &&
                            pip[1].params.calc_type === 'EXCITED_STATE'
                          ) {
                            const td = pythonifySolver(solverConfigs, {
                              solver_name: 'LinearResponse',
                              params: solverConfigs.LinearResponse.solver_parameters.solver_params,
                            });
                            pip[4] = { solver_name: 'LinearResponse', params: td.params };
                            const params = Object.entries(pip[4].params);
                            pip[1].params.td = {};
                            // Apply params to DTF.td object
                            params.forEach(([key, val]) => {
                              pip[1].params.td[key] = formatValToJS([key, val], solverConfigs, 'LinearResponse');
                            });
                            setIsLoaded(false);
                          }
                          // Hide TD Params
                          else if (
                            pipeline[4] &&
                            pip[1].solver_name === 'DFT' &&
                            pip[1].params &&
                            pip[1].params.calc_type &&
                            pip[1].params.calc_type !== 'EXCITED_STATE'
                          ) {
                            pip.pop();
                            pip[1].params.td = 'None';
                            setIsLoaded(false);
                          }
                          setPipeline(pip);
                          props.updatePipeline(pip);
                        }
                      )}
                      {/* TD */}
                      {pipeline[4] ? (
                        <div className='container px-0 mb-3 mx-0'>
                          <SubmitGroupLabel className='w-100 mt-3' text='TD Parameters' />
                          {/* Solver Params */}
                          {ShowInputs(
                            pythonifySolver(solverConfigs, pipeline[4]),
                            4,
                            formProps,
                            solverConfigs,
                            [],
                            validationKeys,
                            pipeline,
                            pip_mod => {
                              let pip = structuredClone(pip_mod);
                              const params = Object.entries(pip_mod[4].params) || [];
                              // Apply params to DTF.td object
                              params.forEach(([key, val]) => {
                                pip[1].params.td[key] = formatValToJS([key, val], solverConfigs, 'LinearResponse');
                              });
                              setPipeline(pip);
                              props.updatePipeline(pip);
                            }
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </SubmitInputGroup>
                  ) : (
                    <React.Fragment>
                      {/* DFT Grids */}
                      {['Grids', 'NLCGrids'].includes(solver.solver_name) && (
                        <DFTGrids
                          paramKey={solver.solver_name}
                          solverConfigs={solverConfigs}
                          solverObj={solver}
                          updatePipeline={pipeline_mod => {
                            setPipeline(pipeline_mod);
                            props.updatePipeline(pipeline_mod);
                          }}
                          formProps={formProps}
                          validationKeys={validationKeys}
                          solverIndex={index}
                          pipeline={pipeline}
                        />
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })}
        <div className='w-100 mb-3'>
          <ResetDropdown
            resetChanges={() => {
              formProps.resetForm();
              setBasis(defaultBasis);
              props.resetPipeline();
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div id='SubmitGeoOpt'>
      {isLoaded && pipeline[0] ? (
        <QFormWrapper
          innerRef={formRef}
          formId='GeoOptForm'
          validationSchema={validationSchema}
          initialValues={getInitialFormValues(pipeline, validationKeys, solverConfigs)}
          onSubmit={() => {}}>
          {formProps => {
            return (
              <React.Fragment>
                {showSolverParams(formProps)}
                {handlePageLoad()}
              </React.Fragment>
            );
          }}
        </QFormWrapper>
      ) : (
        <LoadingBlock text='Loading paramaters...' className='mb-3' />
      )}
    </div>
  );
};

SubmitGeoOpt.propTypes = {
  currentOrg: PropTypes.object,
  solverPipeline: PropTypes.array.isRequired,
  updatePipeline: PropTypes.func.isRequired,
  resetPipeline: PropTypes.func.isRequired,
  updateMoldata: PropTypes.func.isRequired,
  basis: PropTypes.string.isRequired,
};

export default SubmitGeoOpt;
