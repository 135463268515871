import React, { useState, useEffect } from 'react';

const PromoClosed = () => {
  // Init
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => setIsLoaded(true), 500);

    return () => {
      setIsLoaded(false);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id='PromoClosed'>
      {isLoaded ? (
        <React.Fragment>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 col-8-sm col-6-lg d-flex flex-column p-5 content-area'>
                <img src='/public/img/gc-logo.svg' className='logo' />
                <h1 className='text-center'>The promotion is now closed.</h1>
                <div className='notification static d-flex w-100 justify-content-center text-center mt-2 mb-3'>
                  Thank you for your interest in QEMIST Cloud. Please reach out to us directly to chat about free demo
                  credits and how QEMIST Cloud might benefit you and/or your team.
                </div>
                <div className='button-container d-flex align-items-center mt-3'>
                  <a className='btn mx-2' href='https://goodchemistry.com/qemist-cloud-sign-up/'>
                    Contact Us
                  </a>
                  <a className='btn secondary mx-2' href='/'>
                    Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

export default PromoClosed;
