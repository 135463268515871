import mixpanel from 'mixpanel-browser';

// Tracking is off unless a mixpanel token is provided
// AND ENVIRON in webpack is anything but 'local'
export const trackingOn = MIXPANEL_TOKEN && ENVIRON !== 'local';

export function trackAnalytics(method, name, body = null) {
  if (trackingOn) {
    // console.log('Analytics ON');
    mixpanel[method](name, body);
  } else {
    console.log(`Analytics OFF: '${method}', '${name}', '${body}'`);
  }
}

export function listenForElement(root_el, target_el, return_callback) {
  /*
  Check if element exists in the dom.
  
  root_el: id for wrapper element
  target_el: element to listen for that is a child element of root_el
  */
  const target_element = document.querySelector(target_el);
  if (target_element !== null) {
    // Trigger callback when target element is found in the dom
    return_callback();
  } else {
    // Add event listener for target element
    const root_element = document.getElementById(root_el);
    if (root_element) {
      const callback = function (mutations_list, observer) {
        for (const mutation of mutations_list) {
          if (mutation.target === document.querySelector(target_el)) {
            observer.disconnect();
            // Trigger callback when target element is found in the dom
            return_callback();
          }
        }
      };

      const observer = new MutationObserver(callback);
      const config = { attributes: false, childList: true, subtree: true };
      observer.observe(root_element, config);
    } else {
      console.log(`Root element not found: ${root_el}`);
    }
  }
}
