import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../hoc/Auth';
import { useUserData } from '../../hoc/UserData';
// prettier-ignore
import { formatCurrency, formatProjectName, formatStatus, convertTime, convertTimeSpan, downloadFile } from '../../hoc/CommonFunctions';
import QButton from '../button/Button';
import { LoadingFilled, LoadingInline } from '../loading/Loading';
import { visualizeMolecule, visualizeSingleMolecule, visualizeSmileStr, formatAtomString } from './visualizeMolecule';
import { showBadge } from './ProblemsCommonFunctions';
import CancelProblemModal from './cancelProblem/CancelProblemModal';
import ProblemName from './problemName/ProblemName';
import ProblemNote from './problemNote/ProblemNote';
import CodeBlock from '../codeBlock/CodeBlock';
import { QErrorMessage } from '../notifications/Notifications';
import { QModalText } from '../modal/Modal';
import RefreshButton from '../refreshButton/RefreshButton';
import MIProblem from './problemType/MIProblem';
import MLProblem from './problemType/MLProblem';
import DFTProblem from './problemType/DFTProblem';
import QuantumCircuitProblem from './problemType/QuantumCircuitProblem';
import GOProblem from './problemType/GOProblem';
import CSPProblem from './problemType/CSPProblem';
import SelfConsistentProblem from './problemType/SelfConsistentProblem';

const SingleProblem = props => {
  // Init
  const { fetchData, fetchResults } = useAuth();
  const { setRefreshProblems, setUpdatingBalance, solverConstants } = useUserData();
  const [timers, setTimers] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Get problem
  const [fetching, setFetching] = useState(true);
  const [problem, setProblem] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [problemError, setProblemError] = useState(null);
  const [fetchingErrorMessage, setFetchingErrorMessage] = useState(null);
  const [problemType, setProblemType] = useState('');
  const [subproblems, setSubproblems] = useState({});
  const [fetchingSubproblems, setFetchingSubproblems] = useState(false);
  const [fetchSubproblemsError, setFetchSubproblemsError] = useState(null);
  // Cancel Problem
  const [cancelling, setCancelling] = useState(false);
  const [cancelProblemDetails, setCancelProblemDetails] = useState({ problem_handle: '', project_id: '' });
  const [problemCancelled, setProblemCancelled] = useState(false);
  const [cancelProblemModalOpen, setCancelProblemModalOpen] = useState(false);
  const [cancelProblemError, setCancelProblemError] = useState(null);
  // Input data
  const [inputData, setInputData] = useState(null);
  const [downloadInputData, setDownloadInputData] = useState({});
  // Results data
  const [resultsData, setResultsData] = useState(null);
  const [downloadResultsData, setDownloadResultsData] = useState({});
  const [resultError, setResultError] = useState(null);
  const [fetchingResults, setFetchingResults] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [fetchingAttachments, setFetchingAttachments] = useState(false);
  const [attachmentError, setAttachmentError] = useState(null);
  // Enlarge molecule
  const [enlargeMolecule, setEnlargeMolecule] = useState(false);
  // Refresh problem
  const [isRefreshing, setIsRefreshing] = useState(false);
  // Attachements
  const [attachmentsModal, setAttachmentsModal] = useState(false);

  function fetchProblem() {
    // prettier-ignore
    fetchData(signal, `/api/get-single-problem?problem_handle=${props.problemHandle}&project_id=${props.projectId}`, {}, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setFetchError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const p = response.data;
        const problem_type = showBadge(p);
        setProblemType(problem_type);
        setProblem(p);

        // Declare input data
        setDownloadInputData({
          problem_handle: p.problem_handle,
          input: { fragments: p.fragment_data, solver_parameters: p.solver_parameters },
        });
      }
      setIsRefreshing(false);
    });
  }

  function fetchSubproblems() {
    setFetchSubproblemsError(null);

    // Fetch subproblems if MI problem
    if (['iFCI', 'MI-FNO'].includes(problemType)) {
      setFetchingSubproblems(true);

      fetchData(
        signal,
        `/api/get-subproblems?problem_handle=${props.problemHandle}&project_id=${props.projectId}`,
        problem,
        'POST',
        response => {
          // if error is returned
          if (response.status === 'error') {
            setFetchSubproblemsError(response.error);
          }
          // if data is returned
          if (response.status === 'success') {
            setSubproblems(response.data);
          }
          setFetchingSubproblems(false);
        }
      );
    }
  }

  function fetchAttachments() {
    setFetchingAttachments(true);
    setAttachmentError(null);

    // Check for attachments in with presigned urls
    let url = `/api/get-presigned-urls?handle=${problem.problem_handle}&project_id=${problem.project_id}`;

    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setAttachmentError(response.error);
        setFetchingAttachments(false);
      }
      // if data is returned
      if (response.status === 'success') {
        if (response.data && response.data[problem.problem_handle]) {
          const atchmts = Object.entries(response.data[problem.problem_handle]);
          setAttachments(atchmts);
        }
        setFetchingAttachments(false);
      }
    });
  }

  function fetchProblemError() {
    fetchResults(signal, problem.problem_handle, props.projectId, response => {
      // if error is returned
      if (response.status === 'error') {
        setProblemError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        if (response.data.result) {
          const err = response.data.result;
          const err_type = err.error_type;
          const err_msg = err.error_message;
          // Return formatted error message if error_message key found in result data
          if (err_msg) {
            setProblemError({ message: `${err_type ? `${err_type}: ` : ''}` + err_msg });
          }
          // Otherwise return fully stringified result data
          else {
            setProblemError({ messaage: JSON.stringify(response.data.result) });
          }
        } else {
          setProblemError({ message: 'Data parsing error' });
        }
      }
      setFetchingErrorMessage(false);
    });
  }

  useEffect(() => {
    if (problem !== null) {
      setFetching(false);
      fetchSubproblems();
      if (firstRender) {
        // Show error message if problem_status = error
        if (problem.problem_status === 'error') {
          setFetchingErrorMessage(true);
          fetchProblemError();
        } else {
          if (['complete', 'in_progress', 'cancelled'].includes(problem.problem_status)) {
            fetchAttachments();
          }
        }
        setFirstRender(false);
      }
    } else {
      fetchProblem();
    }

    // Delay molecule visualization to allow element to render first
    var vis_mol = setTimeout(() => {
      if (problem && problem.fragment_data && problem.fragment_data.smiles_str) {
        visualizeSmileStr(problem.fragment_data.smiles_str);
      } else {
        visualizeMolecule([problem]);
      }
    }, 500);
    timers.push(vis_mol);

    return () => {
      setCancelling(false);
      setInputData(null);
      setAttachmentError(null);
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, [props.problemHandle, problem]);

  function toggleCancelProblemModal(problem_details) {
    setCancelProblemDetails(problem_details);
    setCancelProblemModalOpen(!cancelProblemModalOpen);
  }

  function cancelProblem(problem_handle, project_id) {
    setCancelling(true);

    const body = {
      project_id: project_id,
      problem_handle: problem_handle,
    };
    fetchData(signal, '/api/cancel-problem', body, 'PUT', response => {
      // if error is returned
      if (response.status === 'error') {
        setCancelling(false);
        setCancelProblemError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setProblemCancelled(true);
        toggleCancelProblemModal(cancelProblemDetails);
        setTimeout(() => setCancelling(false), 500);
        setUpdatingBalance(true);
      }
    });
  }

  function getResult(problem_handle, download = false) {
    setResultError(null);
    setFetchingResults(true);
    fetchResults(signal, problem_handle, props.projectId, response => {
      // if error is returned
      if (response.status === 'error') {
        setResultError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const data = response.data;

        if (data.result) {
          const result_data = {
            problem_handle: data.problem_handle,
            result: data.result,
          };
          setDownloadResultsData(result_data);
          if (download) {
            downloadFile(result_data, `${data.problem_handle}_result`, 'json');
          } else {
            setResultsData({
              title: 'Results',
              problem_handle: data.problem_handle,
              name: 'result',
              data: data,
            });
          }
        }
      }
      setFetchingResults(false);
    });
  }

  function toggleEnlargeMolecule(mol_type) {
    setEnlargeMolecule(!enlargeMolecule);
    if (mol_type === 'xyz') {
      const id = `${problem.problem_handle}_enlarged`;
      const mol_vis = setTimeout(() => visualizeSingleMolecule(problem, id), 500);
      timers.push(mol_vis);
    } else if (mol_type === 'smiles') {
      const mol_vis = setTimeout(() => {
        const mol_vis = setTimeout(
          () => visualizeSmileStr(problem.fragment_data.smiles_str, 'smiles-container_enlarged'),
          500
        );
        timers.push(mol_vis);
      }, 500);
      timers.push(mol_vis);
    }
  }

  function refreshProblem(flash_problems = false) {
    if (flash_problems) {
      setIsRefreshing(true);
    }
    fetchProblem();
    fetchSubproblems();
  }

  const show_s3_links = arr =>
    Array.from(arr).map(([name, link], index) => (
      <div key={index} className='d-flex align-items-center'>
        <i className='icon-documentation mx-2' />
        <a className='text-xs bold link mr-2' href={link} target='_blank'>
          {name}
        </a>
      </div>
    ));

  return (
    <div id='SingleProblemComponent'>
      {fetchError ? (
        <QErrorMessage
          className='row m-3'
          text={<span className='bold'>GET PROBLEM ERROR: {fetchError.message}</span>}
        />
      ) : (
        <React.Fragment>
          {fetching ? (
            <LoadingFilled />
          ) : (
            <React.Fragment>
              {/* BASIC INFO */}
              <div
                className={`d-flex w-100 container-fluid section basic-info ${
                  ['ready', 'in_progress'].includes(problem.problem_status) ? 'active' : ''
                }`}>
                {/* Info */}
                <div className='info-wrapper w-100'>
                  {problem.problem_handle && (
                    <div className='d-flex align-items-center justify-content-between py-2'>
                      <ProblemName
                        problemHandle={problem.problem_handle}
                        problemName={problem.problem_name}
                        projectId={problem.project_id}
                        fetchProblems={() => {
                          fetchProblem();
                          setRefreshProblems(true);
                        }}
                      />
                      <RefreshButton
                        isRefreshing={isRefreshing}
                        dataLoaded={false}
                        refresh={flash_problems => refreshProblem(flash_problems)}
                      />
                    </div>
                  )}
                  {/* Type */}
                  <div className='d-flex align-items-center problem-type'>
                    <div className='badge orange-badge mb-2'>{showBadge(problem)}</div>
                  </div>
                  {/* Handle / Cost / User */}
                  <div className='d-flex align-items-center problem-basic-info-group mb-2'>
                    {/* Problem handle */}
                    {problem.problem_handle && (
                      <div className='d-flex align-self-center align-items-center problem-handle mr-3'>
                        <i className='icon-id' />
                        <span className='text-xxs ml-1' data-testid='problem-handle'>
                          {problem.problem_handle}
                        </span>
                      </div>
                    )}
                    {/* Cost */}
                    <div className='d-flex align-self-center align-items-center problem-cost mr-3'>
                      <i className='icon-money' />
                      <span className='text-xxs ml-1' data-testid='cost'>
                        {formatCurrency(problem.cost)}
                      </span>
                    </div>
                    {/* User email */}
                    <div className='d-flex align-self-center align-items-center problem-cost mr-3'>
                      <i className='icon-profile' />
                      <span className='text-xxs ml-1' data-testid='user-email'>
                        {problem.user_email}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* NOTE */}
              <div className='d-flex container-fluid section problem-note'>
                <ProblemNote
                  problem={problem}
                  fetchProblems={() => fetchProblem()}
                  setProblemError={error => setProblemError(error)}
                  className='my-2'
                />
              </div>

              {/* PROBLEM ERROR MESSAGE */}
              {fetchingErrorMessage ? (
                <div className='d-flex px-3 py-2 bg-grey'>
                  <LoadingInline size='sm' />
                </div>
              ) : (
                <React.Fragment>
                  {problemError ? (
                    <div className='container-fluid bg-grey py-3'>
                      <QErrorMessage className='row mx-0' text={<span className='bold'>{problemError.message}</span>} />
                    </div>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              )}

              {/* PROJECT DETAILS */}
              <div className='d-flex container-fluid section project-details grey-bg py-2'>
                <i className='d-flex align-self-center icon-projects mr-2' />
                <div className='d-flex align-self-center text-xs'>
                  {formatProjectName(problem.project_name)} • {problem.project_id}
                </div>
              </div>

              {/* PROBLEM DETAILS */}
              <div className='d-flex container-fluid section problem-details p-0'>
                {/* Molecule visualization */}
                {problem.fragment_data && problem.fragment_data.mol_data && problem.fragment_data.mol_data.atom && (
                  <div className='molecule grey-bg'>
                    <div className='mol-wrapper'>
                      <div id={problem.problem_handle} className='preview mol-container'></div>
                      <QModalText
                        show={enlargeMolecule}
                        onHide={() => toggleEnlargeMolecule('xyz')}
                        size='xl'
                        id='LargeMoleculeModal'
                        text='Enlarge Molecule'
                        textclassname='text-xs bold p-3'>
                        <div id={`${problem.problem_handle}_enlarged`} className='enlarged-mol-container'></div>
                      </QModalText>
                    </div>
                  </div>
                )}
                {/* Smile String Visualization */}
                {problem.fragment_data && problem.fragment_data.smiles_str && (
                  <div className='molecule grey-bg'>
                    <div className='mol-wrapper'>
                      <div id='smiles-container'></div>
                      <QModalText
                        show={enlargeMolecule}
                        onHide={() => toggleEnlargeMolecule('smiles')}
                        size='xl'
                        id='LargeMoleculeModal'
                        className='smiles'
                        text='Enlarge'
                        textclassname='text-xs bold p-3'>
                        <div
                          id='smiles-container_enlarged'
                          className='enlarged-mol-container d-flex align-items-center justify-content-center'></div>
                      </QModalText>
                    </div>
                  </div>
                )}
                {/* Problem details */}
                <div className='d-flex flex-column pt-2 problem-details-content'>
                  <div className='border-bottom px-3 pb-3 problem-common-details'>
                    {/* Submit time */}
                    {problem.submit_time && (
                      <p className='submit-time'>
                        <span className='label-sm'>Submit Time: </span>
                        <span className='text-xs'>{convertTime(problem.submit_time)}</span>
                      </p>
                    )}
                    {/* Status */}
                    {problem.problem_status && (
                      <div className='status'>
                        <span className='label-sm'>Status: </span>
                        <span
                          className={`text-xs bold ${
                            problem.problem_status.includes('complete')
                              ? 'good'
                              : problem.problem_status.includes('ready') ||
                                problem.problem_status.includes('in_progress')
                              ? 'pending'
                              : 'danger'
                          }`}>
                          {!problemCancelled ? (
                            <span>{formatStatus(problem.problem_status)}</span>
                          ) : (
                            <span className='text-xs'>Problem cancelled.</span>
                          )}
                        </span>
                        {problem.complete_time ? (
                          <span className='text-xs'> - {convertTime(problem.complete_time)}</span>
                        ) : (
                          ''
                        )}
                        {/* Cancel problem */}
                        {['in_progress', 'ready'].includes(problem.problem_status) && (
                          <div className='cancel ml-2'>
                            {!problemCancelled && (
                              <QButton
                                className='xxsmall btn-danger'
                                onClick={() =>
                                  toggleCancelProblemModal({
                                    problem_handle: problem.problem_handle,
                                    project_id: problem.project_id,
                                  })
                                }>
                                Cancel Problem
                              </QButton>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {/* Solve time */}
                    {!['in_progress', 'ready'].includes(problem.problem_status) &&
                      problem.fragment_type !== 'QuantumCircuit' && (
                        <p className='solve-time'>
                          <span className='label-sm'>Solve Time: </span>
                          {problem.solve_time !== null ? (
                            <span className='text-xs good bold'>{convertTimeSpan(problem.solve_time)}</span>
                          ) : (
                            '-'
                          )}
                        </p>
                      )}
                    {/* Start time */}
                    {problem.start_time && (
                      <p className='start-time'>
                        <span className='label-sm'>Start Time: </span>
                        <span className='text-xs'>{convertTime(problem.start_time)}</span>
                      </p>
                    )}
                    {/* Show input */}
                    {downloadInputData && (
                      <div className='problem-input'>
                        <span className='label-sm'>Input: </span>
                        {inputData ? (
                          <CodeBlock
                            data={inputData}
                            hideCodeBlock={() => setInputData(null)}
                            downloadData={downloadInputData}
                            downloadFilename={`${inputData.problem_handle}_${inputData.name}`}
                            actions={true}
                          />
                        ) : (
                          <span
                            className='text-xs bold link'
                            data-testid='show-input-link'
                            onClick={() => {
                              setInputData({
                                title: 'Input Data',
                                problem_handle: problem.problem_handle,
                                name: 'input',
                                data: downloadInputData,
                              });
                            }}>
                            Show Input
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className='px-3 pt-2 pb-2 border-bottom problem-specific-content'>
                    {/* Smiles String */}
                    {problem.fragment_data && problem.fragment_data.smiles_str && (
                      <div className='d-flex flex-wrap align-items-center smiles-str'>
                        <span className='label-sm mr-2 my-2'>SMILES String:</span>
                        <div className='mono'>
                          <pre className='mb-0'>{problem.fragment_data.smiles_str}</pre>
                        </div>
                      </div>
                    )}

                    {/* PROBLEM TYPE SPECIFIC */}
                    {/* GVB */}
                    {problem.solver_parameters && problem.solver_parameters.next_solver === 'GVB' && (
                      <SelfConsistentProblem
                        solverParameters={problem.solver_parameters}
                        solverConstants={solverConstants}
                        solverName='GVB'
                      />
                    )}
                    {/* HF */}
                    {problem.solver_parameters && problem.solver_parameters.next_solver === 'HartreeFock' && (
                      <SelfConsistentProblem
                        solverParameters={problem.solver_parameters}
                        solverConstants={solverConstants}
                        solverName='HartreeFock'
                      />
                    )}
                    {/* OrganicCrystals */}
                    {problem.fragment_type === 'organic_crystal' && (
                      <CSPProblem solverParameters={problem.solver_parameters} solverConstants={solverConstants} />
                    )}
                    {/* GeometryOptimizer */}
                    {problem.solver_parameters && problem.solver_parameters.next_solver === 'GeometryOptimizer' && (
                      <GOProblem solverParameters={problem.solver_parameters} solverConstants={solverConstants} />
                    )}
                    {/* ML */}
                    {problem.solver_parameters &&
                      problem.solver_parameters.next_solver &&
                      problem.solver_parameters.next_solver.includes('ANI') && (
                        <MLProblem
                          problemHandle={problem.problem_handle}
                          projectId={problem.project_id}
                          solverParameters={problem.solver_parameters}
                          solverConstants={solverConstants}
                        />
                      )}
                    {/* DFT */}
                    {problem.solver_parameters && problem.solver_parameters.next_solver === 'DFT' && (
                      <DFTProblem solverParameters={problem.solver_parameters} solverConstants={solverConstants} />
                    )}
                    {/* Quantum Circuit */}
                    {problem.fragment_type === 'QuantumCircuit' && (
                      <QuantumCircuitProblem
                        solver_parameters={problem.solver_parameters}
                        fragment_data={problem.fragment_data}
                      />
                    )}
                    {/* iFCI / MI-FNO */}
                    {problem.solver_parameters &&
                      problem.solver_parameters.next_solver === 'IncrementalDecomposition' &&
                      problem.solver_parameters.solver_params &&
                      problem.solver_parameters.solver_params.solver && (
                        <React.Fragment>
                          {problem.solver_parameters.solver_params.solver.next_solver === 'SNOGVBPP' && (
                            <MIProblem
                              subproblems={subproblems}
                              fetchingSubproblems={fetchingSubproblems}
                              fetchSubproblemsError={fetchSubproblemsError}
                              solver_parameters={problem.solver_parameters}
                              fragment_data={problem.fragment_data}
                              solverConstants={solverConstants}
                              problemType='iFCI'
                            />
                          )}
                          {problem.solver_parameters.solver_params.solver.next_solver === 'FNO' && (
                            <MIProblem
                              subproblems={subproblems}
                              fetchingSubproblems={fetchingSubproblems}
                              fetchSubproblemsError={fetchSubproblemsError}
                              solver_parameters={problem.solver_parameters}
                              fragment_data={problem.fragment_data}
                              solverConstants={solverConstants}
                              problemType='MI-FNO'
                            />
                          )}
                        </React.Fragment>
                      )}
                    {/* Show Attachments & Results if not error status */}
                    {problem.problem_status !== 'error' && (
                      <React.Fragment>
                        {/* Attachments */}
                        <div className='problem-attachments my-2'>
                          <div className='d-flex align-items-start'>
                            <span className='label-sm mr-1'>Attachments: </span>
                            {/* S3 Attachment Links */}
                            {fetchingAttachments ? (
                              <div className='d-flex align-items-center'>
                                <LoadingInline size='sm' className='d-inline-flex mx-2' />
                                <span className='text-xxs'>Checking for attachments</span>
                              </div>
                            ) : (
                              <div className='d-flex flex-column align-items-start'>
                                {attachmentError !== null ? (
                                  <span className='text-xxs danger'>{attachmentError.message}</span>
                                ) : (
                                  <React.Fragment>
                                    {attachments.length > 0 ? (
                                      <div className='d-flex flex-column align-items-start'>
                                        {show_s3_links(attachments.slice(0, 10))}
                                      </div>
                                    ) : (
                                      <span className='text-xxs px-2'>No attachments found</span>
                                    )}
                                    {attachments.length > 10 && (
                                      <QModalText
                                        show={attachmentsModal}
                                        onHide={() => setAttachmentsModal(!attachmentsModal)}
                                        size='lg'
                                        id='AttachmentsModal'
                                        text={`See all (${attachments.length})`}
                                        textclassname='bold text-xxs px-2 pt-2'>
                                        <div className='problem-attachments'>{show_s3_links(attachments)}</div>
                                      </QModalText>
                                    )}
                                  </React.Fragment>
                                )}
                                <button
                                  className='btn-link xmall py-2 d-flex align-items-center'
                                  onClick={() => fetchAttachments()}>
                                  <i className='icon-refresh mx-2' />
                                  <span className='text-xxs bold'>Refresh Attachments</span>
                                </button>
                                {attachments.length > 0 && (
                                  <p className='text-xs py-2'>
                                    Attachments may download to your device if the file is not supported by your
                                    browser. Refresh attachments if the link has expired.
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* Results */}
                        {problem.result_size && (
                          <div className='problem-results mb-2'>
                            <div className='d-flex align-items-center'>
                              <span className='label-sm mr-1'>Results: </span>
                              {fetchingResults ? (
                                <LoadingInline size='sm' />
                              ) : (
                                <React.Fragment>
                                  {problem.result_size > 5 && problem.result_size < 50000 ? (
                                    <React.Fragment>
                                      {!resultsData ? (
                                        <span
                                          className='text-xs bold link'
                                          data-testid='preview-results-link'
                                          onClick={() => getResult(problem.problem_handle)}>
                                          Get Results
                                        </span>
                                      ) : (
                                        <span
                                          className='text-xs bold link'
                                          data-testid='close-results-link'
                                          onClick={() => setResultsData(null)}>
                                          Close
                                        </span>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {/* Download large results from DB */}
                                      {problem.result_size >= 50000 ? (
                                        <span
                                          className='text-xs bold link'
                                          data-testid='preview-results-link'
                                          onClick={() => getResult(problem.problem_handle, true)}>
                                          Download Results
                                        </span>
                                      ) : (
                                        <span className='text-xxs'>No results found</span>
                                      )}
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                            {/* Results (Partial) Block */}
                            {resultsData ? (
                              <CodeBlock
                                data={resultsData}
                                hideCodeBlock={() => setResultsData(null)}
                                downloadData={downloadResultsData}
                                downloadFilename={`${resultsData.problem_handle}_${resultsData.name}`}
                                actions={true}
                                className='mt-2'
                              />
                            ) : (
                              ''
                            )}
                            {resultError && (
                              <QErrorMessage text={<span className='bold'>{resultError.message}</span>} />
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>

                  {/* Coordinates */}
                  {problem.fragment_data && problem.fragment_data.mol_data && problem.fragment_data.mol_data.atom && (
                    <div className='px-3 pt-2 border-bottom problem-coordinates'>
                      <div className='d-flex flex-wrap coordinates'>
                        <span className='label-sm mr-3 mb-3'>Coordinates:</span>
                        <div className='atom-string'>
                          <pre>{formatAtomString(problem.fragment_data.mol_data.atom)}</pre>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <CancelProblemModal
        cancelProblemObj={cancelProblemDetails}
        cancelProblemModalOpen={cancelProblemModalOpen}
        cancelProblemError={cancelProblemError}
        cancelProblem={(problem_handle, project_id) => cancelProblem(problem_handle, project_id)}
        toggleCancelProblemModal={() => toggleCancelProblemModal(cancelProblemDetails)}
        cancelling={cancelling}
      />
    </div>
  );
};

SingleProblem.propTypes = {
  problemHandle: PropTypes.string,
  projectId: PropTypes.string.isRequired,
};

export default SingleProblem;
