import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

// Import hooks & hoc
import { useAuth } from '../../../../hoc/Auth';
import { useUserData } from '../../../../hoc/UserData';
import { getPaginationSets } from '../../../../hoc/CommonFunctions';
import { handleSearchFormSubmit, resetSearchForm } from '../../../../hoc/FilterFuncs';
import { scrollToTop } from '../../../../hoc/ScrollFunctions';

// Import components
import SupportMessage from '../../../../components/support/SupportMessage';
import QButton from '../../../../components/button/Button';
import QModalBtn from '../../../../components/modal/Modal';
import SearchForm from '../../../../components/form/SearchForm';
import { LoadingBlock } from '../../../../components/loading/Loading';
import Pagination, { PaginationSummary } from '../../../../components/pagination/Pagination';
import AddMembersModal from '../../../projects/AddMembers';
import RemoveMemberModal from '../../../projects/RemoveMember';

const ProjectMembers = props => {
  // Init
  const { fetchData } = useAuth();
  const { user } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Group Members
  const [setLength, setSetLength] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(24);
  const [pages, setPages] = useState(1);
  const [members, setMembers] = useState({ total: '', users: '' });
  const [error, setError] = useState(null);
  // Add Members Modal
  const [addMembersModalOpen, setAddMembersModalOpen] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [addMembersError, setAddMembersError] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  // Remove Member Modal
  const [removeUser, setRemoveUser] = useState({ email: '', user_id: '' });
  const [removeMemberModalOpen, setRemoveMemberModalOpen] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [removeMemberError, setRemoveMemberError] = useState(false);
  const [self, setSelf] = useState(false);
  // Filter Members
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [query, setQuery] = useState('');

  let history = useHistory();

  function fetchMembersData(page) {
    let group_id = props.projectData._id;
    let url = '/api/get-group-members?group_id=' + group_id + '&page=' + page;

    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(error);
        setIsLoaded(true);
      }
      // if data is returned
      if (response.status === 'success') {
        setMembers(response.data);
        setFilteredMembers(response.data.users);
        setIsLoaded(true);
        scrollToTop();
        // Get pagination sets
        getPaginationSets(response.data.total, setLength, num => {
          setPages(num);
          setDataStart(page * setLength - setLength);
          setDataEnd(page * setLength - 1);
        });
      }
    });
  }

  useEffect(() => {
    fetchMembersData(currentPage);

    return () => {
      abortController.abort();
    };
  }, []);

  function addMembers(values) {
    let group_id = props.projectData._id;
    setAddMembersError(null);
    setLoadingMembers(true);

    const url = '/api/add-group-members';
    const body = {
      group_id: group_id,
      members: values.members,
    };

    fetchData(signal, url, body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingMembers(false);
        setAddMembersError(error);
      }
      // if data is returned
      if (response.status === 'success') {
        setLoadingMembers(false);
        if (response.data.user_not_found) {
          setUserNotFound(true);
        } else {
          toggleAddMembersModal();
        }
      }
    });
  }

  function toggleAddMembersModal() {
    setAddMembersModalOpen(!addMembersModalOpen);
    fetchMembersData(currentPage);
    setUserNotFound(false);
  }

  function removeMember(values) {
    let group_id = props.projectData._id;
    setRemoveMemberError(null);
    setRemoving(true);

    const url = '/api/remove-member';
    const body = {
      group_id: group_id,
      admin_group_id: '',
      user_id: values.user_id,
    };

    fetchData(signal, url, body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setRemoving(false);
        setRemoveMemberError(error);
      }
      // if data is returned
      if (response.status === 'success') {
        setRemoving(false);
        if (values.user_id === user.userid) {
          setRemoveSuccess(true);
          setTimeout(() => {
            toggleRemoveMemberModal(removeUser);
            setRemoveSuccess(false);
            history.push('/projects');
          }, 3000);
        } else {
          setRemoveSuccess(true);
          setTimeout(() => {
            toggleRemoveMemberModal(removeUser);
            setRemoveSuccess(false);
            fetchMembersData(currentPage);
          }, 3000);
        }
      }
    });
  }

  function toggleRemoveMemberModal(member) {
    if (member.user_id === user.userid) {
      setSelf(true);
    } else {
      setSelf(false);
    }
    setRemoveUser(member);
    setRemoveMemberModalOpen(!removeMemberModalOpen);
  }

  function updatePage(page) {
    setCurrentPage(page);
    fetchMembersData(page);
  }

  const users_list = Array.from(filteredMembers).map((member, index) => (
    <div key={index} className='user-row-container'>
      <div className='row list item user-details'>
        <div className='col-12 col-sm-6 col-lg-4 email'>
          <input value={member.email} data-testid='member-email-input' readOnly />
        </div>
        <div className='col-12 col-sm-6 col-lg-3 name'>
          <input value={member.name} data-testid='member-name-input' readOnly />
        </div>
        <div className='col-12 col-sm-6 col-lg-3 connection'>
          <input value={member.identities[0].connection} data-testid='member-connection-input' readOnly />
        </div>
        <div className='col-12 col-sm-6 col-lg-2 actions'>
          <QButton className='button btn-link' onClick={() => toggleRemoveMemberModal(member)}>
            {member.email === user.email ? 'Remove Myself' : 'Remove'}
          </QButton>
        </div>
      </div>
    </div>
  ));

  return (
    <div id='ProjectMembers' className='view-user container-fluid'>
      <div className='row'>
        <div className='col-12 pt-0 content-container'>
          {/* Users List */}
          <div className='row total'>
            <div className='col-12 text-xs mb-2'>
              <span className='left'>
                <div className='d-flex flex-wrap align-items-center data-sets-summary'>
                  <Pagination
                    pages={pages}
                    currentPage={currentPage}
                    updatePage={value => updatePage(value)}
                    updating={value => {
                      if (value === true) {
                        setIsLoaded(false);
                      } else {
                        setIsLoaded(true);
                      }
                    }}
                  />
                  <PaginationSummary
                    dataTotal={members.total ? parseInt(members.total) : null}
                    dataStart={dataStart}
                    dataEnd={dataEnd}
                    dataType={'members'}
                  />
                  <SearchForm
                    handleSubmit={event =>
                      handleSearchFormSubmit(event, members.users, 'email', query, list => setFilteredMembers(list))
                    }
                    resetForm={event =>
                      resetSearchForm(members.users, 'email', function (list) {
                        setQuery('');
                        setFilteredMembers(list);
                      })
                    }
                    updateQuery={event => setQuery(event.target.value)}
                    searchField='Emails'
                    query={query}
                  />
                </div>
              </span>
              <span className='right'>
                <QModalBtn
                  btnClassName='small'
                  buttonText='Add Members'
                  onHide={() => toggleAddMembersModal()}
                  show={addMembersModalOpen}
                  id='AddMembersModal'
                  size='lg'>
                  {loadingMembers ? (
                    <LoadingBlock text='Please be patient...' className='transparent-bg' />
                  ) : (
                    <AddMembersModal
                      projectName={props.projectName}
                      addMembersError={addMembersError}
                      addMembers={values => addMembers(values)}
                      userNotFound={userNotFound}
                      toggleAddMembersModal={() => toggleAddMembersModal()}
                    />
                  )}
                </QModalBtn>
              </span>
            </div>
          </div>
          <div className='row list header'>
            <div className='col-12 col-sm-6 col-lg-4 email'>User Email</div>
            <div className='col-12 col-sm-6 col-lg-3 name'>Name</div>
            <div className='col-12 col-sm-6 col-lg-3 connection'>Connection</div>
            <div className='col-12 col-sm-6 col-lg-2 actions'>Actions</div>
          </div>
          {!isLoaded ? (
            <LoadingBlock />
          ) : (
            <React.Fragment>
              {error ? (
                <SupportMessage errorClasses='row mx-0 my-3 p0' error={error.message} />
              ) : (
                <React.Fragment>
                  {users_list}
                  {/* Remove Member Modal */}
                  <Modal
                    show={removeMemberModalOpen}
                    onHide={() => toggleRemoveMemberModal(removeUser)}
                    backdrop='static'
                    centered
                    size='lg'
                    id='RemoveMemberModal'
                    className='q-modal-component'>
                    <Modal.Body>
                      <div className='q-modal-body'>
                        <i className='icon-close' onClick={() => toggleRemoveMemberModal(removeUser)} />
                        {removing ? (
                          <LoadingBlock className='transparent-bg' text='Please be patient...' />
                        ) : (
                          <RemoveMemberModal
                            member={removeUser}
                            self={self}
                            projectName={props.projectName}
                            removeMemberError={removeMemberError}
                            toggleRemoveMemberModal={() => toggleRemoveMemberModal(removeUser)}
                            removeMember={values => removeMember(values)}
                            removeSuccess={removeSuccess}
                          />
                        )}
                      </div>
                    </Modal.Body>
                  </Modal>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

ProjectMembers.propTypes = {
  projectData: PropTypes.object.isRequired,
  projectName: PropTypes.string.isRequired,
};

export default ProjectMembers;
