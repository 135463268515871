import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useSubmitData } from '../../../hoc/SubmitData';
import { LoadingBlock } from '../../../components/loading/Loading';
import QFormWrapper from '../../../components/form/QFormWrapper';
import { SubmitGroupLabel, SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';
import {
  buildValidationObj,
  getValidationKeys,
  getInitialFormValues,
  buildErrorMessage,
  validateParam,
  buildParamKeys,
} from '../functions/SubmitFormFunctions';
import { defaultBasis } from '../constants/SubmitConst';
import ShowInputs from '../components/ShowInputs';
import BasisSet from '../components/BasisSet';

const SubmitGVB = props => {
  // Init
  const { solverConfigs, defaultParams } = useSubmitData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationObj, setValidationObj] = useState({});
  const [validationKeys, setValidationKeys] = useState([]);
  const timers = useRef([]);
  // Edit Params
  const [pipeline, setPipeline] = useState([]);
  const [basis, setBasis] = useState(props.basis);
  const blacklist = ['solver'];
  // Refs for form validation
  const [formLoaded, setFormLoaded] = useState(false);
  const formRef = useRef(null);

  const gvb_name = props.solverPipeline[0] ? props.solverPipeline[0].solver_name : '';

  const gvb_validation_items = {
    maxiter__0: yup.string().test(
      'maxiter',
      value => buildErrorMessage(value.value, 'maxiter', gvb_name, solverConfigs),
      value => validateParam(value, 'maxiter', gvb_name, solverConfigs)
    ),
    soscf_start_iter__0: yup.string().test(
      'soscf_start_iter',
      value => buildErrorMessage(value.value, 'soscf_start_iter', gvb_name, solverConfigs),
      value => validateParam(value, 'soscf_start_iter', gvb_name, solverConfigs)
    ),
    soscf_start_grad__0: yup.string().test(
      'soscf_start_grad',
      value => buildErrorMessage(value.value, 'soscf_start_grad', gvb_name, solverConfigs),
      value => validateParam(value, 'soscf_start_grad', gvb_name, solverConfigs)
    ),
    dens_tol__0: yup.string().test(
      'dens_tol',
      value => buildErrorMessage(value.value, 'dens_tol', gvb_name, solverConfigs),
      value => validateParam(value, 'dens_tol', gvb_name, solverConfigs)
    ),
    orb_grad_tol__0: yup.string().test(
      'orb_grad_tol',
      value => buildErrorMessage(value.value, 'orb_grad_tol', gvb_name, solverConfigs),
      value => validateParam(value, 'orb_grad_tol', gvb_name, solverConfigs)
    ),
    n_pair__0: yup.string().test(
      'n_pair',
      value => buildErrorMessage(value.value, 'n_pair', gvb_name, solverConfigs),
      value => validateParam(value, 'n_pair', gvb_name, solverConfigs)
    ),
  };

  useEffect(() => {
    setPipeline(props.solverPipeline);

    if (solverConfigs !== null && defaultParams !== null && props.solverPipeline[0] && props.solverPipeline[0].params) {
      // Solver params
      const param_keys = buildParamKeys(props.solverPipeline);
      setValidationObj(buildValidationObj(gvb_validation_items, param_keys));
      setValidationKeys(getValidationKeys(gvb_validation_items, param_keys));
      setIsLoaded(true);
    }
  }, [props.solverPipeline, solverConfigs, defaultParams]);

  function checkFormValid() {
    // Delay to allow formRef validations to finish
    const t = setTimeout(() => {
      props.setParamsValid(formRef.current.isValid);
    }, 200);
    timers.current.push(t);
  }

  useEffect(() => {
    // Trigger form validations after Form element is loaded
    if (formRef && formRef.current !== null) {
      let fields = {};
      validationKeys.forEach(param => {
        fields[param] = true;
      });
      formRef.current.setTouched(fields);
      checkFormValid();
    }
  }, [formLoaded]);

  useEffect(() => {
    // Update paramsForm in parent component whenever form validity changes
    if (formRef.current) {
      checkFormValid();
    }
  }, [formRef.current]);

  useEffect(() => {
    // Component cleanup
    return () => {
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function handlePageLoad() {
    /*
    This function should be called at the end of the html elements
    load, as it looks for a specific element in the DOM and triggers
    a component state change
    */
    if (!formLoaded) {
      // Delay to allow html elements to load
      const timer = setTimeout(() => {
        const el = document.getElementById('GVBForm');
        if (el !== undefined && el !== null) {
          setFormLoaded(true);
        }
      }, 1000);
      timers.current.push(timer);
    }
  }

  const validationSchema = yup.object().shape(validationObj);

  function showSolverParams(formProps) {
    return Array.from(pipeline).map((solver, index) => {
      return (
        <React.Fragment key={index}>
          <SubmitInputGroup>
            <SubmitGroupLabel className='w-100' text={`${solver.solver_name} Parameters`} />
            {/* Basis */}
            <BasisSet
              solverName={solver.solver_name}
              basisSelected={basis}
              updateBasisSet={val => {
                setBasis(val);
                props.updateMoldata([['basis', val]]);
              }}
            />
            {/* Dynamic Inputs */}
            {ShowInputs(solver, index, formProps, solverConfigs, blacklist, validationKeys, pipeline, pipeline_mod =>
              props.updatePipeline(pipeline_mod)
            )}
          </SubmitInputGroup>
        </React.Fragment>
      );
    });
  }

  return (
    <div id='SubmitGVB'>
      {isLoaded && pipeline[0] ? (
        <QFormWrapper
          innerRef={formRef}
          formId='GVBForm'
          validationSchema={validationSchema}
          initialValues={getInitialFormValues(pipeline, validationKeys, solverConfigs)}
          onSubmit={() => {}}>
          {formProps => {
            return (
              <React.Fragment>
                {showSolverParams(formProps)}
                <div className='w-100 mb-3'>
                  <ResetDropdown
                    resetChanges={() => {
                      formProps.resetForm();
                      setBasis(defaultBasis);
                      props.resetPipeline();
                    }}
                  />
                </div>
                {handlePageLoad()}
              </React.Fragment>
            );
          }}
        </QFormWrapper>
      ) : (
        <LoadingBlock text='Loading paramaters...' className='mb-3' />
      )}
    </div>
  );
};

SubmitGVB.propTypes = {
  currentOrg: PropTypes.object,
  solverPipeline: PropTypes.array.isRequired,
  updatePipeline: PropTypes.func.isRequired,
  resetPipeline: PropTypes.func.isRequired,
  updateMoldata: PropTypes.func.isRequired,
  basis: PropTypes.string.isRequired,
};

export default SubmitGVB;
