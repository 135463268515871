// Beryllium Hydride
const beh2 = `Be   0.0000    0.0000    0.0000
H    0.0000    0.0000    1.3264
H    0.0000    0.0000   -1.3264`;

// Hydrogen Fluoride
const hf = 'F    0.0000   0.0000   0.0000\n  H    0.0000   0.0000   0.9168';

// Oxygen
const O2_xyz = 'O   0.0000  0.0000  0.0000\nO   0.0000  0.0000  1.2075';

// Hydrogen
const h2_xyz = 'H     0.0000   0.0000   0.0000\nH     0.0000   0.0000   0.7414';
const h2_smiles = '[H][H]';

// Methane
const ch4_xyz = `C    0.0000    0.0000    0.0000
H    0.6276    0.6276    0.6276
H    0.6276   -0.6276   -0.6276
H   -0.6276    0.6276   -0.6276
H   -0.6276   -0.6276    0.6276`;
const ch4_list = [
  ['C', [0.0, 0.0, 0.0]],
  ['H', [0.6276, 0.6276, 0.6276]],
  ['H', [0.6276, -0.6276, -0.6276]],
  ['H', [-0.6276, 0.6276, -0.6276]],
  ['H', [-0.6276, -0.6276, 0.6276]],
];

// Benzene
const benzene_smiles = 'c1ccccc1';

// Defaults
export const defaultXYZ = ch4_xyz;
export const defaultList = ch4_list;
export const defaultSmiles = benzene_smiles;
