import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../../hoc/Auth';
import { QErrorMessage } from '../../notifications/Notifications';
import { showParams, solverTooltip } from '../ProblemsCommonFunctions';
import { dftMethods } from './DFTProblem';

export function mlSolvers(solver_parameters, solver_constants) {
  const solver_enums = solver_constants && solver_constants.enums ? solver_constants.enums.DFT : null;

  const blacklist = ['calc_type', 'model_name'];

  function nestedSolver(solver) {
    let nested_solver;

    if (solver.solver_params !== undefined && solver.solver_params !== null) {
      // recursively iterate through nested "solver" objects in solver_parameters
      Object.keys(solver.solver_params).forEach(key => {
        if (key === 'solver') {
          nested_solver = nestedSolver(solver.solver_params[key]);
        }
      });

      const solver_params = showParams(solver, blacklist, solver_constants);

      return (
        <React.Fragment>
          <span>
            <i className='icon-next' />
            {solver.solver_params && Object.keys(solver.solver_params).length > 0 ? (
              <React.Fragment>{solverTooltip(solver.next_solver, solver_params)}</React.Fragment>
            ) : (
              <span className='solver'>{solver.next_solver}</span>
            )}
          </span>
          {nested_solver}
        </React.Fragment>
      );
    }
  }

  const solver_params = showParams(solver_parameters, blacklist, solver_constants);
  const solver_name =
    solver_parameters.solver_params && solver_parameters.solver_params.auto_mode
      ? solver_parameters.next_solver + '-auto'
      : solver_parameters.next_solver;

  return (
    <div className='solvers'>
      {solverTooltip(solver_name, solver_params)}
      {/* Solver Pipeline */}
      {solver_parameters.solver_params !== undefined &&
        solver_parameters.solver_params !== null &&
        solver_parameters.solver_params.solver !== undefined &&
        solver_parameters.solver_params.solver !== null && (
          <React.Fragment>{nestedSolver(solver_parameters.solver_params.solver)}</React.Fragment>
        )}
    </div>
  );
}

const MLProblem = props => {
  // Init
  const { fetchSingleProblem, fetchResults } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // DFT Solver
  const [firstRender, setFirstRender] = useState(true);
  const [dftSolvers, setDftSolvers] = useState(null);
  const [dftProblemError, setDftProblemError] = useState(null);

  function getDFTSolver() {
    if (firstRender) {
      fetchResults(signal, props.problemHandle, props.projectId, response => {
        // if data is returned
        if (response.status === 'success') {
          const data = response.data;
          if (data.result !== undefined && data.result !== null) {
            let dft_handle;

            if (data.result.dft_calc) {
              let dft_handles = Object.keys(data.result.dft_calc);
              dft_handle = dft_handles[0];
            } else if (data.result.DFT_result && data.result.DFT_result.handle) {
              dft_handle = data.result.DFT_result.handle;
            }

            if (dft_handle) {
              // Get DFT subproblem
              // prettier-ignore
              fetchSingleProblem(signal, dft_handle, response => {
                // if error is returned
                if (response.status === 'error') {
                  setDftProblemError(response.error);
                }
                // if data is returned
                if (response.status === 'success') {
                  let dft_problem = response.data.problems[0];
                  let dft_solvers = dft_problem.solver_parameters;
                  setDftSolvers(dft_solvers);
                }
              });
            }
            setFirstRender(false);
          }
        }
      });
    }
  }

  useEffect(() => {
    if (props.solverParameters.solver_params && props.solverParameters.solver_params.auto_mode) {
      getDFTSolver();
    }

    return () => {
      setFirstRender(true);
      abortController.abort();
    };
  }, []);

  return (
    <div id='MLProblem' className='row details problem-type-a'>
      <div className='col-12'>
        {/* SOLVERS */}
        {props.solverParameters !== undefined &&
          props.solverParameters !== null &&
          props.solverParameters.next_solver !== undefined && (
            <div className='row solvers'>
              <div className='col-12 solvers-data'>
                <span className='label-sm'>Solvers: </span>
                <div className='solvers-block d-inline-flex'>
                  {mlSolvers(props.solverParameters, props.solverConstants)}
                  {/* DFT Solver */}
                  {props.solverParameters.solver_params &&
                    props.solverParameters.solver_params.auto_mode &&
                    dftSolvers && (
                      <React.Fragment>
                        <i className='icon-next align-self-center mx-2 pre-next-solver-icon' />
                        {dftMethods(dftSolvers, props.solverConstants)}
                      </React.Fragment>
                    )}
                </div>
              </div>
            </div>
          )}
      </div>
      {dftProblemError && (
        <div className='col-12'>
          <QErrorMessage
            className='row p0'
            text={<span className='bold'>Fetch DFT Problem Error: {dftProblemError.message}</span>}
          />
        </div>
      )}
    </div>
  );
};

MLProblem.propTypes = {
  problemHandle: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  solverParameters: PropTypes.object.isRequired,
  solverConstants: PropTypes.object.isRequired,
};

export default MLProblem;
