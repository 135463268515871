import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useSubmitData } from '../../../hoc/SubmitData';
import { pythonifySolver } from '../../../hoc/submitDataFunctions';
import { LoadingBlock } from '../../../components/loading/Loading';
import QFormWrapper from '../../../components/form/QFormWrapper';
import { SubmitGroupLabel, SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';
import ShowInputs from '../components/ShowInputs';
import {
  buildValidationObj,
  getValidationKeys,
  getInitialFormValues,
  buildErrorMessage,
  validateParam,
  buildParamKeys,
} from '../functions/SubmitFormFunctions';
import { formatValToJS } from '../functions/SubmitFunctions';
import { defaultBasis } from '../constants/SubmitConst';
import DFTGrids from './DFTGrids';
import BasisSet from '../components/BasisSet';

const SubmitDFT = props => {
  // Init
  const { solverConfigs, defaultParams, defaultDFTPip } = useSubmitData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationObj, setValidationObj] = useState({});
  const [validationKeys, setValidationKeys] = useState([]);
  const timers = useRef([]);
  // Edit Params
  const [pipeline, setPipeline] = useState(props.solverPipeline);
  const [basis, setBasis] = useState(props.basis);
  const blacklist = ['td'];
  // Refs for form validation
  const [formLoaded, setFormLoaded] = useState(false);
  const formRef = useRef(null);

  const dft_solver_name = props.solverPipeline[0] ? props.solverPipeline[0].solver_name : '';
  const grids_solver_name = props.solverPipeline[1] ? props.solverPipeline[1].solver_name : '';
  const nlcgrids_solver_name = props.solverPipeline[2] ? props.solverPipeline[2].solver_name : '';

  const dft_validation_items = {
    diis_space__0: yup.string().test(
      'diis_space',
      value => buildErrorMessage(value.value, 'diis_space', dft_solver_name, solverConfigs),
      value => validateParam(value, 'diis_space', dft_solver_name, solverConfigs)
    ),
    diis_start__0: yup.string().test(
      'diis_start',
      value => buildErrorMessage(value.value, 'diis_start', dft_solver_name, solverConfigs),
      value => validateParam(value, 'diis_start', dft_solver_name, solverConfigs)
    ),
    omega__0: yup.string().test(
      'omega',
      value => buildErrorMessage(value.value, 'omega', dft_solver_name, solverConfigs),
      value => validateParam(value, 'omega', dft_solver_name, solverConfigs)
    ),
    scf_damping__0: yup.string().test(
      'scf_damping',
      value => buildErrorMessage(value.value, 'scf_damping', dft_solver_name, solverConfigs),
      value => validateParam(value, 'scf_damping', dft_solver_name, solverConfigs)
    ),
    scf_direct_tol__0: yup.string().test(
      'scf_direct_tol',
      value => buildErrorMessage(value.value, 'scf_direct_tol', dft_solver_name, solverConfigs),
      value => validateParam(value, 'scf_direct_tol', dft_solver_name, solverConfigs)
    ),
    scf_energy_tol__0: yup.string().test(
      'scf_energy_tol',
      value => buildErrorMessage(value.value, 'scf_energy_tol', dft_solver_name, solverConfigs),
      value => validateParam(value, 'scf_energy_tol', dft_solver_name, solverConfigs)
    ),
    scf_grad_tol__0: yup.string().test(
      'scf_grad_tol',
      value => buildErrorMessage(value.value, 'scf_grad_tol', dft_solver_name, solverConfigs),
      value => validateParam(value, 'scf_grad_tol', dft_solver_name, solverConfigs)
    ),
    scf_level_shift__0: yup.string().test(
      'scf_level_shift',
      value => buildErrorMessage(value.value, 'scf_level_shift', dft_solver_name, solverConfigs),
      value => validateParam(value, 'scf_level_shift', dft_solver_name, solverConfigs)
    ),
    scf_maxiter__0: yup.string().test(
      'scf_maxiter',
      value => buildErrorMessage(value.value, 'scf_maxiter', dft_solver_name, solverConfigs),
      value => validateParam(value, 'scf_maxiter', dft_solver_name, solverConfigs)
    ),
  };

  const grids_validation_items = {
    density_tol__1: yup.string().test(
      'density_tol',
      value => buildErrorMessage(value.value, 'density_tol', grids_solver_name, solverConfigs),
      value => validateParam(value, 'density_tol', grids_solver_name, solverConfigs)
    ),
    level__1: yup.string().test(
      'level',
      value => buildErrorMessage(value.value, 'level', grids_solver_name, solverConfigs),
      value => validateParam(value, 'level', grids_solver_name, solverConfigs)
    ),
    atom_grid__1: yup.string().test(
      'atom_grid',
      value => buildErrorMessage(value.value, 'atom_grid', grids_solver_name, solverConfigs),
      value => validateParam(value, 'atom_grid', grids_solver_name, solverConfigs)
    ),
  };

  const nlcgrids_validation_items = {
    density_tol__2: yup.string().test(
      'density_tol',
      value => buildErrorMessage(value.value, 'density_tol', nlcgrids_solver_name, solverConfigs),
      value => validateParam(value, 'density_tol', nlcgrids_solver_name, solverConfigs)
    ),
    level__2: yup.string().test(
      'level',
      value => buildErrorMessage(value.value, 'level', nlcgrids_solver_name, solverConfigs),
      value => validateParam(value, 'level', nlcgrids_solver_name, solverConfigs)
    ),
    atom_grid__2: yup.string().test(
      'atom_grid',
      value => buildErrorMessage(value.value, 'atom_grid', nlcgrids_solver_name, solverConfigs),
      value => validateParam(value, 'atom_grid', nlcgrids_solver_name, solverConfigs)
    ),
  };

  const validation_items = { ...dft_validation_items, ...grids_validation_items, ...nlcgrids_validation_items };

  function tdSolverValidation(pip) {
    const td_solver_name = 'LinearResponse';
    if (pip[3]) {
      const td_validation_items = {
        demin__3: yup.string().test(
          'demin',
          value => buildErrorMessage(value.value, 'demin', td_solver_name, solverConfigs),
          value => validateParam(value, 'demin', td_solver_name, solverConfigs)
        ),
        max_cycle__3: yup.string().test(
          'max_cycle',
          value => buildErrorMessage(value.value, 'max_cycle', td_solver_name, solverConfigs),
          value => validateParam(value, 'max_cycle', td_solver_name, solverConfigs)
        ),
        max_space__3: yup.string().test(
          'max_space',
          value => buildErrorMessage(value.value, 'max_space', td_solver_name, solverConfigs),
          value => validateParam(value, 'max_space', td_solver_name, solverConfigs)
        ),
        nroots__3: yup.string().test(
          'nroots',
          value => buildErrorMessage(value.value, 'nroots', td_solver_name, solverConfigs),
          value => validateParam(value, 'nroots', td_solver_name, solverConfigs)
        ),
      };

      return td_validation_items;
    } else return {};
  }

  function buildValidation(pip) {
    const val_items = { ...validation_items, ...tdSolverValidation(pip) };
    // Solver params
    const param_keys = buildParamKeys(pip);
    setValidationObj(buildValidationObj(val_items, param_keys));
    setValidationKeys(getValidationKeys(val_items, param_keys));
  }

  useEffect(() => {
    if (solverConfigs !== null && defaultParams !== null && pipeline[0]) {
      buildValidation(pipeline);
      setIsLoaded(true);
    }
  }, [props.solverPipeline, solverConfigs, defaultParams]);

  function checkFormValid() {
    // Delay to allow formRef validations to finish
    const t = setTimeout(() => {
      props.setParamsValid(formRef.current.isValid);
    }, 200);
    timers.current.push(t);
  }

  useEffect(() => {
    // Trigger form validations after MoleculeParamsForm element is loaded
    if (formRef && formRef.current !== null) {
      let fields = {};
      validationKeys.forEach(param => {
        fields[param] = true;
      });
      formRef.current.setTouched(fields);
      checkFormValid();
    }
  }, [formLoaded]);

  useEffect(() => {
    // Update paramsForm in parent component whenever form validity changes
    if (formRef.current) {
      checkFormValid();
    }
  }, [formRef.current]);

  useEffect(() => {
    // Component cleanup
    return () => {
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function handlePageLoad() {
    /*
    This function should be called at the end of the html elements
    load, as it looks for a specific element in the DOM and triggers
    a component state change
    */
    if (!formLoaded) {
      // Delay to allow html elements to load
      const timer = setTimeout(() => {
        const el = document.getElementById('DFTForm');
        if (el !== undefined && el !== null) {
          setFormLoaded(true);
        }
      }, 1000);
      timers.current.push(timer);
    }
  }

  const validationSchema = yup.object().shape(validationObj);

  function showSolverParams(formProps) {
    return (
      <React.Fragment>
        {Array.from(pipeline).map((solver, index) => (
          <React.Fragment key={index}>
            {/* Solver Name */}
            {solver.solver_name === 'DFT' ? (
              <SubmitInputGroup>
                <SubmitGroupLabel className='w-100 mb-0' text={`${solver.solver_name} Parameters`} />
                {/* Basis */}
                <BasisSet
                  solverName={solver.solver_name}
                  basisSelected={basis}
                  updateBasisSet={val => {
                    setBasis(val);
                    props.updateMoldata([['basis', val]]);
                  }}
                />
                {/* Edit Pipeline & Parameters */}
                {ShowInputs(
                  solver,
                  index,
                  formProps,
                  solverConfigs,
                  blacklist,
                  validationKeys,
                  pipeline,
                  pipeline_mod => {
                    let pip = structuredClone(pipeline_mod);
                    // Show TD Params
                    if (
                      !pipeline[3] &&
                      pip[0].params &&
                      pip[0].params.calc_type &&
                      pip[0].params.calc_type === 'EXCITED_STATE'
                    ) {
                      const td = pythonifySolver(solverConfigs, {
                        solver_name: 'LinearResponse',
                        params: solverConfigs.LinearResponse.solver_parameters.solver_params,
                      });
                      pip[3] = { solver_name: 'LinearResponse', params: td.params };
                      const params = Object.entries(pip[3].params);
                      pip[0].params.td = {};
                      // Apply params to DTF.td object
                      params.forEach(([key, val]) => {
                        pip[0].params.td[key] = formatValToJS([key, val], solverConfigs, 'LinearResponse');
                      });
                      setIsLoaded(false);
                    }
                    // Hide TD Params
                    else if (
                      pipeline[3] &&
                      pip[0].params &&
                      pip[0].params.calc_type &&
                      pip[0].params.calc_type !== 'EXCITED_STATE'
                    ) {
                      pip.pop();
                      pip[0].params.td = 'None';
                      setIsLoaded(false);
                    }
                    setPipeline(pip);
                    props.updatePipeline(pip);
                  }
                )}
                {/* TD */}
                {pipeline[3] ? (
                  <div className='container px-0 mb-3 mx-0'>
                    <SubmitGroupLabel className='w-100 mt-3' text='TD Parameters' />
                    {/* Solver Params */}
                    {ShowInputs(
                      pythonifySolver(solverConfigs, pipeline[3]),
                      3,
                      formProps,
                      solverConfigs,
                      [],
                      validationKeys,
                      pipeline,
                      pip_mod => {
                        let pip = structuredClone(pip_mod);
                        const params = Object.entries(pip_mod[3].params) || [];
                        // Apply params to DTF.td object
                        params.forEach(([key, val]) => {
                          pip[0].params.td[key] = formatValToJS([key, val], solverConfigs, 'LinearResponse');
                        });
                        setPipeline(pip);
                        props.updatePipeline(pip);
                      }
                    )}
                  </div>
                ) : (
                  ''
                )}
              </SubmitInputGroup>
            ) : (
              <React.Fragment>
                {solver.solver_name !== 'LinearResponse' ? (
                  <DFTGrids
                    paramKey={solver.solver_name}
                    solverConfigs={solverConfigs}
                    solverObj={solver}
                    updatePipeline={pipeline_mod => {
                      setPipeline(pipeline_mod);
                      props.updatePipeline(pipeline_mod);
                    }}
                    formProps={formProps}
                    validationKeys={validationKeys}
                    solverIndex={index}
                    pipeline={pipeline}
                  />
                ) : (
                  ''
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
        <div className='w-100 mb-3'>
          <ResetDropdown
            resetChanges={() => {
              formProps.resetForm();
              setBasis(defaultBasis);
              props.resetPipeline();
              setIsLoaded(false);
              setPipeline(defaultDFTPip);
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div id='SubmitDFT'>
      {isLoaded && pipeline[0] ? (
        <React.Fragment>
          <QFormWrapper
            innerRef={formRef}
            formId='DFTForm'
            validationSchema={validationSchema}
            initialValues={getInitialFormValues(pipeline, validationKeys, solverConfigs)}
            onSubmit={() => {}}>
            {formProps => {
              return (
                <React.Fragment>
                  {showSolverParams(formProps)}
                  {handlePageLoad()}
                </React.Fragment>
              );
            }}
          </QFormWrapper>
        </React.Fragment>
      ) : (
        <LoadingBlock text='Loading paramaters...' className='mb-3' />
      )}
    </div>
  );
};

SubmitDFT.propTypes = {
  currentOrg: PropTypes.object,
  solverPipeline: PropTypes.array.isRequired,
  updatePipeline: PropTypes.func.isRequired,
  resetPipeline: PropTypes.func.isRequired,
  updateMoldata: PropTypes.func.isRequired,
  basis: PropTypes.string.isRequired,
};

export default SubmitDFT;
