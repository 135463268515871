import React, { ReactNode } from 'react';

// Import hooks & hoc
import { formatStringsList } from '../../../hoc/CommonFunctions';

// Import data models
import { MolFileTypes } from '../../../models/molecules';

// Import constants
import { solversFileMap } from '../constants/SubmitConst';

export function constructFileLabel(solver_name: string, file_type: MolFileTypes): string {
  const max_files = solversFileMap[solver_name][file_type].numFilesAllowed;
  const s = max_files > 1 ? 's' : '';
  const str = `${solversFileMap[solver_name][file_type].label} file${s} (${formatStringsList(
    solversFileMap[solver_name][file_type].allowedFileTypes,
    false, // capitalize
    true, // all caps
    true, // join (use 'and' / 'or')
    false // inclusive (use 'and')
  )}) • ${max_files} file${s} max`;
  return str;
}

export function showDescription(solver_name: string, file_type: MolFileTypes): ReactNode {
  const desc = solversFileMap[solver_name].guest.description;
  if (desc) {
    return <p className='mb-2 text-xs'>{solversFileMap[solver_name][file_type].description}</p>;
  }
}
