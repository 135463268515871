import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';

// Import hooks & hoc
import { useSubmitData } from '../../../hoc/SubmitData';

// Import data models
import { ErrorObject } from '../../../models/data';
import { FormPropsObject } from '../../../models/forms';
import { MolFileTypes } from '../../../models/molecules';
import { SolverObject } from '../../../models/solvers';

// Import functions and constants
import { solversFileMap } from '../constants/SubmitConst';
import { constructFileLabel, showDescription } from '../functions/FileUploadFunctions';

// Import components
import { QSelect } from '../../../components/form/FormElements';
import QFormWrapper from '../../../components/form/QFormWrapper';
import { LoadingBlock } from '../../../components/loading/Loading';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import ShowInputs from '../components/ShowInputs';
import { SubmitGroupLabel, SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';
import { getInitialFormValues } from '../functions/SubmitFormFunctions';
import { buildSolverValidationObject } from './submitGNINA/validation';
import UploadComponent from '../../../components/upload/Upload';

interface Props {
  molFiles: {
    combo: File[];
    hosts: File[];
    guest: File[];
    gnina_autobox_ligand: File[];
    gnina_flex: File[];
    gnina_flexdist_ligand: File[];
  };
  resetPipeline: Function;
  setMolFiles: Function;
  setParamsValid: Function;
  solverPipeline: SolverObject[];
  updatePipeline: Function;
}

const SubmitGNINA = ({
  molFiles,
  resetPipeline,
  setMolFiles,
  setParamsValid,
  solverPipeline,
  updatePipeline,
}: Props) => {
  // Init
  const { solverConfigs, defaultParams }: any = useSubmitData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationObj, setValidationObj] = useState<any>({});
  const [validationKeys, setValidationKeys] = useState<string[]>([]);
  const timers = useRef<any[]>([]);
  // Edit Params
  const [pipeline, setPipeline] = useState<SolverObject[]>([]);
  const blacklist = ['solver'];
  // Refs for form validation
  const [formLoaded, setFormLoaded] = useState(false);
  const formRef = useRef<any>(null);
  // Files
  const [autoboxFileError, setAutoboxFileError] = useState<ErrorObject | null>(null);
  const [flexFileError, setFlexFileError] = useState<ErrorObject | null>(null);
  const [flexFile, setFlexFile] = useState<'flex' | 'flexdist'>('flex');

  useEffect(() => {
    setPipeline(solverPipeline);

    if (!!solverConfigs && !!defaultParams && solverPipeline[0] && solverPipeline[0].params) {
      const { validation_object, validation_keys } = buildSolverValidationObject(solverPipeline, solverConfigs);
      setValidationObj(validation_object);
      setValidationKeys(validation_keys);
      setIsLoaded(true);
    }
  }, [solverPipeline, solverConfigs, defaultParams]);

  function checkFormValid() {
    // Delay to allow formRef validations to finish
    const t = setTimeout(() => {
      setParamsValid(formRef.current.isValid);
    }, 200);
    timers.current.push(t);
  }

  useEffect(() => {
    // Trigger form validations after Form element is loaded
    if (formLoaded && formRef && formRef.current !== null) {
      let fields = {};
      validationKeys.forEach(param => {
        fields[param] = true;
      });
      formRef.current.setTouched(fields);
      checkFormValid();
    }
  }, [formLoaded]);

  useEffect(() => {
    // Update paramsForm in parent component whenever form validity changes
    if (formRef.current) {
      checkFormValid();
    }
  }, [formRef.current]);

  useEffect(() => {
    // Component cleanup
    return () => {
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function handlePageLoad() {
    /*
    This function should be called at the end of the html elements
    load, as it looks for a specific element in the DOM and triggers
    a component state change
    */
    if (!formLoaded) {
      // Delay to allow html elements to load
      const timer = setTimeout(() => {
        const el = document.getElementById('AQFEPForm');
        if (el !== undefined && el !== null) {
          setFormLoaded(true);
        }
      }, 1000);
      timers.current.push(timer);
    }
  }

  const validationSchema = yup.object().shape(validationObj);

  function handleFileUpload(files: File[], type: MolFileTypes) {
    // Update mol files structure
    let mol_files = structuredClone(molFiles);
    mol_files[type] = files;
    // Update parent molFiles state
    setMolFiles(mol_files);
  }

  function handleFlexFileChange() {
    // Update mol files structure
    let mol_files = structuredClone(molFiles);
    mol_files.gnina_flex = [];
    mol_files.gnina_flexdist_ligand = [];
    // Update parent molFiles state
    setMolFiles(mol_files);
  }

  function showSolverParams(formProps: FormPropsObject) {
    return Array.from(pipeline).map((solver, index) => {
      return (
        <React.Fragment key={index}>
          <SubmitInputGroup>
            <SubmitGroupLabel className='w-100' text={`${solver.solver_name} Parameters`} />
            {/* Files */}
            {index === 1 && (
              <>
                <div className='row d-flex align-items-center'>
                  <div className='col-12 col-md-3 pr-3 mb-2 mb-md-3 label-sm dark'>Autobox Ligand File (Optional)</div>
                  <div className='col-12 col-md-9 px-1'>
                    {autoboxFileError ? (
                      <QErrorMessage
                        id='UploadError'
                        className='mb-3'
                        text={<span className='bold'>{autoboxFileError.message}</span>}
                      />
                    ) : (
                      ''
                    )}
                    <label className='dark'>{constructFileLabel('GNINA', 'gnina_autobox_ligand')}</label>
                    {showDescription('GNINA', 'gnina_autobox_ligand')}
                    <UploadComponent
                      allowedFileTypes={solversFileMap.GNINA.gnina_autobox_ligand.allowedFileTypes}
                      id='AutoboxLigand'
                      className='mb-3 param-file-upload'
                      files={molFiles.gnina_autobox_ligand || []}
                      fileType={solversFileMap.GNINA.gnina_autobox_ligand.label}
                      maxFiles={solversFileMap.GNINA.gnina_autobox_ligand.numFilesAllowed}
                      setFiles={(files: File[]) => handleFileUpload(files, 'gnina_autobox_ligand')}
                      setFileError={(value: ErrorObject | null) => setAutoboxFileError(value)}
                    />
                  </div>
                </div>
              </>
            )}
            {index === 2 && (
              <>
                <div className='row d-flex align-items-center'>
                  <div className='col-12 col-md-3 pr-3 mb-2 mb-md-3 label-sm dark'>Flex File (Optional)</div>
                  <div className='col-12 col-md-9 px-1'>
                    <div className='col-12 px-0 mb-2'>
                      <QSelect
                        id={'FlexFileSelect'}
                        className={'dark'}
                        value={flexFile}
                        onChange={e => {
                          setFlexFile(e.target.value);
                          handleFlexFileChange();
                        }}
                        menu={[
                          ['flex', 'Flex'],
                          ['flexdist', 'Flex Distance'],
                        ]}
                      />
                    </div>
                    {flexFileError ? (
                      <div className='row d-flex align-items-center'>
                        <QErrorMessage
                          id='UploadError'
                          className='mb-3 mx-3'
                          text={<span className='bold'>{flexFileError.message}</span>}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {flexFile === 'flex' ? (
                      <>
                        <label className='dark'>{constructFileLabel('GNINA', 'gnina_flex')}</label>
                        {showDescription('GNINA', 'gnina_flex')}
                        <UploadComponent
                          allowedFileTypes={solversFileMap.GNINA.gnina_flex.allowedFileTypes}
                          id='Flex'
                          className='mb-3 param-file-upload'
                          files={molFiles.gnina_flex || []}
                          fileType={solversFileMap.GNINA.gnina_flex.label}
                          maxFiles={solversFileMap.GNINA.gnina_flex.numFilesAllowed}
                          setFiles={(files: File[]) => handleFileUpload(files, 'gnina_flex')}
                          setFileError={(value: ErrorObject | null) => setFlexFileError(value)}
                        />
                      </>
                    ) : (
                      ''
                    )}
                    {flexFile === 'flexdist' ? (
                      <>
                        <label className='dark'>{constructFileLabel('GNINA', 'gnina_flexdist_ligand')}</label>
                        {showDescription('GNINA', 'gnina_flexdist_ligand')}
                        <UploadComponent
                          allowedFileTypes={solversFileMap.GNINA.gnina_flexdist_ligand.allowedFileTypes}
                          id='FlexDist'
                          className='mb-3 param-file-upload'
                          files={molFiles.gnina_flexdist_ligand || []}
                          fileType={solversFileMap.GNINA.gnina_flexdist_ligand.label}
                          maxFiles={solversFileMap.GNINA.gnina_flexdist_ligand.numFilesAllowed}
                          setFiles={(files: File[]) => handleFileUpload(files, 'gnina_flexdist_ligand')}
                          setFileError={(value: ErrorObject | null) => setFlexFileError(value)}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </>
            )}
            {/* Dynamic Inputs */}
            {ShowInputs(
              solver,
              index,
              formProps,
              solverConfigs,
              blacklist,
              validationKeys,
              pipeline,
              (pipeline_mod: SolverObject[]) => updatePipeline(pipeline_mod)
            )}
          </SubmitInputGroup>
        </React.Fragment>
      );
    });
  }

  return (
    <div id='SubmitGNINA'>
      {isLoaded && pipeline[0] ? (
        <QFormWrapper
          innerRef={formRef}
          formId='AQFEPForm'
          validationSchema={validationSchema}
          initialValues={getInitialFormValues(pipeline, validationKeys, solverConfigs)}
          onSubmit={() => {}}>
          {(formProps: FormPropsObject) => {
            return (
              <React.Fragment>
                {showSolverParams(formProps)}
                <div className='w-100 mb-3'>
                  <ResetDropdown
                    resetChanges={() => {
                      formProps.resetForm();
                      resetPipeline();
                    }}
                  />
                </div>
                {handlePageLoad()}
              </React.Fragment>
            );
          }}
        </QFormWrapper>
      ) : (
        <LoadingBlock text='Loading paramaters...' className='mb-3' />
      )}
    </div>
  );
};

export default SubmitGNINA;
