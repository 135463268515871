import React, { useState, useEffect, useContext, createContext } from 'react';

import { initRangeStart, getRangeEnd } from './CommonFunctions';
import { useAuth } from './Auth';

// Initialize Context for userContext
const userContext = createContext({});

// ProvideUser wrapper component
// Initiated in index.js
function ProvideUser({ children }) {
  const data = useProvideUser();
  return <userContext.Provider value={data}>{children}</userContext.Provider>;
}

// User object accessible through:
// const user = useUserData();
// Update user state in child component via:
// const { userData, setUserData } = useUserData();
export const useUserData = () => {
  return useContext(userContext);
};

// Create initial auth object for ProvideAuth wrapper
function useProvideUser() {
  const { fetchData, fetchBalance, isAuthenticated } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [firstRender, setFirstRender] = useState(true);
  const [user, setUser] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [groups, setGroups] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [orgIDs, setOrgIDs] = useState(null);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [adminGroups, setAdminGroups] = useState(null);
  const [adminPath, setAdminPath] = useState('');
  const [projects, setProjects] = useState(null);
  const [projectsOrder, setProjectsOrder] = useState('asc');
  const [projectUpdating, setProjectUpdating] = useState(true);
  const [updatingProjects, setUpdatingProjects] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [adminDateRangeStart, setAdminDateRangeStart] = useState(initRangeStart);
  const [adminDateRangeEnd, setAdminDateRangeEnd] = useState(getRangeEnd());
  const [balance, setBalance] = useState('');
  const [updatingBalance, setUpdatingBalance] = useState(false);
  const [overlayLevel1, setOverlayLevel1] = useState(null);
  const [refreshProblems, setRefreshProblems] = useState(false);
  const [solverConstants, setSolverConstants] = useState(null);

  function getBalance(id) {
    fetchBalance(signal, id, response => {
      // if error is returned
      if (response.status === 'error') {
        console.error(response);
      }
      // if data is returned
      if (response.status === 'success') {
        if (response.data.length > 0) {
          setBalance(response.data[0]);
        } else {
          setBalance(0);
        }
      }

      setUpdatingBalance(false);
    });
  }

  function getProblemsData() {
    fetchData(signal, '/api/solver-constants', null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        console.error(response);
      }
      // if data is returned
      if (response.status === 'success') {
        setSolverConstants(response.data);
      }
    });
  }

  useEffect(() => {
    let id;
    if (currentOrg !== null && currentOrg._id !== null && currentOrg._id !== undefined) {
      id = currentOrg._id;
    } else if (user !== null && user.userid !== null && user.userid !== undefined) {
      id = user.userid;
    }

    if (isAuthenticated !== 'false' && id && updatingBalance) {
      getBalance(id);
    }

    if (isAuthenticated !== 'false' && firstRender) {
      getProblemsData();
      setFirstRender(false);
    }

    return () => {
      abortController.abort();
    };
  }, [updatingBalance, isAuthenticated]);

  return {
    user,
    setUser,
    refreshToken,
    setRefreshToken,
    groups,
    setGroups,
    orgs,
    setOrgs,
    orgIDs,
    setOrgIDs,
    currentOrg,
    setCurrentOrg,
    adminGroups,
    setAdminGroups,
    adminPath,
    setAdminPath,
    projects,
    setProjects,
    projectsOrder,
    setProjectsOrder,
    currentProject,
    projectUpdating,
    setProjectUpdating,
    updatingProjects,
    setUpdatingProjects,
    setCurrentProject,
    adminDateRangeStart,
    setAdminDateRangeStart,
    adminDateRangeEnd,
    setAdminDateRangeEnd,
    balance,
    setBalance,
    updatingBalance,
    setUpdatingBalance,
    overlayLevel1,
    setOverlayLevel1,
    refreshProblems,
    setRefreshProblems,
    solverConstants,
  };
}

export default ProvideUser;
