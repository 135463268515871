import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../../hoc/Auth';
import InlineMultilineInput from '../../form/InlineMultilineInput';
import { LoadingInline } from '../../loading/Loading';

const ProblemNote = props => {
  // Init
  const { updateProblem } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Edit note
  const [editNote, setEditNote] = useState([]);
  const [updatingNote, setUpdatingNote] = useState(false);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  function processProblemChanges(problem_handle, new_note) {
    props.setProblemError(null);
    setUpdatingNote(true);

    const payload = { problem_handle: problem_handle, project_id: props.problem.project_id, note: new_note };
    updateProblem(signal, payload, response => {
      // if error object is returned
      if (response.status === 'error') {
        props.setProblemError(response.error);
      }
      // if update visibility is successful
      if (response === 'changes_successful') {
        props.fetchProblems();
      }
      setUpdatingNote(false);
      setEditNote([]);
    });
  }

  return (
    <div id='ProblemNote' className={`d-flex w-100 ${props.className}`}>
      <span className='align-self-center label-sm'>Note:&nbsp;</span>
      {updatingNote ? (
        <LoadingInline size='sm' />
      ) : (
        <React.Fragment>
          {editNote.includes(props.problem.problem_handle) ? (
            <InlineMultilineInput
              id={`${props.problem.problem_handle}-note`}
              className='mt-3'
              value={props.problem.note || ''}
              handleSave={value => processProblemChanges(props.problem.problem_handle, value)}
              toggleInput={() => setEditNote([])}
              maxlength={280}
            />
          ) : (
            <div className='d-flex align-self-start align-items-center note-wrapper'>
              <p className='text-xs mb-0'>{props.problem.note}</p>{' '}
              <i
                className='icon-edit interactive ml-2'
                data-testid='notes-icon'
                onClick={() => setEditNote([props.problem.problem_handle])}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ProblemNote.propTypes = {
  problem: PropTypes.object.isRequired,
  fetchProblems: PropTypes.func.isRequired,
  setProblemError: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ProblemNote;
