import React from 'react';
import moment from 'moment';

export function paginationSetup(callback) {
  const hash = window.location.hash;
  const page_num = hash.split('credits#')[1];
  const num_only = /^\d+$/;
  let stored_session_page = sessionStorage.getItem('qemist.credPage');

  let params = '';
  if (hash.includes('?')) {
    params = '?' + hash.split('?')[1];
    if (params.includes('#')) {
      params = params.split('#')[0];
    }
  }

  function checkSessionStorage() {
    // If page num exists in session storage
    if (stored_session_page !== null) {
      callback(params + '#' + stored_session_page);
    }
    // Else if page num does not exist in session storage
    else if (sessionStorage.getItem('qemist.credPage') === null) {
      sessionStorage.setItem('qemist.credPage', 1);
      callback(params + '#1');
    }
  }

  // If page num exists in url
  if (page_num !== undefined) {
    // If page num in url is not a number
    if (num_only.test(page_num) === false) {
      callback('#1');
    }
    // Else if viewing user's account credits
    else if (hash.includes('#/account/credits')) {
      callback(params + '#' + page_num);
      sessionStorage.setItem('qemist.credPage', page_num);
    }
    // Else if viewing other credits page (admin panel)
    else {
      callback('#' + page_num);
    }
  }

  // Of page num does not exist in url
  if (page_num === undefined) {
    // If viewing user's account credits
    if (hash.includes('#/account/credits')) {
      checkSessionStorage();
    }
    // Else if viewing other credits page (admin panel)
    else {
      callback('#1');
    }
  }
}

export function formatType(type) {
  return <span className={`type badge ${type === 'paid' ? 'paid' : type === 'demo' ? 'demo' : 'promo'}`}>{type}</span>;
}

export function determineExpiry(expiration) {
  // prettier-ignore
  let two_weeks_later = moment().add(14, 'days').unix();
  let now = moment().unix();

  if (expiration >= two_weeks_later) {
    return '';
  }
  if (expiration <= now) {
    return 'expired';
  } else if (expiration < two_weeks_later) {
    return 'expires_in_2_weeks';
  }
}
