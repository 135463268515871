import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { handleInputChange } from '../../hoc/FormFunctions';
import QFormWrapper from '../../components/form/QFormWrapper';
import QFormGroup from '../../components/form/QFormGroup';
import QButton from '../../components/button/Button';
import { QErrorMessage } from '../../components/notifications/Notifications';
import { LoadingInline } from '../../components/loading/Loading';

const DeleteProjectModal = props => {
  const validationSchema = yup.object().shape({
    confirmation: yup.string().matches('DELETE').required('This field is required'),
  });

  return (
    <React.Fragment>
      <h1>Delete Project</h1>
      <p>
        Are you sure you want to delete <span className='bold'>{props.project.name}</span>?<br />
        <span className='danger'>This action cannot be undone.</span>
      </p>
      {props.deleteError ? (
        <QErrorMessage
          className='row mx-0 mb-3 p0'
          text={
            <span className='bold'>
              Uh oh—something went wrong. Please try again later.
              <br />
              {props.deleteError.message}
            </span>
          }
        />
      ) : (
        ''
      )}
      {props.loadingProjects ? (
        <LoadingInline className='transparent-bg' text='Please be patient...' />
      ) : (
        <React.Fragment>
          <QFormWrapper
            formId='DeleteProjectForm'
            initialValues={{
              confirmation: '',
            }}
            initialErrors={{
              confirmation: true,
            }}
            validationSchema={validationSchema}
            onSubmit={() => props.deleteProject()}>
            {formProps => <DeleteProjectForm {...formProps} />}
          </QFormWrapper>
          <QButton className='secondary mt-4' onClick={props.toggleDeleteModal}>
            Cancel
          </QButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DeleteProjectForm = props => (
  <React.Fragment>
    <div className='confirmation'>
      <p className='bold'>Type DELETE to proceed.</p>
      <div className='row'>
        <QFormGroup
          containerClassName='col-12 col-md-8'
          element='text'
          id='confirmation'
          name='confirmation'
          label='Type Delete'
          onChange={e => handleInputChange(e, props, 'confirmation', () => {})}
          type='text'
          includeError={true}
        />
      </div>
    </div>
    <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
      Delete Project
    </QButton>
  </React.Fragment>
);

DeleteProjectModal.propTypes = {
  project: PropTypes.object.isRequired,
  deleteError: PropTypes.any,
  deleteProject: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  loadingProjects: PropTypes.bool.isRequired,
};

export default DeleteProjectModal;
