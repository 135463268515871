import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { useUserData } from '../../hoc/UserData';
import { handleInputChange } from '../../hoc/FormFunctions';
import QFormWrapper from '../../components/form/QFormWrapper';
import QFormGroup from '../../components/form/QFormGroup';
import QButton from '../../components/button/Button';
import { QErrorMessage } from '../../components/notifications/Notifications';

const RemoveMemberModal = props => {
  const validationSchema = yup.object().shape({
    confirmation: yup.string().matches('REMOVE').required('This field is required'),
  });

  return (
    <React.Fragment>
      <h1>{props.self ? 'Leave Group' : 'Remove Member'}</h1>
      {props.removeSuccess ? (
        <p>
          {props.self
            ? 'You have successfully left this group.'
            : 'The team member has been successfully removed from this group.'}
        </p>
      ) : (
        <React.Fragment>
          {props.self ? (
            <p>
              Are you sure you want to leave <span className='bold'> {props.orgName}</span>?
            </p>
          ) : (
            <p>
              Are you sure you want to remove <span className='bold'>{props.member.email}</span> from{' '}
              <span className='bold'>{props.orgName}</span>?
            </p>
          )}
          {props.removeMemberError ? (
            <QErrorMessage
              className='row mx-0 mb-3 p0'
              text={
                <span className='bold'>
                  Uh oh—something went wrong. Please try again later.
                  <br />
                  {props.removeMemberError.message}
                </span>
              }
            />
          ) : (
            ''
          )}
          <QFormWrapper
            formId='RemoveMemberForm'
            initialValues={{
              confirmation: '',
              user_id: props.member.user_id,
            }}
            initialErrors={{
              confirmation: true,
            }}
            validationSchema={validationSchema}
            onSubmit={props.removeMember}>
            {formProps => <RemoveMemberForm {...formProps} />}
          </QFormWrapper>
          <QButton className='secondary mt-4' onClick={props.toggleRemoveMemberModal}>
            Cancel
          </QButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const RemoveMemberForm = props => {
  const { user } = useUserData();

  return (
    <React.Fragment>
      <div className='confirmation'>
        <p className='bold'>Type REMOVE to proceed.</p>
        <div className='row'>
          <QFormGroup
            containerClassName='col-12 col-md-8'
            element='text'
            id='confirmation'
            name='confirmation'
            label='Type Remove'
            onChange={e => handleInputChange(e, props, 'confirmation', () => {})}
            type='text'
            includeError={true}
          />
        </div>
      </div>
      <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
        {props.values.user_id === user.userid ? 'Yes, Leave This Team' : 'Remove Member'}
      </QButton>
    </React.Fragment>
  );
};

RemoveMemberModal.propTypes = {
  member: PropTypes.object,
  self: PropTypes.bool.isRequired,
  orgName: PropTypes.string,
  removeMemberError: PropTypes.any,
  toggleRemoveMemberModal: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  removeSuccess: PropTypes.bool.isRequired,
};

export default RemoveMemberModal;
