// Commonly viewed Scrollable HTML Element Ids
const elementIds = [
  'ProblemsContainer',
  'ProjectContent',
  'RequestLog',
  'SubmitContent',
  'Terms',
  'ViewGroupRequestLog',
  'ViewUserRequestLog',
];

export function scrollToTop(element_id: string = '') {
  // Scroll back to top of the element
  const elements = [element_id, ...elementIds];
  const first_el_found: string | undefined = elements.find(el => !!document.getElementById(el));

  if (!!first_el_found) {
    const element = document.getElementById(first_el_found);
    element?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}

export function scrollToBottom(element_id = '') {
  // Scroll to the bottom of the element
  const elements = [element_id, ...elementIds];
  const first_el_found: string | undefined = elements.find(el => !!document.getElementById(el));

  if (!!first_el_found) {
    const element = document.getElementById(first_el_found);
    element?.scrollTo({
      top: element.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
}
