import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { charactersHelper } from '../../hoc/CommonFunctions';
import QButton from '../button/Button';
import { LoadingInline } from '../loading/Loading';

const InlineMultilineInput = props => {
  const [firstRender, setFirstRender] = useState(true);
  const [value, setValue] = useState('');
  const [saving, setSaving] = useState(false);
  const [elementId, setElementId] = useState('0');

  useEffect(() => {
    setValue(props.value);

    // Prevents the onBlur function from being
    // triggered by autofocus on render
    setFirstRender(false);

    // Auto focus on the input field when first rendered
    let id = props.id || '0';
    document.getElementById(id).focus();
    setElementId(id);

    return () => {
      setFirstRender(true);
    };
  }, []);

  // Track the user's edits in the input field
  function handleChange(event) {
    setValue(event.target.value);
  }

  // Hide input field if the value has not changed
  // and the user clicks away from the input field
  function handleBlur() {
    if (!firstRender && props.value === value) {
      props.toggleInput();
    }
  }

  function handleSave() {
    setSaving(true);
    props.handleSave(value);
  }

  return (
    <div id='InlineMultilineInput' className={`flex-grow-1 ${props.className}`}>
      <div className='d-flex align-items-end'>
        <textarea
          data-testid={props.id + '-input'}
          id={props.id || '0'}
          rows={props.rows || '3'}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          className='mr-md-2'
          maxLength={props.maxlength}></textarea>
        {props.value !== value && (
          <QButton className='xsmall' onClick={() => handleSave()}>
            {saving ? <LoadingInline size='sm' color='light' /> : 'Save'}
          </QButton>
        )}
      </div>
      {props.value !== value && (
        <p className='mt-2 text-xs p-text-light'>{charactersHelper(elementId, props.maxlength || 'unlimited')}</p>
      )}
    </div>
  );
};

InlineMultilineInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSave: PropTypes.func.isRequired,
  toggleInput: PropTypes.func.isRequired,
  maxlength: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InlineMultilineInput;
