import React, { useState, useEffect } from 'react';

// Components
import { useAuth } from '../../hoc/Auth';

const Promo = () => {
  // Init
  const [isLoaded, setIsLoaded] = useState(false);
  const [promo, setPromo] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    let current_promo = window.location.href.split('promo=')[1];

    setPromo(current_promo);

    let timer = setTimeout(() => setIsLoaded(true), 500);

    return () => {
      setIsLoaded(false);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id='Promo'>
      {isLoaded ? (
        <React.Fragment>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 col-8-sm col-6-lg d-flex flex-column p-5 content-area'>
                <img src='/public/img/gc-logo.svg' className='logo' />
                <h1 className='text-center'>Promo is open.</h1>
                <div className='notification static d-flex w-100 justify-content-center text-center mt-2 mb-3'>
                  {auth.isAuthenticated === 'true' ? (
                    <span>Click to claim your promo credits now!</span>
                  ) : (
                    <span>Sign up to QEMIST Cloud now and claim your free credits before the promotion closes!</span>
                  )}
                </div>
                <div className='button-container mt-3'>
                  {auth.isAuthenticated === 'true' ? (
                    <a className='btn' href={`/#/account/credits?promo=${promo}`}>
                      Claim Free Credits
                    </a>
                  ) : (
                    <a className='btn' href={`/promo-signup?promo=${promo}`}>
                      Claim Free Credits
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

export default Promo;
