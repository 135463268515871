import * as yup from 'yup';

import { SolverConfigObject, SolverObject } from '../../../../models/solvers';
import {
  buildValidationObj,
  getValidationKeys,
  buildErrorMessage,
  validateParam,
  buildParamKeys,
} from '../../functions/SubmitFormFunctions';

function gnina_validation_items(solver_name: string, solver_configs: any) {
  return {
    exhaustiveness__0: yup.string().test(
      'exhaustiveness',
      (value: any) => buildErrorMessage(value.value, 'exhaustiveness', solver_name, solver_configs),
      (value: any) => validateParam(value, 'exhaustiveness', solver_name, solver_configs)
    ),
    num_modes__0: yup.string().test(
      'num_modes',
      (value: any) => buildErrorMessage(value.value, 'num_modes', solver_name, solver_configs),
      (value: any) => validateParam(value, 'num_modes', solver_name, solver_configs)
    ),
    seed__0: yup.string().test(
      'seed',
      (value: any) => buildErrorMessage(value.value, 'seed', solver_name, solver_configs),
      (value: any) => validateParam(value, 'seed', solver_name, solver_configs)
    ),
  };
}

function box_options_items(solver_name: string, solver_configs: any) {
  return {
    center_x__1: yup.string().test(
      'center_x',
      (value: any) => buildErrorMessage(value.value, 'center_x', solver_name, solver_configs),
      (value: any) => validateParam(value, 'center_x', solver_name, solver_configs)
    ),
    center_y__1: yup.string().test(
      'center_y',
      (value: any) => buildErrorMessage(value.value, 'center_y', solver_name, solver_configs),
      (value: any) => validateParam(value, 'center_y', solver_name, solver_configs)
    ),
    center_z__1: yup.string().test(
      'center_z',
      (value: any) => buildErrorMessage(value.value, 'center_z', solver_name, solver_configs),
      (value: any) => validateParam(value, 'center_z', solver_name, solver_configs)
    ),
    size_x__1: yup.string().test(
      'size_x',
      (value: any) => buildErrorMessage(value.value, 'size_x', solver_name, solver_configs),
      (value: any) => validateParam(value, 'size_x', solver_name, solver_configs)
    ),
    size_y__1: yup.string().test(
      'size_y',
      (value: any) => buildErrorMessage(value.value, 'size_y', solver_name, solver_configs),
      (value: any) => validateParam(value, 'size_y', solver_name, solver_configs)
    ),
    size_z__1: yup.string().test(
      'size_z',
      (value: any) => buildErrorMessage(value.value, 'size_z', solver_name, solver_configs),
      (value: any) => validateParam(value, 'size_z', solver_name, solver_configs)
    ),
    autobox_add__1: yup.string().test(
      'autobox_add',
      (value: any) => buildErrorMessage(value.value, 'autobox_add', solver_name, solver_configs),
      (value: any) => validateParam(value, 'autobox_add', solver_name, solver_configs)
    ),
  };
}

function flex_options_items(solver_name: string, solver_configs: any) {
  return {
    flexres__2: yup.string().test(
      'flexres',
      (value: any) => buildErrorMessage(value.value, 'flexres', solver_name, solver_configs),
      (value: any) => validateParam(value, 'flexres', solver_name, solver_configs)
    ),
    flexdist__2: yup.string().test(
      'flexdist',
      (value: any) => buildErrorMessage(value.value, 'flexdist', solver_name, solver_configs),
      (value: any) => validateParam(value, 'flexdist', solver_name, solver_configs)
    ),
    flex_limit__2: yup.string().test(
      'flex_limit',
      (value: any) => buildErrorMessage(value.value, 'flex_limit', solver_name, solver_configs),
      (value: any) => validateParam(value, 'flex_limit', solver_name, solver_configs)
    ),
    flex_max__2: yup.string().test(
      'flex_max',
      (value: any) => buildErrorMessage(value.value, 'flex_max', solver_name, solver_configs),
      (value: any) => validateParam(value, 'flex_max', solver_name, solver_configs)
    ),
  };
}

function scoring_options_items(solver_name: string, solver_configs: any) {
  return {
    scoring__3: yup.string().test(
      'scoring',
      (value: any) => buildErrorMessage(value.value, 'scoring', solver_name, solver_configs),
      (value: any) => validateParam(value, 'scoring', solver_name, solver_configs)
    ),
    num_mc_steps__3: yup.string().test(
      'num_mc_steps',
      (value: any) => buildErrorMessage(value.value, 'num_mc_steps', solver_name, solver_configs),
      (value: any) => validateParam(value, 'num_mc_steps', solver_name, solver_configs)
    ),
    num_mc_saved__3: yup.string().test(
      'num_mc_saved',
      (value: any) => buildErrorMessage(value.value, 'num_mc_saved', solver_name, solver_configs),
      (value: any) => validateParam(value, 'num_mc_saved', solver_name, solver_configs)
    ),
    minimize_iters__3: yup.string().test(
      'minimize_iters',
      (value: any) => buildErrorMessage(value.value, 'minimize_iters', solver_name, solver_configs),
      (value: any) => validateParam(value, 'minimize_iters', solver_name, solver_configs)
    ),
    approximation__3: yup.string().test(
      'approximation',
      (value: any) => buildErrorMessage(value.value, 'approximation', solver_name, solver_configs),
      (value: any) => validateParam(value, 'approximation', solver_name, solver_configs)
    ),
    factor__3: yup.string().test(
      'factor',
      (value: any) => buildErrorMessage(value.value, 'factor', solver_name, solver_configs),
      (value: any) => validateParam(value, 'factor', solver_name, solver_configs)
    ),
    force_cap__3: yup.string().test(
      'force_cap',
      (value: any) => buildErrorMessage(value.value, 'force_cap', solver_name, solver_configs),
      (value: any) => validateParam(value, 'force_cap', solver_name, solver_configs)
    ),
  };
}

function cnn_options_items(solver_name: string, solver_configs: any) {
  return {
    cnn_scoring__4: yup.string().test(
      'cnn_scoring',
      (value: any) => buildErrorMessage(value.value, 'cnn_scoring', solver_name, solver_configs),
      (value: any) => validateParam(value, 'cnn_scoring', solver_name, solver_configs)
    ),
    cnn__4: yup.string().test(
      'cnn',
      (value: any) => buildErrorMessage(value.value, 'cnn', solver_name, solver_configs),
      (value: any) => validateParam(value, 'cnn', solver_name, solver_configs)
    ),
    cnn_rotation__4: yup.string().test(
      'cnn_rotation',
      (value: any) => buildErrorMessage(value.value, 'cnn_rotation', solver_name, solver_configs),
      (value: any) => validateParam(value, 'cnn_rotation', solver_name, solver_configs)
    ),
    cnn_empirical_weight__4: yup.string().test(
      'cnn_empirical_weight',
      (value: any) => buildErrorMessage(value.value, 'cnn_empirical_weight', solver_name, solver_configs),
      (value: any) => validateParam(value, 'cnn_empirical_weight', solver_name, solver_configs)
    ),
    cnn_center_x__4: yup.string().test(
      'cnn_center_x',
      (value: any) => buildErrorMessage(value.value, 'cnn_center_x', solver_name, solver_configs),
      (value: any) => validateParam(value, 'cnn_center_x', solver_name, solver_configs)
    ),
    cnn_center_y__4: yup.string().test(
      'cnn_center_y',
      (value: any) => buildErrorMessage(value.value, 'cnn_center_y', solver_name, solver_configs),
      (value: any) => validateParam(value, 'cnn_center_y', solver_name, solver_configs)
    ),
    cnn_center_z__4: yup.string().test(
      'cnn_center_z',
      (value: any) => buildErrorMessage(value.value, 'cnn_center_z', solver_name, solver_configs),
      (value: any) => validateParam(value, 'cnn_center_z', solver_name, solver_configs)
    ),
  };
}

export function buildSolverValidationObject(solver_pipeline: SolverObject[], solver_configs: SolverConfigObject) {
  const gnina_solver_name = solver_pipeline[0] ? solver_pipeline[0].solver_name : '';
  const box_options_name = solver_pipeline[1] ? solver_pipeline[1].solver_name : '';
  const flex_options_name = solver_pipeline[2] ? solver_pipeline[2].solver_name : '';
  const scoring_options_name = solver_pipeline[3] ? solver_pipeline[3].solver_name : '';
  const cnn_options_name = solver_pipeline[4] ? solver_pipeline[4].solver_name : '';

  const val_items = {
    ...gnina_validation_items(gnina_solver_name, solver_configs),
    ...box_options_items(box_options_name, solver_configs),
    ...flex_options_items(flex_options_name, solver_configs),
    ...scoring_options_items(scoring_options_name, solver_configs),
    ...cnn_options_items(cnn_options_name, solver_configs),
  };

  const param_keys = buildParamKeys(solver_pipeline);
  const validation_object = buildValidationObj(val_items, param_keys);
  const validation_keys = getValidationKeys(val_items, param_keys);

  return {
    validation_object,
    validation_keys,
  };
}
