import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

// Import hooks & hoc
import { useAuth } from '../../../hoc/Auth';
// prettier-ignore
import { getPaginationSets, formatCurrency, convertTimestampToDate, exportToCsv, initRangeStart, getRangeEnd, allRangeStart } from '../../../hoc/CommonFunctions';
import { determineExpiry, formatType } from '../../../hoc/CreditsCommonFuncs';
import { scrollToTop } from '../../../hoc/ScrollFunctions';

// Import global components
import { QTip } from '../../../components/tooltip/Tooltip';
import { LoadingBlock, LoadingInline } from '../../../components/loading/Loading';
import DatePicker, { ShowAllButton } from '../../../components/datepicker/DatePicker';
import Pagination, { PaginationSummary } from '../../../components/pagination/Pagination';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import QButton from '../../../components/button/Button';
import RefreshButton from '../../../components/refreshButton/RefreshButton';
import QModalBtn, { QModalText } from '../../../components/modal/Modal';
import EditCreditsModal from '../../../components/credits/EditCreditsModal';

// Import local components
import ConsolidateAliasesModal from './ConsolidateAliasesModal';
import GrantCreditsModal from './GrantCreditsModal';

const CreditPools = props => {
  // Init
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [timers, setTimers] = useState([]);
  // Get Credits Log
  const [setLength, setSetLength] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(49);
  const [rangeStart, setRangeStart] = useState(initRangeStart);
  const [rangeEnd, setRangeEnd] = useState(getRangeEnd());
  const [pages, setPages] = useState(1);
  const [error, setError] = useState(null);
  const [creditPools, setCreditPools] = useState({ credit_pools: [], total: '', summary: {}, owner_name: '' });
  const [names, setNames] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  // Grant Credits
  const [grantCreditsModalOpen, setGrantCreditsModalOpen] = useState(false);
  // Edit Credit Pool
  const [editCreditsModalOpen, setEditCreditsModalOpen] = useState(false);
  const [selectedPool, setSelectedPool] = useState({});
  // Export
  const [exporting, setExporting] = useState(false);
  // Consolidate Aliases
  const [consolidateModalOpen, setConsolidateModalOpen] = useState(false);
  // Refresh Credits Log
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  function fetchCreditPools(page, range_start, range_end) {
    let formatted_range_start = range_start.toFixed(0);
    let formatted_range_end = range_end.toFixed(0);

    const body = {
      group_or_user_id: props.id,
      range_start: formatted_range_start,
      range_end: formatted_range_end,
      offset: page - 1,
      limit: setLength,
    };

    fetchData(signal, '/api/get-credit-pools', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setIsRefreshing(false);
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        const data = response.data;
        setCreditPools(data);
        // If group_or_user_id has multiple owner names in log
        let owner_names = [];
        if (data.credit_pools) {
          data.credit_pools.forEach(item => {
            if (!owner_names.includes(item.owner_name)) {
              owner_names.push(item.owner_name);
            }
          });
          setNames(owner_names);
        }
        // Get pagination sets
        getPaginationSets(data.total, setLength, num => {
          if (num === 0) {
            setPages(1);
          } else {
            setPages(num);
          }
          setDataStart(page * setLength - setLength);
          setDataEnd(page * setLength - 1);
        });
        // Render content
        let timer_item_1 = setTimeout(() => {
          setIsLoaded(true);
          setDataLoaded(true);
          setIsRefreshing(false);
          setIsUpdating(false);
          scrollToTop('CreditPools');
          let timer_item_2 = setTimeout(() => setDataLoaded(false), 2000);
          timers.push(timer_item_2);
        }, 500);
        timers.push(timer_item_1);
      }
    });
  }

  useEffect(() => {
    fetchCreditPools(currentPage, rangeStart, rangeEnd);

    return () => {
      setIsLoaded(false);
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, []);

  function getOtherNames() {
    if (names) {
      let string = '';
      names.forEach(str => {
        if (string === '') {
          string = str;
        } else {
          string = string + ', ' + str;
        }
      });
      return string;
    } else return '';
  }

  function updatePage(page) {
    setCurrentPage(page);
    fetchCreditPools(page, rangeStart, rangeEnd);
  }

  // DateRangePicker
  function handleDateCallback(start, end) {
    const range_start = moment(start).unix();
    const range_end = moment(end).unix();

    setIsLoaded(false), setRangeStart(range_start), setRangeEnd(range_end);
    fetchCreditPools(currentPage, range_start, range_end);
  }

  function showAllTransactions() {
    const range_start = allRangeStart;
    const range_end = getRangeEnd();

    setIsLoaded(false), setRangeStart(range_start), setRangeEnd(range_end);
    fetchCreditPools(currentPage, range_start, range_end);
  }

  function exportData() {
    setExporting(true);

    // 1. Fetch all credit pools for time period
    let formatted_range_start = rangeStart;
    let formatted_range_end = rangeEnd;
    if (rangeStart) {
      formatted_range_start = rangeStart.toFixed(0);
    }
    if (rangeEnd) {
      formatted_range_end = rangeEnd.toFixed(0);
    }
    const body = {
      range_start: formatted_range_start,
      range_end: formatted_range_end,
      group_or_user_id: props.id,
    };

    fetchData(signal, '/api/get-credit-pools', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        let credit_groups_data = response.data.credit_pools;

        // 2. Format for CSV
        let rows = [
          [
            'credit_pool_id',
            'credit_pool_name',
            'group_or_user_id',
            'owner_name',
            'date_granted',
            'expiration',
            'amount_granted',
            'amount_remaining',
            'credits_type',
            'notes',
          ],
        ];

        if (credit_groups_data) {
          credit_groups_data.forEach(row => {
            let row_data = [
              row.credit_pool_id,
              row.credit_pool_name,
              row.group_or_user_id,
              row.owner_name,
              moment.unix(row.date_granted).format('MMMM D YYYY h:mm:ss a'),
              moment.unix(row.expiration).format('MMMM D YYYY h:mm:ss a'),
              row.amount_granted,
              row.amount_remaining,
              row.credits_type,
              row.notes,
            ];
            rows.push(row_data);
          });
        }

        exportToCsv(rows, rangeStart, rangeEnd, props.id);
        setExporting(false);
      }
    });
  }

  function toggleGrantCreditsModal() {
    setGrantCreditsModalOpen(!grantCreditsModalOpen);
    fetchCreditPools(currentPage, rangeStart, rangeEnd);
    setIsUpdating(true);
  }

  function toggleEditCreditsModal() {
    setEditCreditsModalOpen(!editCreditsModalOpen);
    fetchCreditPools(currentPage, rangeStart, rangeEnd);
    setIsUpdating(true);
  }

  function editCredits(pool) {
    setEditCreditsModalOpen(true);
    setSelectedPool(pool);
  }

  function toggleConsolidateModal() {
    setConsolidateModalOpen(!consolidateModalOpen);
    fetchCreditPools(currentPage, rangeStart, rangeEnd);
    setIsUpdating(true);
  }

  function refreshCreditPools() {
    setIsRefreshing(true);
    fetchCreditPools(currentPage, rangeStart, rangeEnd);
  }

  const credit_pools = Array.from(creditPools.credit_pools).map((item, index) => (
    <div key={index} className='row-container'>
      <div className={`row list item ${determineExpiry(item.expiration)}`}>
        <div className='col-6 col-sm-3 col-lg-3 flex-column align-items-start'>
          {/* Pool ID */}
          {item.credit_pool_id ? (
            <input className='text-xs' value={item.credit_pool_id} data-testid='credit-pool-id-input' readOnly />
          ) : (
            ''
          )}
          {/* Credit Pool Name */}
          {item.credit_pool_name ? (
            <input className='text-xs' value={item.credit_pool_name} data-testid='credit-pool-name-input' readOnly />
          ) : (
            ''
          )}
          {/* Determine Expiration */}
          {determineExpiry(item.expiration) === 'expired' ? (
            <span className='bold pl-1'>Expired</span>
          ) : determineExpiry(item.expiration) === 'expires_in_2_weeks' ? (
            <span className='bold pl-1'>About to expire</span>
          ) : (
            <React.Fragment>
              {/* Out of credits */}
              {item.amount_remaining <= 0 && <span className='bold pl-1'>Out of credits</span>}
              {/* Amount Running low */}
              {item.amount_remaining > 0 && item.amount_remaining < 100 && (
                <span className='bold pl-1'>Credits running low</span>
              )}
            </React.Fragment>
          )}
        </div>
        <div className='col-6 col-sm-3 col-lg-2 flex-column align-items-start justify-content-center'>
          {/* Date Granted */}
          {item.date_granted ? (
            <div className='date-granted'>{convertTimestampToDate(item.date_granted, 'medium')}</div>
          ) : (
            ''
          )}
          {/* Expiration */}
          {item.expiration ? (
            <div
              className={`bold ${
                determineExpiry(item.expiration) === 'expired'
                  ? 'danger'
                  : determineExpiry(item.expiration) === 'expires_in_2_weeks'
                  ? 'warning'
                  : ''
              }`}>
              {convertTimestampToDate(item.expiration, 'medium')}
            </div>
          ) : (
            ''
          )}
        </div>
        {/* Amount Granted */}
        <div className='col-6 col-sm-3 col-lg-2'>
          {item.amount_granted !== undefined && item.amount_granted !== null ? (
            <span className='amount-granted'>{formatCurrency(item.amount_granted)}</span>
          ) : (
            ''
          )}
        </div>
        {/* Amount Remaining */}
        <div className='col-6 col-sm-3 col-lg-2'>
          {item.amount_remaining !== undefined && item.amount_remaining !== null ? (
            <span
              className={`text-xs bold ${
                item.amount_remaining <= 0 || determineExpiry(item.expiration) === 'expired'
                  ? 'danger'
                  : item.amount_remaining < 100
                  ? 'warning'
                  : ''
              }`}>
              {formatCurrency(item.amount_remaining)}
            </span>
          ) : (
            ''
          )}
        </div>
        {/* Credits Type */}
        <div className='col-6 col-sm-3 col-lg-1'>{item.credits_type ? formatType(item.credits_type) : ''}</div>
        {/* Other */}
        <div className='col-6 col-sm-3 col-lg-2 d-flex flex-wrap align-items-center'>
          <QButton className='btn-link mr-3' onClick={() => editCredits(item)}>
            Edit
          </QButton>
          {item.notes && (
            <QTip
              content={item.notes}
              trigger='mouseenter click'
              duration={100}
              placement='top-end'
              followCursor={false}
              hideOnClick={true}
              offset={[-26, -12]}>
              <i data-testid='notes-icon' className='icon-documentation' />
            </QTip>
          )}
        </div>
      </div>
    </div>
  ));

  return (
    <div id='CreditPools' data-testid='credit-pools'>
      <div className='content'>
        {!isLoaded ? (
          <LoadingBlock />
        ) : (
          <React.Fragment>
            {/* Credit Pools Heading */}
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12 d-flex align-items-start py-2 grey-bg'>
                  <div className='d-flex flex-wrap align-items-center pr-3'>
                    {/* Credit Group Name */}
                    <h2 className='mb-0 mr-4 '>{creditPools.owner_name}</h2>
                    {/* Total Credit Pools */}
                    <div className='d-flex align-items-center my-2 mr-4'>
                      <span className='text-xs p-text-light mr-2'>Available Credit Pools:</span>
                      <span className='bold'>{creditPools.summary.credit_pools}</span>
                    </div>
                    {/* Total Credits Remaining */}
                    <div className='d-flex align-items-center mr-4'>
                      <span className='text-xs p-text-light mr-2'>Total Remaining Credits:</span>
                      <span
                        className={`bold ${
                          creditPools.summary.credits_outstanding <= 0
                            ? 'danger'
                            : creditPools.summary.credits_outstanding < 100
                            ? 'warning'
                            : 'good'
                        }`}>
                        {formatCurrency(creditPools.summary.credits_outstanding)}
                      </span>
                    </div>
                    {/* Group / User ID */}
                    <div className='d-flex align-items-center'>
                      <span className='text-xs p-text-light mr-2'>Group/User ID:</span>
                      <span>
                        <input
                          className='text-xs group_or_user_id'
                          value={props.id}
                          data-testid='group-or-user-id-input'
                          readOnly
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Credit Pools Details */}
            <div className='credit-pools-list'>
              <div className='container-fluid'>
                {/* Other Names */}
                {names.length > 1 && (
                  <div className='row border-bottom'>
                    <div className='col-12 d-flex flex-wrap justify-content-between align-items-center py-2'>
                      <div className='d-flex align-items-center'>
                        <span className='text-xs p-text-light mr-2'>Aliases:</span>
                        {getOtherNames()}
                      </div>
                      <span className='link mr-2 mr-md-0'>
                        <QModalText
                          text='Consolidate Aliases'
                          onHide={() => setConsolidateModalOpen(!consolidateModalOpen)}
                          show={consolidateModalOpen}
                          id='ConsolidateAliasesModal'
                          size='lg'>
                          {consolidateModalOpen ? (
                            <ConsolidateAliasesModal
                              id={props.id}
                              names={names}
                              ownerName={creditPools.owner_name}
                              toggleConsolidateModal={() => toggleConsolidateModal()}
                            />
                          ) : (
                            ''
                          )}
                        </QModalText>
                      </span>
                    </div>
                  </div>
                )}
                {/* Datepicker */}
                <div className='row'>
                  <div className='col-12 d-flex align-items-center py-2'>
                    <div className='d-flex w-100 justify-content-between flex-column flex-lg-row'>
                      {/* Left Content */}
                      <div className='d-flex flex-wrap align-items-center'>
                        <span className='mr-2'>Granted between:</span>
                        <div className='d-flex align-items-center'>
                          <DatePicker
                            rangeStart={rangeStart}
                            rangeEnd={rangeEnd}
                            handleCallback={(start, end) => handleDateCallback(start, end)}
                          />
                          <ShowAllButton className='mr-4' onClick={() => showAllTransactions()} />
                        </div>
                      </div>
                      {/* Right Content */}
                      <div className='d-flex flex-row-reverse flex-lg-row justify-content-end justify-content-lg-start align-items-center'>
                        {/* Export Button */}
                        {creditPools.credit_pools.length > 0 && (
                          <React.Fragment>
                            {exporting ? (
                              <div className='p-2' data-testid='exporting-spinner'>
                                <LoadingInline size='sm' />
                              </div>
                            ) : (
                              <QButton className='btn-link p-2 ml-2 mr-lg-2' onClick={() => exportData()}>
                                Export
                              </QButton>
                            )}
                          </React.Fragment>
                        )}
                        <RefreshButton
                          className='mr-2'
                          isRefreshing={isRefreshing}
                          dataLoaded={dataLoaded}
                          refresh={() => refreshCreditPools()}
                        />
                        <QModalBtn
                          btnClassName='small my-2 my-lg-0 grant-credits-btn'
                          buttonText='Grant Credits'
                          onHide={() => setGrantCreditsModalOpen(!grantCreditsModalOpen)}
                          show={grantCreditsModalOpen}
                          id='GrantCreditsModal'
                          size='lg'>
                          {grantCreditsModalOpen ? (
                            <GrantCreditsModal
                              id={props.id}
                              names={names}
                              ownerName={creditPools.owner_name}
                              toggleGrantCreditsModal={() => toggleGrantCreditsModal()}
                            />
                          ) : (
                            ''
                          )}
                        </QModalBtn>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Pagination */}
                <div className='row'>
                  <div className='col-12 d-flex align-items-center py-2'>
                    <Pagination
                      pages={pages}
                      currentPage={currentPage}
                      updatePage={value => updatePage(value)}
                      updating={value => setIsUpdating(value)}
                    />
                    <PaginationSummary
                      dataTotal={creditPools.total ? parseInt(creditPools.total) : null}
                      dataStart={dataStart}
                      dataEnd={dataEnd}
                      dataType={'credit pools'}
                    />
                  </div>
                </div>
                {/* Credit Pools List */}
                {/* Header */}
                <div className='row list header border-bottom'>
                  <div className='col-6 col-sm-3 col-lg-3'>Credits ID & Name</div>
                  <div className='col-6 col-sm-3 col-lg-2'>Date Granted & Expiration</div>
                  <div className='col-6 col-sm-3 col-lg-2'>Amt Granted</div>
                  <div className='col-6 col-sm-3 col-lg-2'>Amt Remaining</div>
                  <div className='col-6 col-sm-3 col-lg-1'>Type</div>
                  <div className='col-6 col-sm-3 col-lg-2'>Other</div>
                </div>
                {isUpdating ? (
                  <LoadingBlock />
                ) : (
                  <React.Fragment>
                    {error ? (
                      <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
                    ) : (
                      <React.Fragment>
                        {creditPools.credit_pools.length > 0 ? (
                          <div className='row'>
                            <div className='col-12'>{credit_pools}</div>
                            <Modal
                              show={editCreditsModalOpen}
                              onHide={() => setEditCreditsModalOpen(!editCreditsModalOpen)}
                              backdrop='static'
                              centered
                              size='lg'
                              id='RemoveMemberModal'
                              className='q-modal-component'>
                              <Modal.Body>
                                <div className='q-modal-body'>
                                  <i
                                    className='icon-close'
                                    onClick={() => setEditCreditsModalOpen(!editCreditsModalOpen)}
                                  />
                                  {editCreditsModalOpen ? (
                                    <EditCreditsModal
                                      data={selectedPool}
                                      names={names}
                                      toggleEditCreditsModal={() => toggleEditCreditsModal()}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        ) : (
                          <div className='row'>
                            <div className='col-12 py-2'>No credit pools found.</div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

CreditPools.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CreditPools;
