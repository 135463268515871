import React, { useState, useEffect } from 'react';

// Components
import { useAuth } from '../hoc/Auth';
import { triggerLogout } from '../hoc/CommonFunctions';
import { QErrorMessage } from '../components/notifications/Notifications';
import { LoadingInline } from '../components/loading/Loading';

const Login = () => {
  // Init
  const [isLoaded, setIsLoaded] = useState(false);
  const { fetchData } = useAuth();
  const auth = useAuth();
  const [timers, setTimers] = useState([]);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Resend Verification Email
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [emailResent, setEmailResent] = useState(false);
  // Countdown to Logout
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true), 500;
    });
    timers.push(timer);

    if (auth.serviceFailed || auth.sessionTimeout) {
      const counter = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      timers.push(counter);

      if (countdown === 0) {
        triggerLogout();
      }
    }

    return () => {
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, [auth, countdown]);

  function clearSession() {
    sessionStorage.clear();
  }

  function getEmail() {
    let user_email_partial = window.location.href.split('user_email')[1];
    let user_email = user_email_partial.split('user_id')[0];
    return decodeURIComponent(user_email);
  }

  function resendVerifyEmail() {
    setIsSending(true);
    let user_id_partial = window.location.href.split('user_id')[1];
    let user_id = user_id_partial.split('#')[0];

    const body = {
      user_id: user_id
    };
    fetchData(signal, '/send-verification-email', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
        setIsSending(false);
      }
      // if data is returned
      if (response.status === 'success') {
        setEmailResent(true);
        setIsSending(false);
        setTimeout(() => {
          setEmailResent(false);
        }, 5000);
      }
    });
  }

  /* -----
  '/get-tokens?token-redirect=false&error=unauthorized&error_description=unverified_account' will become legacy code when we remove email verification from Auth0 Rules.

  Email verification is duplicated in Auth0 Actions and the following code will cover both implementations.

  When Auth0 Rules are switched off for all deployments we'll clean up this file.
  -------- */

  return (
    <div id='LoginComponent'>
      {isLoaded ? (
        <React.Fragment>
          {auth.sessionTimeout && <QErrorMessage text='Your session has timed out. Please log in again to continue.' />}
          {auth.serviceFailed && (
            <QErrorMessage text="Sorry we're having some technical difficulties. Please try again later." />
          )}
          {/* Token error redirect */}
          {window.location.href.includes('/redirect?from=token-redirect') && (
            <div className='error-wrapper'>
              <QErrorMessage
                text={
                  <React.Fragment>
                    <p className='bold mb-0'>Unable to authorize.</p>
                    <p className='mb-0'>
                      Your API token expires after 7 days for security purposes. Please log in, get a new token, and try
                      again.
                    </p>
                  </React.Fragment>
                }
              />
            </div>
          )}
          {/* Error in url param */}
          {window.location.href.includes('error=unauthorized&error_description=Access%20denied.') && (
            <div className='error-wrapper'>
              <QErrorMessage
                text={
                  <span className='bold'>
                    Sorry, access to QEMIST Cloud is currently restricted. Please contact your QEMIST Cloud rep for more
                    details.
                  </span>
                }
              />
            </div>
          )}
          {window.location.href.includes('error=unauthorized&error_description=unverified_account') ||
          window.location.href.includes('error=access_denied&error_description=unverified_account') ? (
            <div className='error-wrapper'>
              <QErrorMessage
                className='verify-email warn'
                text={
                  <div>
                    <p>
                      Please verify your email <span className='accent'>{getEmail()} </span>to continue.
                    </p>
                    {isSending ? (
                      <LoadingInline />
                    ) : (
                      <p className='link' onClick={() => resendVerifyEmail()}>
                        <span className='mr-2'>Resend Verification Email</span>
                        {emailResent && <span className='badge'>Email sent</span>}
                      </p>
                    )}
                  </div>
                }
              />
            </div>
          ) : (
            <React.Fragment>
              {window.location.href.includes('error=access_denied') && (
                <div className='error-wrapper'>
                  <QErrorMessage
                    text={
                      <span className='bold'>
                        Sorry, access to QEMIST Cloud is currently restricted. Please contact your QEMIST Cloud rep for
                        more details.
                      </span>
                    }
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {window.location.href.includes('error=password_leaked') && (
            <div className='error-wrapper'>
              <QErrorMessage
                text={
                  <span className='bold'>
                    Sorry there's a problem with your user password. Try resetting your password with the Forgot
                    Password flow located in the login window.
                  </span>
                }
              />
            </div>
          )}
          {window.location.href.includes('error=too_many_attempts') && (
            <div className='error-wrapper'>
              <QErrorMessage
                text={
                  <span className='bold'>
                    Sorry, the account is blocked due to too many attempts to sign in. Please try again later.
                  </span>
                }
              />
            </div>
          )}
          {/* LOGIN CONTENT */}
          <div id='LoginContent' data-testid='login-component'>
            {auth.serviceFailed || auth.sessionTimeout ? (
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <h3 className='m-0'>Redirecting you in... {countdown}</h3>
                    <a className='btn mt-2' href='/logout' onClick={() => clearSession()}>
                      Start Over Now
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className='login-box'>
                <img src='/public/img/gc-logo.svg' className='logo' />
                <h1>Welcome to QEMIST Cloud</h1>
                <p>Please contact your Good Chemistry rep if you need help accessing your QEMIST Cloud account.</p>
                <div className='button-container'>
                  {window.location.href.includes('/redirect?from=token-redirect') ? (
                    <a className='btn' href='/login?token-redirect=true' onClick={() => clearSession()}>
                      Log In
                    </a>
                  ) : (
                    <a
                      className='btn'
                      href={
                        window.location.href.includes('error=unauthorized&error_description=unverified_account') ||
                        window.location.href.includes('error=access_denied&error_description=unverified_account')
                          ? '/logout'
                          : '/login'
                      }
                      onClick={() => clearSession()}>
                      {window.location.href.includes('error=unauthorized&error_description=unverified_account') ||
                      window.location.href.includes('error=access_denied&error_description=unverified_account')
                        ? 'Start Over'
                        : 'Log In'}
                    </a>
                  )}
                  {window.location.href.includes('error=unauthorized&error_description=unverified_account') ||
                    (window.location.href.includes('error=access_denied&error_description=unverified_account') && (
                      <a className='btn ml-2' href='/login'>
                        Email Verified
                      </a>
                    ))}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

export default Login;
