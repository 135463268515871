import React from 'react';
import PropTypes from 'prop-types';

import { DisplayCode } from '../../../components/codeBlock/CodeBlock';
import QDropdown, { Toggle, Menu, Item } from '../../../components/dropdown/Dropdown';
import QButton from '../../../components/button/Button';

const SubmitTextGroup = props => (
  <div className={`submit-text-group ${props.className}`}>
    <div className='label-sm'>{props.label}</div>
    <div className='my-2'>
      <DisplayCode>{props.content}</DisplayCode>
    </div>
  </div>
);

SubmitTextGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  content: PropTypes.any,
};

export const SubmitGroupLabel = props => (
  <div className={`submit-group-label label mb-4 ${props.className}`}>{props.text}</div>
);

SubmitGroupLabel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export const SubmitInputGroup = props => {
  return (
    <div id={props.id} className={`submit-input-row row border mx-0 mb-3 ${props.containerClassName}`}>
      <div className={`col-12 p-3 ${props.className}`}>{props.children}</div>
    </div>
  );
};

SubmitInputGroup.propTypes = {
  children: PropTypes.any.isRequired,
  id: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
};

export const ResetDropdown = props => {
  return (
    <QDropdown id='more-dropdown'>
      <Toggle dataTestId='reset-dropdown-toggle'>
        <i className='icon-more' />
      </Toggle>
      <Menu>
        {props.discardChanges && <Item onClick={props.discardChanges}>Discard Changes</Item>}
        <Item onClick={props.resetChanges}>Reset to Defaults</Item>
      </Menu>
    </QDropdown>
  );
};

ResetDropdown.propTypes = {
  discardChanges: PropTypes.func,
  resetChanges: PropTypes.func.isRequired,
};

export default SubmitTextGroup;
