import React from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

// Components
import {
  QTextInput,
  QTextareaInput,
  QTextareaAutoResize,
  QPasswordInput,
  QSelect,
  QFormErrorMessage,
} from './FormElements';

export const InlineFormGroup = props => (
  <div className={`row d-flex align-items-center inline-form-group ${props.containerClassName}`}>
    <label className={`col-12 col-md-3 pr-3 mb-0 mb-md-3 label-sm dark ${props.labelClassName}`}>{props.label}</label>
    <FormGroup className='col-12 col-md-9'>
      {props.element === 'text' && (
        <React.Fragment>
          <QTextInput
            id={props.id} // string
            name={props.name} // string
            className={props.className} // string
            onChange={props.onChange} // function
            onKeyDown={props.onKeyDown}
            onBlur={props.onBlur}
            type={props.type} // string
            helperText={props.helperText} // node
            placeholder={props.placeholder} // string
            inputProps={props.inputProps} // object
            autoComplete={props.autoComplete} // string
            autoFocus={props.autoFocus} // boolean
            defaultValue={props.defaultValue} // any
            disabled={props.disabled} // boolean
            fullWidth={props.fullWidth} // boolean
            required={props.required} // boolean
            value={props.value} // any
            variant={props.variant} // 'filled' | 'outlined' | 'standard' (default)
          />
          {props.children}
        </React.Fragment>
      )}
      {props.element === 'password' && (
        <React.Fragment>
          <QPasswordInput
            id={props.id} // string
            name={props.name} // string
            label={props.label} // node
            className={props.className} // string
            onChange={props.onChange} // function
            onKeyDown={props.onKeyDown}
            onBlur={props.onBlur}
            helperText={props.helperText} // node
            placeholder={props.placeholder} // string
            inputProps={props.inputProps} // object
            autoComplete={props.autoComplete} // string
            autoFocus={props.autoFocus} // boolean
            defaultValue={props.defaultValue} // any
            disabled={props.disabled} // boolean
            fullWidth={props.fullWidth} // boolean
            required={props.required} // boolean
            value={props.value} // any
            variant={props.variant} // 'filled' | 'outlined' | 'standard' (default)
          />
          {props.children}
        </React.Fragment>
      )}
      {props.element === 'textarea' && (
        <QTextareaInput
          id={props.id} // string
          name={props.name} // string
          label={props.label} // node
          className={props.className}
          onChange={props.onChange} // function
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          type={props.type} // string
          helperText={props.helperText} // node
          placeholder={props.placeholder} // string
          inputProps={props.inputProps} // object
          autoComplete={props.autoComplete} // string
          autoFocus={props.autoFocus} // boolean
          defaultValue={props.defaultValue} // any
          disabled={props.disabled} // boolean
          fullWidth={props.fullWidth} // boolean
          multiline={true}
          required={props.required} // boolean
          rows={props.rows} // number or string
          rowsMax={props.rowsMax} // number or string
          value={props.value} // any
          variant={props.variant} // 'filled' | 'outlined' | 'standard' (default)
        />
      )}
      {props.element === 'textarea-resize' && (
        <QTextareaAutoResize
          id={props.id} // string
          name={props.name} // string
          label={props.label} // node
          className={props.className}
          onChange={props.onChange} // function
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
          type={props.type} // string
          helperText={props.helperText} // node
          placeholder={props.placeholder} // string
          autoComplete={props.autoComplete} // string
          autoFocus={props.autoFocus} // boolean
          defaultValue={props.defaultValue} // any
          disabled={props.disabled} // boolean
          multiline={true}
          required={props.required} // boolean
          rows={props.rows} // number or string
          rowsMax={props.rowsMax} // number or string
          value={props.value} // any
          variant={props.variant} // 'filled' | 'outlined' | 'standard' (default)
        />
      )}
      {props.element === 'select' && (
        <React.Fragment>
          <QSelect
            id={props.id}
            name={props.name}
            className={props.className}
            selectClasses={props.selectClassName}
            inputProps={props.inputProps}
            onChange={props.onChange}
            value={props.value}
            defaultValue={props.defaultValue}
            menu={props.selectMenu}
          />
        </React.Fragment>
      )}
      {props.includeError && <QFormErrorMessage name={props.name} />}
      {props.showButtons && (
        <div className='button-group'>
          <i
            className={`icon-circle-check mr-1 ${!props.isValid ? 'disabled' : ''}`}
            onClick={props.apply}
            data-testid='apply-icon'
          />
          <i className='icon-circle-ex' onClick={props.cancel} data-testid='cancel-icon' />
        </div>
      )}
    </FormGroup>
  </div>
);

InlineFormGroup.propTypes = {
  element: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  required: PropTypes.bool,
  includeError: PropTypes.bool.isRequired,
  showButtons: PropTypes.bool,
  isValid: PropTypes.bool,
  apply: PropTypes.func,
  cancel: PropTypes.func,
  children: PropTypes.any,
  // QSelect specific
  selectClassName: PropTypes.string,
  selectMenu: PropTypes.array,
  // QTextInput, QPasswordInput & QTextareaInput specific
  inputClassName: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  // QTextareaInput specific
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InlineFormGroup;
