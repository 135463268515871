import React from 'react';
import PropTypes from 'prop-types';

export const QErrorMessage = props => (
  <div
    id={props.id}
    className={`d-flex flex-column align-items-start justify-content-start error-message ${props.className}`}
    data-testid='notification-error'>
    <div className='d-flex align-items-center'>
      <i className='icon-caution' />
      <div className='text'>{props.text}</div>
    </div>
    {props.children}
  </div>
);

export const QSuccessMessage = props => (
  <div
    id={props.id}
    className={`d-flex flex-column align-items-start justify-content-start success-message ${props.className}`}
    data-testid='notification-success'>
    <div className='d-flex align-items-center'>
      <i className='icon-check' />
      <div className='text'>{props.text}</div>
    </div>
    {props.children}
  </div>
);

const propTypes = {
  id: PropTypes.string,
  text: PropTypes.node.isRequired,
  className: PropTypes.string,
  linkText: PropTypes.string,
  linkPath: PropTypes.string,
  children: PropTypes.node,
};

QErrorMessage.propTypes = propTypes;
QSuccessMessage.propTypes = propTypes;
