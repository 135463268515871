import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import TransitionWrapper from './components/TransitionWrapper';
import ViewCreditsReport from './creditsOverview/ViewCreditsReport';

const CreditsRouter = props => {
  // Init
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <React.Fragment>
      <div id='CreditsRouter'>
        <TransitionWrapper isLoaded={isLoaded} location={props.location}>
          <Route path='/admin/credits-overview' render={() => <ViewCreditsReport />} />
        </TransitionWrapper>
      </div>
    </React.Fragment>
  );
};

CreditsRouter.propTypes = {
  location: PropTypes.object.isRequired
};

export default CreditsRouter;
