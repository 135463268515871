import React, { useEffect } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';

// Components
import { useUserData } from '../hoc/UserData';
import AdminTopNav from './AdminTopNav';
import UsersList from '../views/admin/usersList/UsersList';
import GroupsList from '../views/admin/groupsList/GroupsList';
import GroupDetails from '../views/admin/GroupDetails';
import UserDetails from '../views/admin/UserDetails';
import MetricsRouter from '../views/admin/MetricsRouter';
import CreditsRouter from '../views/admin/CreditsRouter';
import StatusDashboard from '../views/admin/statusDashboard/StatusDashboard';

const AdminLayout = props => {
  const { user } = useUserData();

  useEffect(() => {}, [user]);

  return (
    <div id='AdminLayout'>
      <AdminTopNav />
      <HashRouter>
        {user.role === 'qemist_admin' ? (
          <Switch>
            <Route exact path='/admin/status' component={StatusDashboard} />
            <Route exact path='/admin/groups' component={GroupsList} />
            <Route path='/admin/users' component={UsersList} />
            <Route path='/admin/group/*' component={GroupDetails} />
            <Route path='/admin/user/*' component={UserDetails} />
            <Route path='/admin/metrics*' component={MetricsRouter} />
            <Route path='/admin/credits*' component={CreditsRouter} />
            <Route path='/admin'>
              <Redirect to='/admin/status' />
            </Route>
          </Switch>
        ) : (
          <Route path='*'>
            <Redirect to='/projects' />
          </Route>
        )}
      </HashRouter>
    </div>
  );
};

export default AdminLayout;
