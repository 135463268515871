import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import TransitionWrapper from './components/TransitionWrapper';
import ViewMetrics from './metrics/ViewMetrics';

const MetricsRouter = props => {
  // Init
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <React.Fragment>
      <div id='Metrics'>
        <TransitionWrapper isLoaded={isLoaded} location={props.location}>
          <Route exact path='/admin/metrics' render={() => <ViewMetrics />} />
          <Route path='/admin/metrics/*'>
            <Redirect to='/admin/metrics' />
          </Route>
        </TransitionWrapper>
      </div>
    </React.Fragment>
  );
};

MetricsRouter.propTypes = {
  location: PropTypes.object.isRequired
};

export default MetricsRouter;
