import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import mixpanel from 'mixpanel-browser';

// Components
import { useAuth } from '../../hoc/Auth';
import { useUserData } from '../../hoc/UserData';
import { sortProjectList } from '../../hoc/CommonFunctions';
import { browserStore } from '../../hoc/BrowserStorage';
import { LoadingFilled, LoadingBlock } from '../../components/loading/Loading';
import { QErrorMessage } from '../../components/notifications/Notifications';
import QModalBtn, { QModal } from '../../components/modal/Modal';
import CreateNewProjectModal from './CreateProject';
import CopyToClipboard, { copyToClipboard } from '../../components/copyToClipboard/CopyToClipboard';
import ProjectMembers from './ProjectMembers';
import RenameProjectModal from './RenameProject';
import DeleteProjectModal from './DeleteProject';

const Projects = props => {
  // Init
  const { fetchData, fetchUserProjects } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const { user, orgIDs, currentOrg, projects, setProjects, projectsOrder, setProjectsOrder, setUpdatingProjects } =
    useUserData();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Projects
  const [fetchError, setFetchError] = useState(null);
  // New Project
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [createProjectError, setCreateProjectError] = useState(null);
  // Rename Project
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [renameProjectModalOpen, setRenameProjectModalOpen] = useState(false);
  const [renameError, setRenameError] = useState(null);
  const [projectRenamed, setProjectRenamed] = useState(null);
  const [renameBadgeHidden, setRenameBadgeHidden] = useState(true);
  // Delete Project
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const sortOrderName = `${user.userid}_${currentOrg._id || 'personal'}_project.sort_order`;
  // Responsive
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // Project Members
  const [selectedProject, setSelectedProject] = useState(null);
  const [membersModalOpen, setMembersModalOpen] = useState(false);

  useEffect(() => {
    // Get sort order from session storage
    const stored_order = browserStore('get', sortOrderName);
    if (stored_order !== null) {
      setProjectsOrder(stored_order);
    } else {
      browserStore('set', sortOrderName, 'asc');
    }

    // Event Tracking
    if (MIXPANEL_TOKEN && firstRender) {
      mixpanel.time_event('Projects Component: Load');
      mixpanel.track('Projects Component: Mounted');
    }
    setFirstRender(false);

    // Delay render to allow projects data to load
    const loadData = setTimeout(() => {
      setIsLoaded(true);
      setIsShown(true);
    }, 500);

    return () => {
      setFirstRender(true);
      abortController.abort();
      clearTimeout(loadData);
    };
  }, []);

  function updateOrder() {
    const new_order = projectsOrder === 'asc' ? 'desc' : 'asc';
    setProjectsOrder(new_order);
    browserStore('set', sortOrderName, new_order);
    sortProjectList(projects, new_order, sorted_projects => {
      setProjects(sorted_projects);
    });
  }

  function createNewProject(values) {
    setCreateProjectError(null);
    setLoadingProjects(true);

    const body = {
      name: values.name,
      description: values.description || '-',
      org_id: currentOrg._id,
      org_ids: orgIDs,
    };
    fetchData(signal, '/api/create-project', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingProjects(false);
        setCreateProjectError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const data = response.data;
        setUpdatingProjects(true);

        sortProjectList(data, projectsOrder, sorted_projects => {
          setProjects(sorted_projects);
          setLoadingProjects(false);
        });

        toggleNewProjectModal();
      }
    });
  }

  function renameProject(values) {
    setRenameError(null);
    setLoadingProjects(true);

    const body = {
      user_id: user.userid,
      org_id: currentOrg._id,
      org_ids: orgIDs,
      group_id: selectedProject._id,
      new_name: values.name,
      description: values.description || '-',
    };
    fetchData(signal, '/api/update-project', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingProjects(false);
        setRenameError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setLoadingProjects(false);
        const updated_projects = response.data;

        // Update stored project name
        const id = currentOrg._id || 'personal';
        const stored_project = browserStore('get', `${id}.project`);
        if (stored_project !== null && stored_project._id === selectedProject._id) {
          updated_projects.forEach(project => {
            if (project._id === stored_project._id) {
              browserStore('set', `${id}.project`, project);
            }
          });
        }
        setUpdatingProjects(true);

        // Show project renamed badge
        setProjectRenamed(selectedProject);
        setRenameBadgeHidden(false);
        setTimeout(() => {
          setRenameBadgeHidden(true);
          setProjectRenamed(null);
        }, 8000);

        // Refresh projects list
        const data = response.data;
        sortProjectList(data, projectsOrder, sorted_projects => {
          setProjects(sorted_projects);
          setLoadingProjects(false);
        });

        // Dismiss edit project modal
        toggleEditProjectModal(null);
      }
    });
  }

  function deleteProject() {
    setLoadingProjects(true);
    const body = {
      user_id: user.userid,
      org_id: currentOrg._id,
      org_ids: orgIDs,
      group_id: selectedProject._id,
    };
    fetchData(signal, '/api/delete-project', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingProjects(false);
        setDeleteError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setUpdatingProjects(true);
        sortProjectList(response.data, projectsOrder, sorted_projects => {
          setProjects(sorted_projects);
          setLoadingProjects(false);
        });
        toggleDeleteProjectModal(null);
      }
    });
  }

  function updateProjects() {
    setFetchError(null);

    fetchUserProjects(signal, user.userid, currentOrg._id, orgIDs, response => {
      // if error is returned
      if (response.status === 'error') {
        setFetchError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        // Update projects
        sortProjectList(response.data, projectsOrder, sorted_projects => {
          setProjects(sorted_projects);
        });
      }
    });
  }

  function toggleNewProjectModal() {
    setNewProjectModalOpen(!newProjectModalOpen);
  }

  function toggleProjectMembersModal(project) {
    setSelectedProject(project);
    setMembersModalOpen(!membersModalOpen);
  }

  function toggleEditProjectModal(project) {
    setSelectedProject(project);
    setRenameProjectModalOpen(!renameProjectModalOpen);
  }

  function toggleDeleteProjectModal(project) {
    setSelectedProject(project);
    setDeleteProjectModalOpen(!renameProjectModalOpen);
  }

  function listProjects(projects) {
    return Array.from(projects).map((project, index) => (
      <div key={index} className='col-12 list item static'>
        <div className='row align-items-start'>
          <div className='col-12 col-md-4 d-flex flex-column align-items-start'>
            <div className='d-flex align-items-center project-name'>
              <span className='bold'>{project.name}</span>
              {projectRenamed && projectRenamed._id == project._id ? (
                <span className={`renamed ${renameBadgeHidden ? 'hidden' : ''}`}>Updated!</span>
              ) : (
                ''
              )}
            </div>
            <span className='d-flex align-items-center mono xs'>
              <span className='mr-2'>{project._id}</span>{' '}
              <CopyToClipboard onClick={() => copyToClipboard(project._id)} />
            </span>
          </div>
          <div className='col-12 col-md-3 d-flex description'>{project.description}</div>
          <div className='col-12 col-md-1 d-flex'>
            <span className='bold link d-flex align-items-center pr-3'>
              <div
                className='d-flex align-items-center'
                onClick={() => toggleProjectMembersModal(project)}
                data-testid='project-members-count'>
                <span className='mr-1'>{project.members ? project.members.length : 1}</span>
                {isTabletOrMobile ? (
                  <React.Fragment>{project.members.length == 1 ? 'member' : 'members'}</React.Fragment>
                ) : (
                  <i className='icon-show'></i>
                )}
              </div>
            </span>
          </div>
          <div className='col-12 col-md-1 d-flex'>
            <span className='text-xs'>
              {project.requests}
              {isTabletOrMobile ? ' requests' : ''}
            </span>
          </div>
          <div className='col-12 col-md-1 d-flex'>
            <span className='text-xs'>
              ${project.total_spend}
              {isTabletOrMobile ? ' spent' : ''}
            </span>
          </div>
          <div className='col-12 col-md-2 d-flex actions'>
            <span className='bold text-xs link mr-3' onClick={() => toggleEditProjectModal(project)}>
              Edit
            </span>
            {projects.length > 1 ? (
              <span className='bold text-xs link' onClick={() => toggleDeleteProjectModal(project)}>
                Delete
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div id='Projects' className={`container-fluid content-container ${isShown ? 'show' : 'hide'}`}>
      {!projects || !isLoaded ? (
        <LoadingFilled />
      ) : (
        <React.Fragment>
          <div className='row pt-3'>
            <div className='col-12 d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <h2 className='mr-1 mb-0'>Projects</h2>
                {fetchError ? <QErrorMessage text={<span className='bold'>{fetchError.message}</span>} /> : ''}
                {projects.length > 1 ? (
                  <span className='d-flex align-items-center link bold p-2' onClick={() => updateOrder()}>
                    {projectsOrder === 'asc' ? 'A-Z' : 'Z-A'}
                    <i className='icon-sort' />
                  </span>
                ) : (
                  ''
                )}
              </div>
              {/* Create New Project */}
              <QModalBtn
                buttonText='New Project'
                show={newProjectModalOpen}
                size='lg'
                id='CreateNewProject'
                onHide={toggleNewProjectModal}>
                {loadingProjects ? (
                  <LoadingBlock className='transparent-bg' text='Please be patient...' />
                ) : (
                  <CreateNewProjectModal
                    projects={projects}
                    createProjectError={createProjectError}
                    toggleNewProjectModal={toggleNewProjectModal}
                    createNewProject={values => createNewProject(values)}
                  />
                )}
              </QModalBtn>
            </div>
          </div>
          {/* Projects List */}
          {!isTabletOrMobile ? (
            <div className='row py-1 list header mt-3'>
              <div className='col-12 col-md-4'>Name & ID</div>
              <div className='col-12 col-md-3'>Description</div>
              <div className='col-12 col-md-1'>Members</div>
              <div className='col-12 col-md-1'>Requests</div>
              <div className='col-12 col-md-1'>Spend</div>
              <div className='col-12 col-md-2'>Actions</div>
            </div>
          ) : (
            ''
          )}
          <div className={`row d-flex ${isTabletOrMobile ? 'mt-2' : ''}`}>{listProjects(projects)}</div>
          {/* Project Members Modal */}
          {selectedProject && membersModalOpen ? (
            <QModal
              id='ProjectMembersModal'
              show={membersModalOpen}
              size='xl'
              onHide={() => toggleProjectMembersModal(null)}>
              <ProjectMembers
                project={selectedProject}
                toggleProjectMembersModal={() => toggleProjectMembersModal(null)}
                updateProjects={() => updateProjects()}
              />
            </QModal>
          ) : (
            ''
          )}
          {/* Edit Project Modal */}
          {selectedProject && renameProjectModalOpen ? (
            <QModal
              id='EditProjectModal'
              show={renameProjectModalOpen}
              size='lg'
              onHide={() => toggleEditProjectModal(null)}>
              <RenameProjectModal
                project={selectedProject}
                projects={projects}
                renameError={renameError}
                renameProject={values => renameProject(values)}
                toggleRenameModal={() => toggleEditProjectModal(null)}
                loadingProjects={loadingProjects}
              />
            </QModal>
          ) : (
            ''
          )}
          {/* Delete Project Modal */}
          {selectedProject && deleteProjectModalOpen ? (
            <QModal
              id='DeleteProjectModal'
              show={deleteProjectModalOpen}
              size='lg'
              onHide={() => setDeleteProjectModalOpen(null)}>
              <DeleteProjectModal
                project={selectedProject}
                deleteError={deleteError}
                deleteProject={values => deleteProject(values)}
                toggleDeleteModal={() => setDeleteProjectModalOpen(null)}
                loadingProjects={loadingProjects}
              />
            </QModal>
          ) : (
            ''
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Projects;
