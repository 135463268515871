import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';

// Import hooks & hoc
import { useSubmitData } from '../../../hoc/SubmitData';

// Import data models
import { FormPropsObject } from '../../../models/forms';
import { SolverObject } from '../../../models/solvers';

// Import components
import QFormWrapper from '../../../components/form/QFormWrapper';
import { LoadingBlock } from '../../../components/loading/Loading';
import ShowInputs from '../components/ShowInputs';
import { SubmitGroupLabel, SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';
import {
  buildValidationObj,
  getValidationKeys,
  getInitialFormValues,
  buildErrorMessage,
  validateParam,
  buildParamKeys,
} from '../functions/SubmitFormFunctions';

interface Props {
  resetPipeline: Function;
  setParamsValid: Function;
  solverPipeline: SolverObject[];
  updatePipeline: Function;
}

const SubmitAQFEP = ({ resetPipeline, setParamsValid, solverPipeline, updatePipeline }: Props) => {
  // Init
  const { solverConfigs, defaultParams }: any = useSubmitData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [validationObj, setValidationObj] = useState<any>({});
  const [validationKeys, setValidationKeys] = useState<string[]>([]);
  const timers = useRef<any[]>([]);
  // Edit Params
  const [pipeline, setPipeline] = useState<SolverObject[]>([]);
  const blacklist = ['solver'];
  // Refs for form validation
  const [formLoaded, setFormLoaded] = useState(false);
  const formRef = useRef<any>(null);

  const solver_name = solverPipeline[0] ? solverPipeline[0].solver_name : '';

  const validation_items = {
    fep_params__0: yup.string().test(
      'fep_params',
      (value: any) => buildErrorMessage(value.value, 'fep_params', solver_name, solverConfigs),
      (value: any) => validateParam(value, 'fep_params', solver_name, solverConfigs)
    ),
  };

  useEffect(() => {
    setPipeline(solverPipeline);

    if (solverConfigs !== null && defaultParams !== null && solverPipeline[0] && solverPipeline[0].params) {
      // Solver params
      const param_keys = buildParamKeys(solverPipeline);
      setValidationObj(buildValidationObj(validation_items, param_keys));
      setValidationKeys(getValidationKeys(validation_items, param_keys));
      setIsLoaded(true);
    }
  }, [solverPipeline, solverConfigs, defaultParams]);

  function checkFormValid() {
    // Delay to allow formRef validations to finish
    const t = setTimeout(() => {
      setParamsValid(formRef.current.isValid);
    }, 200);
    timers.current.push(t);
  }

  useEffect(() => {
    // Trigger form validations after Form element is loaded
    if (formLoaded && formRef && formRef.current !== null) {
      let fields = {};
      validationKeys.forEach(param => {
        fields[param] = true;
      });
      formRef.current.setTouched(fields);
      checkFormValid();
    }
  }, [formLoaded]);

  useEffect(() => {
    // Update paramsForm in parent component whenever form validity changes
    if (formRef.current) {
      checkFormValid();
    }
  }, [formRef.current]);

  useEffect(() => {
    // Component cleanup
    return () => {
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function handlePageLoad() {
    /*
    This function should be called at the end of the html elements
    load, as it looks for a specific element in the DOM and triggers
    a component state change
    */
    if (!formLoaded) {
      // Delay to allow html elements to load
      const timer = setTimeout(() => {
        const el = document.getElementById('AQFEPForm');
        if (el !== undefined && el !== null) {
          setFormLoaded(true);
        }
      }, 1000);
      timers.current.push(timer);
    }
  }

  const validationSchema = yup.object().shape(validationObj);

  function showSolverParams(formProps: FormPropsObject) {
    return Array.from(pipeline).map((solver, index) => {
      return (
        <React.Fragment key={index}>
          <SubmitInputGroup>
            <SubmitGroupLabel className='w-100' text={`${solver.solver_name} Parameters`} />
            {/* Dynamic Inputs */}
            {ShowInputs(
              solver,
              index,
              formProps,
              solverConfigs,
              blacklist,
              validationKeys,
              pipeline,
              (pipeline_mod: SolverObject[]) => updatePipeline(pipeline_mod)
            )}
          </SubmitInputGroup>
        </React.Fragment>
      );
    });
  }

  return (
    <div id='SubmitAQFEP'>
      {isLoaded && pipeline[0] ? (
        <QFormWrapper
          innerRef={formRef}
          formId='AQFEPForm'
          validationSchema={validationSchema}
          initialValues={getInitialFormValues(pipeline, validationKeys, solverConfigs)}
          onSubmit={() => {}}>
          {(formProps: FormPropsObject) => {
            return (
              <React.Fragment>
                {showSolverParams(formProps)}
                <div className='w-100 mb-3'>
                  <ResetDropdown
                    resetChanges={() => {
                      formProps.resetForm();
                      resetPipeline();
                    }}
                  />
                </div>
                {handlePageLoad()}
              </React.Fragment>
            );
          }}
        </QFormWrapper>
      ) : (
        <LoadingBlock text='Loading paramaters...' className='mb-3' />
      )}
    </div>
  );
};

export default SubmitAQFEP;
