import React from 'react';
import PropTypes from 'prop-types';

// Components
import { showParams, solverTooltip } from '../ProblemsCommonFunctions';
import { dftMethods } from './DFTProblem';
import { mlSolvers } from './MLProblem';

const GOProblem = props => {
  const blacklist = [];

  function parentSolver(solver_parameters) {
    const solver_params = showParams(solver_parameters, blacklist);

    return (
      <div className='solvers'>
        {solverTooltip(solver_parameters.next_solver, solver_params)}
        {solver_parameters.solver_params !== undefined &&
          solver_parameters.solver_params !== null &&
          solver_parameters.solver_params.solver !== undefined &&
          solver_parameters.solver_params.solver !== null &&
          solver_parameters.solver_params.solver.next_solver !== undefined &&
          solver_parameters.solver_params.solver.next_solver !== null && (
            <React.Fragment>
              {solver_parameters.solver_params.solver.next_solver === 'DFT' ? (
                <React.Fragment>
                  <i className='icon-next align-self-center mx-2 pre-next-solver-icon' />
                  {dftMethods(solver_parameters.solver_params.solver, props.solverConstants)}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {solver_parameters.solver_params.solver.next_solver.includes('ANI') ? (
                    <React.Fragment>
                      <i className='icon-next align-self-center mx-2 pre-next-solver-icon' />
                      {mlSolvers(solver_parameters.solver_params.solver, props.solverConstants)}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
      </div>
    );
  }

  return (
    <div id='GOProblem' className='row details problem-type-a'>
      <div className='col-12'>
        {/* SOLVERS */}
        {props.solverParameters !== undefined &&
          props.solverParameters !== null &&
          props.solverParameters.next_solver !== undefined && (
            <div className='row solvers'>
              <div className='col-12 solvers-data'>
                <span className='label-sm'>Solvers: </span>
                <div className='solvers-block'>{parentSolver(props.solverParameters)}</div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

GOProblem.propTypes = {
  solverParameters: PropTypes.object.isRequired,
  solverConstants: PropTypes.object.isRequired,
};

export default GOProblem;
