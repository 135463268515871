/*
This document is used to hold functions that may be used by multiple components, or complex functions pertaining to submitting problems via the dashboard.

Functions:
  • formatBasicValToPython() - return python value from js true, false & null
  • pythonifySolver() - format all solver items to its python value (& stringify enums)
  • buildDefaultPipeline() - build default pipelines for each solver type
  • buildDefaultParameters() - build object that contains all solvers, their solver_name, local_name, default next_solver, & params object
*/

export const basic_python_vals = ['None', 'True', 'False'];
export const basic_js_vals = [null, true, false];

export function formatBasicValToPython(val) {
  // Format value from js to python
  if (val === null) {
    return 'None';
  } else if (val === false) {
    return 'False';
  } else if (val === true) {
    return 'True';
  } else return val;
}

export function pythonifySolver(configs, solver) {
  /*
  Update solver values from js to pythonic values.
  */
  let solver_mod = structuredClone(solver);

  const params = Object.entries(solver.params);
  params.forEach(([key, val]) => {
    if (basic_js_vals.includes(val)) {
      solver_mod.params[key] = formatBasicValToPython(val);
    }
    // Update enum to enum string
    if (
      configs[solver.solver_name].configurable &&
      configs[solver.solver_name].configurable[key] &&
      configs[solver.solver_name].configurable[key].config_types &&
      configs[solver.solver_name].configurable[key].config_types.includes('enums')
    ) {
      const options = configs[solver.solver_name].configurable[key].options;
      options.forEach(option => {
        if (JSON.stringify(val) == JSON.stringify(option[1])) {
          solver_mod.params[key] = `${option[0]}`;
        }
      });
    }
  });

  return solver_mod;
}

export function buildDefaultParameters(config_obj) {
  /*
  Return an object that holds all configurable solver parameters and its default keys.

  Example:
  {
    IncrementalDecomposition: {
      solver_name: 'IncrementalDecomposition',
      next_solver: 'SNO',
      params: {
        truncation_order: 1,
        increment_screening_threshold: 0.0001,
        ...
      },
    },
    SNO: {
      solver_name: 'SNO',
      next_solver: 'HBCI',
      ...
    }
  }
  */

  function getNextSolver(solver_name) {
    /* 
    Return next_solver string based on the following hardcoded assumptions.
    */
    if (solver_name === 'IncrementalDecomposition') {
      return 'SNO';
    } else if (['SNO', 'FNO'].includes(solver_name)) {
      return 'HBCI';
    } else return null;
  }

  function getParams(config_obj, solver_name) {
    /*
    Return an object of solver params and default values.

    Example:
    params = {
      truncation_order: 1,
      method_of_truncation: 1
    }
    */

    // Find all configurable params supplied as config_obj arg fetched from /api/get-solver-configs endpoint
    const configurable_params = Object.keys(config_obj[solver_name].configurable);
    // Build configurable params object
    let params = {};
    // Wrap in try block in case config_obj structure changes and a key becomes undefined
    try {
      configurable_params.forEach(param => {
        const serialized_solver_param_keys = Object.keys(config_obj[solver_name].solver_parameters.solver_params);
        if (serialized_solver_param_keys.includes(param)) {
          params[param] = config_obj[solver_name].solver_parameters.solver_params[param];
        }
      });
      return params;
    } catch (e) {
      console.warn(e);
    }
  }

  // Dynamic build default configurable params objects per solver based on config_obj arg
  let solver_parameters = {};
  const solvers = Object.keys(config_obj);
  solvers.forEach(solver => {
    const ignore = ['CSP'];
    if (!ignore.includes(solver)) {
      solver_parameters[solver] = {
        solver_name: solver,
        next_solver: getNextSolver(solver),
        params: getParams(config_obj, solver),
      };
    }
  });

  return solver_parameters;
}

export function buildDefaultPipeline(configs, defaults, solvers_arr) {
  /*
  Return an ordered array as a solver pipeline.

  Example:
  [{
      solver_name: 'IncrementalDecomposition',
      next_solver: 'SNO',
      params: {
        truncation_order: 1,
        increment_screening_threshold: 0.0001,
        ...
      }
    },
    {
      solver_name: 'FNO',
      next_solver: 'HBCI',
      params: {
        ...
      }
    },
    
  }]

  Expected arguments:
  • configs: object returned from client library detailing configurable fields as well as the solver's solver_parameters dictionary
  • defaults: object constructed via buildDefaultParameters()
  • solvers_arr: array with an ordered pipeline (e.g. ['IncrementalDecomposition', 'FNO', 'HBCI'])
  */
  try {
    let default_pipeline = [];
    solvers_arr.forEach(solver => {
      default_pipeline.push(defaults[solver]);
    });
    // Construct enum strings from configs
    default_pipeline.forEach((solver, i) => {
      default_pipeline[i] = pythonifySolver(configs, solver);
    });
    return default_pipeline;
  } catch (e) {
    console.error(e);
  }
}
