import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { initRangeStart, getRangeEnd } from '../../../../hoc/CommonFunctions';
import { paginationSetup } from '../../../../hoc/ProblemsCommonFuncs';
import RequestLogComponent from '../../../../components/requestLog/RequestLogComponent';

const ProjectRequestLog = props => {
  // Init
  const history = useHistory();
  // GET Problems
  const [projectID, setProjectID] = useState(null);
  const [offset, setOffset] = useState(0);
  const [setLength, setSetLength] = useState(10);
  const [rangeStart, setRangeStart] = useState(initRangeStart);
  const [rangeEnd, setRangeEnd] = useState(getRangeEnd());

  useEffect(() => {
    // Set project data
    if (props.projectData && props.projectData._id !== undefined) {
      setProjectID(props.projectData._id);
    }

    // Handle pagination setup
    paginationSetup(new_path => {
      history.replace(new_path);
    });

    // Set offset & date range
    let page_num = window.location.href.split('/view-projects#')[1];
    if (page_num !== undefined) {
      setOffset(page_num * setLength - setLength);
    }
  }, [props.projectData]);

  return (
    <div id='ProjectRequestLog' className='view-user'>
      {projectID !== null ? (
        <RequestLogComponent
          setIsShown={() => function () {}}
          offset={offset}
          setOffset={value => setOffset(value)}
          setLength={setLength}
          userID={null}
          groupID={null}
          projectID={projectID}
          rangeStart={rangeStart}
          setRangeStart={value => setRangeStart(value)}
          rangeEnd={rangeEnd}
          setRangeEnd={value => setRangeEnd(value)}
          saveRangeName={'adminProjRqlRanges'}
          visibilityName={'adminProjRqlVisibility'}
          trackRequestLogError={() => function () {}}
          updateHistory={value => history.push(value)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

ProjectRequestLog.propTypes = {
  projectData: PropTypes.object.isRequired,
};

export default ProjectRequestLog;
