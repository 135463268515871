import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import PropTypes from 'prop-types';

import { useAuth } from '../../../hoc/Auth';
import { charactersHelper } from '../../../hoc/CommonFunctions';
import { handleInputChange } from '../../../hoc/FormFunctions';
import QFormWrapper from '../../../components/form/QFormWrapper';
import QFormGroup from '../../../components/form/QFormGroup';
import QButton from '../../../components/button/Button';
import { QSuccessMessage, QErrorMessage } from '../../../components/notifications/Notifications';
import { LoadingBlock } from '../../../components/loading/Loading';

const GrantCreditsModal = props => {
  // Init
  const { fetchData } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Add Credit Pool
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [expiration, setExpiration] = useState(moment().add(30, 'days').unix());

  const validationSchema = yup.object().shape({
    credit_pool_name: yup.string(),
    amount_granted: yup.number('Must be a valid number').required('This field is required'),
    notes: yup.string().max(120),
  });

  useEffect(() => {
    return () => {
      setSuccess(null);
      setError(null);
    };
  }, []);

  function processGrantCredits(values) {
    setProcessing(true);

    const body = {
      credit_pool_name: values.credit_pool_name,
      group_or_user_id: props.id,
      owner_name: props.ownerName,
      expiration: expiration,
      amount_granted: values.amount_granted,
      amount_remaining: values.amount_granted,
      credits_type: values.credits_type,
      notes: values.notes,
    };

    fetchData(signal, '/api/add-credits', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setProcessing(false);
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        setProcessing(false);
        setSuccess(true);
        setTimeout(() => {
          props.toggleGrantCreditsModal();
        }, 2000);
      }
    });
  }

  function formatAliases() {
    if (props.names) {
      let string = '';
      props.names.forEach(str => {
        if (string === '') {
          string = str;
        } else {
          string = string + ', ' + str;
        }
      });
      return string;
    } else return '';
  }

  return (
    <div className='container-fluid'>
      <h1>Grant Credits</h1>
      <p>
        Start a <strong>credit pool</strong> for group/user id: {props.id} <br />
        {props.names.length > 1 ? (
          <span>
            Aliases: <strong>{formatAliases()}</strong>
          </span>
        ) : (
          <span>
            Group Name / User Email: <strong>{props.ownerName}</strong>
          </span>
        )}
      </p>
      {processing ? (
        <LoadingBlock />
      ) : (
        <React.Fragment>
          {/* Success Message */}
          {success ? (
            <QSuccessMessage text='Credits added!' className='my-4' />
          ) : (
            <React.Fragment>
              {/* Error Message */}
              {error ? (
                <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
              ) : (
                <React.Fragment>
                  <QFormWrapper
                    formId='GrantCreditsForm'
                    initialValues={{
                      credit_pool_name: 'unnamed',
                      expiration: expiration,
                      amount_granted: '',
                      credits_type: 'demo',
                      notes: '',
                    }}
                    initialErrors={{
                      credit_pool_name: true,
                      expiration: true,
                      amount_granted: true,
                      credits_type: false,
                      notes: true,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => processGrantCredits(values)}>
                    {formProps => (
                      <GrantCreditsForm
                        expiration={expiration}
                        setExpiration={value => setExpiration(value)}
                        {...formProps}
                      />
                    )}
                  </QFormWrapper>
                  <QButton className='secondary mt-4' onClick={props.toggleGrantCreditsModal}>
                    Cancel
                  </QButton>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

GrantCreditsModal.propTypes = {
  id: PropTypes.string.isRequired,
  names: PropTypes.array.isRequired,
  toggleGrantCreditsModal: PropTypes.func.isRequired,
  ownerName: PropTypes.string.isRequired,
};

const GrantCreditsForm = props => {
  // Init
  const [creditType, setCreditType] = useState('demo');

  useEffect(() => {}, [props.expiration]);

  function handleDateCallback(date) {
    let unixtimestamp = date.unix();
    props.setExpiration(unixtimestamp);
  }

  return (
    <React.Fragment>
      <div className='row'>
        {/* Amount */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='amount_granted'
          name='amount_granted'
          label='Amount'
          onChange={e => handleInputChange(e, props, 'amount_granted', () => {})}
          inputProps={{ 'data-testid': 'amount-input' }}
          includeError={true}
        />
        {/* Expiration */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='expiration'
          name='expiration'
          label='Expiration'
          onChange={e => handleInputChange(e, props, 'expiration', () => {})}
          inputProps={{ readOnly: true, 'data-testid': 'expiration-input' }}
          value={moment.unix(props.expiration).format('MMM D, YYYY')}
          type='text'
          includeError={false}>
          <DateRangePicker
            initialSettings={{
              singleDatePicker: true,
              showDropdowns: true,
            }}
            onCallback={date => handleDateCallback(date)}>
            <div className='date-filter' data-testid='datepicker'>
              <i className='icon-calendar' />
            </div>
          </DateRangePicker>
        </QFormGroup>
      </div>
      <div className='row'>
        {/* Credit Pool Name */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='credit_pool_name'
          name='credit_pool_name'
          label='Credit Pool Name'
          onChange={e => handleInputChange(e, props, 'credit_pool_name', () => {})}
          inputProps={{ 'data-testid': 'credit-pool-name-input' }}
          includeError={true}
        />
        {/* Credit Type */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='select'
          id='credits_type'
          name='credits_type'
          label='Credits Type'
          inputProps={{ name: 'credits_type', 'data-testid': 'credits-type-select' }}
          onChange={e =>
            handleInputChange(e, props, 'credits_type', e => {
              setCreditType(e.target.value);
            })
          }
          value={creditType}
          selectMenu={[
            ['demo', 'Demo'],
            ['promo', 'Promo'],
            ['paid', 'Paid'],
          ]}
          includeError={false}
        />
      </div>
      <div className='row'>
        {/* Notes */}
        <QFormGroup
          containerClassName='col-12 col-md-6 mb-3'
          element='textarea'
          id='notesInput'
          name='notes'
          label='Notes (Recommended)'
          rowsMax={4}
          inputProps={{ 'data-testid': 'notes-input' }}
          helperText={charactersHelper('notesInput', 120)}
          onChange={e => handleInputChange(e, props, 'notes', () => {})}
          includeError={true}
        />
      </div>
      <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
        Grant Credits
      </QButton>
    </React.Fragment>
  );
};

GrantCreditsForm.propTypes = {
  expiration: PropTypes.number.isRequired,
  setExpiration: PropTypes.func.isRequired,
};

export default GrantCreditsModal;
