import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LoadingBlock } from '../../../components/loading/Loading';
import { SubmitGroupLabel, SubmitInputGroup } from '../components/SubmitElements';
import ShowInputs from '../components/ShowInputs';
import { formatSolverName } from '../functions/SubmitFunctions';

const DFTGrids = props => {
  const [gridObj, setGridObj] = useState(null);
  const blacklist = [];

  useEffect(() => {
    setGridObj(props.solverObj);
  }, [props.solverObj]);

  return (
    <div id='DFTGrids'>
      {gridObj !== null ? (
        <SubmitInputGroup>
          <SubmitGroupLabel className='w-100' text={`${formatSolverName(props.paramKey)} Parameters`} />
          {/* Inputs */}
          {ShowInputs(
            gridObj,
            props.solverIndex,
            props.formProps,
            props.solverConfigs,
            blacklist,
            props.validationKeys,
            props.pipeline,
            pipeline_mod => props.updatePipeline(pipeline_mod)
          )}
        </SubmitInputGroup>
      ) : (
        <LoadingBlock size='sm' />
      )}
    </div>
  );
};

DFTGrids.propTypes = {
  paramKey: PropTypes.string.isRequired,
  solverConfigs: PropTypes.object.isRequired,
  solverObj: PropTypes.object.isRequired,
  updatePipeline: PropTypes.func.isRequired,
  formProps: PropTypes.object.isRequired,
  validationKeys: PropTypes.array.isRequired,
  solverIndex: PropTypes.number.isRequired,
  pipeline: PropTypes.array.isRequired,
};

export default DFTGrids;
