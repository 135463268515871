import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

// Components
import { QButton } from '../button/Button';
import { LoadingInline } from '../loading/Loading';

const DatePicker = props => {
  useEffect(() => {}, [props.rangeStart, props.rangeEnd]);

  function formatDate(date) {
    var formattedDate = date.format('MM/DD/YYYY');
    return formattedDate;
  }

  const label =
    moment.unix(props.rangeStart).format('MMM D, YYYY') + ' - ' + moment.unix(props.rangeEnd).format('MMM D, YYYY');

  if (props.rangeStart && props.rangeEnd) {
    return (
      <div className='DateRangePicker'>
        <DateRangePicker
          initialSettings={{
            showDropdowns: true,
            minDate: '01/01/2021',
            maxDate: formatDate(moment().endOf('year')),
            startDate: moment.unix(props.rangeStart),
            endDate: moment.unix(props.rangeEnd),
            ranges: {
              Today: [moment().startOf('day'), moment().endOf('day')],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month')
              ]
            }
          }}
          onCallback={(start, end) => props.handleCallback(start, end)}>
          <div className='date-filter mr-2' data-testid='datepicker'>
            <span>{label}</span>
            <i className='icon-dropdown' />
          </div>
        </DateRangePicker>
      </div>
    );
  } else return <LoadingInline className='mr-2' />;
};

export const ShowAllButton = props => {
  return (
    <div className={`show-all ${props.className}`}>
      <QButton className='small' onClick={props.onClick}>
        Show All
      </QButton>
    </div>
  );
};

DatePicker.propTypes = {
  rangeStart: PropTypes.number,
  rangeEnd: PropTypes.number,
  handleCallback: PropTypes.func.isRequired
};

ShowAllButton.propTypes = {
  className: PropTypes.string
};

export default DatePicker;
