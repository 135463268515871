import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../hoc/Auth';
import { sortProjectList } from '../../hoc/CommonFunctions';
import { useUserData } from '../../hoc/UserData';
import { browserStore } from '../../hoc/BrowserStorage';
import QDropdown, { Toggle, Menu, Item, Header, Divider } from '../dropdown/Dropdown';
import { QErrorMessage } from '../notifications/Notifications';
import CopyToClipboard, { copyToClipboard } from '../copyToClipboard/CopyToClipboard';
import { LoadingInline } from '../loading/Loading';

const ProjectsDropdown = props => {
  // Init
  let timers = [];
  const { fetchUserProjects } = useAuth();
  const {
    user,
    orgIDs,
    currentOrg,
    projects,
    setProjects,
    projectsOrder,
    setProjectsOrder,
    currentProject,
    setCurrentProject,
    setProjectUpdating,
    updatingProjects,
    setUpdatingProjects,
  } = useUserData();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [isLoaded, setIsLoaded] = useState(false);
  // GET Projects
  const [error, setError] = useState(null);
  const sortOrderName = `${user.userid}_${currentOrg._id || 'personal'}_project.sort_order`;

  function handleFetchCallback(response) {
    // if error is returned
    if (response.status === 'error') {
      setError(response.error);
    }
    // if data is returned
    if (response.status === 'success') {
      const data = response.data;

      if (Array.isArray(data)) {
        // Handle projects
        // Get sort order from session storage
        const stored_order = browserStore('get', sortOrderName);
        if (stored_order !== null) {
          setProjectsOrder(stored_order);
        } else {
          browserStore('set', sortOrderName, 'asc');
          setProjectsOrder('asc');
        }
        sortProjectList(data, stored_order || 'asc', sorted_projects => {
          // Handle current project
          const id = `${user.userid}_${currentOrg._id || 'personal'}`;
          const stored_project = browserStore('get', `${id}.project`);
          if (stored_project !== null) {
            // Validate stored project
            const stored_project_valid = sorted_projects.some(project => project._id === stored_project._id);
            if (stored_project_valid) {
              // If stored project is valid, update stored project info with project data returned from /get-projects in case project name has been updated
              sorted_projects.forEach(project => {
                if (stored_project._id === project._id) {
                  setCurrentProject(project);
                  browserStore('set', `${id}.project`, project);
                }
              })
            } else {
              // If stored project no longer exists, update stored project
              browserStore('set', `${id}.project`, sorted_projects[0]);
            }
            
          } else {
            setCurrentProject(sorted_projects[0]);
            browserStore('set', `${id}.project`, sorted_projects[0]);
          }

          setProjects(sorted_projects);
          setProjectUpdating(false);
          setUpdatingProjects(false);
          setIsLoaded(true);
        });
      }
    }
  }

  useEffect(() => {
    if (!props.workspaceUpdating || updatingProjects) {
      setIsLoaded(false);
      setCurrentProject(null);
      if (currentOrg._id !== undefined) {
        fetchUserProjects(signal, user.userid, currentOrg._id, orgIDs, response => handleFetchCallback(response));
      } else {
        fetchUserProjects(signal, user.userid, '', orgIDs, response => handleFetchCallback(response)); // personal projects
      }
    }

    return () => {
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, [props.workspaceUpdating, updatingProjects]);

  function changeProject(p) {
    setProjectUpdating(true);
    setIsLoaded(false);

    const t = setTimeout(() => {
      setCurrentProject(p);
      const id = `${user.userid}_${currentOrg._id || 'personal'}`;
      browserStore('set', `${id}.project`, p);

      setProjectUpdating(false);
      setIsLoaded(true);
    }, 500);
    timers.push(t);
  }

  function showRemainingProjects() {
    return Array.from(projects).map((project, index) => (
      <React.Fragment key={index}>
        {project._id !== currentProject._id ? <Item onClick={() => changeProject(project)}>{project.name}</Item> : ''}
      </React.Fragment>
    ));
  }

  function updateOrder() {
    const new_order = projectsOrder === 'asc' ? 'desc' : 'asc';
    setProjectsOrder(new_order);
    browserStore('set', sortOrderName, new_order);
    sortProjectList(projects, new_order, sorted_projects => {
      setProjects(sorted_projects);
    });
  }

  return (
    <div id='ProjectsDropdown'>
      {!isLoaded ? (
        <LoadingInline size='sm' color='white' className='mr-4 ml-2' />
      ) : (
        <QDropdown className='reversed'>
          <Toggle dataTestId={'projects-dropdown-toggle'}>
            {!currentProject ? <LoadingInline size='sm' /> : <span className='reversed'>{currentProject.name}</span>}
          </Toggle>
          {error ? (
            <QErrorMessage text={<span className='bold'>{error.message}</span>} />
          ) : (
            <React.Fragment>
              <Menu className='light'>
                <Header>Current Project</Header>
                {!projects ? (
                  <LoadingInline size='sm' />
                ) : (
                  <React.Fragment>
                    {/* Current Project */}
                    <div className='d-flex flex-column item current-item py-0 pt-2 notification static info'>
                      <div className='d-flex align-items-center'>
                        <span className='bold h5 mr-2 mb-0'>{currentProject.name}</span>{' '}
                        {/* <span className='badge orange-badge mb-1'>current</span> */}
                      </div>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='mono xs mr-2'>{currentProject._id}</span>{' '}
                        <CopyToClipboard onClick={() => copyToClipboard(currentProject._id)} />
                      </div>
                    </div>
                    {projects.length > 1 ? (
                      <React.Fragment>
                        <Divider />
                        <Header>
                          <div className='d-flex justify-content-between align-items-center'>
                            <span>Switch Project</span>
                            <span className='d-flex align-items-center link bold p-2' onClick={() => updateOrder()}>
                              {projectsOrder === 'asc' ? 'A-Z' : 'Z-A'}
                              <i className='icon-sort' />
                            </span>
                          </div>
                        </Header>
                        {showRemainingProjects()}
                      </React.Fragment>
                    ) : (
                      ''
                    )}

                    <Link to='/projects' className='manage-projects-link'>
                      <Item className='d-flex align-items-center border-top manage'>
                        <i className='icon-projects mr-2'></i>
                        <span className='link bold'>Manage Projects</span>
                      </Item>
                    </Link>
                  </React.Fragment>
                )}
              </Menu>
            </React.Fragment>
          )}
        </QDropdown>
      )}
    </div>
  );
};

export default ProjectsDropdown;

ProjectsDropdown.propTypes = {
  workspaceUpdating: PropTypes.bool.isRequired,
};
