export function filterList(data, obj_key, query, callback) {
  // Show all data if search query is empty
  if (query === null) {
    callback(data);
  }
  // Otherwise show only objects that include query string
  else {
    let new_list = [];
    data.forEach(obj => {
      let obj_value = obj[obj_key].toUpperCase();
      let formatted_query_str = query.toUpperCase();
      if (obj_value.includes(formatted_query_str)) {
        new_list.push(obj);
      }
    });
    callback(new_list);
  }
}

export function handleSearchFormSubmit(event, data, key, query, callback) {
  event.preventDefault();
  filterList(data, key, query, list => callback(list));
}

export function resetSearchForm(data, key, callback) {
  filterList(data, key, null, list => callback(list));
}
