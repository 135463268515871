import React from 'react';
import PropTypes from 'prop-types';

const ViewUserSettings = props => (
  <div id='ViewUserSettings' className='view-user container-fluid'>
    <div className='row'>
      <div className='col-12 content-container'>
        <div className='row'>
          <div className='col-12 col-lg-4 label-group'>
            <p className='label-sm'>Email</p>
            <p className='bold'>{props.user.email}</p>
          </div>
          <div className='col-12 col-lg-8 label-group'>
            <p className='label-sm'>User ID</p>
            <p className='bold'>{props.user.user_id}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ViewUserSettings.propTypes = {
  user: PropTypes.object.isRequired
};

export default ViewUserSettings;
