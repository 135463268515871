import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../hoc/Auth';
import { useUserData } from '../../hoc/UserData';
import { convertTimestampToDate, formatCurrency } from '../../hoc/CommonFunctions';
import QModalBtn from '../modal/Modal';
import { LoadingFilled } from '../loading/Loading';
import EditCreditsModal from './EditCreditsModal';

const CreditPoolDetails = props => {
  // Init
  const { fetchData } = useAuth();
  const { user } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Credit Pool Details
  const [poolDetails, setPoolDetails] = useState({ credit_details: {} });
  const [error, setError] = useState(null);
  // Edit Credit Pool
  const [editCreditsModalOpen, setEditCreditsModalOpen] = useState(false);

  const admin = eval(user.role === 'qemist_admin');

  function fetchCreditPoolDetails() {
    const body = {
      group_or_user_id: props.id,
      credit_pool_id: props.poolId
    };

    fetchData(signal, '/api/get-credit-pools', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        setPoolDetails(response.data.credit_details);
      }
      setIsLoaded(true);
    });
  }

  useEffect(() => {
    fetchCreditPoolDetails();

    return () => {
      setIsLoaded(false);
      setError(null);
      abortController.abort();
    };
  }, []);

  function formatType(type) {
    return (
      <span className={`type badge ${type === 'paid' ? 'paid' : type === 'demo' ? 'demo' : 'promo'}`}>{type}</span>
    );
  }

  function toggleEditCreditsModal() {
    setEditCreditsModalOpen(!editCreditsModalOpen);
    fetchCreditPoolDetails();
  }

  return (
    <div id='CreditPoolDetails'>
      <div className='content'>
        {!isLoaded ? (
          <LoadingFilled className='with-subnav' />
        ) : (
          <React.Fragment>
            {/* Credit Pool Details Heading */}
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12 d-flex py-3 grey-bg border-top'>
                  <h2 className='d-flex align-items-center mb-0'>Credit Pool Details</h2>
                </div>
              </div>
            </div>
            {/* Credit Pools Details */}
            <div className='container-fluid pt-3 pb-5'>
              {/* Credit Pool Name */}
              <div className='row py-1 d-flex align-items-center'>
                <div className='col-3 label'>Credit Pool Name:</div>
                <div className='col-9'>{poolDetails.credit_pool_name}</div>
              </div>
              {/* Credit Pool Name */}
              <div className='row py-1 d-flex align-items-center'>
                <div className='col-3 label'>Credit Pool ID:</div>
                <div className='col-9'>{poolDetails.credit_pool_id}</div>
              </div>
              {/* Owner Name */}
              <div className='row py-1 d-flex align-items-center'>
                <div className='col-3 label'>Belongs To:</div>
                <div className='col-9'>{poolDetails.owner_name}</div>
              </div>
              {/* Date Granted */}
              <div className='row py-1 d-flex align-items-center'>
                <div className='col-3 label'>Date Granted:</div>
                <div className='col-9'>{convertTimestampToDate(poolDetails.date_granted, 'long')}</div>
              </div>
              {/* Expiration */}
              <div className='row py-1 d-flex align-items-center'>
                <div className='col-3 label'>Expiration:</div>
                <div className='col-9 bold'>{convertTimestampToDate(poolDetails.expiration, 'long')}</div>
              </div>
              {/* Amount Granted */}
              <div className='row py-1 d-flex align-items-center'>
                <div className='col-3 label'>Amount Granted:</div>
                <div className='col-9'>{formatCurrency(poolDetails.amount_granted)}</div>
              </div>
              {/* Amount Remaining */}
              <div className='row py-1 d-flex align-items-center'>
                <div className='col-3 label'>Amount Remaining:</div>
                <div
                  className={`col-9 bold ${
                    poolDetails.amount_remaining <= 0
                      ? 'danger'
                      : poolDetails.amount_remaining < 100
                      ? 'warning'
                      : 'good'
                  }`}>
                  {formatCurrency(poolDetails.amount_remaining)}
                </div>
              </div>
              {/* Credit Type */}
              <div className='row py-1 d-flex align-items-center'>
                <div className='col-3 label'>Credit Type:</div>
                <div className='col-9'>{formatType(poolDetails.credits_type)}</div>
              </div>
              {/* Notes */}
              <div className='row py-1 d-flex align-items-start'>
                <div className='col-3 label'>Notes:</div>
                <div className='col-9'>{poolDetails.notes}</div>
              </div>
              {/* Edit Credits Modal */}
              {admin ? (
                <div className='row py-1 d-flex align-items-center'>
                  <div className='col-12 mt-3'>
                    <QModalBtn
                      btnClassName='small'
                      buttonText='Edit Credit Pool'
                      onHide={() => setEditCreditsModalOpen(!editCreditsModalOpen)}
                      show={editCreditsModalOpen}
                      id='EditCreditsModal'
                      size='lg'>
                      {editCreditsModalOpen && (
                        <EditCreditsModal
                          data={poolDetails}
                          names={[poolDetails.owner_name]}
                          toggleEditCreditsModal={() => {
                            toggleEditCreditsModal();
                            setIsLoaded(false);
                          }}
                        />
                      )}
                    </QModalBtn>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

CreditPoolDetails.propTypes = {
  poolId: PropTypes.string.isRequired
};

export default CreditPoolDetails;
