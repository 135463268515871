import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Subnav = props => (
  <div id='SubnavComponent' className='container-fluid'>
    <div className='row'>
      <div className='col-12 d-flex flex-align-center'>{props.children}</div>
    </div>
  </div>
);

Subnav.propTypes = {
  children: PropTypes.any
};

export const SubnavLink = props => (
  <Link to={props.link} onClick={props.onClick} className={`mr-5 ${props.className}`}>
    <span>{props.label}</span>
  </Link>
);

SubnavLink.propTypes = {
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string
};

export default Subnav;
