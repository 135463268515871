import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';

// Components
import { useAuth } from '../../hoc/Auth';
import { handleInputChange } from '../../hoc/FormFunctions';
import { QModalText } from '../../components/modal/Modal';
import { QSuccessMessage, QErrorMessage } from '../../components/notifications/Notifications';
import QFormWrapper from '../../components/form/QFormWrapper';
import QFormGroup from '../../components/form/QFormGroup';
import QButton from '../../components/button/Button';

const Profile = props => {
  // Init
  const { fetchData } = useAuth();
  const [isShown, setIsShown] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Update Password
  const [passwordModalVisible, setPasswordModal] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [error, setError] = useState(null);
  // Performance Metrics
  const [firstRender, setFirstRender] = useState(true);

  function togglePasswordModal() {
    setPasswordModal(!passwordModalVisible);
  }

  useEffect(() => {
    setIsShown(true);

    if (MIXPANEL_TOKEN) {
      if (firstRender) {
        mixpanel.track('Account Profile Component: Mounted');
      }
    }
    setFirstRender(false);

    return () => {
      setFirstRender(true);
      abortController.abort();
    };
  }, []);

  // TODO:
  // Verify old password, then submit new password
  function processFormSubmit(values) {
    let body = values.new_password;
    fetchData(signal, '/api/change-password', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        togglePasswordModal();
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        togglePasswordModal();
        setPasswordSuccess(true);
      }
    });
  }

  const validationSchema = yup.object().shape({
    // old_password: yup.string().required('This field is required'),
    new_password: yup.string().required('This field is required').min(12, 'Minimum 12 characters, please'),
    confirm_password: yup
      .string()
      .required('This field is required')
      .oneOf([yup.ref('new_password'), null], 'New passwords must match'),
  });

  return (
    <React.Fragment>
      <div id='Profile' className={`sub-content container-fluid ${isShown ? 'show' : 'hide'}`}>
        <div className='row'>
          <div className='col-lg-1'></div>
          <div className='col-12 col-lg-10 content-container'>
            <div className='row'>
              {/* User ID */}
              <div className='col-12 pt-3 pt-lg-0 mb-2 mb-md-5'>
                <div className='text-group'>
                  <div className='label-sm'>User ID</div>
                  <div className='bold'>{props.user.userid}</div>
                </div>
              </div>
            </div>
            <div className='row'>
              {/* Email */}
              <div className='col-12 mb-2 mb-md-5'>
                <div className='text-group'>
                  <div className='label-sm'>Email</div>
                  <div className='bold'>{props.user.email}</div>
                </div>
              </div>
              {/* Password */}
              <div className='col-12 mb-md-5'>
                <div className='text-group'>
                  <div className='label-sm'>Password</div>
                  {props.user.userid.split('|')[0].includes('google') ? (
                    <p>Authenticated with Google</p>
                  ) : (
                    <React.Fragment>
                      {/* Change Password Modal */}
                      <QModalText
                        text='Change Password'
                        textclassname='bold'
                        onHide={togglePasswordModal}
                        show={passwordModalVisible}
                        size='sm'
                        id='ChangePasswordModal'>
                        {passwordModalVisible ? (
                          <React.Fragment>
                            <h1>Change Password</h1>
                            <QFormWrapper
                              initialValues={{
                                new_password: '',
                                confirm_password: '',
                              }}
                              initialErrors={{
                                new_password: true,
                                confirm_password: true,
                              }}
                              validationSchema={validationSchema}
                              onSubmit={values => processFormSubmit(values)}>
                              {formProps => <ChangePasswordForm {...formProps} />}
                            </QFormWrapper>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </QModalText>
                      {/* Success Message */}
                      {passwordSuccess ? <QSuccessMessage text='Password changed!' className='success-message' /> : ''}
                      {/* Error Message */}
                      {error ? (
                        <QErrorMessage text={<span className='bold'>{error.message}</span>} className='fail-message' />
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            {!['none', 'None'].includes(IMAGE_VERSION) && (
              <div className='row app-version'>
                <div className='col-12'>
                  <span className='text-xs'>{IMAGE_VERSION}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-lg-1'></div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ChangePasswordForm = props => (
  <React.Fragment>
    {/* Verifying old password unavailable with current workflow
      <QFormGroup
          id='old_password'
          name='old_password'
          label='Old Password'
          onChange={e => handleInputChange(e, props, 'old_password', () => {})}
          value={old_password}
          includeError={true}
        />*/}

    <QFormGroup
      element='password'
      id='new_password'
      name='new_password'
      label='New Password'
      onChange={e => handleInputChange(e, props, 'new_password', () => {})}
      includeError={true}
    />

    <QFormGroup
      element='password'
      id='confirm_password'
      name='confirm_password'
      label='Confirm New Password'
      onChange={e => handleInputChange(e, props, 'confirm_password', () => {})}
      includeError={true}
    />

    <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
      Change Password
    </QButton>
  </React.Fragment>
);

ChangePasswordForm.propTypes = {
  isValid: PropTypes.bool.isRequired,
};

export default Profile;
