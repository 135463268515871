import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

// Components
import { useAuth } from '../hoc/Auth';
import PreAuthLayout from './PreAuthLayout';
import PostAuthLayout from './postAuthLayout/PostAuthLayout';
import ErrorBoundary from '../hoc/ErrorBoundary';
import { QErrorMessage } from '../components/notifications/Notifications';

function RootLayout(props) {
  // REF: const { match, location, history } = props;
  const auth = useAuth();

  if (MIXPANEL_TOKEN) {
    // Allow mixpanel debug for non-prod deployments
    const env = ['production', 'prd'].includes(ENVIRON) ? false : true;
    mixpanel.init(MIXPANEL_TOKEN, { debug: env });
  }

  return (
    <ErrorBoundary
      headline='Hmm... Something went wrong.'
      message='Please check your network connection and/or refresh the page shortly.'>
      <div id='RootLayout'>
        {auth.error ? (
          <div className='error-wrapper p-5'>
            <QErrorMessage text={<span className='bold'>{auth.error.message}</span>} />
          </div>
        ) : (
          ''
        )}
        <Switch>
          <React.Fragment>
            {window.location.href.includes('promo-closed') ||
            window.location.href.includes('promo-open') ||
            auth.isAuthenticated === 'false' ||
            auth.serviceFailed ? (
              <Route path='*' component={PreAuthLayout} />
            ) : (
              <Route path='*' component={PostAuthLayout} />
            )}
          </React.Fragment>
        </Switch>
      </div>
    </ErrorBoundary>
  );
}

RootLayout.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default RootLayout = withRouter(RootLayout);
