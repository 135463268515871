import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

// Import hooks & hoc
import { useAuth } from '../../hoc/Auth';
import { useUserData } from '../../hoc/UserData';
import { getPaginationSets } from '../../hoc/CommonFunctions';
import { handleSearchFormSubmit, resetSearchForm } from '../../hoc/FilterFuncs';
import { scrollToTop } from '../../hoc/ScrollFunctions';

// Import components
import SupportMessage from '../../components/support/SupportMessage';
import QButton from '../../components/button/Button';
import QModalBtn from '../../components/modal/Modal';
import SearchForm from '../../components/form/SearchForm';
import AddMembersModal from './AddMembers';
import RemoveMemberModal from './RemoveMember';
import Pagination, { PaginationSummary } from '../../components/pagination/Pagination';
import { LoadingBlock } from '../../components/loading/Loading';

const ProjectMembers = props => {
  // Init
  const { fetchData } = useAuth();
  const { user } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const [timers, setTimers] = useState([]);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Group Members
  const [setLength, setSetLength] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(24);
  const [pages, setPages] = useState(1);
  const [members, setMembers] = useState({ total: '', users: '' });
  const [error, setError] = useState(null);
  const [updatingList, setUpdatingList] = useState(true);
  // Add Members Modal
  const [addMembersModalOpen, setAddMembersModalOpen] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [addMembersError, setAddMembersError] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  // Remove Member Modal
  const [removeUser, setRemoveUser] = useState({ email: '', user_id: '' });
  const [removeMemberModalOpen, setRemoveMemberModalOpen] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [removeSuccess, setRemoveSuccess] = useState(false);
  const [removeMemberError, setRemoveMemberError] = useState(false);
  const [self, setSelf] = useState(false);
  // Filter Members
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [query, setQuery] = useState('');

  let history = useHistory();

  function fetchMembersData(page) {
    const url = '/api/get-group-members?group_id=' + props.project._id + '&page=' + page;
    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        const data = response.data;
        setMembers(data);
        setFilteredMembers(data.users);
        setTimeout(() => {
          setIsLoaded(true);
          scrollToTop();
        }, 500);
        // Get pagination sets
        getPaginationSets(data.total, setLength, num => {
          setPages(num);
          setDataStart(page * setLength - setLength);
          setDataEnd(page * setLength - 1);
        });
      }
      setUpdatingList(false);
    });
  }

  useEffect(() => {
    fetchMembersData(currentPage);

    return () => {
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, []);

  function addMembers(values) {
    setAddMembersError(null);
    setLoadingMembers(true);

    const body = {
      group_id: props.project._id,
      members: values.members,
    };
    fetchData(signal, '/api/add-group-members', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setLoadingMembers(false);
        setAddMembersError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setUpdatingList(true);
        setLoadingMembers(false);
        if (response.data.user_not_found) {
          setUserNotFound(true);
        } else {
          toggleAddMembersModal();
        }
      }
    });
  }

  function toggleAddMembersModal() {
    setAddMembersModalOpen(!addMembersModalOpen);
    fetchMembersData(currentPage);
    setUserNotFound(false);
  }

  function removeMember(values) {
    setRemoveMemberError(null);
    setRemoving(true);

    const body = {
      group_id: props.project._id,
      admin_group_id: '',
      user_id: values.user_id,
    };
    fetchData(signal, '/api/remove-member', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setRemoving(false);
        setRemoveMemberError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setUpdatingList(true);
        setRemoving(false);
        setRemoveSuccess(true);
        props.updateProjects();

        if (values.user_id === user.userid) {
          const timer = setTimeout(() => {
            toggleRemoveMemberModal(removeUser);
            setRemoveSuccess(false);
            // Dismiss members modal
            props.toggleProjectMembersModal();
          }, 3000);
          timers.push(timer);
        } else {
          const timer = setTimeout(() => {
            toggleRemoveMemberModal(removeUser);
            setRemoveSuccess(false);
            fetchMembersData(currentPage);
          }, 3000);
          timers.push(timer);
        }
      }
    });
  }

  function toggleRemoveMemberModal(member) {
    if (member.user_id === user.userid) {
      setSelf(true);
    } else {
      setSelf(false);
    }
    setRemoveUser(member);
    setRemoveMemberModalOpen(!removeMemberModalOpen);
  }

  function updatePage(page) {
    setCurrentPage(page);
    fetchMembersData(page);
  }

  const users_list = Array.from(filteredMembers).map((member, index) => (
    <div key={index} className='user-row-container'>
      <div className='row list item user-details static'>
        <div className='col-12 col-sm-6 col-lg-4 email'>
          <input value={member.email} data-testid='member-email-input' readOnly />
        </div>
        <div className='col-12 col-sm-6 col-lg-3 name'>
          <input value={member.name} data-testid='member-name-input' readOnly />
        </div>
        <div className='col-12 col-sm-6 col-lg-3 connection'>
          <input value={member.identities[0].connection} data-testid='member-connection-input' readOnly />
        </div>
        <div className='col-12 col-sm-6 col-lg-2 actions'>
          {members.total > 1 ? (
            <QButton className='button btn-link' onClick={() => toggleRemoveMemberModal(member)}>
              {member.email === user.email ? 'Remove Myself' : 'Remove'}
            </QButton>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  ));

  return (
    <div id='ProjectMembers' className='container-fluid pt-4'>
      <div className='row'>
        <div className='col-12 pt-2'>
          {/* Users List */}
          <div className='row'>
            <div className='col-12 d-flex flex-column align-items-start justify-content-start text-xs mb-2'>
              <h2 className='mb-4'>{props.project.name}</h2>
              <div className='d-flex flex-wrap w-100 align-items-center data-sets-summary'>
                <Pagination
                  pages={pages}
                  currentPage={currentPage}
                  updatePage={value => updatePage(value)}
                  updating={value => {
                    if (value === true) {
                      setIsLoaded(false);
                    } else {
                      setIsLoaded(true);
                    }
                  }}
                />
                <PaginationSummary
                  dataTotal={members.total ? parseInt(members.total) : null}
                  dataStart={dataStart}
                  dataEnd={dataEnd}
                  dataType={'members'}
                />
                <div className='d-flex justify-content-between align-items-center w-100 pb-3'>
                  <SearchForm
                    handleSubmit={event =>
                      handleSearchFormSubmit(event, members.users, 'email', query, list => setFilteredMembers(list))
                    }
                    resetForm={event =>
                      resetSearchForm(members.users, 'email', function (list) {
                        setQuery('');
                        setFilteredMembers(list);
                      })
                    }
                    updateQuery={event => setQuery(event.target.value)}
                    searchField='Emails'
                    query={query}
                    className='mr-3 pt-0'
                  />
                  <QModalBtn
                    btnClassName='small no-wrap'
                    buttonText='Add Members'
                    onHide={() => setAddMembersModalOpen(!addMembersModalOpen)}
                    show={addMembersModalOpen}
                    id='AddMembersModal'
                    size='lg'>
                    {loadingMembers ? (
                      <LoadingBlock className='transparent-bg' text='Please be patient...' />
                    ) : (
                      <React.Fragment>
                        {addMembersModalOpen ? (
                          <AddMembersModal
                            projectName={props.project.name}
                            addMembersError={addMembersError}
                            addMembers={values => addMembers(values)}
                            userNotFound={userNotFound}
                            toggleAddMembersModal={() => setAddMembersModalOpen(!addMembersModalOpen)}
                          />
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    )}
                  </QModalBtn>
                </div>
              </div>
            </div>
          </div>
          <div className='row list header'>
            <div className='col-12 col-sm-6 col-lg-4 email'>User Email</div>
            <div className='col-12 col-sm-6 col-lg-3 name'>Name</div>
            <div className='col-12 col-sm-6 col-lg-3 connection'>Connection</div>
            <div className='col-12 col-sm-6 col-lg-2 actions'>Actions</div>
          </div>
          {!isLoaded ? (
            <LoadingBlock />
          ) : (
            <React.Fragment>
              {error ? (
                <SupportMessage errorClasses='row mx-0 my-3 p0' error={error.message} />
              ) : (
                <React.Fragment>
                  {updatingList ? <LoadingBlock /> : <React.Fragment>{users_list}</React.Fragment>}

                  {/* Remove Member Modal */}
                  {removeMemberModalOpen ? (
                    <Modal
                      show={removeMemberModalOpen}
                      onHide={() => toggleRemoveMemberModal(removeUser)}
                      backdrop='static'
                      centered
                      size='lg'
                      id='RemoveMemberModal'
                      className='q-modal-component'>
                      <Modal.Body>
                        <div className='q-modal-body'>
                          <i className='icon-close' onClick={() => toggleRemoveMemberModal(removeUser)} />
                          {removing ? (
                            <LoadingBlock className='transparent-bg' text='Please be patient...' />
                          ) : (
                            <RemoveMemberModal
                              member={removeUser}
                              self={self}
                              projectName={props.project.name}
                              removeMemberError={removeMemberError}
                              toggleRemoveMemberModal={() => toggleRemoveMemberModal(removeUser)}
                              removeMember={values => removeMember(values)}
                              removeSuccess={removeSuccess}
                            />
                          )}
                        </div>
                      </Modal.Body>
                    </Modal>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

ProjectMembers.propTypes = {
  project: PropTypes.object.isRequired,
  toggleProjectMembersModal: PropTypes.func.isRequired,
  updateProjects: PropTypes.func.isRequired,
};

export default ProjectMembers;
