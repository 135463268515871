import React from 'react';
import PropTypes from 'prop-types';

import InlineFormGroup from '../../../components/form/InlineFormGroup';
import { handleInputChange } from '../../../hoc/FormFunctions';

const MeanFieldHandle = props => {
  return (
    <div id='MeanFieldHandle' className='d-flex w-100'>
      <InlineFormGroup
        element='text'
        containerClassName='w-100'
        id={`${props.inputName}-input`}
        name={props.inputName}
        placeholder='SCF Problem Handle'
        inputProps={{ 'data-testid': `${props.inputName}-input` }}
        label='Mean Field (Optional)'
        value={props.handle || ''}
        className='dark'
        onChange={e => {
          handleInputChange(e, props.formProps, props.inputName, e => props.setHandle(e.target.value));
        }}
        includeError={true}
      />
    </div>
  );
};

MeanFieldHandle.propTypes = {
  inputName: PropTypes.string.isRequired,
  handle: PropTypes.string,
  setHandle: PropTypes.func.isRequired,
  formProps: PropTypes.object.isRequired,
};

export default MeanFieldHandle;
