import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
import { getProjectName } from '../../../hoc/CommonFunctions';
import { LoadingBlock } from '../../../components/loading/Loading';
import { QErrorMessage } from '../../../components/notifications/Notifications';

const ViewUserProjects = props => {
  // Init
  const { fetchUserProjects } = useAuth();
  const { setOverlayLevel1 } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET projects
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  function fetchProjectsList() {
    fetchUserProjects(signal, props.user.user_id, null, null, response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
        setIsLoaded(true);
      }
      // if data is returned
      if (response.status === 'success') {
        setProjects(response.data);
        setIsLoaded(true);
      }
    });
  }

  useEffect(() => {
    fetchProjectsList();

    return () => {
      setIsLoaded(false);
      abortController.abort();
    };
  }, []);

  const projects_list = Array.from(projects).map((project, index) => (
    <div
      onClick={() => {
        const obj = {
          content_type: 'project-details',
          selected_project: project,
          projects: projects,
          user: props.user
        };
        setOverlayLevel1(obj);
      }}
      key={index}
      data-testid='project-list-item'>
      <div className='row list item border-bottom projects-list'>
        <div className='col-12 col-sm-4 bold'>{getProjectName(project)}</div>
        <div className='col-12 col-sm-4'>{project.description}</div>
        <div className='col-12 col-sm-4'>{project._id}</div>
      </div>
    </div>
  ));

  return (
    <div id='ViewUserProjects'>
      {!isLoaded ? (
        <LoadingBlock className='transparent-bg' />
      ) : (
        <React.Fragment>
          {error ? (
            <div className='view-user container-fluid'>
              <QErrorMessage className='row mx-2 my-4' text={<span className='bold'>{error.message}</span>} />
            </div>
          ) : (
            <div className='view-user container-fluid'>
              <div className='row'>
                <div className='col-12 content-container pt-0'>
                  {projects.length > 0 ? (
                    <React.Fragment>
                      {/* Header */}
                      <div className='row list header'>
                        <div className='col-12 col-sm-4'>Project Name</div>
                        <div className='col-12 col-sm-4'>Description</div>
                        <div className='col-12 col-sm-4'>Project ID</div>
                      </div>
                      {/* List */}
                      {projects_list}
                    </React.Fragment>
                  ) : (
                    <div className='my-3'>No projects found.</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ViewUserProjects.propTypes = {
  user: PropTypes.object.isRequired
};

export default ViewUserProjects;
