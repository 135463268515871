import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../hoc/Auth';
import ViewUserTopNav from './users/ViewUserTopNav';
import TransitionWrapper from './components/TransitionWrapper';
import ViewUserCredits from './users/ViewUserCredits';
import ViewUserProjects from './users/ViewUserProjects';
import ViewUserRequestLog from './users/ViewUserRequestLog';
import ViewUserSettings from './users/ViewUserSettings';

const UserDetails = props => {
  // Init
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [viewUserID, setViewUserID] = useState('');
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET User Details
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);

  function fetchUserDetails(user_id) {
    let url = '/api/get-user-by-id?user_id=' + user_id;
    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
        setIsLoaded(true);
      }
      // if data is returned
      if (response.status === 'success') {
        setUser(response.data);
        setIsLoaded(true);
      }
    });
  }

  useEffect(() => {
    let url_partial = props.location.pathname.split('user/')[1];
    let user_id = url_partial.split('/')[0];

    setViewUserID(user_id);
    fetchUserDetails(user_id);

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div id='UserDetails'>
      <ViewUserTopNav user_id={viewUserID} user={user} />
      <TransitionWrapper isLoaded={isLoaded} location={props.location} error={error}>
        <Route path='*/credits' render={() => <ViewUserCredits user={user} />} />
        <Route path='*/view-projects' render={() => <ViewUserProjects user={user} />} />
        <Route path='*/view-request-log' render={() => <ViewUserRequestLog user={user} />} />
        <Route path='*/profile' render={() => <ViewUserSettings user={user} />} />
      </TransitionWrapper>
    </div>
  );
};

UserDetails.propTypes = {
  location: PropTypes.object.isRequired
};

export default UserDetails;
