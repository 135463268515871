import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

// Components
import { useAuth } from '../hoc/Auth';
import { QErrorMessage } from '../components/notifications/Notifications';
import CopyToClipboard, { copyToClipboard } from '../components/copyToClipboard/CopyToClipboard';

const Download = props => {
  // Init
  const { fetchData } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [isShown, setIsShown] = useState(false);
  // Get release notes
  const [releaseNotes, setReleaseNotes] = useState({});
  const [error, setError] = useState(null);
  // Client Lib
  const [clientLibVersion, setClientLibVersion] = useState('');
  const [clientLibError, setClientLibError] = useState(null);
  // PIP Package
  const [pipFileLink, setPipFileLink] = useState('');
  const [fileName, setFileName] = useState('pip_package_file');
  const [fileLinkError, setFileLinkError] = useState(null);

  useEffect(() => {
    setIsShown(true);
    fetchData(signal, '/api/public_info?filename=release_notes.json', {}, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        setReleaseNotes(response.data);
      }
    });

    if (MIXPANEL_TOKEN) {
      mixpanel.track('Download Component: Mounted');
    }

    if (CLIENT_LIB_VERSION) {
      setClientLibVersion(CLIENT_LIB_VERSION);
    } else {
      setClientLibError(true);
    }

    if (QEMIST_PIP_PACKAGE_LINK) {
      setPipFileLink(QEMIST_PIP_PACKAGE_LINK);
      let file_name = QEMIST_PIP_PACKAGE_LINK.split('/');
      setFileName(file_name[file_name.length - 1]);
    } else {
      setFileLinkError(true);
    }

    return () => {
      setIsShown(false);
      setClientLibError(null);
      setFileLinkError(null);
      abortController.abort();
    };
  }, []);

  const run_install_code = `pip3 install ${fileName}`;

  return (
    <div id='Download' className={isShown ? 'show' : 'hide'}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-1'></div>
          <div className='col-12 col-lg-10 content-container'>
            <div className='row'>
              {/* Client Library */}
              <div className='col-12 grey-bg rounded-corners px-4 py-4 mb-4'>
                <h3 className='bold'>QEMIST Client Library</h3>
                <p>
                  You will need this to send solve requests to the QEMIST API. See <Link to='/docs'>documentation</Link>
                  .
                </p>
                <div className='mb-4'>
                  <ul>
                    <li>
                      Version:{' '}
                      {clientLibError ? (
                        <span className='danger bold'>Client Library Version not provided.</span>
                      ) : (
                        <React.Fragment>{clientLibVersion}</React.Fragment>
                      )}
                    </li>
                    {releaseNotes.qemist_cloud &&
                    releaseNotes.qemist_cloud[clientLibVersion] &&
                    releaseNotes.qemist_cloud[clientLibVersion].release_date ? (
                      <li>Released: {releaseNotes.qemist_cloud[clientLibVersion].release_date}</li>
                    ) : (
                      ''
                    )}
                    <li>
                      Requires:{' '}
                      <a className='bold' href='https://www.python.org/downloads/' target='_blank'>
                        Python Version 3.0
                      </a>{' '}
                      or higher &{' '}
                      <a className='bold' href='https://pip.pypa.io/en/stable/installing/' target='_blank'>
                        pip3
                      </a>
                    </li>
                    <li>
                      Dependencies:{' '}
                      <a className='bold' href='https://numpy.org/' target='_blank'>
                        NumPy
                      </a>
                      ,{' '}
                      <a className='bold' href='https://pypi.org/project/sseclient/' target='_blank'>
                        sseclient
                      </a>
                      , and{' '}
                      <a className='bold' href='https://pypi.org/project/rdkit/' target='_blank'>
                        rdkit
                      </a>{' '}
                      will be automatically installed by following the instructions below
                    </li>
                  </ul>
                </div>
                {fileLinkError ? (
                  <QErrorMessage
                    text={<span className='bold'>Pip package link not provided in frontend environment variable.</span>}
                  />
                ) : (
                  <React.Fragment>
                    <a href={pipFileLink} className='btn small inline-block bold mb-4'>
                      <i className='icon-download' /> Download
                    </a>
                    <div className='mb-3'>
                      <p className='bold'>Installation Instructions</p>
                      <ol>
                        <li>Move downloaded file to your preferred local directory.</li>
                        <li>Go to your terminal and navigate to the directory containing the wheel file.</li>
                        <li>
                          <span className='d-flex align-items-center'>
                            Run <code className='mx-2 inline'>{run_install_code}</code>{' '}
                            <CopyToClipboard onClick={() => copyToClipboard(run_install_code)} />
                          </span>
                        </li>
                      </ol>
                    </div>
                  </React.Fragment>
                )}
              </div>
              {/* Tangelo */}
              <div className='col-12 grey-bg rounded-corners px-4 py-4 mb-4'>
                <h3>Tangelo</h3>
                <p>
                  Use this optional open-source package to run experiments on quantum devices, and other joint features
                  with QEMIST Cloud.
                </p>
                <div className='mb-4'>
                  <ul>
                    <li>
                      Find all installation instructions and details at{' '}
                      <a href='https://github.com/goodchemistryco/Tangelo' target='_blank'>
                        https://github.com/goodchemistryco/Tangelo
                      </a>
                    </li>
                    <li>
                      Find tutorials and examples of use at{' '}
                      <a href='https://github.com/goodchemistryco/Tangelo-Examples' target='_blank'>
                        https://github.com/goodchemistryco/Tangelo-Examples
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-1'></div>
        </div>
      </div>
    </div>
  );
};

export default Download;
