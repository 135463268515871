import React from 'react';
import PropTypes from 'prop-types';

import QTextInput from './FormElements';

export const SearchForm = props => (
  <form
    id='search-form'
    className={`search-field ${props.className}`}
    onSubmit={event => props.handleSubmit(event)}
    onReset={event => props.resetForm(event)}>
    <QTextInput
      id='search'
      name='search'
      label={`Search ${props.searchField}`}
      placeholder='Search'
      onChange={event => props.updateQuery(event)}
    />
    <button type='submit' className='search' data-testid='search-button'>
      <i className='icon-search' />
    </button>
    {props.query !== '' ? (
      <button type='reset' className='clear-filter'>
        Clear Filter <i className='icon-close-small' />
      </button>
    ) : (
      ''
    )}
  </form>
);

SearchForm.propTypes = {
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  searchField: PropTypes.string,
  query: PropTypes.string.isRequired
};

export default SearchForm;
