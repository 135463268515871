import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

export const QButton = props => (
  <Button
    color='primary'
    className={`q-button-component ${props.className}`}
    disabled={props.disabled}
    onClick={props.onClick}
    type={props.type}
    onSubmit={props.onSubmit}
    name={props.name}
    data-testid={props.dataTestId}>
    {props.children}
  </Button>
);

QButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default QButton;
