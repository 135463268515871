import React from 'react';

import { handleInputChange } from '../../../hoc/FormFunctions';
import { formatToCapitalCase } from '../../../hoc/CommonFunctions';
import InlineFormGroup from '../../../components/form/InlineFormGroup';
import { getSelectMenu } from '../functions/SubmitFormFunctions';
import { checkConfigType, hideConfig, getAllowedValues, translateParamKey } from '../functions/SubmitFunctions';

function formatInputLabel(param_key) {
  // Update label as per custom dictionary
  const translated = translateParamKey(param_key);
  // Return formatted snake_case to Capital Case
  return formatToCapitalCase(translated);
}

function handleInputValue(key, val, solver, solver_configs) {
  // If config_type is dict
  const dict_type_found = checkConfigType(solver_configs, solver.solver_name, key, 'dict');
  // If config_type is list
  const list_type_found = checkConfigType(solver_configs, solver.solver_name, key, 'list');

  // Stringify value
  if ((dict_type_found || list_type_found) && typeof val !== 'string') {
    return JSON.stringify(val);
  } else return val;
}

function ShowInputs(solver, solverIndex, formProps, configs, blacklist, validation_keys, pipeline, updatePipeline) {
  const params = Object.entries(solver.params);

  // Filter out certain params
  let filtered_params = [];
  params.forEach(param => {
    // Blacklist
    if (!blacklist.includes(param[0])) {
      filtered_params.push(param);
    }
  });

  function handleSolverParamChange(param, value) {
    let pipeline_mod = structuredClone(pipeline);
    // Modify pipeline
    pipeline_mod[solverIndex].params[param] = value;
    // Update pipeline
    updatePipeline(pipeline_mod);
  }

  function inputChange(e, formProps, param_key, validation_keys) {
    if (validation_keys.includes(`${param_key}__${solverIndex}`)) {
      handleInputChange(e, formProps, `${param_key}__${solverIndex}`, e => {
        handleSolverParamChange(param_key, e.target.value);
      });
    } else {
      handleSolverParamChange(param_key, e.target.value);
    }
  }

  function showParam([param_key, param_val]) {
    // Show Select component if more than one dropdown option available
    const select_type_found = checkConfigType(configs, solver.solver_name, param_key, 'select');
    const dict_type_found = checkConfigType(configs, solver.solver_name, param_key, 'dict');

    if (select_type_found) {
      const menu = getSelectMenu(configs, solver.solver_name, param_key);
      if (menu.length > 1) {
        return (
          <InlineFormGroup
            element='select'
            id={`${param_key}-input`}
            className='dark mr-3'
            containerClassName='w-100'
            data-testid={`${param_key}-input`}
            label={formatInputLabel(param_key)}
            value={param_val}
            onChange={e => {
              handleSolverParamChange(param_key, e.target.value);
            }}
            selectMenu={menu}
            includeError={false}
          />
        );
      }
    }
    // Otherwise show input group if
    // • allows more than just "NoneType"
    // • more than a single "allowed_value" is available
    else {
      const hide_config = hideConfig(configs, solver.solver_name, param_key);
      const allowed_values = getAllowedValues(configs, solver.solver_name, param_key);
      if ((!hide_config && !allowed_values) || (allowed_values && allowed_values.length > 1)) {
        return (
          <InlineFormGroup
            element={dict_type_found ? 'textarea-resize' : 'text'}
            id={`${param_key}__${solverIndex}-input`}
            name={`${param_key}__${solverIndex}`}
            inputProps={{ 'data-testid': `${param_key}__${solverIndex}-input` }}
            label={formatInputLabel(param_key)}
            value={handleInputValue(param_key, param_val, solver, configs)}
            className='dark'
            containerClassName='w-100'
            onChange={e => inputChange(e, formProps, param_key, validation_keys, pipeline)}
            includeError={validation_keys.includes(`${param_key}__${solverIndex}`) ? true : false}
          />
        );
      }
    }
  }

  // Render parameter input components
  return Array.from(filtered_params).map((param, index) => (
    <React.Fragment key={index}>{showParam(param)}</React.Fragment>
  ));
}

export default ShowInputs;
