import moment from 'moment';
import { evaluate } from 'mathjs';

export const allRangeStart = 1609488000; // Jan 1, 2021
export const initRangeStart = 1672560000; // Jan 1, 2023
export function getRangeEnd() {
  const end_of_day = moment().endOf('day').unix();
  return end_of_day;
}

export function getProjectName(project) {
  // Format 'Project Name--!1--Project'
  // to simply 'Project Name'
  if (project !== undefined && project.name !== undefined) {
    if (project.name.indexOf('--!') > 0) {
      let project_name = project.name.split('--!')[0];
      return project_name;
    } else {
      let project_name = project.name.replace('--Project', '');
      return project_name;
    }
  }
}

export function sortProjectList(data, new_order, callback) {
  var sorted_projects = data.sort(function (a, b) {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (new_order === 'asc') {
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    } else {
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
    }
    return 0;
  });
  callback(sorted_projects);
}

export function getOrgsList(list) {
  // Filter list to include only objects that contain '|Org' or '--Org' in its name.
  let orgs_list = [];
  list.forEach(group => {
    if (
      ['|Org', '--Org'].some(function (value) {
        return group.name.indexOf(value) >= 0;
      })
    ) {
      orgs_list.push(group);
    }
  });
  return orgs_list;
}

export function getOrgName(group) {
  // Format 'Org Name--Org' or 'Org Name|Org'
  // to simply 'Org Name'
  // Pipe version is legacy
  if (group !== undefined && group.name !== undefined) {
    let formatted_name = group.name.replace('|Org', '');
    formatted_name = formatted_name.replace('--Org', '');
    return formatted_name;
  }
}

export function getAdminGroupName(group) {
  // Format 'Org Name--Admin'
  // to simply 'Org Name'
  if (group !== undefined && group.name !== undefined) {
    let formatted_name = group.name.replace('--Admin', '');
    return formatted_name;
  }
}

export function getDataTotal(data, param) {
  // Recursively iterate through arg 1 data list,
  // find the key specified in arg 2,
  // then add the values up to calculate sum
  if (data !== undefined && data !== null) {
    var data_total = [];
    Array.from(data).map((object, index) => {
      if (object[param] !== null) {
        data_total.push(object[param]);
      }
    });

    var sum = data_total.reduce(function (a, b) {
      return a + b;
    }, 0);

    return sum;
  }
}

export function convertSecondstoTime(given_seconds, callback) {
  // Convert seconds to 00:00:00 string format
  if (given_seconds !== undefined && given_seconds !== null) {
    var hours = Math.floor(given_seconds / 3600);
    var minutes = Math.floor((given_seconds - hours * 3600) / 60);
    var seconds = given_seconds - hours * 3600 - minutes * 60;

    var timeString =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0');

    callback(timeString);
  }
}

export function convertTimestampToDate(unixtimestamp, dateStyle = 'medium') {
  // Convert unix timestamp to Jan 1, 2022 format
  let milliseconds = unixtimestamp * 1000;
  let timeObj = new Date(milliseconds);
  let formattedDate = new Intl.DateTimeFormat([], { dateStyle: dateStyle }).format(timeObj);
  return formattedDate;
}

export function convertTime(unixtimestamp) {
  // Convert unixtimestamp to Jan 1, 2022, 10:40:00AM PST format
  let milliseconds = unixtimestamp * 1000;
  let timeObj = new Date(milliseconds);
  let formattedTime = new Intl.DateTimeFormat([], { dateStyle: 'medium', timeStyle: 'long' }).format(timeObj);
  return formattedTime;
}

export function convertTimeSpan(seconds) {
  if (seconds >= 0) {
    let secs = seconds;
    let formatted_string = '';
    // Calculate hours
    const hours = Math.trunc(secs / 3600);
    if (hours) {
      formatted_string = formatted_string + `${hours}h `;
    }
    secs = secs - hours * 3600;
    // Calculate minutes
    const mins = Math.trunc(secs / 60);
    if (mins) {
      formatted_string = formatted_string + `${mins}m `;
    }
    secs = secs - mins * 60;
    // Calculate seconds
    if (secs) {
      formatted_string = formatted_string + `${secs}s`;
    }
    // Convert seconds to 1h 2m 3s format
    return formatted_string;
  } else return '-';
}

export function convertToSciNote(value) {
  // Convert plain number to 1e-6 format
  if (typeof value === 'number') {
    if (value === 0) {
      return 0;
    } else {
      let sci_note = value.toExponential();
      return sci_note;
    }
  } else return value;
}

export function formatProjectName(name) {
  // Reformat "Project Name--!1--Project" to "Project Name"
  if (name !== null && name !== undefined) {
    if (name.indexOf('--!') > 0) {
      return name.split('--!')[0];
    } else {
      return name.replace('--Project', '');
    }
  }
}

export function formatStatus(word) {
  // Reformat "in_progress" to "In Progress"
  if (word !== undefined) {
    if (word.indexOf('_') !== -1) {
      let newWord = word.replace(/_/g, ' ');
      var splitStr = newWord.split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    } else {
      var formattedWord = word.charAt(0).toUpperCase() + word.slice(1);
      return formattedWord;
    }
  }
}

export function formatCurrency(num) {
  // Reformat number to $0.00
  if (!num || num === null) {
    return '$0.00';
  } else {
    let formatted_number = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
    return formatted_number.format(num);
  }
}

export function formatToCapitalCase(original_str) {
  /*
  Format label to capitalized words

  Args:
    • original_str = string: e.g. 'truncation_order'

  Returns:
    • Capitalized text with appropriate spaces: 'Truncation Order'
  */

  // Replace underscore with space
  let formatted_text = original_str.replaceAll('_', ' ');

  // Capitalize each word
  const split_words = formatted_text.split(' ');
  split_words.forEach((word, i) => {
    split_words[i] = word[0].toUpperCase() + word.substr(1);
  });

  // Join words
  formatted_text = split_words.join(' ');
  return formatted_text;
}

export function formatStringsList(list, capitalize = false, all_caps = false, join = true, inclusive = true) {
  /*
  Format list to capitalized words

  Args:
    • list: e.g. ['apple', 'banana']
    • capitalize (boolean)
    • all_caps (boolean)

  Returns:
    • String of list with commas/and: 'apple and banana'
  */
  let string = '';
  const last_index = list.length - 1;
  list.forEach((str, i) => {
    // Transform list item into string
    if (typeof str !== 'string') {
      str = JSON.stringify(str);
    }
    // Capitalize first character
    if (capitalize) {
      str = formatToCapitalCase(str);
    }
    // All caps
    if (all_caps) {
      str = str.toUpperCase();
    }
    // Join list items
    if (i === 0) {
      string = str;
    } else if (i !== last_index) {
      string = string + ', ' + str;
    } else if (i === last_index && list.length > 2) {
      const _inclusive = inclusive ? ', and ' : ' or ';
      const _join = join ? _inclusive : ', ';
      string = string + _join + str;
    } else {
      const _inclusive = inclusive ? ' and ' : ' or ';
      const _join = join ? _inclusive : ', ';
      string = string + _join + str;
    }
  });
  return string;
}

export function validatePagination(url, valid_routes, callback) {
  // Check for valid_routes substring in url
  let valid_route;
  valid_routes.forEach(route => {
    if (url.includes(route)) {
      valid_route = route;
    }
  });

  // Execute pagination functions only if url contains expected substring
  if (valid_route) {
    // Get post_hash_value from url
    let post_hash_value;
    if (url.includes(valid_route)) {
      const url_partial = url.split(valid_route);
      post_hash_value = url_partial[1];
    }

    // Check if post_hash_value is a number
    const is_num = /^\d+$/;
    // If pagination not in url or post_hash_value is not a number
    if (!post_hash_value || !is_num.test(post_hash_value)) {
      callback('invalid');
    }
    // Otherwise return valid post_hash_value
    else {
      callback(post_hash_value);
    }
  }
}

export function getPaginationSets(total, set_length, callback) {
  // Return total number of pages
  // based on total and set_length args
  if (total !== null && total !== undefined) {
    // Examples:
    // 5 total, 10 set_length = 1 page
    // 12 total, 10 set_length = 2 pages
    // 30 total, 10 set_length = 3 pages
    let pages = Math.trunc(total / set_length) + 1;
    if (total % set_length === 0) {
      pages = Math.trunc(total / set_length);
    }
    callback(pages);
  }
}

export function charactersHelper(element_id, max_char) {
  // Show remaining characters allowed
  let element = document.getElementById(element_id);
  if (element !== null) {
    let chars_used = element.value.length;
    let remaining_chars = max_char - chars_used;
    return 'Characters remaining: ' + remaining_chars;
  }
}

export function downloadFile(data, filename, ext) {
  // Download data as file
  // Create blob link
  const url = window.URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('data-testid', 'download-element');
  link.setAttribute('download', `${filename}.${ext}`);
  // Append to html
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

export function openFileNewWindow(data) {
  // Open data in new window
  // Create blob link
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('data-testid', 'open-link-element');
  link.setAttribute('target', `_blank`);
  // Append to html
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

export function openLink(url) {
  // Open cross-origin link in new window
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('data-testid', 'download-link-element');
  link.setAttribute('target', `_blank`);
  // Append to html
  document.body.appendChild(link);
  // Open link
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

export function exportToCsv(rows, range_start, range_end, id = null) {
  // Create and download credit report to csv file
  let csv_content = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');

  let date_range = moment.unix(range_start).format('YYMMDD') + '_' + moment.unix(range_end).format('YYMMDD');

  let id_label = '';
  if (id) {
    id_label = '_' + id + '_';
  }
  // 3. Create new download link in dom
  let encoded_uri = encodeURI(csv_content);
  let link = document.createElement('a');
  link.setAttribute('data-testid', 'appended-export-data');
  link.setAttribute('href', encoded_uri);
  link.setAttribute('style', 'visibility: hidden;');
  link.setAttribute('download', 'credits_report_' + id_label + date_range + '.csv');
  document.body.appendChild(link);

  link.click(); // Trigger download
}

export function triggerLogout() {
  // Programatically trigger logging out of session
  let link = document.createElement('a');
  link.setAttribute('href', '/logout');
  link.setAttribute('style', 'visibility: hidden;');
  document.body.appendChild(link);

  link.click(); // Trigger logout
}

export function formatDataSize(bytes) {
  // Take a number of bytes and translate it to string:
  // 999 bytes
  // 1.25 MB
  let size = bytes;
  let unit = 'bytes';

  // Format to KB
  if (999 < bytes && bytes < 1000000) {
    size = (bytes / 1000).toFixed(0);
    unit = 'KB';
  }

  // Format to MB
  else if (1000000 <= bytes && bytes < 1000000000) {
    size = (bytes / 1000000).toFixed(1);
    unit = 'MB';
  }

  // Format to GB
  else if (1000000000 <= bytes) {
    size = (bytes / 1000000000).toFixed(1);
    unit = 'GB';
  }

  return size.toString() + ' ' + unit;
}

export function printDocument(element_id) {
  // Print document based on HTML element id
  var print_window = window.open('', 'PRINT', 'height=680,width=1200');
  print_window.document.write(
    '<html><head>' +
      '<style>' +
      'body {font-family: "Georgia", serif; font-size: 11pt; line-height: 15pt;}' +
      'h1 {margin-top: 24pt; margin-bottom: 24pt;}' +
      'h2 {margin-top: 20pt; margin-bottom: 20pt;}' +
      '</style>' +
      '<title>' +
      document.title +
      '</title>'
  );
  print_window.document.write('</head><body>');
  print_window.document.write(document.getElementById(element_id).innerHTML);
  print_window.document.write('</body></html>');

  print_window.document.close(); // necessary for IE >= 10
  print_window.focus(); // necessary for IE >= 10*/

  print_window.print();
  print_window.close();
  return true;
}

export function evaluateMath(str) {
  try {
    return evaluate(str);
  } catch (e) {
    console.warn(e);
  }
}

export function checkListForItem(item, list) {
  /*
  Check if a string exists in a list strings.
  Returns a boolean value.
  */

  // Convert all items in list arg to lowercase
  const modified_list = list ? list.map(v => v.toLowerCase()) : [];

  // Check if the lowercased item exists in the modified list
  return modified_list.includes(item.toLowerCase());
}
