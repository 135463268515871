export const singleTerms = {
  required_terms: ['mock_required_terms_1'],
  terms: [
    {
      last_seen: 'never',
      name: 'mock_required_terms_1',
      response: 'pending'
    }
  ]
};

export const multipleTerms = {
  required_terms: ['mock_required_terms_1', 'mock_required_terms_2', 'mock_required_terms_3'],
  terms: [
    {
      last_seen: 'never',
      name: 'mock_required_terms_1',
      response: 'pending'
    },
    {
      last_seen: 'never',
      name: 'mock_required_terms_2',
      response: 'pending'
    },
    {
      last_seen: 'never',
      name: 'mock_required_terms_3',
      response: 'pending'
    }
  ]
};

export const mock_required_terms_1 = {
 "cells": [
  {
   "cell_type": "markdown",
   "id": "a0bd918b",
   "metadata": {},
   "source": [
    "\n",
    "# Mock Required Terms 1\n",
    "\n",
    "**Supercalifragilisticexpialidocious**\n",
   ]
  }
 ],
 "metadata": {
  "kernelspec": {
   "display_name": "Python 3 (ipykernel)",
   "language": "python",
   "name": "python3"
  },
  "language_info": {
   "codemirror_mode": {
    "name": "ipython",
    "version": 3
   },
   "file_extension": ".py",
   "mimetype": "text/x-python",
   "name": "python",
   "nbconvert_exporter": "python",
   "pygments_lexer": "ipython3",
   "version": "3.9.7"
  }
 },
 "nbformat": 4,
 "nbformat_minor": 5
}

export const mock_required_terms_2 = {
 "cells": [
  {
   "cell_type": "markdown",
   "id": "a0bd918b",
   "metadata": {},
   "source": [
    "\n",
    "# Mock Required Terms 2\n",
    "\n",
    "**Rock paper scissor**\n",
   ]
  }
 ],
 "metadata": {
  "kernelspec": {
   "display_name": "Python 3 (ipykernel)",
   "language": "python",
   "name": "python3"
  },
  "language_info": {
   "codemirror_mode": {
    "name": "ipython",
    "version": 3
   },
   "file_extension": ".py",
   "mimetype": "text/x-python",
   "name": "python",
   "nbconvert_exporter": "python",
   "pygments_lexer": "ipython3",
   "version": "3.9.7"
  }
 },
 "nbformat": 4,
 "nbformat_minor": 5
}

export const mock_required_terms_3 = {
 "cells": [
  {
   "cell_type": "markdown",
   "id": "a0bd918b",
   "metadata": {},
   "source": [
    "\n",
    "# Mock Required Terms 3\n",
    "\n",
    "**Meow mix meow mix**\n",
   ]
  }
 ],
 "metadata": {
  "kernelspec": {
   "display_name": "Python 3 (ipykernel)",
   "language": "python",
   "name": "python3"
  },
  "language_info": {
   "codemirror_mode": {
    "name": "ipython",
    "version": 3
   },
   "file_extension": ".py",
   "mimetype": "text/x-python",
   "name": "python",
   "nbconvert_exporter": "python",
   "pygments_lexer": "ipython3",
   "version": "3.9.7"
  }
 },
 "nbformat": 4,
 "nbformat_minor": 5
}
