import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

import { useAuth } from '../../hoc/Auth';
import { QSelect } from '../../components/form/FormElements';
import { LoadingInline } from '../../components/loading/Loading';

const ChangeRoleForm = props => {
  // Init
  const { fetchData } = useAuth();
  const [initialValue, setInitialValue] = useState('');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1199px)' });
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Select
  const [selected, setSelected] = useState('');
  const [activated, setActivated] = useState(false);
  // Change Role
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.adminEmails.includes(props.member.email)) {
      setInitialValue('group_admin');
      setSelected('group_admin');
    } else {
      setInitialValue('standard_user');
      setSelected('standard_user');
    }

    return () => {
      abortController.abort();
    };
  }, [props.member, props.user, props.adminGroup, props.adminEmails]);

  const handleChange = event => {
    setSelected(event.target.value);
    if (event.target.value !== initialValue) {
      setActivated(true);
    } else {
      setActivated(false);
    }
  };

  const cancel = () => {
    setSelected(initialValue);
    setActivated(false);
  };

  const changePermissions = () => {
    setProcessing(true);
    const body = {
      group_id: props.adminGroup._id,
      member_id: props.member.user_id,
      role: selected
    };
    fetchData(signal, '/api/change-role', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setProcessing(false);
        setError(response.error);
        setTimeout(() => setError(null), 3000);
      }
      // if data is returned
      if (response.status === 'success') {
        setProcessing(false);
        setActivated(false);
        if (response.data.result === 'removed') {
          setInitialValue('standard_user');
          setSelected('standard_user');
          const index = props.adminEmails.indexOf(props.member.email);
          if (index > -1) {
            props.adminEmails.splice(index, 1);
          }
        } else if (response.data.result === 'added') {
          setInitialValue('group_admin');
          setSelected('group_admin');
          props.adminEmails.push(props.member.email);
        }
      }
    });
  };

  function formatRole(role) {
    if (role === 'group_admin') {
      return 'Group Admin';
    } else if (role === 'standard_user') {
      return 'User';
    }
  }

  const selectInput = props => (
    <React.Fragment>
      {error ? (
        <div className='error'>
          <i className='icon-caution' data-testid='icon-caution' /> Error
        </div>
      ) : (
        <React.Fragment>
          <QSelect
            id='change-role'
            className={`show-on-hover ${activated ? 'activated' : ''}`}
            onChange={e => handleChange(e)}
            value={selected}
            inputProps={{ 'data-testid': 'select-input' }}
            menu={[
              ['standard_user', 'User'],
              ['group_admin', 'Group Admin']
            ]}></QSelect>
          {activated && (
            <div className='circle-buttons'>
              <i className='icon-circle-check' data-testid='icon-circle-check' onClick={changePermissions} />
              <i className='icon-circle-ex' data-testid='icon-circle-ex' onClick={cancel} />
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return (
    <div id='ChangeRoleForm'>
      {processing ? (
        <LoadingInline size='sm' />
      ) : (
        <React.Fragment>
          {!isTabletOrMobile ? (
            <React.Fragment>
              <span className={`static ${activated ? 'hidden' : ''}`}>{formatRole(initialValue)}</span>
              {selectInput(props)}
            </React.Fragment>
          ) : (
            <React.Fragment>{selectInput(props)}</React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ChangeRoleForm.propTypes = {
  member: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  adminGroup: PropTypes.object.isRequired,
  adminEmails: PropTypes.array.isRequired
};

export default ChangeRoleForm;
