import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Import hooks & hoc
import { validatePagination, getPaginationSets, formatCurrency } from '../../hoc/CommonFunctions';
import { scrollToTop } from '../../hoc/ScrollFunctions';
import { useUserData } from '../../hoc/UserData';

// Import components
import { LoadingBlock } from '../loading/Loading';
import { QTip } from '../tooltip/Tooltip';
import Pagination, { PaginationSummary } from '../pagination/Pagination';

export function checkMathSign(number) {
  let num = Math.sign(number);
  if (num === -1) {
    return 'negative';
  }
}

const CreditTransactions = props => {
  const { user, setUpdatingBalance, setOverlayLevel1 } = useUserData();
  const [dataStart, setDataStart] = useState(0);
  const [dataEnd, setDataEnd] = useState(props.setLength - 1);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  // Update pagination page
  const [isUpdating, setIsUpdating] = useState(null);

  const history = useHistory();
  const admin = eval(user.role === 'qemist_admin');

  useEffect(() => {
    const url = window.location.hash;
    const valid_routes = ['credits#'];

    validatePagination(url, valid_routes, post_hash_value => {
      // Redirect page to #1 if post_hash_value is invalid
      if (post_hash_value === 'invalid') {
        history.push('#1');
      }
      // Update data if post_hash_value is valid
      else {
        const current_page = parseInt(post_hash_value, 10);
        setUpdatingBalance(true);

        getPaginationSets(props.creditsTotal, props.setLength, num => {
          // Push 1 as page number if pagination num in url is less than 0, or exceeds max number of pages for data set
          if (current_page < 1 || current_page > num) {
            history.push('#1');
          }

          // Set up pagination based on data set
          setCurrentPage(current_page);
          props.setOffset(current_page * props.setLength - props.setLength);
          setDataStart(current_page * props.setLength - props.setLength);
          setDataEnd(current_page * props.setLength - 1);
          setPages(num);
          scrollToTop();
        });

        setIsUpdating(false);
      }
    });

    return () => {};
  }, [props.credits, props.creditsTotal, window.location.href]);

  function formatTransactionsDate(unixtimestamp) {
    let milliseconds = unixtimestamp * 1000;
    let timeObj = new Date(milliseconds);
    let formattedDate = new Intl.DateTimeFormat([], { dateStyle: 'short', timeStyle: 'long' }).format(timeObj);
    return formattedDate;
  }

  function selectProblem(problem_handle) {
    const obj = {
      content_type: 'problem',
      problem_handle: problem_handle,
    };
    setOverlayLevel1(obj);
  }

  function getCreditPools(credit_pools) {
    let pools_list = Array.from(credit_pools).map((pool, index) => (
      <div key={index}>
        <span onClick={() => props.showCreditPoolDetails(pool.credit_pool_id)} className='link mono'>
          {pool.credit_pool_id}
        </span>
        {credit_pools.length > 1 && credit_pools.length !== index + 1 ? ', ' : ''}
      </div>
    ));
    return pools_list;
  }

  function showList(credits) {
    if (credits === null || credits.length === 0) {
      return (
        <div className='row info empty'>
          <div className='col-12 bold'>No credit transactions to be displayed.</div>
        </div>
      );
    } else if (credits !== null || credits !== undefined) {
      return Array.from(credits).map((credit, index) => (
        <div key={index} className='credits-row-container' data-testid='credits-row'>
          <div className='row list item d-flex align-items-start'>
            {/* Transaction Date & Authorized By & Credit Pool ID */}
            <div className='col-12 col-sm-4 col-lg-4 d-flex flex-wrap py-2'>
              <div className='d-flex w-100 align-items-center'>
                <QTip
                  content={credit.transaction_id}
                  trigger='mouseenter click'
                  duration={100}
                  placement='bottom-start'
                  followCursor={false}
                  hideOnClick={true}
                  offset={[0, 0]}
                  interactive={true}>
                  <i data-testid='id-icon' className='icon-id mr-1' />
                </QTip>
                <input
                  className='text-xs flex-grow-1'
                  value={formatTransactionsDate(credit.transaction_time)}
                  data-testid='transaction-date-input'
                  readOnly
                />
              </div>
              {admin || checkMathSign(credit.amount) === 'negative' ? (
                <input
                  className='text-xs'
                  value={credit.authorized_by_email}
                  data-testid='authorized-by-input'
                  readOnly
                />
              ) : (
                ''
              )}
              {credit.credit_pools.length > 0 ? (
                <div className='d-flex flex-wrap align-items-center pl-1'>
                  <span className='p-text-light mr-1'>Credit Pool ID:</span>
                  {getCreditPools(credit.credit_pools)}
                </div>
              ) : (
                ''
              )}
            </div>
            {/* Amount */}
            <div className={`col-12 col-sm-4 ${admin ? 'col-lg-2' : 'col-lg-3'} d-flex flex-wrap py-2`}>
              <input
                className={`bold ${checkMathSign(credit.amount) === 'negative' ? 'p-text' : 'good'} pl-0`}
                value={formatCurrency(credit.amount)}
                data-testid='amount-input'
                readOnly
              />
            </div>
            {/* Problem Handle */}
            <div className='col-12 col-sm-4 col-lg-3 py-2'>
              {credit.problem_handle !== null && credit.problem_handle !== 'null' && (
                <span className='problem-handle'>
                  <span className='link' onClick={() => selectProblem(credit.problem_handle)}>
                    {credit.problem_handle}
                  </span>
                </span>
              )}
            </div>
            {/* Balance */}
            <div className='col-12 col-sm-4 col-lg-2 py-2'>{formatCurrency(credit.balance)}</div>
            {/* Notes */}
            {admin && (
              <div className='col-1 py-2'>
                {credit.notes && (
                  <QTip
                    content={credit.notes}
                    trigger='mouseenter click'
                    duration={100}
                    placement='top-end'
                    followCursor={false}
                    hideOnClick={true}
                    offset={[0, 0]}
                    interactive={false}>
                    <i data-testid='notes-icon' className='icon-documentation' />
                  </QTip>
                )}
              </div>
            )}
          </div>
        </div>
      ));
    }
  }

  function updatePage(page) {
    setCurrentPage(page);
    history.push('#' + page);
    props.setOffset(page * props.setLength - props.setLength);
    props.fetchCredits(page * props.setLength - props.setLength);
  }

  return (
    <React.Fragment>
      <div id='CreditTransactionsComponent'>
        {/* Show credits summary if at least 1 transaction */}
        {props.credits.length > 0 && (
          <div className='d-flex flex-wrap data-sets-summary'>
            <Pagination
              pages={pages}
              currentPage={currentPage}
              updatePage={value => updatePage(value)}
              updating={value => setIsUpdating(value)}
            />
            <PaginationSummary
              dataTotal={props.creditsTotal}
              dataStart={dataStart}
              dataEnd={dataEnd}
              dataType={'transactions'}
            />
          </div>
        )}
        <div className='transactions-wrapper'>
          {/* Transactions header row */}
          {props.credits.length !== 0 ? (
            <React.Fragment>
              {/* Transactions Header */}
              <div className='row list header'>
                <div className='col-12 col-sm-4 col-lg-4'>Transaction</div>
                <div className={`col-12 col-sm-4 ${admin ? 'col-lg-2' : 'col-lg-3'} `}>Amount</div>
                <div className='col-12 col-sm-4 col-lg-3'>Problem Handle</div>
                <div className='col-12 col-sm-4 col-lg-2'>Balance</div>
                {/* if admin */}
                {admin && <div className='col-1'>Notes</div>}
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
          {/* Loading state */}
          {props.creditsLoading ? (
            <div className='credits-row-container'>
              <div className='row list item spinner'>
                <div className='col-12'>
                  <LoadingBlock />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {/* Show credits set */}
          {isUpdating ? (
            <LoadingBlock />
          ) : (
            <React.Fragment>
              {showList(props.credits)}
              {/* Show text on last pagination page */}
              {dataEnd === props.creditsTotal && (
                <div className='row ending'>
                  <div className='col-12'>End of transactions</div>
                </div>
              )}
              {/* Show pagination if at least 1 transaction */}
              {props.credits.length > 0 && (
                <div className='mt-3 data-sets-summary'>
                  <Pagination
                    pages={pages}
                    currentPage={currentPage}
                    updatePage={value => updatePage(value)}
                    updating={value => setIsUpdating(value)}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

CreditTransactions.propTypes = {
  credits: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  creditsLoading: PropTypes.bool,
  creditsTotal: PropTypes.number.isRequired,
  rangeStart: PropTypes.number,
  rangeEnd: PropTypes.number,
  setLength: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
  fetchCredits: PropTypes.func.isRequired,
  showCreditPoolDetails: PropTypes.func,
};

export default CreditTransactions;
