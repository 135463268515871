import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { browserStore } from '../hoc/BrowserStorage';
import { useUserData } from '../hoc/UserData';

export const AdminTopNav = props => {
  const { setAdminPath } = useUserData();

  function updateAdminPath(link) {
    browserStore('set', 'adminPath', link, 'session');
    setAdminPath(link);
  }

  return (
    <React.Fragment>
      <div id='AdminTopNav' className='container-fluid'>
        <div className='row'>
          <div className='col-12 links'>
            <Link
              to='/admin/status'
              onClick={() => updateAdminPath('/admin/status')}
              className={`${window.location.href.includes('status') ? 'active' : ''}`}>
              <span>Status</span>
            </Link>
            <Link
              to='/admin/groups'
              onClick={() => updateAdminPath('/admin/groups')}
              className={`${window.location.href.includes('group') ? 'active' : ''}`}>
              <span>Organizations</span>
            </Link>
            <Link
              to='/admin/users'
              onClick={() => updateAdminPath('/admin/users')}
              className={`${window.location.href.includes('user') ? 'active' : ''}`}>
              <span>Users</span>
            </Link>
            <Link
              to='/admin/credits-overview'
              onClick={() => updateAdminPath('/admin/credits-overview')}
              className={`${window.location.href.includes('credits-overview') ? 'active' : ''}`}>
              <span>Credits Overview</span>
            </Link>
            <Link
              to='/admin/metrics'
              onClick={() => updateAdminPath('/admin/metrics')}
              className={`${window.location.href.includes('metrics') ? 'active' : ''}`}>
              <span>Metrics</span>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminTopNav;
