import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { getOrgName } from '../../../hoc/CommonFunctions';
import { browserStore } from '../../../hoc/BrowserStorage';
import { useUserData } from '../../../hoc/UserData';
import { LoadingInline } from '../../../components/loading/Loading';

const ViewGroupTopNav = props => {
  const { setAdminPath } = useUserData();

  function updateAdminPath(link) {
    browserStore('set', 'adminPath', link, 'session');
    setAdminPath(link);
  }

  return (
    <div id='ViewGroupTopNav' className='container-fluid'>
      <div className='row'>
        <div className='col-12 col-md-6 left-col'>
          <Link to='/admin/groups' onClick={() => updateAdminPath('/admin/groups')}>
            <i className='icon-prev' />
            {!props.isLoaded ? <LoadingInline size='sm' /> : <h2 className='text-lg'>{getOrgName(props.group)}</h2>}
          </Link>
        </div>
        <div className='col-12 col-md-6 right-col'>
          <div className='links'>
            <Link
              to={`/admin/group/${props.group_id}/credits`}
              onClick={() => updateAdminPath(`/admin/group/${props.group_id}/credits`)}
              className={`${window.location.href.includes('/credits') ? 'active' : ''}`}>
              <span className='text'>Credits</span>
            </Link>
            <Link
              to={`/admin/group/${props.group_id}/members`}
              onClick={() => updateAdminPath(`/admin/group/${props.group_id}/members`)}
              className={`${window.location.href.includes('/members') ? 'active' : ''}`}>
              <span className='text'>Members</span>
            </Link>
            <Link
              to={`/admin/group/${props.group_id}/view-projects`}
              onClick={() => updateAdminPath(`/admin/group/${props.group_id}/view-projects`)}
              className={`${window.location.href.includes('/view-projects') ? 'active' : ''}`}>
              <span className='text'>Projects</span>
            </Link>
            <Link
              to={`/admin/group/${props.group_id}/view-request-log`}
              onClick={() => updateAdminPath(`/admin/group/${props.group_id}/view-request-log`)}
              className={`${window.location.href.includes('/view-request-log') ? 'active' : ''}`}>
              <span className='text'>Requests</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewGroupTopNav.propTypes = {
  group: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  group_id: PropTypes.string.isRequired,
};

export default ViewGroupTopNav;
