import React from 'react';
const $3Dmol = require('3dmol/build/3Dmol-nojquery.js');

// prettier-ignore
import { moleculeA, moleculeA2, moleculeB, moleculeB2, benzene, benzeneIndex, moleculeD, moleculeD2, co2, pyridine, dc, h2o, aniOutput } from './Molecules.js'
import { formatAtomString } from '../components/requestLog/visualizeMolecule';

function visualizeMolecule(molecule) {
  setTimeout(() => {
    let id = molecule.handle;
    let atomStr = formatAtomString(molecule.geometry);
    console.log(atomStr);

    let element = document.getElementById(id);
    let config = { backgroundColor: '#3169B0' };

    try {
      let viewer = $3Dmol.createViewer(element, config);
      if (viewer !== undefined) {
        // Add molecule visualization
        viewer.addModel(atomStr, 'xyz');
        viewer.setStyle(
          {},
          {
            stick: { colorscheme: 'default', hidden: false, opacity: 1 },
          }
        );
        viewer.zoomTo();
        viewer.render();
        viewer.rotate(45, { x: 1, y: 1, z: 1 });
        viewer.zoom(1.2, 1000);
      }
    } catch (err) {
      return (element.innerHTML = 'Molecule visualization unavailable.');
    }
  }, 1000);
}

const DebugVisualizeMolecule = () => {
  return (
    <React.Fragment>
      <div id='DebugVisualizeMolecule' className='container'>
        <div className='row'>
          {/* H2O */}
          <div className='molecule-group'>
            <div className='mol-label'>H2O</div>
            <div id='h2o' className='mol-container'>
              {visualizeMolecule(h2o)}
            </div>
            <div className='mol-geometry'>
              <pre>{formatAtomString(h2o.geometry)}</pre>
            </div>
          </div>
          {/* ANI Output */}
          <div className='molecule-group'>
            <div className='mol-label'>ANI Output</div>
            <div id='aniOutput' className='mol-container'>
              {visualizeMolecule(aniOutput)}
            </div>
            <div className='mol-geometry'>
              <pre>{formatAtomString(aniOutput.geometry)}</pre>
            </div>
          </div>
        </div>

        <div className='row'>
          {/* CO2 */}
          <div className='molecule-group'>
            <div className='mol-label'>CO2</div>
            <div id='co2' className='mol-container'>
              {visualizeMolecule(co2)}
            </div>
            <div className='mol-geometry'>
              <pre>{formatAtomString(co2.geometry)}</pre>
            </div>
          </div>
          {/* Pyridine */}
          <div className='molecule-group'>
            <div className='mol-label'>Pyridine</div>
            <div id='pyridine' className='mol-container'>
              {visualizeMolecule(pyridine)}
            </div>
            <div className='mol-geometry'>
              <pre>{formatAtomString(pyridine.geometry)}</pre>
            </div>
          </div>
          {/* Benzene */}
          <div className='molecule-group'>
            <div className='mol-label'>Benzene</div>
            <div id='benzene' className='mol-container'>
              {visualizeMolecule(benzene)}
            </div>
            <div className='mol-geometry'>
              <pre>{formatAtomString(benzene.geometry)}</pre>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DebugVisualizeMolecule;
