import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { evaluate } from 'mathjs';
import PropTypes from 'prop-types';

import { useAuth } from '../../hoc/Auth';
import { charactersHelper, formatCurrency } from '../../hoc/CommonFunctions';
import { mathFormat, handleInputChange, isValidAdjustment } from '../../hoc/FormFunctions';
import QFormWrapper from '../form/QFormWrapper';
import QFormGroup from '../form/QFormGroup';
import QButton from '../button/Button';
import { LoadingInline } from '../loading/Loading';
import { QSuccessMessage, QErrorMessage } from '../notifications/Notifications';

const EditCreditsModal = props => {
  // Init
  const { fetchData } = useAuth();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Add Credit Pool
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [pool, setPool] = useState(props.data);
  const [expiration, setExpiration] = useState(props.data.expiration);
  const [dateGranted, setDateGranted] = useState(props.data.date_granted);
  const [amountGranted, setAmountGranted] = useState(props.data.amount_granted);
  const [amountRemaining, setAmountRemaining] = useState(props.data.amount_remaining);
  const [adjustmentAmount, setAdjustmentAmount] = useState(0);

  const validationSchema = yup.object().shape({
    credit_pool_name: yup.string(),
    owner_name: yup.string(),
    adjustment_amount: yup
      .string()
      .test('adjustment_amount', 'Must be a valid math format', value => mathFormat.test(value)),
    notes: yup.string().max(120).nullable(),
    confirmation: yup.string().matches('CONFIRM').required('This field is required'),
  });

  useEffect(() => {
    if (props.data) {
      setPool(props.data);
    }

    return () => {
      setSuccess(null);
      setError(null);
      abortController.abort();
    };
  }, [props.data]);

  function processEditCredits(values) {
    setProcessing(true);
    let notes = '';
    if (values.notes !== null) {
      notes = values.notes + ' ';
    }

    const body = {
      credit_pool_id: props.data.credit_pool_id,
      credit_pool_name: values.credit_pool_name,
      group_or_user_id: props.data.group_or_user_id,
      owner_name: props.data.owner_name,
      date_granted: dateGranted,
      expiration: expiration,
      amount_granted: amountGranted,
      amount_remaining: amountRemaining,
      adjustment_amount: evaluate(values.adjustment_amount),
      credits_type: values.credits_type,
      notes: notes + '(Edited ' + moment().format('MMM D, YYYY') + ')',
    };

    fetchData(signal, '/api/add-credits', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setProcessing(false);
        setError(response.error);
      }
      // if data is returned
      if (response.data) {
        setProcessing(false);
        setSuccess(true);
        setTimeout(() => {
          props.toggleEditCreditsModal();
        }, 2000);
      }
    });
  }

  return (
    <div id='EditCreditsModal'>
      <div className='container-fluid'>
        <h1 className='mb-0'>Edit Credit Pool</h1>
        {processing ? (
          <LoadingInline className='my-3' />
        ) : (
          <React.Fragment>
            {/* Success Message */}
            {success ? (
              <QSuccessMessage text='Success!' className='my-4' />
            ) : (
              <React.Fragment>
                {/* Error Message */}
                {error ? (
                  <QErrorMessage className='row p0' text={<span className='bold'>{error.message}</span>} />
                ) : (
                  <React.Fragment>
                    <QFormWrapper
                      formId='EditCreditsForm'
                      initialValues={{
                        credit_pool_id: pool.credit_pool_id,
                        credit_pool_name: pool.credit_pool_name,
                        owner_name: pool.owner_name,
                        date_granted: pool.date_granted,
                        expiration: pool.expiration,
                        amount_granted: pool.amount_granted,
                        amount_remaining: pool.amount_remaining,
                        adjustment_amount: '',
                        credits_type: pool.credits_type,
                        notes: pool.notes,
                        confirmation: '',
                      }}
                      initialErrors={{
                        credit_pool_id: false,
                        credit_pool_name: false,
                        owner_name: false,
                        date_granted: false,
                        expiration: false,
                        amount_granted: false,
                        amount_remaining: false,
                        adjustment_amount: false,
                        credits_type: false,
                        notes: false,
                        confirmation: true,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={values => processEditCredits(values)}>
                      {formProps => (
                        <EditCreditsForm
                          data={pool}
                          expiration={expiration}
                          setExpiration={value => setExpiration(value)}
                          dateGranted={dateGranted}
                          setDateGranted={value => setDateGranted(value)}
                          amountGranted={amountGranted}
                          setAmountGranted={value => setAmountGranted(value)}
                          amountRemaining={amountRemaining}
                          setAmountRemaining={value => setAmountRemaining(value)}
                          {...formProps}
                        />
                      )}
                    </QFormWrapper>
                    <QButton className='secondary mt-4' onClick={props.toggleEditCreditsModal}>
                      Cancel
                    </QButton>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

EditCreditsModal.propTypes = {
  data: PropTypes.object.isRequired,
  names: PropTypes.array.isRequired,
  toggleEditCreditsModal: PropTypes.func.isRequired,
};

const EditCreditsForm = props => {
  // Init
  const [creditType, setCreditType] = useState('');
  const [amountGranted, setAmountGranted] = useState(props.amountGranted);
  const [amountRemaining, setAmountRemaining] = useState(props.amountRemaining);

  useEffect(() => {
    if (props.data) {
      setCreditType(props.data.credits_type);
    }
  }, [props.data, props.expiration, props.dateGranted]);

  function handleDateCallback(unixtimestamp, str) {
    if (str === 'expiration') {
      props.setExpiration(unixtimestamp);
    }
    if (str === 'date_granted') {
      props.setDateGranted(unixtimestamp);
    }
  }

  return (
    <React.Fragment>
      <div className='row'>
        {/* Credit Pool ID */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='credit_pool_id'
          name='credit_pool_id'
          label='Credit Pool ID (Read Only)'
          className='read-only'
          inputProps={{ readOnly: true, 'data-testid': 'credit-pool-id-input' }}
          defaultValue={props.data.credit_pool_id}
          onChange={e => handleInputChange(e, props, 'credit_pool_id', () => {})}
          includeError={false}
        />
        {/* Owner Name */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='owner_name'
          name='owner_name'
          label='Group Name / User Email (Read Only)'
          className='read-only'
          defaultValue={props.data.owner_name}
          inputProps={{ readOnly: true, 'data-testid': 'owner-name-input' }}
          onChange={e => handleInputChange(e, props, 'owner_name', () => {})}
          includeError={true}
        />
      </div>
      <div className='row'>
        {/* Credit Pool Name */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='credit_pool_name'
          name='credit_pool_name'
          label='Credit Pool Name'
          defaultValue={props.data.credit_pool_name}
          onChange={e => handleInputChange(e, props, 'credit_pool_name', () => {})}
          inputProps={{ 'data-testid': 'credit-pool-name-input' }}
          includeError={true}
        />
        {/* Date Granted */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='date_granted'
          name='date_granted'
          label='Date Granted'
          onChange={e => handleInputChange(e, props, 'date_granted', () => {})}
          inputProps={{ readOnly: true, 'data-testid': 'date-granted-input' }}
          value={moment.unix(props.dateGranted).format('MMM D, YYYY')}
          type='text'
          includeError={false}>
          <DateRangePicker
            initialSettings={{
              startDate: moment.unix(props.dateGranted),
              endDate: moment.unix(props.dateGranted),
              singleDatePicker: true,
              showDropdowns: true,
            }}
            onCallback={date => handleDateCallback(date.unix(), 'date_granted')}>
            <div className='date-filter' data-testid='datepicker'>
              <i className='icon-calendar' />
            </div>
          </DateRangePicker>
        </QFormGroup>
      </div>
      <div className='row'>
        {/* Amounts Affected */}
        <div className='col-12 col-md-6 order-2 order-md-1'>
          <div className='d-flex align-items-center'>
            <span className='text-xs p-text-light mr-2'>Original Amount Granted:</span>
            <span>{formatCurrency(props.data.amount_granted)}</span>
          </div>
          <div className='d-flex align-items-center'>
            <span className='text-xs p-text-light mr-2'>Original Amount Remaining:</span>
            <span>{formatCurrency(props.data.amount_remaining)}</span>
          </div>
          {props.data.amount_granted !== props.amountGranted && (
            <div className='d-flex align-items-center'>
              <span className='text-xs p-text-light mr-2'>Adjust Amount Granted:</span>
              <span
                className={`bold ${
                  props.amountGranted < props.data.amount_granted
                    ? 'warning'
                    : props.amountGranted > props.data.amount_granted
                    ? 'good'
                    : ''
                }`}>
                {formatCurrency(props.amountGranted)}
              </span>
            </div>
          )}
          {props.data.amount_remaining !== props.amountRemaining && (
            <div className='d-flex align-items-center'>
              <span className='text-xs p-text-light mr-2'>Amount Remaining:</span>
              <span
                className={`bold ${
                  props.amountRemaining <= 0 ? 'danger' : props.amountRemaining <= 100 ? 'warning' : 'good'
                }`}>
                {formatCurrency(props.amountRemaining)}
              </span>
            </div>
          )}
        </div>
        {/* Expiration */}
        <QFormGroup
          containerClassName='col-12 col-md-6 order-1 order-md-2'
          element='text'
          id='expiration'
          name='expiration'
          label='Expiration'
          onChange={e => handleInputChange(e, props, 'expiration', () => {})}
          inputProps={{ readOnly: true, 'data-testid': 'expiration-input' }}
          value={moment.unix(props.expiration).format('MMM D, YYYY')}
          type='text'
          includeError={false}>
          <DateRangePicker
            initialSettings={{
              startDate: moment.unix(props.expiration),
              endDate: moment.unix(props.expiration),
              singleDatePicker: true,
              showDropdowns: true,
            }}
            onCallback={date => handleDateCallback(date.unix(), 'expiration')}>
            <div className='date-filter' data-testid='datepicker'>
              <i className='icon-calendar' />
            </div>
          </DateRangePicker>
        </QFormGroup>
      </div>
      <div className='row'>
        {/* Adjustment Amount */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='adjustment_amount'
          name='adjustment_amount'
          label='Adjustment Amount'
          defaultValue='0'
          onChange={e =>
            handleInputChange(e, props, 'adjustment_amount', e => {
              isValidAdjustment(e.target.value, adjustment_amount => {
                props.setAmountGranted(amountGranted + adjustment_amount);
                props.setAmountRemaining(amountRemaining + adjustment_amount);
              });
            })
          }
          inputProps={{ 'data-testid': 'adjustment-amount-input' }}
          includeError={true}
        />
        {/* Credit Type */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='select'
          id='credits_type'
          name='credits_type'
          label='Credits Type'
          inputProps={{ name: 'credits_type', 'data-testid': 'credits-type-select' }}
          onChange={e =>
            handleInputChange(e, props, 'credits_type', () => {
              setCreditType(e.target.value);
            })
          }
          value={creditType}
          selectMenu={[
            ['demo', 'Demo'],
            ['promo', 'Promo'],
            ['paid', 'Paid'],
          ]}
          includeError={false}
        />
      </div>
      <div className='row'>
        {/* Notes */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='textarea'
          id='notesInput'
          name='notes'
          label='Notes'
          rowsMax={4}
          inputProps={{ 'data-testid': 'notes-input' }}
          defaultValue={props.data.notes}
          helperText={charactersHelper('notesInput', 120)}
          onChange={e => handleInputChange(e, props, 'notes', () => {})}
          includeError={true}
        />
        {/* Confirmation */}
        <QFormGroup
          containerClassName='col-12 col-md-6'
          element='text'
          id='confirmation'
          name='confirmation'
          label='Type CONFIRM'
          onChange={e => handleInputChange(e, props, 'confirmation', () => {})}
          inputProps={{ 'data-testid': 'confirmation-input' }}
          includeError={true}
        />
      </div>
      <QButton type='submit' className={`mt-3 ${props.isValid ? '' : 'disabled'}`}>
        Edit Credit Pool
      </QButton>
    </React.Fragment>
  );
};

EditCreditsForm.propTypes = {
  data: PropTypes.object.isRequired,
  expiration: PropTypes.number.isRequired,
  setExpiration: PropTypes.func.isRequired,
  dateGranted: PropTypes.number.isRequired,
  setDateGranted: PropTypes.func.isRequired,
  amountGranted: PropTypes.number.isRequired,
  setAmountGranted: PropTypes.func.isRequired,
  amountRemaining: PropTypes.number.isRequired,
  setAmountRemaining: PropTypes.func.isRequired,
};

export default EditCreditsModal;
