import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';

// Import hooks & hoc
import { useAuth } from '../../../hoc/Auth';
import { handleInputChange, isList, isInt, isFloat } from '../../../hoc/FormFunctions';
import { scrollToTop } from '../../../hoc/ScrollFunctions';
import { useUserData } from '../../../hoc/UserData';

// Import data models
import { ErrorObject, ResponseObject } from '../../../models/data';
import { FormRefObject, FormPropsObject } from '../../../models/forms';
import { MoldataObject, MolFilesObject, MolFileTypes, MolSystemTypes } from '../../../models/molecules';
import { SolverObject } from '../../../models/solvers';

// Import functions and constants
import { defaultXYZ } from '../constants/Molecules';
import { solversFileMap } from '../constants/SubmitConst';
import { constructFileLabel, showDescription } from '../functions/FileUploadFunctions';
import { getAllowedMolValue, getAllowedSystemTypes } from '../functions/SubmitFunctions';

// Import components
import LoadingFilled, { LoadingInline } from '../../../components/loading/Loading';
import { QRadioGroup, QRadio, QSelect } from '../../../components/form/FormElements';
import QFormWrapper from '../../../components/form/QFormWrapper';
import QFormGroup from '../../../components/form/QFormGroup';
import InlineFormGroup from '../../../components/form/InlineFormGroup';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import QButton from '../../../components/button/Button';
import QDropdown, { Toggle, Menu, Item } from '../../../components/dropdown/Dropdown';
import { visualizeSmileStr } from '../../../components/requestLog/visualizeMolecule';
import UploadComponent from '../../../components/upload/Upload';
import { SubmitInputGroup, ResetDropdown } from '../components/SubmitElements';

interface Props {
  className?: string;
  solverPipeline: SolverObject[];
  moldata: MoldataObject;
  molFiles: MolFilesObject;
  setMolFiles: Function;
  handleMolParamChange: Function;
  resetMolAtom: Function;
  resetMolParams: () => void;
  molError?: { message: string };
  setMolError: (arg: { message: string } | null) => void;
  molWarning?: string;
  setMolWarning: (arg: string | null) => void;
  prev: () => void;
  next: () => void;
}

const SubmitMolecule = ({
  className,
  solverPipeline,
  moldata,
  molFiles,
  setMolFiles,
  handleMolParamChange,
  resetMolAtom,
  resetMolParams,
  molError,
  setMolError,
  molWarning,
  setMolWarning,
  prev,
  next,
}: Props) => {
  // Init
  const { fetchData }: any = useAuth();
  const { solverConstants }: any = useUserData();
  const abortController = new AbortController();
  const signal = abortController.signal;
  const timers = useRef<any[]>([]);
  const [updateParams, setUpdateParams] = useState<string[] | null>(null);
  const [molParams, setMolParams] = useState<MoldataObject | null>(null);
  const [cartOptions, setCartOptions] = useState<any[]>([
    ['True', 'True'],
    ['False', 'False'],
  ]);
  // Files
  const [filesType, setFilesType] = useState<'combo' | 'distinct' | null>(null);
  const [fileUploadError, setFileUploadError] = useState<ErrorObject | null>(null);
  // Validate Molecule
  const [validating, setValidating] = useState<boolean>(false);
  const [molValid, setMolValid] = useState<boolean | null>(null);
  // Solver constraints
  const [allowedSystemTypes, setAllowedSystemTypes] = useState<MolSystemTypes[]>([]);
  const [solverName, setSolverName] = useState<string>('');
  const invalidSmilesMessage = 'Invalid SMILES string.';
  // Refs for form validation
  const [molParamFormLoaded, setMolParamFormLoaded] = useState<boolean>(false);
  const molAtomRef = useRef<FormRefObject | null>(null);
  const molParamRef = useRef<FormRefObject | null>(null);

  function triggerSmilesVisualization(mol_params: MoldataObject | { atom: string } | null = molParams) {
    if (mol_params !== null) {
      // Delay to allow HTML element to render in the DOM
      const timer = setTimeout(() => {
        visualizeSmileStr(mol_params.atom, 'smiles-container', (response?: string) => {
          if (response === 'error') {
            setMolError({ message: invalidSmilesMessage });
          }
        });
      }, 200);
      timers.current.push(timer);
    }
  }

  function updateInputOptions(solver_name: string) {
    // Cartesian
    const allowed_cart = getAllowedMolValue(solverConstants, solver_name, 'allowed_cart');
    if (allowed_cart === false) {
      setCartOptions([['False', 'False']]);
    } else if (allowed_cart === true) {
      setCartOptions([['True', 'True']]);
    } else if (allowed_cart !== 'not_found') {
      setCartOptions([
        ['True', 'True'],
        ['False', 'False'],
      ]);
    }
  }

  useEffect(() => {
    // Get allowed system type constraints
    const { allowed_system_type, solver_name } = getAllowedSystemTypes(solverConstants, solverPipeline);
    setAllowedSystemTypes(allowed_system_type);
    setSolverName(solver_name);

    // Update input options
    updateInputOptions(solver_name);

    // Convert frozen orbitals to string
    convertFOToString(moldata);

    // Visualize smiles string on first render if atom_input_type is smiles
    if (moldata && moldata.atom_input_type === 'smiles') {
      triggerSmilesVisualization({ atom: moldata.atom });
    }
    // Show file upload components
    if (moldata && moldata.atom_input_type === 'file-upload') {
      setFilesType('distinct');
    }
  }, []);

  useEffect(() => {
    // Handle mol param reset updates
    if (updateParams) {
      let data = structuredClone(molParams);

      if (data !== null) {
        // Update atom inputs only
        if (updateParams.includes('atom')) {
          ['atom', 'atom_input_type'].forEach(param => {
            data[param] = moldata[param];
          });

          // Trigger smiles string visualization when atom is updated
          if (data.atom_input_type === 'smiles') {
            triggerSmilesVisualization({ atom: moldata.atom });
          }
        }
        // Update other molecule inputs
        else {
          updateParams.forEach(param => {
            data[param] = moldata[param];
          });
        }

        // Change frozen_orbital param to string
        // so it may be read by form input field
        convertFOToString(data);
      }
    }
  }, [updateParams]);

  useEffect(() => {
    // Trigger form validations after MoleculeParamsForm element is loaded
    if (molParamRef && molParamRef.current !== null) {
      const fields = {
        charge: true,
        spin: true,
        frozen_orbitals: true,
      };
      molParamRef.current.setTouched(fields);
    }

    if (molAtomRef && molAtomRef.current !== null) {
      const atom_fields = { atom: true };
      molAtomRef.current.setTouched(atom_fields);
    }
  }, [molParamFormLoaded]);

  useEffect(() => {
    // Component clean up
    return () => {
      setMolError(null);
      abortController.abort();
      for (var i = 0; i < timers.current.length; i++) {
        clearTimeout(timers.current[i]);
      }
    };
  }, []);

  function handlePageLoad() {
    /*
    This function should be called at the end of the html elements
    load, as it looks for a specific element in the DOM and triggers
    a component state change
    */
    if (!molParamFormLoaded) {
      // Delay to allow html elements to load
      const timer = setTimeout(() => {
        const el = document.getElementById('MoleculeParamsForm');
        if (el !== undefined && el !== null) {
          setMolParamFormLoaded(true);
        }
      }, 1000);
      timers.current.push(timer);
    }
  }

  function validateFrozenOrbital(val) {
    if (val === 'None') {
      return true;
    } else if (isList.test(val)) {
      try {
        JSON.parse(val);
        return true;
      } catch (e) {
        return false;
      }
    } else if (isInt.test(val)) {
      return true;
    } else if (isFloat.test(val)) {
      try {
        // Accept 1.0 float format
        const num = JSON.parse(val);
        if (isInt.test(num)) {
          return true;
        } else return false;
      } catch (e) {
        return false;
      }
    }
  }

  function validateFrozenOrbitalList(val) {
    if (isList.test(val)) {
      try {
        const arr = JSON.parse(val);
        if (Array.isArray(arr)) {
          if (arr.some(item => !isInt.test(item))) {
            return false;
          } else {
            return true;
          }
        }
      } catch (e) {
        return false;
      }
    } else return true;
  }

  const atomSchema = yup.object().shape({
    atom: yup.string().required('This is a required field'),
  });

  const molParamsSchema = yup.object().shape({
    charge: yup.number('Must be a number').required('This is a required field').integer('Must be an integer'),
    spin: yup
      .number('Must be a number')
      .required('This is a required field')
      .min(0, 'Must be a positive number')
      .integer('Must be an integer'),
    frozen_orbitals: yup
      .string()
      .test('frozen_orbitals', 'Frozen orbitals must be either an integer or a list of integers', (value: string) =>
        validateFrozenOrbital(value)
      )
      .test('frozen_orbitals_list', 'List must only contain integers', (value: string) =>
        validateFrozenOrbitalList(value)
      ),
  });

  function convertFOToString(moldata: MoldataObject) {
    let mol_data = structuredClone(moldata);
    if (typeof mol_data.frozen_orbitals !== 'string') {
      mol_data.frozen_orbitals = JSON.stringify(mol_data.frozen_orbitals);
    }
    setMolParams(mol_data);
    // Clear update component state params
    setUpdateParams(null);
  }

  function handleMolChange(e: React.ChangeEvent<HTMLInputElement>, param: string) {
    /*
    Cannot check for formProps errors in this function
    as this function fires before the error is produced
    */
    const val = e.target.value;
    let mol_params = structuredClone(molParams);
    if (mol_params !== null) {
      mol_params[param] = val;

      // Reset props.molError and props.molWarning
      setMolError(null);
      setMolWarning(null);

      // Update atom params if changing atom input type
      if (param === 'atom_input_type') {
        setMolError(null);
        if (val === 'xyz') {
          mol_params.atom = defaultXYZ;
        }
        // visualize SMILES string
        if (val === 'smiles') {
          triggerSmilesVisualization();
        }
        // Handle file upload selection
        if (val === 'file-upload') {
          mol_params.atom = '';
          setFilesType('distinct');
        } else {
          setFilesType(null);
        }
      }

      // Trigger smiles string visualization
      if (param === 'atom' && mol_params['atom_input_type'] === 'smiles') {
        setMolError(null);
        triggerSmilesVisualization({ atom: val });
      }

      setMolParams(mol_params);

      // Set moldata changes in parent component
      handleMolParamChange([[param, val]]);
    }
  }

  function validateMolecule(atom: string) {
    /*
    This function checks whether atom input is valid and 
    display the results to the user.
    */
    setMolValid(null);
    fetchData(signal, '/api/validate-molecule', { molecule: atom }, 'POST', (response: ResponseObject) => {
      setValidating(false);
      // if error is returned
      if (response.status === 'error' && response.error) {
        setMolError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setMolValid(true);
        const timer = setTimeout(() => setMolValid(null), 2700);
        timers.current.push(timer);
      }
    });
  }

  function validateAtomChanges() {
    /*
    Handle validating the atom input depending on the atom_input_type
    */
    setMolError(null);
    setValidating(true);

    // Hide errors
    setMolWarning(null);

    if (molParams !== null) {
      // Validate smiles string
      if (molParams.atom_input_type === 'smiles') {
        const dest = document.getElementById('drawing');
        if (dest !== null && dest.innerHTML !== null) {
          setMolValid(true);
        } else {
          setMolError({ message: invalidSmilesMessage });
        }
        setValidating(false);
      }
      // Otherwise validate xyz input
      else {
        validateMolecule(molParams.atom);
      }
    }
  }

  function handleFileUploadError(value: ErrorObject | null) {
    // Show error
    setFileUploadError(value);
    scrollToTop();
  }

  function handleFileUpload(files: File[], type: MolFileTypes) {
    // Update mol files structure
    let mol_files = structuredClone(molFiles);
    mol_files[type] = files;
    // Update parent molFiles state
    setMolFiles(mol_files);
  }

  return (
    <React.Fragment>
      {molParams ? (
        <div id='SubmitMolecule' className={className}>
          <SubmitInputGroup>
            <QFormWrapper
              innerRef={molAtomRef}
              formId='MoleculeAtomForm'
              validationSchema={atomSchema}
              initialValues={{
                atom: molParams.atom,
              }}
              onSubmit={() => {}}>
              {(formProps: FormPropsObject) => {
                return (
                  <div className='row'>
                    <div className='col-12 col-md-3'>
                      {/* Choose mol input type */}
                      <QRadioGroup
                        id='SelectMolType'
                        className='dark mb-2'
                        contentClass='d-flex flex-row align-items-center'
                        name='select_mol_type'
                        label='Molecule Input Type'
                        value={molParams.atom_input_type}
                        onChange={e => handleMolChange(e, 'atom_input_type')}>
                        {molParams.atom_input_type !== 'smiles' && (
                          <React.Fragment>
                            {/* XYZ inputs */}
                            <span className='d-flex align-items-center text-xxs w-100 mt-1'>
                              Changing input type will reset molecule input to a default value.
                            </span>
                            {allowedSystemTypes.includes('xyz') && <QRadio label='XYZ string' value='xyz' />}
                          </React.Fragment>
                        )}
                        {/* SMILES input */}
                        {allowedSystemTypes.includes('smiles') && <QRadio label='SMILES string' value='smiles' />}
                        {/* Files allowed */}
                        {allowedSystemTypes.includes('file-upload') && (
                          <QRadio label='File Upload' value='file-upload' />
                        )}
                      </QRadioGroup>
                    </div>
                    <div className='col-12 col-md-9'>
                      {/* Error message & files dropdown */}
                      {filesType && (
                        <>
                          {fileUploadError ? (
                            <QErrorMessage
                              id='UploadError'
                              className='mb-3'
                              text={<span className='bold'>{fileUploadError.message}</span>}
                            />
                          ) : (
                            ''
                          )}
                          {/* Select for combo files hidden for now
                          <QSelect
                            id='file-type'
                            value={filesType}
                            onChange={e => {
                              setMolFiles(defaultMolFiles);
                              setFilesType(e.target.value);
                            }}
                            menu={[
                              ['distinct', 'Separate Host & Guest File(s)'],
                              ['combo', 'Combined File(s)'],
                            ]}
                          /> */}
                        </>
                      )}
                      {/* Upload components */}
                      {filesType === 'combo' && (
                        <div>
                          <label className='dark'>{`File containing host(s) & guest(s) (PDB or SDF)`}</label>
                          <UploadComponent
                            allowedFileTypes={['pdb', 'sdf']}
                            id='UploadCombo'
                            files={molFiles.combo || []}
                            fileType='Combined'
                            maxFiles={100}
                            setFiles={(files: File[]) => handleFileUpload(files, 'combo')}
                            setFileError={(value: ErrorObject | null) => handleFileUploadError(value)}
                          />
                        </div>
                      )}
                      {filesType === 'distinct' && (
                        <>
                          <div className='mb-2'>
                            <label className='dark'>{constructFileLabel(solverName, 'host')}</label>
                            {showDescription(solverName, 'host')}
                            <UploadComponent
                              allowedFileTypes={solversFileMap[solverName].host.allowedFileTypes}
                              id='UploadHosts'
                              files={molFiles.host || []}
                              fileType={solversFileMap[solverName].host.label}
                              maxFiles={solversFileMap[solverName].host.numFilesAllowed}
                              setFiles={(files: File[]) => handleFileUpload(files, 'host')}
                              setFileError={(value: ErrorObject | null) => handleFileUploadError(value)}
                            />
                          </div>
                          <div className='mb-3'>
                            <label className='dark'>{constructFileLabel(solverName, 'guest')}</label>
                            {showDescription(solverName, 'guest')}
                            <UploadComponent
                              allowedFileTypes={solversFileMap[solverName].guest.allowedFileTypes}
                              id='UploadGuests'
                              files={molFiles.guest || []}
                              fileType={solversFileMap[solverName].guest.label}
                              maxFiles={solversFileMap[solverName].guest.numFilesAllowed}
                              setFiles={(files: File[]) => handleFileUpload(files, 'guest')}
                              setFileError={(value: ErrorObject | null) => handleFileUploadError(value)}
                            />
                          </div>
                        </>
                      )}
                      {/* Atom input */}
                      {molParams.atom_input_type !== 'file-upload' && (
                        <QFormGroup
                          element='textarea-resize'
                          id='molecule-input'
                          name='atom'
                          label='Molecule'
                          containerClassName='d-block w-100 atom-input-container'
                          value={molParams.atom}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e, formProps, 'atom', (e: React.ChangeEvent<HTMLInputElement>) => {
                              handleMolChange(e, 'atom');
                            })
                          }
                          className='text-sm dark mb-0'
                          includeError={true}
                        />
                      )}
                      {molParams.atom_input_type !== 'file-upload' && (
                        <p className='text-xxs mb-0'>Validate input to check for errors.</p>
                      )}
                      {/* SMILES mol visualization */}
                      {molParams.atom_input_type === 'smiles' ? <div id='smiles-container'></div> : ''}
                      {/* MolWarning */}
                      {molWarning && (
                        <div className='notification static info bold d-inline-flex p-2 mt-2'>{molWarning}</div>
                      )}
                      {/* validateMolecule() error */}
                      {molError ? <QErrorMessage className='mt-2' text={`${molError.message}`} /> : ''}
                      {/* validateMolecule() success */}
                      {molValid === true && (
                        <div className='notification static info show d-inline-flex align-items-center mt-2 py-1 px-2 text-xxs'>
                          <i className='icon-circle-check mr-1' />
                          <span>Valid</span>
                        </div>
                      )}
                      {molParams.atom_input_type !== 'file-upload' ? (
                        <>
                          {!validating ? (
                            <div className='d-flex w-100 align-items-center'>
                              <QButton
                                className='xsmall mr-4'
                                disabled={!formProps.isValid || molError !== null}
                                onClick={() => validateAtomChanges()}>
                                Validate
                              </QButton>
                              <QDropdown id='more-dropdown'>
                                <Toggle dataTestId='atom-reset-dropdown'>
                                  <i className='icon-more' />
                                </Toggle>
                                <Menu>
                                  <Item
                                    onClick={() => {
                                      formProps.resetForm();
                                      setMolError(null);
                                      setUpdateParams(['atom']);
                                      resetMolAtom();
                                    }}>
                                    Reset to Default
                                  </Item>
                                </Menu>
                              </QDropdown>
                            </div>
                          ) : (
                            <LoadingInline className='mt-2' />
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              }}
            </QFormWrapper>
          </SubmitInputGroup>
          {molParams.atom_input_type === 'xyz' ? (
            <SubmitInputGroup>
              <QFormWrapper
                innerRef={molParamRef}
                formId='MoleculeParamsForm'
                validationSchema={molParamsSchema}
                initialValues={{
                  charge: molParams.charge,
                  spin: molParams.spin,
                  frozen_orbitals: molParams.frozen_orbitals,
                }}
                initialErrors={{
                  charge: false,
                  spin: false,
                  frozen_orbitals: false,
                }}
                onSubmit={() => {}}>
                {formProps => {
                  return (
                    <div>
                      <InlineFormGroup
                        element='text'
                        id='charge-input'
                        name='charge'
                        inputProps={{ 'data-testid': 'charge-input' }}
                        label='Charge'
                        value={molParams.charge}
                        className='dark'
                        onChange={e =>
                          handleInputChange(e, formProps, 'charge', () => {
                            handleMolChange(e, 'charge');
                          })
                        }
                        includeError={true}
                      />
                      <InlineFormGroup
                        element='text'
                        id='spin-input'
                        name='spin'
                        inputProps={{ 'data-testid': 'spin-input' }}
                        label='Spin'
                        value={molParams.spin}
                        className='dark'
                        onChange={e =>
                          handleInputChange(e, formProps, 'spin', () => {
                            handleMolChange(e, 'spin');
                          })
                        }
                        includeError={true}
                      />
                      {cartOptions.length > 1 ? (
                        <InlineFormGroup
                          element='select'
                          id='cartesian-input'
                          inputProps={{ 'data-testid': 'cartesian-input' }}
                          label='Cartesian'
                          value={molParams.cart}
                          className='dark'
                          onChange={e => {
                            handleMolChange(e, 'cart');
                          }}
                          selectMenu={cartOptions}
                          includeError={false}
                        />
                      ) : (
                        ''
                      )}
                      <InlineFormGroup
                        element='select'
                        id='unit-input'
                        inputProps={{ 'data-testid': 'unit-input' }}
                        value={molParams.unit}
                        className='dark'
                        label='Unit'
                        onChange={e => {
                          handleMolChange(e, 'unit');
                        }}
                        selectMenu={[
                          ['Angstrom', 'Angstrom'],
                          ['Bohr', 'Bohr'],
                        ]}
                        includeError={false}
                      />
                      <InlineFormGroup
                        element='text'
                        id='frozen-orbitals-input'
                        name='frozen_orbitals'
                        inputProps={{ 'data-testid': 'frozen-orbitals-input' }}
                        label='Frozen Orbitals'
                        value={
                          Array.isArray(molParams.frozen_orbitals) && molParams.frozen_orbitals.length === 0
                            ? []
                            : molParams.frozen_orbitals
                        }
                        className='dark'
                        onChange={e =>
                          handleInputChange(e, formProps, 'frozen_orbitals', () => {
                            handleMolChange(e, 'frozen_orbitals');
                          })
                        }
                        includeError={true}
                      />
                      <div className='d-flex w-100 align-items-center'>
                        <ResetDropdown
                          resetChanges={() => {
                            formProps.resetForm();
                            resetMolParams();
                            setUpdateParams(['charge', 'spin', 'cart', 'unit', 'frozen_orbitals']);
                          }}
                        />
                      </div>
                      {handlePageLoad()}
                    </div>
                  );
                }}
              </QFormWrapper>
            </SubmitInputGroup>
          ) : (
            ''
          )}
          <div className='d-flex align-items-center '>
            <QButton className='small btn-link mr-2' onClick={prev}>
              Prev
            </QButton>
            <QButton className='small mr-2' onClick={next}>
              Next
            </QButton>
          </div>
        </div>
      ) : (
        <LoadingFilled />
      )}
    </React.Fragment>
  );
};

export default SubmitMolecule;
