import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

// Components
import { QButton } from '../button/Button';

// State is lifted up to higher order class intentionally
// Modal state must be accessible by other elements, such as a form onSubmit()

// Modal without built-in trigger
export const QModal = props => (
  <Modal
    show={props.show}
    onHide={props.onHide}
    backdrop={props.backdrop || 'static'}
    centered
    size={props.size} // sm, lg, xl
    id={props.id}
    dialogClassName={`q-modal-component ${props.className}`}>
    <Modal.Body>
      <div className='q-modal-body'>
        <i className='icon-close' data-testid='modal-close-x' onClick={props.onHide} />
        {props.children}
      </div>
    </Modal.Body>
  </Modal>
);

// Modal triggered by button
export const QModalBtn = props => (
  <React.Fragment>
    <QButton className={`modal-btn-text ${props.btnClassName}`} onClick={props.onHide} {...props}>
      {props.buttonText}
    </QButton>
    <QModal
      onHide={props.onHide}
      backdrop={props.backdrop}
      show={props.show}
      size={props.size}
      id={props.id}
      className={props.className}
      children={props.children}
    />
  </React.Fragment>
);

// Modal triggered by text
export const QModalText = props => (
  <React.Fragment>
    <span className={`modal-text ${props.textclassname}`} onClick={props.onHide}>
      {props.text}
    </span>
    <QModal
      onHide={props.onHide}
      backdrop={props.backdrop}
      show={props.show}
      size={props.size}
      id={props.id}
      className={props.className}
      children={props.children}
    />
  </React.Fragment>
);

QModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any.isRequired
};

QModalBtn.propTypes = {
  btnClassName: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any.isRequired
};

QModalText.propTypes = {
  textclassname: PropTypes.string,
  text: PropTypes.any.isRequired,
  onHide: PropTypes.func.isRequired,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default QModalBtn;
