import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { useUserData } from '../../../hoc/UserData';
import { paginationSetup } from '../../../hoc/ProblemsCommonFuncs';
import RequestLogComponent from '../../../components/requestLog/RequestLogComponent';

const ViewUserRequestLog = props => {
  // Init
  const history = useHistory();
  // GET Problems
  // prettier-ignore
  const { adminDateRangeStart, setAdminDateRangeStart, adminDateRangeEnd, setAdminDateRangeEnd } = useUserData();
  const [offset, setOffset] = useState(0);
  const [setLength, setSetLength] = useState(10);

  useEffect(() => {
    // Handle pagination setup
    paginationSetup(new_path => {
      history.replace(new_path);
    });

    // Set offset & fetch
    let page_num = window.location.href.split('/view-request-log#')[1];
    if (page_num !== undefined) {
      setOffset(page_num * setLength - setLength);
    }
  }, [props.user]);

  function updateHistory(value) {
    let page = window.location.hash.split('request-log')[1];
    if (page !== value) {
      history.push(value);
    }
  }

  return (
    <div id='ViewUserRequestLog' className='view-user'>
      {props.user !== undefined && props.user.user_id !== undefined ? (
        <RequestLogComponent
          setIsShown={() => function () {}}
          offset={offset}
          setOffset={value => setOffset(value)}
          setLength={setLength}
          userID={props.user.user_id}
          groupID={null}
          projectID={null}
          rangeStart={adminDateRangeStart}
          setRangeStart={value => setAdminDateRangeStart(value)}
          rangeEnd={adminDateRangeEnd}
          setRangeEnd={value => setAdminDateRangeEnd(value)}
          saveRangeName={'adminUsrRqlRanges'}
          visibilityName={'adminUsrRqlVisibility'}
          trackRequestLogError={() => function () {}}
          updateHistory={value => updateHistory(value)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

ViewUserRequestLog.propTypes = {
  user: PropTypes.object,
};

export default ViewUserRequestLog;
