import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { useAuth } from '../../hoc/Auth';
import ViewGroupTopNav from './groups/ViewGroupTopNav';
import TransitionWrapper from './components/TransitionWrapper';
import ViewGroupCredits from './groups/ViewGroupCredits';
import ViewGroupProjects from './groups/ViewGroupProjects';
import ViewGroupRequestLog from './groups/ViewGroupRequestLog';
import ViewGroupMembers from './groups/ViewGroupMembers';

const GroupDetails = props => {
  // Init
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [groupID, setGroupID] = useState('');
  const abortController = new AbortController();
  const signal = abortController.signal;
  // GET Group Details
  const [group, setGroup] = useState({});
  const [adminGroup, setAdminGroup] = useState({});
  const [error, setError] = useState(null);

  function fetchGroupDetails(group_id) {
    let url = '/api/get-group-by-id?group_id=' + group_id;
    fetchData(signal, url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setIsLoaded(true);
        setGroup(response.data);
      }
    });

    // Get Org's Admin Group
    let nested_group_url = '/api/get-nested-groups?group_id=' + group_id;
    fetchData(signal, nested_group_url, null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        response.data.forEach(group => {
          if (group.name.includes('--Admin')) {
            setAdminGroup(group);
          }
        });
      }
    });
  }

  useEffect(() => {
    let url_partial = props.location.pathname.split('group/')[1];
    let group_id = url_partial.split('/')[0];

    setIsLoaded(false);
    setGroupID(group_id);
    fetchGroupDetails(group_id);

    return () => {
      abortController.abort();
      setError(null);
    };
  }, []);

  return (
    <React.Fragment>
      <div id='GroupDetails'>
        <ViewGroupTopNav group_id={groupID} group={group} isLoaded={isLoaded} />
        <TransitionWrapper isLoaded={isLoaded} location={props.location} error={error}>
          <Route path='*/credits' render={() => <ViewGroupCredits group={group} />} />
          <Route path='*/members' render={() => <ViewGroupMembers group={group} adminGroup={adminGroup} />} />
          <Route path='*/view-projects' render={() => <ViewGroupProjects group={group} />} />
          <Route path='*/view-request-log' render={() => <ViewGroupRequestLog group={group} />} />
        </TransitionWrapper>
      </div>
    </React.Fragment>
  );
};

GroupDetails.propTypes = {
  location: PropTypes.object.isRequired
};

export default GroupDetails;
