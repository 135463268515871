import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import QTip from '../../tooltip/Tooltip';

const QuantumCircuitProblem = props => {
  function showParams(params) {
    return (
      <div className='params'>
        {params.target !== null && params.target >= 0 ? <div>Target:&nbsp;{params.target}</div> : ''}
        {params.control !== null && params.control >= 0 ? <div>Control:&nbsp;{params.control}</div> : ''}
        {params.parameter !== null && params.parameter !== '' ? <div>Parameter:&nbsp;{params.parameter}</div> : ''}
      </div>
    );
  }

  function showGates(fragment_data) {
    if (fragment_data.gates !== undefined && fragment_data.gates !== null) {
      function determineParams(params) {
        if (params !== undefined && params !== null) {
          if (params.target !== undefined && params.target !== null && params.target >= 0) {
            return true;
          } else if (params.control !== undefined && params.control !== null && params.control >= 0) {
            return true;
          } else if (params.parameter !== undefined && params.parameter !== null && params.parameter !== '') {
            return true;
          } else return false;
        }
      }

      return Array.from(fragment_data.gates).map((gate, index) => (
        <div key={index}>
          {determineParams(gate.params) ? (
            <div className={'gate has-params'}>
              <QTip
                className='solver-params-tooltip'
                content={showParams(gate.params)}
                trigger='mouseenter click'
                hideOnClick={true}
                offset={[0, 5]}
                placement='top-start'>
                <span>{gate.params.name !== undefined ? gate.params.name : 'undefined'}</span>
              </QTip>
            </div>
          ) : (
            <div className='gate'>
              <span>{gate.params.name !== undefined ? gate.params.name : 'undefined'}</span>
            </div>
          )}
        </div>
      ));
    } else return '';
  }

  return (
    <div id='QuantumCircuitProblem' className='row details'>
      <div className='col-12'>
        {/* BACKEND */}
        {props.solver_parameters !== undefined &&
          props.solver_parameters !== null &&
          props.solver_parameters.backend !== undefined && (
            <div className='row backend'>
              <div className='col-12'>
                <span className='label-sm'>Backend:&nbsp;</span>
                <span className='text-xs'>{props.solver_parameters.backend}</span>
              </div>
            </div>
          )}
        {/* SHOTS */}
        {props.solver_parameters !== undefined &&
          props.solver_parameters !== null &&
          props.solver_parameters.shots !== undefined && (
            <div className='row shots'>
              <div className='col-12'>
                <span className='label-sm'>Shots:&nbsp;</span>
                <span className='text-xs'>{props.solver_parameters.shots}</span>
              </div>
            </div>
          )}
        {/* GATES
        {props.fragment_data && (
          <div className='row gates'>
            <div className='col-12'>
              <span className='label-sm'>Gates:&nbsp;</span>
              <div className='data-block'>{showGates(JSON.parse(props.fragment_data))}</div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

QuantumCircuitProblem.propTypes = {
  solver_parameters: PropTypes.object.isRequired,
  fragment_data: PropTypes.object.isRequired
};

export default QuantumCircuitProblem;
