import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Import hooks & hoc
import { defaultProblemTotals } from '../../constants/problemDefaults';
import { validatePagination, getPaginationSets } from '../../hoc/CommonFunctions';
import { scrollToTop } from '../../hoc/ScrollFunctions';
import { useUserData } from '../../hoc/UserData';

// Import data models
import { ErrorObject } from '../../models/data';
import { ProblemObject, ProblemTotalsObject, ProblemVisibilityObject } from '../../models/problems';

// Import components
import { LoadingBlock } from '../loading/Loading';
import { QErrorMessage } from '../notifications/Notifications';
import Pagination, { PaginationSummary } from '../pagination/Pagination';

// Import local components
import ShowCompactProblems from './ShowCompactProblems';

interface Props {
  currentPage: number;
  setCurrentPage: Function;
  problems: ProblemObject[];
  problemsTotals: ProblemTotalsObject;
  totalSolveTime?: number | null;
  totalCost?: number | null;
  setLength: number;
  offset: number;
  setOffset: Function;
  isRefreshing: boolean;
  fetchProblems: Function;
  updateProblem: Function;
  visibility: ProblemVisibilityObject;
}

const Problems = ({
  currentPage,
  setCurrentPage,
  problems,
  problemsTotals,
  totalSolveTime,
  totalCost,
  setLength,
  offset,
  setOffset,
  isRefreshing,
  fetchProblems,
  updateProblem,
  visibility,
}: Props) => {
  const { setUpdatingBalance }: any = useUserData();
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [localProblems, setLocalProblems] = useState<ProblemObject[] | null>(null);
  const [totals, setTotals] = useState<ProblemTotalsObject>(defaultProblemTotals);
  const [problemStart, setProblemStart] = useState<number>(0);
  const [problemEnd, setProblemEnd] = useState<number>(9);
  const [pages, setPages] = useState<number>(1);
  // Update pagination page
  const [isUpdating, setIsUpdating] = useState<boolean>(true);
  // API error
  const [apiError, setAPIError] = useState<ErrorObject | null>(null);

  const history = useHistory();

  useEffect(() => {
    const url = window.location.hash;
    const valid_routes = ['request-log#', 'view-projects#'];
    setTotals(problemsTotals);

    if (firstRender) {
      setLocalProblems(problems);
      setFirstRender(false);
      setIsUpdating(false);
    } else if (localProblems) {
      const current_probs = Array.from(localProblems).map(prob =>
        JSON.stringify({
          handle: prob.problem_handle,
          name: prob.problem_name,
          visibility: prob.visibility,
          status: prob.problem_status,
          cost: prob.cost,
        })
      );
      const new_probs = Array.from(problems).map(problem =>
        JSON.stringify({
          handle: problem.problem_handle,
          name: problem.problem_name,
          visibility: problem.visibility,
          status: problem.problem_status,
          cost: problem.cost,
        })
      );

      const has_changes = new_probs.some((val, i) => current_probs[i] !== val);
      if (current_probs.length !== new_probs.length || has_changes) {
        setLocalProblems(problems);
      }

      setIsUpdating(false);
    }

    validatePagination(url, valid_routes, post_hash_value => {
      // Redirect page to #1 if post_hash_value is invalid
      if (post_hash_value === 'invalid') {
        history.push('#1');
      }
      // Update data if post_hash_value is valid
      else {
        const current_page = parseInt(post_hash_value, 10);
        setUpdatingBalance(true);

        getPaginationSets(problemsTotals.total, setLength, (num: number) => {
          // Push 1 as page number if pagination num in url is less than 0, or exceeds max number of pages for data set
          if (current_page < 1 || current_page > num) {
            const url_page = window.location.hash.split('/request-log#')[1];
            if (url_page != '1') {
              history.push('#1');
            }
          }

          // Set up pagination based on data set
          setCurrentPage(current_page);
          setOffset(current_page * setLength - setLength);
          setProblemStart(current_page * setLength - setLength);
          setProblemEnd(current_page * setLength - 1);
          setPages(num);
          scrollToTop();
        });
      }
    });

    return () => {
      setAPIError(null);
    };
  }, [problems, problemsTotals]);

  function updatePage(page: number) {
    const p = page.toString();
    setIsUpdating(true);
    setCurrentPage(page);
    history.push('#' + p);
    if (window.location.href.includes('#/request-log')) {
      sessionStorage.setItem('qemist.probPage', p);
    }
    setOffset(page * setLength - setLength);
    scrollToTop();
  }

  return (
    <React.Fragment>
      {currentPage !== null && (
        <div id='Problems'>
          {/* Error message */}
          {apiError && (
            <React.Fragment>
              {/* prettier-ignore */}
              <QErrorMessage
                className='api-error'
                text={<span className='bold'>{apiError.message}</span>} />
            </React.Fragment>
          )}
          {/* Show problems summary if at least 1 problem */}
          {localProblems && localProblems.length > 0 && (
            <div className='d-flex flex-wrap justify-content-space-between'>
              <div className='d-flex flex-wrap flex-xs-column flex-sm-row align-items-xs-start align-items-sm-center data-sets-summary'>
                <Pagination pages={pages} currentPage={currentPage} updatePage={value => updatePage(value)} />
                <PaginationSummary
                  dataTotal={totals.total}
                  dataStart={problemStart}
                  dataEnd={problemEnd}
                  dataType={'requests'}
                />
                {/* Total Solve Time */}
                {totalSolveTime !== 0 && (
                  <div className='d-flex align-items-center justify-content-start justify-content-lg-center mr-4 total-solve-time'>
                    <p className='mb-0 label'>Total Solve Time</p>
                    <p className='mb-0 ml-3 mono summed-data'>{totalSolveTime}</p>
                  </div>
                )}
                {/* Total Cost */}
                {totalCost !== 0 && (
                  <div className='d-flex align-items-center justify-content-start justify-content-lg-center total-cost'>
                    <p className='mb-0 label'>Total Cost</p>
                    <p className='mb-0 ml-3 mono summed-data'>${totalCost ? totalCost.toFixed(2) : ''}</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Show problems set */}
          {isUpdating || isRefreshing ? (
            <LoadingBlock />
          ) : (
            <React.Fragment>
              {localProblems && localProblems.length > 0 && (
                <p className='text-xs mt-2'>Time stamps are expressed in your local browser time zone.</p>
              )}
              <ShowCompactProblems
                problems={localProblems}
                problemsTotals={totals}
                fetchProblems={() => fetchProblems(offset, visibility)}
                updateProblem={payload => updateProblem(payload)}
                collapseArchived={visibility.hidden ? false : true}
              />
              {/* Show text on last pagination page */}
              {localProblems && localProblems.length > 0 && problemEnd === totals.total && (
                <div className='d-flex justify-content-center justify-content-lg-start text-xs end-of-problems'>
                  End of Log
                </div>
              )}
              {/* Show pagination if at least 1 problem */}
              {localProblems && localProblems.length > 0 && (
                <div className='mt-3 data-sets-summary'>
                  <Pagination pages={pages} currentPage={currentPage} updatePage={value => updatePage(value)} />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Problems;
