import React, { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { QErrorMessage } from '../components/notifications/Notifications';

const Documentation = props => {
  const [isShown, setIsShown] = useState(false);
  const [docsLink, setDocsLink] = useState('');
  const [docsLinkError, setDocsLinkError] = useState(null);

  useEffect(() => {
    setTimeout(() => setIsShown(true), 600);
    if (MIXPANEL_TOKEN) {
      mixpanel.track('Documentation Component: Mounted');
    }

    if (QEMIST_DOCS_LINK) {
      setDocsLink(QEMIST_DOCS_LINK);
    } else {
      setDocsLinkError(true);
    }

    return () => {
      setIsShown(false);
      setDocsLinkError(null);
    };
  }, []);

  return (
    <div id='Documentation' className={isShown ? 'show' : 'hide'} data-testid='documentation-view'>
      {docsLinkError ? (
        <QErrorMessage
          className='m-3'
          text={<span className='bold'>Docs link not provided in frontend environment variable.</span>}
        />
      ) : (
        <iframe
          src={docsLink}
          title='QEMIST Cloud Documentation'
          className='docs-frame'
          data-testid='docs-frame'></iframe>
      )}
    </div>
  );
};

export default Documentation;
