import React from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { handleInputChange } from '../../hoc/FormFunctions';
import QFormWrapper from '../../components/form/QFormWrapper';
import QFormGroup from '../../components/form/QFormGroup';
import QButton from '../../components/button/Button';

const RevokeToken = props => {
  const validationSchema = yup.object().shape({
    confirmation: yup.string().matches('REVOKE').required('This field is required'),
  });

  return (
    <React.Fragment>
      <h1>Revoke Token</h1>
      <p>
        Are you sure you want to revoke your API token?
        <br />
        <strong>Your existing token will be disabled immediately.</strong>
      </p>
      <QFormWrapper
        formId='RevokeTokenForm'
        initialValues={{
          confirmation: '',
        }}
        initialErrors={{
          confirmation: true,
        }}
        validationSchema={validationSchema}
        onSubmit={props.revokeToken}>
        {formProps => <RevokeTokenForm {...formProps} />}
      </QFormWrapper>
      <QButton className='secondary mt-4' onClick={props.toggleModal}>
        No, Cancel
      </QButton>
    </React.Fragment>
  );
};

const RevokeTokenForm = props => {
  return (
    <React.Fragment>
      <div className='confirmation'>
        <p className='bold'>Type REVOKE to proceed.</p>
        <div className='row'>
          <QFormGroup
            containerClassName='col-12 col-md-8'
            element='text'
            id='confirmation'
            name='confirmation'
            label='Type Revoke'
            onChange={e => handleInputChange(e, props, 'confirmation', () => {})}
            type='text'
            includeError={true}
          />
        </div>
      </div>
      <QButton type='submit' className={props.isValid ? '' : 'disabled'}>
        Yes, Revoke Token
      </QButton>
    </React.Fragment>
  );
};

RevokeToken.propTypes = {
  revokeToken: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default RevokeToken;
