import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Components
import { useAuth } from '../../../hoc/Auth';
import { useUserData } from '../../../hoc/UserData';
// prettier-ignore
import { getOrgName, formatCurrency, initRangeStart, getRangeEnd, allRangeStart } from '../../../hoc/CommonFunctions';
import { paginationSetup } from '../../../hoc/CreditsCommonFuncs';
import { QButton } from '../../../components/button/Button';
import RefreshButton from '../../../components/refreshButton/RefreshButton';
import { QModalBtn } from '../../../components/modal/Modal';
import { QErrorMessage } from '../../../components/notifications/Notifications';
import { LoadingBlock } from '../../../components/loading/Loading';
import CreditTransactions from '../../../components/credits/Credits';
import DatePicker, { ShowAllButton } from '../../../components/datepicker/DatePicker';
import GrantCreditsModal from '../components/GrantCreditsModal';

const ViewGroupCredits = props => {
  // Init
  const { fetchData, fetchCredits } = useAuth();
  const { setOverlayLevel1 } = useUserData();
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const [groupID, setGroupID] = useState('');
  const [groupName, setGroupName] = useState('');
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [timers, setTimers] = useState([]);
  // GET Credit Pools
  const [creditSummary, setCreditSummary] = useState({ credits_outstanding: null, groups_or_pools: null });
  const [creditSummaryError, setCreditSummaryError] = useState(null);
  // GET Credits
  const { adminDateRangeStart, setAdminDateRangeStart } = useUserData();
  const { adminDateRangeEnd, setAdminDateRangeEnd } = useUserData();
  const [credits, setCredits] = useState([]);
  const [creditsTotal, setCreditsTotal] = useState(0);
  const [creditsLoading, setCreditsLoading] = useState(null);
  const [fetchCreditsError, setFetchCreditsError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [setLength, setSetLength] = useState(20);
  // Refresh Credits
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  // Grant Credits
  const [grantCreditsModalOpen, setGrantCreditsModalOpen] = useState(false);

  function fetchCreditSummary(group_id) {
    const body = {
      group_or_user_id: group_id,
    };

    fetchData(signal, '/api/get-credits-summary', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setCreditSummaryError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setCreditSummary(response.data);
      }
    });
  }

  function handleCreditsCallback(response) {
    // if error is returned
    if (response.status === 'error') {
      setIsLoaded(true);
      setIsRefreshing(false);
      setCreditsLoading(false);
      setFetchCreditsError(response.error);
    }
    // if credits data is returned
    if (response.data) {
      setIsLoaded(true);
      setDataLoaded(true);
      setIsRefreshing(false);
      setCreditsLoading(false);
      setCredits(response.data.credits);
      setCreditsTotal(response.data.total);
      let timer_item = setTimeout(() => setDataLoaded(false), 2000);
      timers.push(timer_item);
    }
  }

  useEffect(() => {
    let url_partial = window.location.href.split('group/')[1];
    let group_id;
    if (url_partial) {
      group_id = url_partial.split('/')[0];
    }
    let group_name = getOrgName(props.group);

    setGroupID(group_id);
    setGroupName(group_name);

    if (sessionStorage.getItem('qemist.adminDateRangeStart') === null) {
      sessionStorage.setItem('qemist.adminDateRangeStart', initRangeStart);
      setAdminDateRangeStart(initRangeStart);
    } else {
      let stored_value = sessionStorage.getItem('qemist.adminDateRangeStart');
      let stored_value_num = parseInt(stored_value, 10);
      setAdminDateRangeStart(stored_value_num);
    }

    const range_end = getRangeEnd();
    if (sessionStorage.getItem('qemist.adminDateRangeEnd') === null) {
      sessionStorage.setItem('qemist.adminDateRangeEnd', range_end);
      setAdminDateRangeEnd(range_end);
    } else {
      let stored_value = sessionStorage.getItem('qemist.adminDateRangeEnd');
      let stored_value_num = parseInt(stored_value, 10);
      setAdminDateRangeEnd(stored_value_num);
    }

    paginationSetup(new_path => {
      history.replace(new_path);
    });

    // Set offset & fetch
    let page_num = window.location.href.split('/credits#')[1];
    let start_fetch;
    const this_offset = page_num * setLength - setLength;

    if (page_num !== undefined) {
      setOffset(this_offset);
      start_fetch = setTimeout(() => {
        fetchCreditSummary(group_id);
        fetchCredits(signal, group_id, adminDateRangeStart, adminDateRangeEnd, this_offset, setLength, response =>
          handleCreditsCallback(response)
        );
      }, 500);
    }

    return () => {
      clearTimeout(start_fetch);
      abortController.abort();
      for (var i = 0; i < timers.length; i++) {
        clearTimeout(timers[i]);
      }
    };
  }, [adminDateRangeStart, adminDateRangeEnd, creditsLoading]);

  // DateRangePicker
  function handleCallback(start, end) {
    const range_start = moment(start).unix();
    const range_end = moment(end).unix();

    setCreditsLoading(true), setAdminDateRangeStart(range_start), setAdminDateRangeEnd(range_end);
    sessionStorage.setItem('qemist.adminDateRangeStart', range_start);
    sessionStorage.setItem('qemist.adminDateRangeEnd', range_end);
  }

  function showAllTransactions() {
    const range_start = allRangeStart;
    const range_end = getRangeEnd();

    setCreditsLoading(true), setAdminDateRangeStart(range_start), setAdminDateRangeEnd(range_end);
    sessionStorage.setItem('qemist.adminDateRangeStart', range_start);
    sessionStorage.setItem('qemist.adminDateRangeEnd', range_end);
    let page = window.location.hash.split('/credits')[1];
    if (page !== '#1') {
      history.push('#1');
    }
  }

  function refreshCredits() {
    setIsRefreshing(true);
    setCreditsLoading(true);
  }

  function toggleGrantCreditsModal() {
    setGrantCreditsModalOpen(!grantCreditsModalOpen);
    setCreditsLoading(true);
  }

  return (
    <div id='ViewGroupCredits' className='view-user container-fluid'>
      {!isLoaded ? (
        <LoadingBlock />
      ) : (
        <React.Fragment>
          <div className='row'>
            <div className='col-12 content-container'>
              {/* Summary (Green Area) */}
              <div className='row py-3 mb-3 green-bg border-top'>
                <div className='col-12 d-flex w-100 flex-wrap align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    {/* Group / User ID */}
                    <div className='d-flex align-items-center mr-4'>
                      <span className='text-xs p-text-light mr-2'>Group ID:</span>
                      <span>
                        <input
                          className='text-xs group_or_user_id'
                          value={groupID}
                          data-testid='group-or-user-id-input'
                          readOnly
                        />
                      </span>
                    </div>
                    {/* Total Credits Remaining */}
                    <div className='d-flex align-items-center mr-5'>
                      <span className='text-xs p-text-light mr-2'>Balance:</span>
                      <span
                        className={`bold ${
                          creditSummary.credits_outstanding <= 0
                            ? 'danger'
                            : creditSummary.credits_outstanding < 100
                            ? 'warning'
                            : 'good'
                        }`}>
                        {formatCurrency(creditSummary.credits_outstanding)}
                      </span>
                    </div>
                  </div>
                  {/* Show Credit Pools */}
                  <div className='d-flex align-items-center'>
                    <QButton
                      className='small'
                      onClick={() => {
                        const obj = {
                          content_type: 'credit-pools',
                          credit_pool_id: groupID,
                        };
                        setOverlayLevel1(obj);
                      }}>
                      See Credit Pools
                    </QButton>
                    <QModalBtn
                      btnClassName='small ml-2 grant-credits-btn'
                      buttonText='Grant Credits'
                      onHide={() => setGrantCreditsModalOpen(!grantCreditsModalOpen)}
                      show={grantCreditsModalOpen}
                      id='GrantCreditsModal'
                      size='lg'>
                      {grantCreditsModalOpen ? (
                        <GrantCreditsModal
                          id={groupID}
                          names={[groupName]}
                          ownerName={groupName}
                          toggleGrantCreditsModal={() => toggleGrantCreditsModal()}
                        />
                      ) : (
                        ''
                      )}
                    </QModalBtn>
                  </div>
                </div>
              </div>
              {/* Date Picker */}
              <div className='row'>
                <div className='col-12 d-flex flex-wrap align-items-center justify-content-between'>
                  <div className='d-flex flex-wrap align-items-center DateRangePicker mr-2'>
                    <DatePicker
                      rangeStart={adminDateRangeStart}
                      rangeEnd={adminDateRangeEnd}
                      handleCallback={(start, end) => handleCallback(start, end)}
                    />
                    <ShowAllButton onClick={() => showAllTransactions()} />
                  </div>
                  <RefreshButton
                    className='mt-2 mt-sm-0'
                    isRefreshing={isRefreshing}
                    dataLoaded={dataLoaded}
                    refresh={() => refreshCredits()}
                  />
                </div>
              </div>
              {/* Error */}
              {creditSummaryError && (
                <QErrorMessage
                  className='row p0 my-2'
                  text={<span className='bold'>GET Credits Summary Error: {creditSummaryError.message}</span>}
                />
              )}
              {fetchCreditsError ? (
                <QErrorMessage
                  className='row p0 my-2'
                  text={<span className='bold'>GET Credits Error: {fetchCreditsError.message}</span>}
                />
              ) : (
                <React.Fragment>
                  {creditsLoading ? (
                    <LoadingBlock className='mt-3' />
                  ) : (
                    <CreditTransactions
                      credits={credits}
                      creditsTotal={creditsTotal}
                      creditsLoading={creditsLoading}
                      rangeStart={adminDateRangeStart}
                      rangeEnd={adminDateRangeStart}
                      setLength={setLength}
                      setOffset={value => setOffset(value)}
                      // prettier-ignore
                      fetchCredits={new_offset => {
                        fetchCredits(signal, groupID, adminDateRangeStart, adminDateRangeEnd, new_offset, setLength, response => handleCreditsCallback(response));
                      }}
                      showCreditPoolDetails={id => {
                        const obj = {
                          content_type: 'credit-pool-details',
                          pool_id: id,
                        };
                        setOverlayLevel1(obj);
                      }}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

ViewGroupCredits.propTypes = {
  group: PropTypes.object.isRequired,
};

export default ViewGroupCredits;
