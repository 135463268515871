export const defaultFilters = {
  order_by: 'submit_time',
  order_dir: 'DESC',
  problem_status: 'all',
  search_query: null,
} as const;

export const defaultProblemTotals = { total: 0, total_hidden: 0, total_regular: 0, total_starred: 0 };

export const defaultVisibility = { all: true, visible: false, starred: false, hidden: false };
