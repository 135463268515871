// Modules
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormLabel,
  Switch,
  Checkbox,
  RadioGroup,
  Radio,
  MenuItem,
  TextareaAutosize,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

// Autocomplete
// https://v4.mui.com/components/autocomplete/
export const QAutocomplete = props => (
  <Autocomplete
    id={props.id}
    className={`QAutocomplete ${props.className}`}
    options={props.options}
    getOptionLabel={props.getOptionLabel}
    onChange={props.onChange}
    onInputChange={props.onInputChange}
    value={props.value}
    inputValue={props.inputValue}
    disableClearable={props.disableClearable}
    renderInput={params => <TextField {...params} label={props.label} name={props.name} />}
  />
);

// Text Input
// https://material-ui.com/api/text-field/#textfield-api
export const QTextInput = props => (
  <TextField
    id={props.id} // string
    name={props.name} // string
    label={props.label} // node
    className={`inputs form-control QInputComponent ${props.className}`}
    onChange={props.onChange} // function
    onKeyDown={props.onKeyDown}
    onBlur={props.onBlur}
    type={props.type} // string
    helperText={props.helperText} // node
    placeholder={props.placeholder} // string
    inputProps={props.inputProps} // object
    autoComplete={props.autoComplete} // string
    autoFocus={props.autoFocus} // boolean
    defaultValue={props.defaultValue} // any
    disabled={props.disabled} // boolean
    fullWidth={props.fullWidth} // boolean
    required={props.required} // boolean
    value={props.value} // any
    variant={props.variant} // 'filled' | 'outlined' | 'standard' (default)
  />
);

// Text Area Input
export const QTextareaInput = props => (
  <TextField
    id={props.id} // string
    name={props.name} // string
    label={props.label} // node
    className={`inputs form-control QInputComponent ${props.className}`}
    onChange={props.onChange} // function
    onKeyDown={props.onKeyDown}
    onBlur={props.onBlur}
    type={props.type} // string
    helperText={props.helperText} // node
    placeholder={props.placeholder} // string
    inputProps={props.inputProps} // object
    autoComplete={props.autoComplete} // string
    autoFocus={props.autoFocus} // boolean
    defaultValue={props.defaultValue} // any
    disabled={props.disabled} // boolean
    fullWidth={props.fullWidth} // boolean
    multiline={true}
    required={props.required} // boolean
    rows={props.rows} // number or string
    rowsMax={props.rowsMax} // number or string
    value={props.value} // any
    variant={props.variant} // 'filled' | 'outlined' | 'standard' (default)
  />
);

export const QTextareaAutoResize = props => (
  <div className='QTextareaAutoResize'>
    <label htmlFor={props.id} className='w-100 label-sm'>
      {props.label}
    </label>
    <TextareaAutosize
      id={props.id} // string
      name={props.name} // string
      className={`inputs form-control QInputComponent ${props.className}`}
      onChange={props.onChange} // function
      type={props.type} // string
      placeholder={props.placeholder} // string
      autoComplete={props.autoComplete} // string
      autoFocus={props.autoFocus} // boolean
      defaultValue={props.defaultValue} // any
      disabled={props.disabled} // boolean
      required={props.required} // boolean
      minRows={props.rows} // number or string
      maxRows={props.rowsMax} // number or string
      value={props.value} // any
      variant={props.variant} // 'filled' | 'outlined' | 'standard' (default)
    />
  </div>
);

// Password Input
export const QPasswordInput = props => {
  const [displayAs, setDisplayAs] = useState('password');

  const togglePassword = () => {
    if (displayAs === 'password') {
      setDisplayAs('text');
    } else {
      setDisplayAs('password');
    }
  };

  return (
    <React.Fragment>
      <div className='QPasswordComponent'>
        <TextField
          id={props.id} // string
          name={props.name} // string
          label={props.label} // node
          className={`inputs form-control QInputComponent ${props.className}`}
          onChange={props.onChange} // function
          type={displayAs}
          helperText={props.helperText} // node
          placeholder={props.placeholder} // string
          inputProps={props.inputProps} // object
          autoComplete={props.autoComplete} // string
          autoFocus={props.autoFocus} // boolean
          defaultValue={props.defaultValue} // any
          disabled={props.disabled} // boolean
          fullWidth={props.fullWidth} // boolean
          required={props.required} // boolean
          value={props.value} // any
          variant={props.variant} // 'filled' | 'outlined' | 'standard' (default)
        />

        {/* Password Toggle */}
        <i
          className={` ${displayAs === 'password' ? 'icon-password-show' : 'icon-password-hide'} float-right`}
          alt='icon'
          onClick={togglePassword}
        />
      </div>
    </React.Fragment>
  );
};

// Select
export const QSelect = props => {
  let menu = Array.from(props.menu).map((item, index) => (
    <MenuItem key={index} value={item[0]}>
      {item[1]}
    </MenuItem>
  ));

  return (
    <FormControl className={`inputs form-control QInputComponent QSelectComponent ${props.className}`}>
      {props.inputLabel && <InputLabel id={props.id}>{props.inputLabel}</InputLabel>}
      <Select
        labelId={props.id}
        inputProps={props.inputProps}
        className={props.selectClasses}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}>
        {menu}
      </Select>
    </FormControl>
  );
};

// Toggle Switch
export const QSwitch = props => {
  const [enabled, setEnabled] = useState(false);

  const handleChange = () => {
    setEnabled(!enabled);
  };

  return (
    <FormControlLabel
      className={`QSwitchComponent ${props.className}`}
      label={props.label} // node
      control={
        <Switch
          id={props.id} // string
          name={props.name} // string
          checked={enabled} // boolean
          onChange={handleChange} // function
          value={enabled} // any
          disabled={props.disabled} // boolean
          inputProps={props.inputProps} // object
          required={props.required} // boolean
          color={'primary'} // material-ui blue
        />
      }
    />
  );
};

// Checkbox
export const QCheckbox = props => {
  return (
    <FormControlLabel
      className={`QCheckboxComponent ${props.className}`}
      label={props.label}
      control={
        <Checkbox
          id={props.id} // string
          checked={props.checked} // boolean
          onChange={event => props.handleChange(event.target)} // function
          value={props.value} // any
          disabled={props.disabled} // boolean
          inputProps={props.inputProps} // object
          required={props.required} // boolean
          color={'primary'} // material-ui blue
        />
      }
    />
  );
};

// Radio Group
export const QRadioGroup = props => (
  <FormControl
    id={props.id}
    component='fieldset'
    className={`QRadioGroupComponent ${props.className}`}
    error={props.error}>
    {props.label && (
      <FormLabel component='legend' className='label-sm'>
        {props.label}
      </FormLabel>
    )}
    <RadioGroup
      aria-label={props.name}
      className={props.contentClass}
      name={props.name}
      onChange={props.onChange}
      value={props.value}>
      {props.children}
    </RadioGroup>
  </FormControl>
);

QRadioGroup.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  contentClass: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

// Radio button
export const QRadio = props => {
  return (
    <FormControlLabel
      className={`QRadioComponent ${props.className}`}
      label={props.label} // node
      value={props.value} // any
      disabled={props.disabled} // boolean
      control={<Radio color={'primary'} />}
    />
  );
};

QRadio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

// Error Message
export const QFormErrorMessage = props => (
  <span className='error-text right mui'>
    <ErrorMessage name={props.name} />
  </span>
);

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.any,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  children: PropTypes.node,
};

QTextInput.propTypes = propTypes;
QTextareaInput.propTypes = propTypes;
QTextareaAutoResize.propTypes = propTypes;
QPasswordInput.propTypes = propTypes;

QAutocomplete.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  inputValue: PropTypes.string,
  label: PropTypes.string,
  disableClearable: PropTypes.bool,
};

QSelect.propTypes = {
  id: PropTypes.string.isRequired,
  inputLabel: PropTypes.string,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  selectClasses: PropTypes.string,
  value: PropTypes.any.isRequired,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  menu: PropTypes.array.isRequired,
};

QSwitch.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  required: PropTypes.bool,
};

QCheckbox.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  required: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

QFormErrorMessage.propTypes = {
  name: PropTypes.string,
};

export default QTextInput;
