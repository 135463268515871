import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

// Import hooks & hoc
import { useAuth } from '../../hoc/Auth';
import { printDocument } from '../../hoc/CommonFunctions';
import { scrollToTop } from '../../hoc/ScrollFunctions';

// Import global components
import { mock_required_terms_1, mock_required_terms_2, mock_required_terms_3 } from '../../../__mocks__/fetchTermsMock';
import { LoadingFilled } from '../../components/loading/Loading';
import QButton from '../../components/button/Button';
import { QErrorMessage } from '../../components/notifications/Notifications';

// Import local components
import { qemist_cloud_eval_tos_22_04_09 } from './agreements/qemist_cloud_eval_tos_22_04_09.js';

const Terms = props => {
  // Init
  const { fetchData } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const abortController = new AbortController();
  const signal = abortController.signal;
  // Get terms
  const [termsVisible, setTermsVisible] = useState(true);
  const [incompleteTerms, setIncompleteTerms] = useState(null);
  const [termsDisplayed, setTermsDisplayed] = useState(null);
  const [termsNum, setTermsNum] = useState(null);
  const [termsError, setTermsError] = useState(null);
  // Decline
  const [showDeclineDisclaimer, setShowDeclineDisclaimer] = useState(false);

  function updateTerms(terms_name, user_response, callback) {
    const body = {
      name: terms_name,
      user_response: user_response,
    };
    fetchData(signal, '/api/update-terms', body, 'POST', response => {
      // if error is returned
      if (response.status === 'error') {
        setTermsError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        callback(response.data);
      }
    });
  }

  function fetchTerms() {
    fetchData(signal, '/get-terms', null, 'GET', response => {
      // if error is returned
      if (response.status === 'error') {
        setIsLoaded(true);
        setTermsError(response.error);
      }
      // if data is returned
      if (response.status === 'success') {
        setIsLoaded(true);
        // Set terms to complete
        if (response.data.terms) {
          let terms_to_complete = [];
          response.data.terms.forEach(term => {
            if (response.data.required_terms.includes(term.name) && term.response !== 'accepted') {
              terms_to_complete.push(term);
            }
          });
          setIncompleteTerms(terms_to_complete);
          setTermsDisplayed(terms_to_complete[0]);
          setTermsNum(1);
          updateTerms(terms_to_complete[0]['name'], null, () => {});
        }
      }
    });
  }

  useEffect(() => {
    fetchTerms();

    // Event Tracking
    if (MIXPANEL_TOKEN) {
      mixpanel.track('Terms Component: Mounted');
    }

    return () => {
      abortController.abort();
    };
  }, []);

  function showTermsContent() {
    let content;
    let args = {
      qemist_cloud_eval_tos_22_04_09: qemist_cloud_eval_tos_22_04_09,
      mock_required_terms_1: mock_required_terms_1,
      mock_required_terms_2: mock_required_terms_2,
      mock_required_terms_3: mock_required_terms_3,
    };
    if (incompleteTerms && termsNum) {
      content = args[incompleteTerms[termsNum - 1]['name']];
    }
    if (content) {
      return (
        <div id='TermsContent'>
          <Markdown>{content}</Markdown>
        </div>
      );
    } else return '';
  }

  function acceptTerms() {
    updateTerms(termsDisplayed.name, 'accepted', _resp => {
      if (incompleteTerms.length === termsNum) {
        props.hideTerms();
      } else {
        // Scroll to top and show next incomplete terms
        scrollToTop();
        setTermsVisible(false);
        setTimeout(() => {
          setTermsDisplayed(incompleteTerms[termsNum]);
          setTermsNum(termsNum + 1);
          updateTerms(incompleteTerms[termsNum]['name'], null, () => {});
          setTermsVisible(true);
        }, 1000);
      }
    });
  }

  function declineTerms() {
    setShowDeclineDisclaimer(true);
  }

  function goBack() {
    setShowDeclineDisclaimer(false);
  }

  function confirmDecline() {
    updateTerms(termsDisplayed.name, 'declined', _resp => {
      sessionStorage.clear();
      history.push('/logout');
      props.hideTerms();
    });
  }

  return (
    <div id='Terms' className='overlay-content'>
      {!isLoaded ? (
        <LoadingFilled />
      ) : (
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-12 px-3 py-5 px-sm-5'>
              {/* Terms Content */}
              {termsNum !== null && incompleteTerms !== null && (
                <div className='text-xs p-text-light mb-3'>
                  Agreement {termsNum} of {incompleteTerms.length}
                </div>
              )}
              <div data-testid='terms-container' className={termsVisible ? 'show' : 'hide'}>
                {showTermsContent()}
                {termsError ? <QErrorMessage text={<span className='bold'>{termsError.message}</span>} /> : ''}
              </div>
              {/* Terms Buttons */}
              <div className='mt-4 pt-3 actions'>
                {!showDeclineDisclaimer ? (
                  <React.Fragment>
                    <div className='d-flex flex-fill align-items-center justify-content-between'>
                      <div>
                        <QButton
                          className='secondary d-flex align-items-center'
                          onClick={() => printDocument('TermsContent')}>
                          <i className='icon-printer mr-2' />
                          Print
                        </QButton>
                      </div>
                      <div className='d-flex align-items-center justify-content-end flex-wrap'>
                        <QButton classname='my-2' onClick={() => acceptTerms()}>
                          Accept
                        </QButton>
                        <QButton className='my-2 ml-2 tertiary' onClick={() => declineTerms()}>
                          Decline
                        </QButton>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className='d-flex flex-column align-items-end'>
                      <span className='notification static mb-3'>
                        You will not have access to QEMIST Cloud without accepting our terms and conditions. Please
                        contact support if you need help regarding these terms.
                      </span>
                      <div className='d-flex align-items-center justify-content-end'>
                        <QButton onClick={() => goBack()}>Go Back</QButton>
                        <a className='btn danger ml-2' href='/logout' onClick={() => confirmDecline()}>
                          Decline & Log Out
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Terms.propTypes = {
  hideTerms: PropTypes.func.isRequired,
};

export default Terms;
