import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

export const LoadingComponent = props => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => setVisible(true), 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <React.Fragment>
      {visible ? (
        <div
          className={`d-flex align-items-center justify-content-center loading-component ${props.className}`}
          data-testid={props.dataTestId}>
          <Spinner color='dark' className={`${props.text ? 'mr-2' : ''}`} data-testid='spinner' />
          {props.text && <span>{props.text}</span>}
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

LoadingComponent.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  text: PropTypes.string,
};

export const LoadingFilled = props => (
  <LoadingComponent
    className={`full-screen-backdrop ${props.className}`}
    text={props.text}
    dataTestId='full-screen-loading'
  />
);

LoadingFilled.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export const LoadingBlock = props => (
  <LoadingComponent className={`loading-block ${props.className}`} text={props.text} dataTestId='loading-block' />
);

LoadingBlock.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export const LoadingInline = props => (
  <div
    className={`d-flex align-items-center justify-content-start loading-component loading-inline ${props.className}`}
    data-testid='inline-loading'>
    <Spinner size={props.size} color={props.color || 'dark'} className={`${props.text ? 'mr-2' : ''}`} />
    {props.text && <span>{props.text}</span>}
  </div>
);

LoadingInline.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string, // 'sm'
  color: PropTypes.string, // 'primary', 'secondary', 'success', 'danger', 'warning' 'info', 'light', 'dark'
  text: PropTypes.string,
};

export default LoadingFilled;
